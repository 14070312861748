import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: "",
  message: "",
  singles: null,
  brands: [],
  wallet: null,
  transactions: [],
  playlists: [],
  playlist_detail: null,
  playlist: [],
  playlist_local: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    wallet: null,
    playlists: null,
    singles: null,
  },
  reducers: {
    setPlaylistsAndSingles: (state, action) => {
      state.playlists = action.payload.playlists;
      state.singles = action.payload.singles;
    },
    fetchWalletSuccess: (state, action) => {
      state.wallet = action.payload.summary;
    },
  },
});

export const { setPlaylistsAndSingles, fetchWalletSuccess } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
