import React from "react";
import UnlocksFlow from "./UnlocksFlow";
import Collection from "../components/Collection";
import { useSelector } from "react-redux";
export default function LearnTab() {
  let { unlocks, completed_sets, next_sets, completed } = useSelector(
    (state) => state.unlocks
  );
  return (
    <>
      <UnlocksFlow />
      <Collection />
    </>
  );
}
