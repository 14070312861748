import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBrand } from "../Store/Brands/Reducers";
import { openModal } from "../Store/Modal/Reducers";
import { Singles } from "../Constants/Singles";
import { isMobile } from "react-device-detect";
import BestMatch from "./BestMatch";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";

export default function SingleBox() {
  const dispatch = useDispatch();
  const [singleRows, setSingleRows] = useState([]); //array that contains the singles, split by row
  const [singleRowsMobile, setSingleRowsMobile] = useState([]); //array that contains the singles, split by row
  const rows = [...Array(Math.ceil(Singles.length / (isMobile ? 4 : 5)))]; //number of rows
  const { settings } = useSelector((state) => state.brands);
  const { user } = useSelector((state) => state.login);
  const { wallet, singles } = useSelector((state) => state.dashboard);
  const firstBuy =
    wallet && (wallet.playlists.length > 0 || wallet.brands.length > 0)
      ? false
      : true;
  const [bestMatch, setBestMatch] = useState("");

  const handleOpen = async (brand) => {
    let brandMutable = JSON.parse(JSON.stringify(brand));
    let brandData = { brand: brandMutable };
    if (firstBuy && user.in_case_of_loss === null) {
      dispatch(openModal({ modal: "investor", origin: "buyBrand" }));
    } else {
      dispatch(openModal({ modal: "buyBrand" }));
    }
    dispatch(setBrand(brandData));
  };
  useEffect(() => {
    if (document.getElementById("EmbedSocialHashtagScript")) {
      return;
    }
  }, []);

  //maps singles to empty rows
  const mapRows = (singlesMutable) =>
    rows.map(() => {
      let singleCopy = singlesMutable;
      return singleCopy.splice(0, isMobile ? 4 : 5);
    });

  const checkSingleOwned = async (single, setting, singlesMutable) => {
    single["owned"] = true;
    single["is_paused"] = setting.is_paused;
    single["is_pending"] =
      setting.frequency === "one-time" ? true : setting.is_pending;
    single["rule_amount"] = setting.rule_amount;
    single["frequency"] = setting.frequency;
    single["created_at"] = setting.created_at;
  };

  //get paused, pending, etc
  const getSinglesStatus = async (singlesMutable) => {
    if (settings) {
      const settingsMap = {};
      for (let setting of settings) {
        settingsMap[setting.symbol] = setting;
      }

      for (let single of singlesMutable) {
        const setting = settingsMap[single.symbol];
        if (setting) {
          await checkSingleOwned(single, setting);
          singlesMutable = singlesMutable.filter((item) => item !== single);
          singlesMutable.unshift(single);
        }
      }

      return singlesMutable;
    }
  };
  //get best match and on the low
  const getSinglesFromDw = async (singlesMutable) => {
    const bestMatch = singles
      ? singles.filter((item) => item.is_best_match)
      : [];
    setBestMatch(bestMatch[0]);

    if (singles) {
      for (let single of singlesMutable) {
        for (let singleDw of singles) {
          if (single.symbol === singleDw.symbol) {
            if (singleDw.in_low) {
              single["in_low"] = true;
            }
            if (singleDw.price_5y_ago) {
              single["price_5y_ago"] = singleDw.price_5y_ago;
            }
          }
        }
      }
    }
  };

  //sorts the playlists by best matched first and fill the singleRows array
  useEffect(async () => {
    let singlesMutable = JSON.parse(JSON.stringify(Singles));
    await getSinglesStatus(singlesMutable);
    await getSinglesFromDw(singlesMutable);
    const mappedRows = mapRows(singlesMutable);
    setSingleRows(mappedRows);
  }, [Singles, settings, singles]);

  useEffect(() => {
    if (isMobile) {
      let singleRowsCopy = singleRows.map((single) => [...single]);
      let splitRows = [];
      for (let row of singleRowsCopy) {
        let slotOne = row.splice(0, 2);
        let slotTwo = row.splice(0, 2);
        splitRows.push([slotOne, slotTwo]);
      }
      setSingleRowsMobile(splitRows);
    }
  }, [singles, singleRows]);

  //component if on desktop
  const onDesktop = () => {
    return (
      <>
        {singleRows.map((singles, index) => (
          <div className="single-box-resp" key={index}>
            {singles.map((single, index) => (
              <div
                className="single-small pointer"
                style={{
                  paddingTop:
                    bestMatch && single.symbol === bestMatch.symbol
                      ? "0.5rem"
                      : "2rem",
                }}
                key={index}
                onClick={() => handleOpen(single)}
              >
                {/*bestMatch && single.symbol === bestMatch.symbol ? (
                  <BestMatch />
                ) : null*/}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={single.image}
                      width="100"
                      style={{ margin: "auto" }}
                    />
                    <br />
                    {single.in_low ? (
                      <span className="neon-color txt-s">on the low</span>
                    ) : (
                      <br />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "1rem",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <span className="white txt-1 bold">
                        Owned by
                        <br /> {single.brand_name}
                      </span>
                      <span className="white txt-s-light">{single.info}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "right",
                        height: "70%",
                      }}
                    >
                      <button
                        className="btn-neon txt-1"
                        onClick={() => handleOpen(single)}
                        style={{
                          minWidth: "3rem",
                        }}
                      >
                        {getButtonText(single)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    );
  };

  //component if on mobile
  const onMobileView = () => {
    return (
      <Swiper
        pagination={true}
        modules={[Virtual, Pagination]}
        spaceBetween={60}
        slidesPerView={1}
        virtual
        className="mySwiper"
      >
        {singleRowsMobile.map((rows, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <div
              className={
                rows[1].length === 1
                  ? "single-box-resp-col-left"
                  : "single-box-resp-col"
              }
            >
              {rows.map((row, index) => (
                <div className="single-box-resp" key={index}>
                  {row.map((single, index) => (
                    <div
                      className="single-small pointer"
                      style={{
                        paddingTop: "2rem",
                        width: "20% !important",
                      }}
                      key={index}
                      onClick={() => handleOpen(single)}
                    >
                      {/*bestMatch && single.symbol === bestMatch.symbol ? (
                        <BestMatch />
                      ) : null*/}
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        onClick={() => {}}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={single.image}
                            style={{
                              margin: "auto",
                              display: "block",
                              width: "70%",
                              height: "100%",
                            }}
                          />

                          {single.in_low ? (
                            <span className="neon-color txt-s">on the low</span>
                          ) : (
                            <br />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: "1rem",
                            width: "90%",
                            margin: "auto",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <span
                              className="white txt-1 bold"
                              style={{ textAlign: "left" }}
                            >
                              {single.owned_by !== "VOO"
                                ? "Owned by"
                                : "Own 'Em"}
                              <br /> {single.brand_name}
                            </span>
                            <span className="white txt-s-light">
                              {single.info}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "right",
                              height: "70%",
                            }}
                          >
                            <button
                              id={
                                "button-" + single.brand_name.replace(/ /g, "-")
                              }
                              className="btn-neon txt-1"
                              onClick={() => handleOpen(single)}
                              style={{
                                minWidth: "3rem",
                              }}
                            >
                              {getButtonText(single)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const getButtonText = (single) => {
    if (single.is_pending && !single.is_paused) {
      return "pending";
    } else if (single.is_paused) {
      return "resume";
    } else if (single.is_paused === false && single.is_pending === false) {
      return "edit";
    } else {
      return "+$1";
    }
  };

  return (
    <div
      className="card-deck"
      style={{ height: "100%" }}
      data-testid="curated"
      id="curated"
    >
      <div
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <div className="card-wrapper collection-width">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span className={"futura-mid-1pt17 white pointer"}>
                Singles{"   "}
                <span
                  className="tab-title"
                  style={{
                    width: 90,
                    display: "inline-block",
                    backgroundColor: "#F5E814",
                    color: "black",
                    textAlign: "center",
                    fontSize: 12,
                  }}
                >
                  START WITH $1
                </span>
              </span>
            </div>
          </div>
          <div>
            {thoseCompaniesText()}
            <div
              className="collections-position"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isMobile ? onMobileView() : onDesktop()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const thoseCompaniesText = () => {
  return (
    <div className="single-title">
      <span className="white txt-1-mid" style={{ lineHeight: "1.8" }}>
        Companies profit from what we buy. You can own stock in those companies.
        Own them, don't let them own you.
      </span>
    </div>
  );
};
