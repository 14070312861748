import axios from "axios";
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { URIS } from "../../../Constants/Endpoints";
import { openModal } from "../../../Store/Modal/Reducers";
import CompleteLabel from "../../../components/CompleteLabel";
import TriangleSpinner from "../../../components/TriangleSpinner";
import Switcher from "../../../components/ui/Switcher/Switcher";
import "./PayCard.css";
import PlaidLink from "../../../components/Plaid";
import { useHistory } from "react-router-dom";

export default function PayCard() {
  const [textActive, setTextActive] = useState(false);
  const history = useHistory();
  const [bankAdded, setBankAdded] = useState(false);
  const [autoPayActivated, setAutoPayActivated] = useState(false);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [textValue, setText] = useState("");
  let { modal, origin, data } = useSelector((state) => state.modal);
  let { access_token, user } = useSelector((state) => state.login);
  const [cardData, setCardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [linkToken, setLinkToken] = useState(null);

  const [form, setForm] = useState({
    amount: null,
    account_sr_no: null,
  });

  function goBack() {
    dispatch(openModal({ modal: "CardDashboard", origin: modal }));
  }

  const getPlaidToken = async () => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url:
              process.env.REACT_APP_CORE_API + URIS.CREATE_PLAID_TOKEN,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setLinkToken(data.link_token);
      })
      .catch((error) => {
        if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          history.push("/404");
        }
      });
  };

  useEffect(() => {
    setTextActive(false);
    if (selected === 4) {
      setTextActive(true);
    }
  }, [selected]);

  const handleInput = (e) => {
    setText(e.target.value.replace(/[^\d]/g, ""));
  };

  useEffect(() => {
    getPlaidToken();
  }, []);


  const handleListBanks = async () => {
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, null, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CARD_BANK_ACCT_LIST,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const cardData = res.data.results.data.bankAccountsListResponse;
          //setCardData(cardData);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };

  const addNewBank = async () => {
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, null, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CARD_CREATE_BANK_ACCT,
        },
      })
      .then((res) => {})
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };

  const handleSwitch = () => {
    setAutoPayActivated(!autoPayActivated);
  };

  const getPaymentInfo = async () => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CARD_GET_PAYMENT_INFO,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const cardData = res.data.results.data.getCreditPaymentInfoResponse;
          setCardData(cardData);
        }
      });
  };

  const handleAddBank = async () => {
    setBankAdded(true);
  };

  const handlePayBill = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_PAY_BILL,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPaymentCompleted(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };

  useEffect(() => {
    handleListBanks();
    getPaymentInfo();
  }, []);

  const handleAmount = (option) => {
    setSelected(option);
    setForm({
      ...form,
      amount: cardData.balance * -1,
      account_sr_no: "47200",
    });
  };

  return (
    <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
      <div className="playlist-component white">
        <div style={{ padding: "1rem", textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "left",
            }}
          >
            <span className="txt-1 neon-color pointer" onClick={() => goBack()}>
              Back
            </span>
            <div
              className="futura-bold white row"
              style={{
                maxwidth: "90%",
                textAlign: "left",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              <span style={{ width: "100%" }}>Pay Bill</span>
              <span
                id="close-single-modal-button"
                className="title white pointer"
                style={{
                  textAlign: "right",
                  width: "5%",
                  right: "0",
                }}
                onClick={() => {
                  dispatch(openModal({ modal: origin, origin: modal }));
                }}
              >
                X
              </span>
            </div>
            <div
              className="txt-1-mid"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "auto",
                alignSelf: "center",
                marginBottom: "2rem",
              }}
            >
              <div
                style={{
                  color: "white",
                  marginTop: "3rem",
                  marginBottom: "2rem",
                }}
              >
                Choose Payment Amount
              </div>
              <div className="txt-s">
                <div
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    id="term1"
                    name="radAnswer"
                    onChange={() => handleAmount(1)}
                  />
                  <div>
                    Current Bill:
                    {cardData ? (
                      <> ${cardData.balance.toFixed(2) * -1}</>
                    ) : (
                      <Skeleton width={"50px"} />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    id="term1"
                    name="radAnswer"
                    onChange={() => setSelected(2)}
                  />
                  <div>
                    Current Balance:{" "}
                    {cardData ? (
                      <> ${cardData.balance.toFixed(2) * -1}</>
                    ) : (
                      <Skeleton width={"50px"} />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "2rem",
                  }}
                >
                  <input
                    type="radio"
                    id="term1"
                    name="radAnswer"
                    onChange={() => setSelected(3)}
                  />

                  <div>
                    Minimun:
                    {cardData ? (
                      <> ${cardData.balance.toFixed(2) * -1}</>
                    ) : (
                      <Skeleton width={"50px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="txt-1-mid"
              style={{
                color: "white",
                fontWeight: "bold",
                alignSelf: "center",
              }}
            >
              Payment Date (Due: Feb 28,2023)
            </div>
            <div
              className="txt-s-light"
              style={{
                marginBottom: "2rem",
                marginTop: "1rem",
                alignSelf: "center",
              }}
            >
              Feb 28, 2023
            </div>
            <Switcher
              active={autoPayActivated}
              onSwitch={handleSwitch}
              title={"Autopay"}
            />
            <div className="single-title" style={{ textAlign: "left" }}>
              <span
                className="white txt-1"
                style={{ lineHeight: "1.8", textAlign: "left" }}
              >
                Payment date: We'll make payments to this card account each
                month on your due date (or, if falls on a Saturday, the Friday
                before.)
                <br />
                Payment amount: Minimun payment due (Statement Balance)
              </span>
            </div>
            {paymentCompleted ? (
              <div style={{ alignSelf: "center" }}>
                <CompleteLabel text={"Payment scheduled successfully!"} />
              </div>
            ) : (
              <></>
            )}
            <div style={{ alignSelf: "center" }}>
              <span className="txt-s futura-mid white">
                &#128274; BANK LEVEL PRIVACY AND SECURITY
              </span>
            </div>

            {!loading && !bankAdded ? (
              <PlaidLink
                token={linkToken}
                setLoading={setLoading}
                plaidNotification={true}
                setAcctAdded={setBankAdded}
              >
                <button className="btn-inverse-round txt-1 ">
                  {cardData && bankAdded
                    ? "Pay bill"
                    : " Select Bank Account to Pay With"}
                </button>
              </PlaidLink>
            ) : cardData && bankAdded ? (
              <button
                className="btn-inverse-round txt-1 "
                onClick={handlePayBill}
              >
                Pay bill
              </button>
            ) : (
              <div className="mt-2" style={{ marginInline: "auto" }}>
                <TriangleSpinner />
              </div>
            )}
            <span
              className="txt-s-light"
              style={{
                color: "white",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              Payment after 3PM ET may not take effect until the following
              bussiness day. We'll credit your account for the above payment
              date, but you might not see it for 1 to 2 days.
            </span>
          </div>
        </div>
      </div>{" "}
    </SkeletonTheme>
  );
}
