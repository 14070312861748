import React, { useState } from "react";
import { brands, topTen } from "../Constants/Brands";
import Tags from "../components/Tags";
import ListAction from "../components/ListAction";
import {
  fetchBySymbol,
  fetchByTag,
  fetchBrandSuccess,
  fetchBrand,
} from "../Store/Search/Actions";
import { useDispatch, useSelector } from "react-redux";
import { market, tags } from "../Constants/Tags";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { openModal, closeModal } from "../Store/Modal/Reducers";

export default function SearchStocks({ setBrand, userData }) {
  function handleClose() {
    dispatch(closeModal());
  }
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("Neon Money Club's top 10");
  const [showTags, setShowTags] = useState(true);
  const handleBrand = (brand) => {
    const brandName = brand;
    const seletectedBrand = brands.find((brand) => brand.name === brandName);
    setBrand(seletectedBrand);
  };
  const brandsState = useSelector((state) => state.search.brands);

  const handleOpenBrand = (brand) => {
    dispatch(fetchBrandSuccess(null));
    const data = {
      phone_number: userData.phone_number,
      symbol: brand.symbol,
    };
    dispatch(fetchBrand(data, brand));
    dispatch(openModal({ modal: "buyBrand" }));
  };

  const handleOpen = async (config, brand) => {
    handleBrand(brand);
    dispatch(openModal({ modal: config }));
  };

  const handleClear = () => {
    return;
    setSearchText("");
    setShowTags(true);
  };

  const handleSearchByInput = async (data) => {
    await dispatch(fetchBySymbol(data));
    setShowTags(false);
  };

  const handleTag = async (tag) => {
    const data = {
      vfp: userData.vfp,
      phone_number: userData.phone_number,
      category: tag,
      market: market,
    };
    await dispatch(fetchByTag(data));
    setShowTags(false);
  };

  const top10 = (
    <div style={{ width: "100%" }}>
      <div style={{ overflowX: "auto !important" }}>
        <table className="table white" style={{ overflowX: "auto !important" }}>
          <thead className="txt-2">
            <tr>
              <th>Brands</th>
              <th></th>
              <th>Owned by</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {topTen &&
              topTen.map((brand, index) => (
                <tr
                  key={index}
                  className="pointer txt-2"
                  onClick={() => handleOpenBrand(brand)}
                >
                  <td
                    className="pointer txt-2"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {brand.images &&
                      brand.images.map((img, index) => (
                        <button
                          key={index}
                          className="related-button"
                          style={{
                            backgroundColor: "white",
                            width: "2rem",
                            height: "2rem",
                            backgroundImage: "url(" + img + ")",
                            backgroundSize: "cover",
                            marginLeft: index > 0 ? "-1rem" : "unset",
                          }}
                        />
                      ))}
                  </td>
                  <td className="pointer txt-2">{brand.brands}</td>
                  <td className="pointer txt-2">{brand.owned_by}</td>
                  <td className=" sub-title" style={{ textAlign: "right" }}>
                    <MdOutlineKeyboardArrowRight />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const disclosure = (
    <div
      className="sub-card txt-mid-1"
      style={{
        padding: "1rem",
        textAlign: "center !important",
        lineHeight: "0.8rem",
        marginTop: "2rem",
      }}
    >
      <span className="txt-s white">
        Companies make profits from what we buy. It's our turn to get ownership
        in these companies.
      </span>
    </div>
  );

  const handleInput = (text) => {
    dispatch(fetchBrandSuccess(null));
    setSearchText(text);
    if (text.length >= 3) {
      const data = {
        phone_number: userData.phone_number,
        criteria: text,
      };
      handleSearchByInput(data);
    } else {
      setShowTags(true);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <div
        className="search-width"
        style={{
          paddingInline: "1rem",
          textAlign: "left",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            Neon Money Club Top 10
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
        <div style={{ marginTop: "1rem" }}>
          {/*<div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid ",
              borderColor: "white",
              borderWidth: "1px",
              borderRadius: "0.5rem",
              paddingInline: "0.25rem",
            }}
          >
            <input
              className="search-box"
              placeholder="Brand, Stock, Crypto, etc"
              value={searchText}
              onChange={(e) => handleInput(e.target.value)}
              onFocus={() => setSearchText("")}
              disabled
            />
          </div>*/}
          {showTags ? disclosure : null}
          {showTags ? (
            top10
          ) : (
            <ListAction
              brands={brandsState}
              handleOpenBrand={handleOpenBrand}
            />
          )}
          <div
            style={{ width: "auto", paddingBottom: "1rem", paddingTop: "1rem" }}
          >
            <span className="white txt-s">
              The logos listed here are registered trademarks of their
              respective owners and not Neon Money Club.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
