import apple from "../images/singles/AAPL.png";
import google from "../images/singles/GOOGL.png";
import nike from "../images/singles/NKE.png";
import vf from "../images/singles/VF.png";
import kellogs from "../images/singles/K.png";
import pep from "../images/singles/PEP.png";
import coca from "../images/singles/KO.png";
import mondelez from "../images/singles/MDLZ.png";
import block from "../images/singles/SQ.png";
import amazon from "../images/singles/AMZ.png";
import voo from "../images/singles/voo-gif.gif";

export const Singles = [
  {
    owned_by: "VOO",
    brand_name: "Top 500 brands",
    brands: [],
    image: voo,
    symbol: "VOO",
    description:
      "Why choose one Stock, when you can buy into 500 at once. This investment follows the S&P 500 - which is a collection of 500 of the largest companies in the stock market. Own 'em all.",
    detail: null,
  },
  {
    brand_name: "Nike",
    owned_by: "NIKE",
    brands: [],
    image: nike,
    symbol: "NKE",
    description:
      "How many brands have their own Frank Ocean song? Just one. The Swoosh house is also home to MJ (Jordan Brand) and Chuck Taylor (Converse).",
    detail: null,
  },
  {
    brand_name: "VF Corp",
    owned_by: "VF Corp",
    brands: [],
    image: vf,
    symbol: "VFC",
    description:
      "Now you can own Supreme stock to go with your box logo tee. This streetwear titan was purchased by VF Corp (VFC) in 2020. VF also owns brands like The Northface, Vans, Timberland and more.",
    detail: null,
  },

  {
    brand_name: "Pepsi",
    owned_by: "PEP",
    brands: [],
    image: pep,
    detail: null,
    symbol: "PEP",
    description:
      "'I've done commercials for both Coke and Pepsi. Truth is, I can't even taste the difference, but Pepsi paid me last, so there it is.' - Dave Chappelle. Aside from pushing soda, Pepsi owns some of the most well known brands on the planet like Frito-Lay, Doritos, Mountain Dew, Cheetos, Tropicana and more...",
  },

  {
    owned_by: "AMZN",
    brand_name: "Amazon",
    brands: [],
    image: amazon,
    symbol: "AMZN",
    description:
      "Seller of everything. Father of Alexa. First of its name. Why aim for a billion dollar valuation, when you can shoot for a trillion. Amazon's run is legendary.",
    detail: null,
  },
  {
    owned_by: "SQ",
    brand_name: "Block",
    brands: [],
    image: block,
    symbol: "SQ",
    description:
      "It's 'Jack' Magic. Did you know that CashApp, Tidal and AfterPay were all owned by Square parent company, Block? Pay Here, Pay Friends, Pay Later, Play Music? It all makes sense if you make it, right?",
    detail: null,
  },
  {
    owned_by: "KO",
    brand_name: "Coca-Cola",
    brands: [],
    image: coca,
    symbol: "KO",
    description:
      "Close your eyes and think Coca-Cola. The first sound you imagine will probably be the signature hiss sound after twisting the top. Coke owns a whole family of popular brands like Sprite, Vitamin Water, Honest Tea and Simply Orange Juice.",
    detail: null,
  },
  {
    owned_by: "K",
    brand_name: "Kelloggs",
    brands: [],
    image: kellogs,
    symbol: "K",
    description:
      "'They're Great!' - Tony the Tiger. Kellogg's Brands have been posted up in your supermarket for generations.",
    detail: null,
  },
  {
    owned_by: "MDLZ",
    brand_name: "Mondelez",
    brands: [],
    image: mondelez,
    symbol: "MDLZ",
    description:
      "Snacks, on snacks, on snacks. From Trident to Ritz Crackers - Mondelez owns more brands than we can count on one hand.",
    detail: null,
  },
  {
    owned_by: "GOOGL",
    brand_name: "Alphabet",
    brands: [],
    image: google,
    symbol: "GOOGL",
    description:
      "Google it. Big G did online search so well that its name became a verb.",
    detail: null,
  },
  {
    owned_by: "AAPL",
    brand_name: "Apple",
    brands: [],
    image: apple,
    symbol: "AAPL",
    description:
      "The house that Steve Jobs built. Apple made it uncool to see a green bubble in your SMS messages and Google is still mad about it.",
    detail: null,
  },
];
