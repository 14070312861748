import React, { useState, useEffect } from "react";
import { openModal } from "../../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { URIS } from "../../../Constants/Endpoints";
import TriangleSpinner from "../../../components/TriangleSpinner";
import InputMask from "react-input-mask";
import CompleteLabel from "../../../components/CompleteLabel";

export default function CardSpendingPower() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  let { access_token, user } = useSelector((state) => state.login);
  const [cardActive, setCardActive] = useState(false);
  const [cardStatus, setCardStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleCheck = () => {
    setChecked(true);
  };

  const switchCardStatus = async () => {
    if (
      (cardStatus === "Unlocked" && cardActive) ||
      (cardStatus === "Locked" && !cardActive)
    ) {
      return;
    }
    setLoading(true);
    const data = {
      status: cardStatus === "Unlocked" ? "INACTIVE" : "OPEN",
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_LOCK,
        },
      })
      .then((res) => {
        setLoading(false);
        setCardStatus(cardStatus === "Unlocked" ? "Locked" : "Unlocked");
      });
  };

  const getCardInfo = async () => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_INFO,
        },
      })
      .then((res) => {
        const response = res;
        if (response.data.status) {
          const cardState =
            response.data.results.data.getCardAuthDataResponse.status.code;
          if (cardState === "B") {
            setCardStatus("Unlocked");
          } else if (cardState === "I") {
            setCardStatus("Locked");
          }
        }
      });
  };

  const handleSwitch = () => {
    setCardActive(!cardActive);
    //switchCardStatus();
  };

  useEffect(() => {
    getCardInfo();
  }, []);

  useEffect(() => {
    if (cardStatus && cardStatus === "Unlocked") {
      setCardActive(true);
    } else {
      setCardActive(false);
    }
  }, [cardStatus]);

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() => dispatch(openModal({ modal: origin }))}
          >
            Back
          </span>
          <div
            className="futura-bold white row"
            style={{
              maxwidth: "90%",
              textAlign: "left",
              fontSize: "1.5rem",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>Spending power</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardDashboard", origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="single-title">
              <span className="white txt-1" style={{ lineHeight: "1.8" }}>
                Your card has no preset limit. Use this tool to check if your
                purchases will be approved. Your spending power is based on
                factors such as purchase, payment and credit history.
              </span>
            </div>

            <>
              <span className="sub-title bold mt-1">
                {" "}
                Expected purchase amount
              </span>
              <input
                pattern="\d*"
                className="input mt-1"
                placeholder="Enter amount"
                type="number"
              />
              {checked ? <CompleteLabel text={"Looks good!"} /> : <></>}
              <button
                className="btn-inverse-round txt-1 mt-2"
                onClick={handleCheck}
              >
                Check another amount
              </button>
            </>
          </div>
          <span
            className="txt-s-light"
            style={{
              color: "white",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            This approval is based on your current account status. Certain
            changes to your account could affect the approved amount, such as
            past due balance, a returned payment, a significant change in your
            spending pattern, or a fraud risk on your Card.
          </span>
        </div>
      </div>
    </div>
  );
}
