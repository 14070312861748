import {
  SEARCH_SYMBOL_SUCCESS,
  SEARCH_SYMBOL_FAILURE,
  SEARCH_TAG_SUCCESS,
  SEARCH_TAG_FAILURE,
  FETCH_BRAND,
} from "./Types";
import axios from "axios";
import { URIS } from "../../Constants/Endpoints";

export const fetchBySymbol = (data) => {
  const auth = localStorage.getItem("access");
  const gcpAuthToken = localStorage.getItem("gcpauth");
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${auth}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_BY_SYMBOL,
        },
      })
      .then((response) => {
        dispatch(fetchBySymbolSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchBySymbolFailure(error));
      });
  };
};

export const fetchByTag = (data) => {
  const auth = localStorage.getItem("access");
  const gcpAuthToken = localStorage.getItem("gcpauth");
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${auth}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_BY_TAG,
        },
      })
      .then((response) => {
        dispatch(fetchByTagSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchByTagFailure(error));
      });
  };
};

export const fetchBrand = (data, localData) => {
  const auth = localStorage.getItem("access");
  const gcpAuthToken = localStorage.getItem("gcpauth");
  return function (dispatch) {
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${auth}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_BRAND_DETAILS,
        },
      })
      .then((response) => {
        dispatch(fetchBrandSuccess(response.data.brand, localData));
      })
      .catch((error) => {});
  };
};

export const fetchBrandSuccess = (data, localData) => {
  return {
    type: FETCH_BRAND,
    payload: data,
    local: localData,
  };
};

export const fetchBySymbolSuccess = (data) => {
  return {
    type: SEARCH_SYMBOL_SUCCESS,
    payload: data.brands,
  };
};

export const fetchBySymbolFailure = (error) => {
  return {
    type: SEARCH_SYMBOL_FAILURE,
    payload: error,
  };
};

export const fetchByTagSuccess = (data) => {
  return {
    type: SEARCH_TAG_SUCCESS,
    payload: data.brands,
  };
};

export const fetchByTagFailure = (error) => {
  return {
    type: SEARCH_TAG_FAILURE,
    payload: error,
  };
};
