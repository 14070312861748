import React from "react";
import TriangleSpinner from "./TriangleSpinner";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export function DocsTabs({
  showDocs,
  loading,
  getTradeConfirms,
  getDocuments,
  filter,
  documents,
  showDocsByMonth,
  downloadDoc,
  showDocsByDay,
  setDocs,
}) {
  return !showDocs ? (
    loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          marginTop: "10rem",
        }}
      >
        <TriangleSpinner />
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginInline: "2rem",
          marginTop: "-2rem",
        }}
      >
        <table className="table white">
          <thead className="white txt-1-mid">
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              className="txt-s tr-border pointer"
              onClick={() => getTradeConfirms("CONFIRMS")}
            >
              <td>Trade Confirmations</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                <MdOutlineKeyboardArrowRight />
              </td>
            </tr>
            <tr
              className="txt-s tr-border pointer"
              onClick={() => getDocuments("STATEMENTS")}
            >
              <td>Statements</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                <MdOutlineKeyboardArrowRight />
              </td>
            </tr>
            <tr
              className="txt-s pointer "
              onClick={() => getDocuments("TAXFORMS")}
            >
              <td>Tax</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                <MdOutlineKeyboardArrowRight />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  ) : filter === "years" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginInline: "2rem",
        marginTop: "-2rem",
      }}
    >
      <table className="table white">
        <thead className="white txt-1-mid">
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents !== null && documents.length > 0
            ? documents.map((doc, index) => (
                <tr
                  className="txt-s tr-border pointer"
                  key={index}
                  onClick={() => showDocsByMonth(doc.docs)}
                >
                  <td>{doc.year}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <MdOutlineKeyboardArrowRight />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  ) : filter === "months" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginInline: "2rem",
        marginTop: "-2rem",
      }}
    >
      <table className="table white">
        <thead className="white txt-1-mid">
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents !== null && documents.length > 0
            ? documents.slice(0, 10).map((doc, index) => (
                <tr
                  className="txt-s tr-border pointer"
                  key={index}
                  onClick={
                    doc.documents.length === 1
                      ? () => downloadDoc(doc.documents[0].fileKey)
                      : doc.documents.length > 1
                      ? () => showDocsByDay(doc.documents)
                      : doc.documents.length === 0
                      ? () => setDocs(doc.documents)
                      : null
                  }
                >
                  <td>{doc.date || doc.documents[0].displayName}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <MdOutlineKeyboardArrowRight />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  ) : filter === "days" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginInline: "2rem",
        marginTop: "-2rem",
      }}
    >
      <table className="table white">
        <thead className="white txt-1-mid">
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {documents !== null && documents.length > 0
            ? documents.slice(0, 31).map((doc, index) => (
                <tr
                  className="txt-s tr-border pointer"
                  key={index}
                  onClick={() => downloadDoc(doc.fileKey)}
                >
                  <td>{doc.displayName}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <MdOutlineKeyboardArrowRight />
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  ) : null;
}
