import React from "react";
export function NotAMember({ handleClickNotRegistered }) {
  return (
    <div
      style={{
        width: 250,
        marginTop: "2rem",
      }}
    >
      <span
        style={{
          color: "white",
          width: 250,
          fontFamily: "futura-light",
          fontSize: "0.75rem",
        }}
      >
        Not a member? {" "}Join {" "}
        <span
          className="pointer"
          onClick={() => handleClickNotRegistered("other")}
          style={{
            textDecoration: "underline",
          }}
        >
         here.
        </span>
      </span>
    </div>
  );
}
