import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../Store/Modal/Reducers";
import "./CardDashboard.css";
import { URIS } from "../../../Constants/Endpoints";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { formatDate } from "../../../helpers/Dates/DatesFunctions";
import { refresh, logOut } from "../../../helpers/Auth";
import { setAccess } from "../../../Store/Login/Reducers";
import { setBillType, setCardState } from "../../../Store/Card/Reducer";
import { GoXCircleFill, GoCheckCircleFill } from 'react-icons/go'
import { useAlert } from 'react-alert'

export default function CardDashBoard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { access_token, user, refresh_token } = useSelector(
    (state) => state.login
  );
  const alert = useAlert()
  const { billType } = useSelector((state) => state.card);
  const [cardBalance, setCardBalance] = useState();
  const [spentSoFar, setSpentSoFar] = useState(0)
  const [recentTransactions, setRecentTransactions] = useState(null);
  const [dueDate, setDueDate] = useState(0)
  const [points, setPoints] = useState(0)
  const [spentToday, setSpentToday] = useState(0)
  const today = new Date()
  const failedBill = ['F', 'C', 'R', 'B', 'E', 'N', 'X', 'M', 'H', 'J', 'K']

  function goBack() {
    dispatch(closeModal());
    alert.removeAll()
  }

  function goToDetails() {
    dispatch(openModal({ modal: "CardDetails", origin: "CardDashboard" }));
  }

  const goToManage = () => {
    dispatch(openModal({ modal: "manageCard", origin: "CardDashboard" }));
  };

  function goToAddToWallet() {
    dispatch(openModal({ modal: "AddToWallet", origin: "CardDashboard" }));
  }

  const goToPoints = () => {
    dispatch(openModal({ modal: "PointsOnPoints", origin: "CardDashboard" }));
  };

  function goToTransactions() {
    dispatch(openModal({ modal: "CardTransactions", origin: "CardDashboard" }));
  }

  function goToBenefits() {
    dispatch(openModal({ modal: "cardBenefits", origin: "CardDashboard" }));
  }

  function gotoRealFriends() {
    dispatch(openModal({ modal: "cardRealFriends", origin: "CardDashboard" }));
  }

  function goToPay() {
    dispatch(
      openModal({
        modal: "PayCard",
        origin: "CardDashboard",
        data: cardBalance,
      })
    )
  }

  const getCardHightligth = () => {
    if (user.neon_card_userflow_status === 'OVERDUE') {
      return {
        highlight: <GoXCircleFill size={50} color={'red'} />,
        title: "Bill overdue",
        subtitle: "Avoid impact on your credit score",
        fn: goToPay
      }
    }
    else if (billType === 'PROCESSED') {
      return {
        highlight: <GoCheckCircleFill size={50} color={'#16e7cf'} />,
        title: "Bill Processed",
        subtitle: "Your bill of $100 has been received",
        fn: goToPay
      }
    }
    else if (billType === 'INITIATED') {
      return {
        highlight: <GoCheckCircleFill size={50} color={'#16e7cf'} />,
        title: "Bill Initiated",
        subtitle: "Your payment has been initiated",
        fn: goToPay
      }
    }
    else if (billType === 'FAILED') {
      return {
        highlight: <GoXCircleFill size={50} color={'red'} />,
        title: "Payment Unsuccessful",
        subtitle: "Tap to update payment",
        fn: goToPay
      }
    }
    else if (billType === 'PAID') {
      return {
        highlight: <GoCheckCircleFill size={50} color={'#16e7cf'} />,
        title: "You paid your bill",
        subtitle: "Payment your bill of $100 has been received",
        fn: goToPay
      }
    }
    else if (user.neon_card_userflow_status === 'CARD-COLLECTIONS') {
      return {
        highlight: <GoXCircleFill size={50} color={'red'} />,
        title: "Bill overdue",
        subtitle: "Avoid further impatct on your credit score.",
        fn: goToPay
      }
    }
    else {
      return {
        highlight: dueDate + ' \r\n Days',
        title: "'Til your bill is due",
        subtitle: "Tap to pay",
        fn: goToPay
      }
    }
  }

  const cards = [
    getCardHightligth()
    ,
    {
      highlight: points,
      title: "Points earned",
      subtitle: "Redeem or double it towards investing",
      fn: () => goToPoints(),
    },
    {
      highlight: "Activate",
      title: "Monthly bonus points",
      subtitle: "Real friends get money together",
      fn: () => gotoRealFriends(),
    },
  ];

  const cardOptions = [
    {
      title: "Add to Digital Wallet",
      fn: () => goToAddToWallet(),
    },
    {
      title: "Pay Card",
      fn: () =>
        dispatch(
          openModal({
            modal: "PayCard",
            origin: "CardDashboard",
            data: cardBalance,
          })
        ),
    },
    {
      title: "Manage",
      fn: () => goToManage(),
    },
  ];

  const getCardHistory = async (accessToken) => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${accessToken}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_HISTORY,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.results.last_10_transactions_in_time_frame) {
            let results = res.data.results.last_10_transactions_in_time_frame;
            setRecentTransactions(results);
          }
          if (res.data.raw_response.statement.transactions.length > 0) {
            let totalTodayValue = 0
            let todayTransactions = res.data.raw_response.statement.transactions.filter(dateCompare)
            todayTransactions.forEach(item => {
              totalTodayValue = totalTodayValue + ((-1) * item.amount)
            });
            setSpentToday(totalTodayValue)
          }

        }
      });
  };

  const getSchedulePaymentes = async (accessToken) => {
    let searchingCode = ['S', 'A', 'L', 'I']
    let matchFound = false
    await axios.post(process.env.REACT_APP_AUTH_PROXY_URL, {}, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_SCHEDULE_PAYMETS,
      },
    }).then(res => {
      let resData = res.data.results.data.b2CTransferListResponse
      if (resData.singleTransfers && resData.singleTransfers.length > 0) {
        for (let i = 0; i < resData.singleTransfers.length; i++) {
          if (searchingCode.includes(resData.singleTransfers[i].status)) {
            matchFound = true
            dispatch(setBillType('INITIATED'))
            return;
          }
          else if (failedBill.includes(resData.singleTransfers[i].status) && resData.singleTransfers[i].amount == spentSoFar) {
            matchFound = true
            dispatch(setBillType('FAILED'))
            return;
          }
        }
      }
      if (resData.recurringTransfers && resData.recurringTransfers.length > 0) {
        for (let i = 0; i < resData.recurringTransfers.length; i++) {
          if (searchingCode.includes(resData.recurringTransfers[i].status)) {
            matchFound = true
            dispatch(setBillType('INITIATED'))
            return;
          }
          else if (failedBill.includes(resData.recurringTransfers[i].status) && resData.recurringTransfers[i].amount == spentSoFar) {
            matchFound = true
            dispatch(setBillType('FAILED'))
            return;
          }
        }
      }
      if (!matchFound) {
        dispatch(setBillType(''))
      }
    })
  }

  const handleGetCardHistory = () => {
    goToTransactions();
  };

  const getCardBalance = async (accessToken) => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${accessToken}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_BALANCE,
        },
      })
      .then((res) => {
        const response = res.data;
        if (response.status) {
          const data = response.results.data.balanceInquiryAdvanceResponse;
          setSpentSoFar(data.balance)
          setPoints(response.PointsBalance ? response.PointsBalance : 0)
        }
      });
  };

  const setRefId = async (accessToken) => {
    return;
    const data = { card_referenceid: "333165547282" };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${accessToken}`,
          audience_url: process.env.REACT_APP_CORE_API + "db/test/4156230590",
          "X-NEON-TOKEN": "d66e67309924b68cad05784f10e2417d1c173a98",
          "CLIENT-ID": "473e67a5e0a768380b5111a9aa90d04ab36f84d4",
        },
      })
      .then((res) => {
        console.log(res);
      });
  };

  const getPaymentInfo = async (accessToken) => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${accessToken}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CARD_GET_PAYMENT_INFO,
        },
      })
      .then((res) => {
        if (res.data.results.data.getCreditPaymentInfoResponse.PaymentType === 'PAYMENT_RECVD' && dueDate > 0) {
          dispatch(setBillType('PAID'))
        }
      });
  };


  const dateCompare = (date) => {
    const split = date.businessDate.split('/')
    const compareDate = new Date(split[2], split[0] - 1, split[1])
    const todayNormalized = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    if (todayNormalized.getTime() == compareDate.getTime()) {
      return date
    }
  }

  const getCardInfo = async (accessToken) => {
    const data = {}
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${accessToken}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CARD_INFO,
        },
      })
      .then((res) => {
        setCardBalance((-1) * res.data.results.data.getCardAccountsResponse.balance)
        setDueDate(res.data.results.data.getCardAccountsResponse.cards[0].daysOfDueDate)
        dispatch(setCardState(res.data.results.data.getCardAuthDataResponse.status.code))
      });
  }


  useEffect(async () => {
    let access = await refresh(refresh_token);
    if (access.status) {
      dispatch(setAccess(access.access_token));
      getCardBalance(access.access_token);
      getCardHistory(access.access_token);
      getPaymentInfo(access.access_token);
      getCardInfo(access_token)
      getSchedulePaymentes(access_token)
      setRefId(access.access_token);
      alert.show((
        <div style={{marging:200, textAlign:'center'}} >
          Club Rule: We don't put you in debt. Your <div style={{fontWeight:'bold', display:'inline-block'}}>balance due</div> requires payment in full every month.
        </div>
      ))
    } else {
      logOut(refresh_token, history);
    }
  }, []);

  return (
    <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
      <div className="main-Container">
        <div className="header-Container">
          <div className="txt-1 pointer back" onClick={() => goBack()}>
            Back
          </div>
          <div
            className="title-Container futura-bold white"
            style={{ fontSize: "1.5rem" }}
          >
            <div className="title" onClick={() => goBack()}>
              X
            </div>
          </div>
        </div>
        <div
          style={{ color: "#16e7cf", display: "flex", flexDirection: "column" }}
        >
          <div className="mock-Card" />
          <div
            className="txt-s"
            style={{ marginTop: "1rem" }}
            onClick={() => goToDetails()}
          >
            Details{" "}
          </div>
        </div>
        {cardBalance ? (
          <div className="row white mt-2" style={{ justifyContent: "center" }}>
            <div className="column" style={{ margin: "auto" }}>
              <span className="txt-1">Balance Due </span>
              <span className="title bold">${cardBalance}</span>
            </div>
            <div className="column" style={{ margin: "auto" }}>
              {" "}
              <span className="txt-1">Spent So Far</span>
              <span className="title bold">${spentSoFar}</span>
            </div>
          </div>
        ) : (
          <div className="mt-2 mb-1">
            <Skeleton height="50px" width="70%" />
          </div>
        )}
        {cards.map((item, index) => {
          return (
            <div
              className="white row mt-1"
              onClick={item.fn}
              key={index}
              style={{
                width: "90vw",
                backgroundColor: "#212121",
                height: 100,
                alignSelf: "center",
                borderRadius: 10,
                marginBottom: 15,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "28%",
                  height: 85,
                  backgroundColor: "black",
                  borderRadius: 10,
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: 10,
                }}
              >
                <div
                  className="bold title"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  {item.highlight}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                  flex: 1,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {item.title}
                </div>
                <div
                  style={{ color: "white", textAlign: "left", fontSize: 13 }}
                >
                  {item.subtitle}
                </div>
              </div>

              <div
                style={{
                  color: "white",
                  alignSelf: "center",
                  fontSize: 50,
                  width: 25,
                }}
              >
                &#x203A;
              </div>
            </div>
          );
        })}
        <div
          className="row txt-1 mb-1"
          style={{
            justifyContent: "space-between",
            width: "90vw",
            alignSelf: "center",
          }}
        >
          {cardOptions.map((item, index) => {
            return (
              <div
                style={{
                  backgroundColor: "#212121",
                  borderRadius: "0.5rem",
                  width: "32%",
                  height: 50,
                  display: "flex",
                }}
                onClick={item.fn}
                key={index}
              >
                <div
                  style={{
                    color: "white",
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    alignSelf: "center",
                  }}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="single-title" style={{ width: "90%" }}>
          {cardBalance ? (
            <span className="white txt-1-mid" style={{ lineHeight: "1.8" }}>
              Today, you spent ${spentToday}
            </span>
          ) : (
            <Skeleton height="20px" width="100px" />
          )}
        </div>
        <div
          className="neon-color txt-1 "
          onClick={() =>
            dispatch(
              openModal({
                modal: "spendingPower",
                origin: "CardDashboard",
                data: cardBalance,
              })
            )
          }
        >
          Check Spending Power{" "}
        </div>
        {recentTransactions ? (
          <>
            <div
              className="txt-1 mt-2 white"
              style={{
                textAlign: "left",
                width: "90vw",
                alignSelf: "center",
                marginBottom: "1rem",
              }}
            >
              Recent transactions
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                width: "90vw",
              }}
            >
              {recentTransactions &&
                recentTransactions.map((transaction, index) => {
                  return (
                    <div
                      className="row white"
                      style={{
                        borderTop: index === 0 ? "1px solid grey" : 0,
                        borderBottom: "1px solid grey",
                        width: "100%",
                        height: 50,
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      <div
                        className="column txt-1"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <div>{transaction.description}</div>
                        <div
                          className="column txt-s"
                          style={{ textAlign: "left" }}
                        >
                          {formatDate(transaction.localDateTime)}
                        </div>
                      </div>
                      <div
                        className="column txt-s bold"
                        style={{
                          alignSelf: "center",
                        }}
                      >
                        {"$" + (transaction.amount * -1).toFixed(2)}
                      </div>
                    </div>
                  );
                })}
            </div>
            <button
              onClick={() => handleGetCardHistory()}
              className="btn-inverse-round txt-1 "
            >
              See more transactions
            </button>
          </>
        ) : (
          <></>
        )}
        <div
          className="neon-color txt-s"
          style={{
            alignSelf: "center",
            marginBottom: "1rem",
          }}
          onClick={() => goToManage()}
        >
          Manage Card
        </div>
      </div>
    </SkeletonTheme>
  );
}
