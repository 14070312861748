import React, { useState, useEffect } from "react";
import { openModal } from "../../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import "./LockCard.css";
import axios from "axios";
import { URIS } from "../../../Constants/Endpoints";
import TriangleSpinner from "../../../components/TriangleSpinner";
import Switcher from "../../../components/ui/Switcher/Switcher";

export default function LockCard() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  let { access_token, user } = useSelector((state) => state.login);
  const [cardActive, setCardActive] = useState(false);
  const [cardStatus, setCardStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const switchCardStatus = async () => {
    if (
      (cardStatus === "Unlocked" && cardActive) ||
      (cardStatus === "Locked" && !cardActive)
    ) {
      return;
    }
    setLoading(true);
    const data = {
      status: cardStatus === "Unlocked" ? "INACTIVE" : "OPEN",
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_LOCK,
        },
      })
      .then((res) => {
        setLoading(false);
        setCardStatus(cardStatus === "Unlocked" ? "Locked" : "Unlocked");
      });
  };

  const getCardInfo = async () => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_INFO,
        },
      })
      .then((res) => {
        const response = res;
        if (response.data.status) {
          const cardState =
            response.data.results.data.getCardAuthDataResponse.status.code;
          if (cardState === "B") {
            setCardStatus("Unlocked");
          } else if (cardState === "I") {
            setCardStatus("Locked");
          }
        }
      });
  };

  const handleSwitch = () => {
    setCardActive(!cardActive);
    //switchCardStatus();
  };

  useEffect(() => {
    getCardInfo();
  }, []);

  useEffect(() => {
    if (cardStatus && cardStatus === "Unlocked") {
      setCardActive(true);
    } else {
      setCardActive(false);
    }
  }, [cardStatus]);

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() => dispatch(openModal({ modal: origin }))}
          >
            Back
          </span>
          <div
            className="futura-bold white row"
            style={{
              maxwidth: "90%",
              textAlign: "left",
              fontSize: "1.5rem",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>Lock card</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardDashboard", origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="single-title">
              <span className="white txt-1" style={{ lineHeight: "1.8" }}>
                Misplaced your card? Instantly block any new purchases made with
                your card. If your card is lost or damaged, hit us on chat to
                request a new one.
              </span>
            </div>
            {cardStatus ? (
              <>
                <Switcher
                  active={cardActive}
                  onSwitch={handleSwitch}
                  title={"Tap to Lock/Unlock"}
                />
                {!loading ? (
                  <span className="txt-s mt-1">
                    {"Current status: " + cardStatus}
                  </span>
                ) : (
                  <div className="mt-2">
                    <TriangleSpinner />
                  </div>
                )}
                <button
                  className="btn-inverse-round txt-1 "
                  style={{
                    marginTop: "1rem",
                  }}
                  onClick={() => switchCardStatus(!cardActive)}
                >
                  Save
                </button>
                <span
                  className="neon-color txt-s mt-1"
                  onClick={() => switchCardStatus()}
                >
                  Problems or lost your card?{" "}
                </span>
              </>
            ) : (
              <div className="mt-2">
                <TriangleSpinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
