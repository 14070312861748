import React, { useEffect } from "react";
import { useState } from "react";
import "./CardTransactionReport.css";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../Store/Modal/Reducers";

export default function CardTransactReport() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  let { modal, origin } = useSelector((state) => state.modal);

  const reasons = [
    "I returned or canceled this and still haven’t received the credit",
    "I never received the product or service",
    "I was charged the wrong amount",
    "I got charged more than once for the same transaction",
    "I did not make this purchase",
    "I’m not happy with the product or service",
    "The product or service is not as described",
  ];
  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: origin, origin: modal }))
            }
          >
            Back
          </span>
          <div
            className="futura-bold white "
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ width: "100%" }}>Report a problem</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: origin, origin: modal }));
              }}
            >
              X
            </span>
          </div>
        </div>
        <div
          className="txt-1 column"
          style={{
            width: "auto",
            alignSelf: "center",
          }}
        >
          <div
            className="txt-1-mid"
            style={{
              marginTop: "3rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            Select a reason below
          </div>

          {reasons.map((reason, index) => (
            <div
              key={index}
              className="row"
              style={{
                marginBottom: "2rem",
                alignSelf: "center",
                width: "70vw",
              }}
            >
              <input
                type="radio"
                id="term1"
                name="radAnswer"
                onChange={() => setSelected(index + 1)}
              />
              <div style={{ textAlign: "left" }}>{reason}</div>
            </div>
          ))}
        </div>
        <div style={{ width: "90vw", alignSelf: "center" }}>
          <button className="btn-inverse-round txt-1 ">Next</button>
        </div>
      </div>
    </div>
  );
}
