import React from "react";
import SingleBox from "../components/SingleBox";
import PlaylistsBox from "../components/PlaylistsBox";
import viewing from "../images/buttons/VIEWING.png";
import tap from "../images/buttons/TAP.png";
import check from "../images/buttons/CHECK.png";
import ActionCustomComponent from "../components/ActionCustomComponent/ActionCustomComponent";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { openModal } from "../Store/Modal/Reducers";

export default function InvestTab() {
  let dispatch = useDispatch();
  let { wallet } = useSelector((state) => state.dashboard);
  let { user } = useSelector((state) => state.login);
  const openM = () => {
    dispatch(openModal({ modal: "works" }));
  };
  return (
    <>
      <div style={{ margin: "1rem", display: "flex", flexDirection: "column" }}>
        <span className="title white bold">
          Own Them, Don't Let Them Own You
        </span>
        {wallet && (wallet.playlists.length > 0 || wallet.brands.length) > 0 ? (
          <div
            id="wallet_button"
            className="row mt-1"
            style={{ alignItems: "center" }}
            onClick={() => dispatch(openModal({ modal: "yourStocks" }))}
          >
            (<span className="futura-mid-1pt17 white">Wallet</span>
            )
            <MdOutlineKeyboardArrowRight size={18} color={"gray"} />
          </div>
        ) : null}
        {wallet &&
        wallet.balance_amount &&
        wallet.balance_amount.toFixed(2) > 0 ? (
          <span className="title white">
            ${wallet.balance_amount.toFixed(2)}
          </span>
        ) : null}
        {wallet.playlists.length > 0.0 || wallet.brands.length > 0 ? null : (
          <div onClick={() => {}}>
            <ActionCustomComponent
              image={viewing}
              textTop={"Explore brands to invest"}
              textBtm={"Your first $30 is on us. Get started."}
            />
          </div>
        )}
        <div onClick={() => openM()}>
          <ActionCustomComponent
            image={user.user_educated ? check : tap}
            textTop={"How to get started"}
            textBtm={"3 GIFs to show you how easy it is."}
            arrow={true}
          />
        </div>
      </div>
      <SingleBox />
      <PlaylistsBox />
    </>
  );
}
