import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../Store/Modal/Reducers";

export default function CardStatements() {
  const dispatch = useDispatch();
  const [selectedTab, setTab] = useState("Statement");

  function close() {
    dispatch(closeModal());
  }
  let { modal, origin } = useSelector((state) => state.modal);

  function goBack() {
    dispatch(openModal({ modal: "manageCard" }));
  }

  const Statements = [
    {
      Title: "October",
    },
    {
      Title: "September",
    },
    {
      Title: "August",
    },
    {
      Title: "July",
    },
    {
      Title: "June",
    },
  ];

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: origin, origin: modal }))
            }
          >
            Back
          </span>
          <div
            className="futura-bold white "
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ width: "100%" }}> Statements & Documents</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: origin, origin: modal }));
              }}
            >
              X
            </span>
          </div>
        </div>

        <div
          className="txt-1"
          style={{
            display: "flex",
            flexDirection: "row",
            color: "white",
            marginBottom: "3rem",
            marginTop: "2rem",
            width: "90vw",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              fontWeight: selectedTab === "Statement" ? "bold" : "normal",
            }}
          >
            Statements
          </div>{" "}
          |
          <div
            style={{ fontWeight: selectedTab === "Docs" ? "bold" : "normal" }}
          >
            Docs
          </div>
        </div>

        <div
          className="column txt-1 white"
          style={{
            alignSelf: "center",
            width: "90vw",
          }}
        >
          {Statements.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  borderTop: index === 0 ? "1px solid grey" : 0,
                  borderBottom:
                    index !== Statements.length - 1 ? "1px solid grey" : 0,
                  height: 50,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ alignSelf: "center" }}>{item.Title}</div>
                <div
                  style={{
                    color: "white",
                    fontSize: 30,
                    width: 25,
                  }}
                >
                  &#x203A;
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
