import MONEY from "../../images/neon-imgs/money.gif";
import SWAG from "../../images/neon-imgs/swag.gif";
import faang from "../../images/neon-imgs/faang.gif";
import PRESSPLAY from "../../images/neon-imgs/press-play.gif";

//SWAG PLAYLIST
import polo from "../../images/SWAG/polo.png";
import gps from "../../images/SWAG/GPS.png";
import Nike from "../../images/SWAG/Nike.png";
import stadium from "../../images/SWAG/stadium.jpg";
import supreme from "../../images/SWAG/supreme.png";

//MONEY PLAYLIST
import affirm from "../../images/MONEY/affirm.png";
import bitcoin from "../../images/MONEY/bitcoin.png";
import chase from "../../images/MONEY/chase.png";
import coinbase from "../../images/MONEY/coinbase.jpg";
import square from "../../images/MONEY/square.png";
import visa from "../../images/MONEY/visa.png";

//FAANG PLAYLIST
import amazon from "../../images/FAANG/amazon.png";
import apple from "../../images/FAANG/apple.png";
import google from "../../images/FAANG/google.png";
import instagram from "../../images/FAANG/instagram.png";
import netflix from "../../images/FAANG/netflix.png";

//PRESSPLAY PLAYLIST
import att from "../../images/PRESSPLAY/att.png";
import comcast from "../../images/PRESSPLAY/comcast.png";
import disney from "../../images/PRESSPLAY/disney.png";
import livenation from "../../images/PRESSPLAY/livenation.png";
import spotify from "../../images/PRESSPLAY/spotify.png";

export const playlists = [
  {
    title: "SWAG",
    image: SWAG,
    info: "Take back your closet.",
    long_info:
      "Take back your closet. Own the companies who make what you love.",
    type: "(Stock Playlist)",
    brands: [
      {
        title: "ralph lauren",
        image: polo,
        description:
          "'It ain't Ralph though!' - Kanye West Hip-Hop adopted Ralph Lauren in the 90's and we never let go. From Raekwon in the Snow Beach pullover to Ye in the Pink Polo. Who will remix it next?",
      },
      {
        title: "yeezy",
        image: gps,
        description:
          "While YEEZY isn't a publicly traded company, we've included GAP (GPS), one of Ye's big collaborators.",
      },
      {
        title: "nike",
        image: Nike,
        description:
          "This is the house that Tinker Hatfiled and His Airness built. The Jordan brand is timeless and unbound by trends and has more collabs under its belt than any other. You can own this and the rest of the Nike brand family.",
      },
      {
        title: "stadium goods",
        image: stadium,
        description:
          "Did you know that Stadium Goods, one of the go-to place for sneakers, is actually owned by one of the biggest resale brands around - Farfetch.",
      },
      {
        title: "supreme",
        image: supreme,
        description:
          "This streetwear titan was purchased by VF Corp (VF) in 2020. VF also owns brands like The Northface, Vans, Timberland and more. ",
      },
    ],
  },
  {
    title: "MONEY",
    image: MONEY,
    info: "Cash rules everything around me.",
    long_info: "Cash rules everything around me.",
    type: "(Stock + Crypto Playlist)",
    brands: [
      {
        title: "affirm",
        image: affirm,
        description:
          "Affirm took the pay later concept that was used forever in places like Brazil and spread it worldwide. It created a whole industry called Buy Now, Pay Later.",
      },
      {
        title: "visa",
        image: visa,
        description:
          "Trivia Question: Which gigantic bank used to own Visa? Answer: Bank of America. Before 1976, Visa's name was BankAmericard. It later changed its name to Visa to be consistent globally and eventually turned into an independent publicly traded company in 2008.",
      },
      {
        title: "coinbase",
        image: coinbase,
        description:
          "They may be a new publicly traded company, but they aren't new to crypto.",
      },
      {
        title: "chase",
        image: chase,
        description:
          "Often referred to in closed circles as the Nike of Banks. JPMorgan Chase is the biggest bank in the United States.",
      },
      {
        title: "square",
        image: square,
        description:
          "Did you know that CashApp, Tidal and AfterPay were all owned by Square parent company, Block? Pay Here, Pay Friends, Pay Later, Play Music? It makes sense if you make it.",
      },
    ],
  },
  {
    title: "FAANG",
    image: faang,
    info: "They're all listening to us.",
    long_info: "They’re all listening to us. Own them back.",
    type: "(Stock Playlist)",
    brands: [
      {
        title: "amazon",
        image: amazon,
        description:
          "Seller of everything. Father of Alexa. First of its name.",
      },
      {
        title: "apple",
        image: apple,
        description:
          "They made it uncool to see a green bubble in your SMS messages. ",
      },
      {
        title: "google",
        image: google,
        description:
          "Google did online search so well that its name became a verb.",
      },
      {
        title: "instagram",
        image: instagram,
        description:
          "If you know what Instagram is, then you're probably on Instagram. Instagram's uncle's name is Facebook and they all live in a house now named Meta.",
      },
      {
        title: "netflix",
        image: netflix,
        description:
          "Netflix started out mailing DVDs, now its dominating Hollywood and streaming video.",
      },
    ],
  },
  {
    title: "PRESS PLAY",
    image: PRESSPLAY,
    info: "Start a mood.",
    long_info: "Start a mood. Invest and chill.",
    type: "(Stock Playlist)",
    brands: [
      {
        title: "att",
        image: att,
        description:
          "AT&T has been doing communications longer than most brands around. From internet and cable to mobile phone service - if you need it they probably have it.",
      },
      {
        title: "comcast",
        image: comcast,
        description:
          "Comcast is one of the biggest modern success stories from the city of Philadelphia. It started small and now owns businesses across cable, internet, media and film.",
      },
      {
        title: "disney",
        image: disney,
        description:
          "From Mickey to Marvel, Hulu to ESPN - Disney is a household name.",
      },
      {
        title: "live nation",
        image: livenation,
        description:
          "The undisputed king of events and entertainment. LiveNation has both RocNation and Ticketmaster under its umbrella. If you're out enjoying an event, there's a strong possibility that they're behind it somehow.",
      },
      {
        title: "spotify",
        image: spotify,
        description:
          "You should really check out that Neon playlist at joinneon.com. Just sayin.",
      },
    ],
  },
];
