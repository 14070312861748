import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import CompleteLabel from "../components/CompleteLabel";
import DynamicAmount from "../components/DynamicAmount";
import TransactionDetail from "../components/TransactionDetail";
import SkewLoader from "react-spinners/SkewLoader";
import { css } from "@emotion/react";
import { refresh } from "../helpers/Auth";
import { closeModal } from "../Store/Modal/Reducers";
import ReactGA from "react-ga4";
import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function SellStock({ brandToSell }) {
  const dispatch = useDispatch();
  const override = css`
    display: inline-flex;
    margin-bottom: 1rem;
  `;
  function handleClose() {
    dispatch(closeModal());
  }
  const { vfp, access_token, refresh_token, user } = useSelector(
    (state) => state.login
  );

  const averageGainsTxt = `This is the difference between today's \n market value of your stocks versus \n when you bought them.`;

  const [custom, setCustom] = useState();
  const [error, setError] = useState({});
  const [sold, setSold] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState({
    vfp: vfp,
    user_id: user.id,
    type: "SELL",
    quantity: 0,
    symbol: brandToSell.symbol,
  });
  let stockAmount =
    (stock.quantity * brandToSell.today_value_available) /
    brandToSell.quantity_available;
  const [detail, setDetail] = useState(null);
  const fullStockAmount = brandToSell.quantity_available;

  console.log(brandToSell);

  const handleInput = (e) => {
    const customAmount = e.target.value;
    if (customAmount <= fullStockAmount) {
      setError({});
      setStock({ ...stock, quantity: customAmount });
    } else {
      setStock({ ...stock, quantity: 0 });
      setError({
        message:
          "The amount must be equal or less than " +
          fullStockAmount.toFixed(5) +
          " qty",
        destiny: "customAmount",
      });
    }
  };
  useEffect(() => {
    refresh(refresh_token);
  }, []);

  const handleAmount = () => {
    setCustom(false);
    setError({});
    setStock({ ...stock, quantity: fullStockAmount });
  };

  const handleCustom = () => {
    setStock({ ...stock, quantity: 0 });
    setCustom(true);
  };

  const handleSell = () => {
    if (stock.quantity > 0) {
      compleSell();
    } else {
      setError({
        message: "Please select one option.",
        destiny: "customAmount",
      });
    }
  };

  const compleSell = async () => {
    ReactGA.event({
      category: "SELL",
      action: "SELL_CLICKED",
      label: "quantity: " + stock.quantity,
    });
    setLoading(true);
    setError({});
    const data = {
      user_id: user.id,
      type: "SELL",
      quantity: parseFloat(stock.quantity),
      symbol: brandToSell.symbol,
      playlist_id: brandToSell.playlist_id,
      amount: stockAmount,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.STOCK_TRADING,
        },
      })
      .then((response) => {
        if (!response.data.detail[0].status) {
          handleSellError();
          return;
        }
        ReactGA.event({
          category: "SELL",
          action: "SELL_COMPLETED",
        });
        setDetail(response.data.detail[0]);
        setLoading(false);
        setSold(true);
      })
      .catch((error) => {
        console.error(error.response);
        handleSellError();
      });
  };

  const handleSellError = () => {
    ReactGA.event({
      category: "SELL",
      action: "SELL_ERROR",
    });
    setError({
      message:
        "Something went wrong. Please hit us on chat if the issue persists.",
      destiny: "sell",
    });
    setLoading(false);
  };

  const sellIt = (
    <div style={{ width: "auto", margin: "auto" }}>
      <div
        style={{
          paddingTop: "2rem",
          paddingInline: "1rem",
          textAlign: "center",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <span className="title white pointer" onClick={() => handleClose()}>
            X
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "justify",
        }}
      >
        <div style={{ marginTop: "1rem" }}>
          <div
            style={{
              maxWidth: "25rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span className="sub-title white bold">
              {brandToSell.brand_name} you own
            </span>
            <span className="sub-title white ">
              ${brandToSell.today_value_available.toFixed(2)}
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            paddingTop: "2rem",
            fontSize: "1rem",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <DynamicAmount
              amount={brandToSell.since_purchase_value.toFixed(2)}
              text={""}
              customStyle={{ justifyContent: "center" }}
            />
            <span
              className={
                brandToSell.average_gains.toFixed(2) >= 0
                  ? "neon-color white-space"
                  : "white white-space"
              }
            >
              {" "}
              average total{" "}
              <div
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                gains
                <div>
                  <span
                    className="txt-s"
                    style={{
                      color: "white",
                      display: "inline-flex",
                      alignItems: "end",
                    }}
                    data-tip={averageGainsTxt}
                    data-for="tooltip5"
                  >
                    <IoMdInformationCircleOutline
                      style={{ fontSize: 17, paddingTop: 2 }}
                      viewBox="0 0 590 590"
                    />
                  </span>
                  <div
                    style={{
                      marginRight: "2rem",
                      display: "flex",
                    }}
                  >
                    <ReactTooltip
                      id="tooltip5"
                      type="warning"
                      effect="solid"
                      place="bottom"
                      backgroundColor="gray"
                      multiline={true}
                      clickable={true}
                      wrapper="div"
                    >
                      <span
                        className="white"
                        style={{
                          display: "block",
                          width: "15rem",
                          height: "auto",
                          textAlign: "center",
                        }}
                      >
                        {averageGainsTxt}
                      </span>
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div>
            <div style={{ paddingTop: "2rem" }}>
              <span className="white bold">How much do you want to sell?</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "1rem",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "5rem", margin: "auto" }}>
                  <button
                    className={custom === false ? "btn" : "btn-inverse"}
                    style={{ width: "100%", fontSize: "1rem" }}
                    onClick={() => handleAmount()}
                  >
                    Sell all
                  </button>
                </div>
                <div
                  style={{ width: "5rem", margin: "auto", textAlign: "left" }}
                >
                  <span className="white txt-1">
                    {brandToSell.quantity_available.toFixed(4)} qty
                  </span>
                </div>
              </div>

              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "5rem", margin: "auto" }}>
                  {!custom ? (
                    <button
                      className={"btn-inverse"}
                      style={{ width: "100%", fontSize: "1rem" }}
                      onClick={() => handleCustom()}
                    >
                      custom
                    </button>
                  ) : (
                    <input
                      className="input txt-1"
                      type="number"
                      value={stock.quantity ? stock.quantity : ""}
                      placeholder="Enter amount"
                      style={{ maxWidth: "100%" }}
                      onChange={(e) => handleInput(e)}
                    />
                  )}
                </div>
                <div
                  style={{ width: "5rem", margin: "auto", textAlign: "left" }}
                >
                  <span className="white txt-1" style={{ color: "black" }}>
                    $50.12
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ErrorMessage error={error} destiny={"customAmount"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "1rem",
              }}
            >
              <div style={{ paddingTop: "1rem" }}>
                <span className="white bold">Deposit into</span>
              </div>
              <div style={{ paddingTop: "1rem" }}>
                <span className="white">Neon Money Club wallet</span>
              </div>

              {loading ? (
                <div style={{ paddingTop: "2rem" }}>
                  <SkewLoader color={"#16e7cf"} css={override} size={8} />
                </div>
              ) : (
                <div>
                  <div style={{ paddingTop: "1rem" }}>
                    <ErrorMessage error={error} destiny={"sell"} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <span className="white">
            {stock.quantity + " qty - ($" + stockAmount.toFixed(2) + ")"}
          </span>
        </div>
        <div
          style={{ width: "100%", marginTop: "0.5rem", alignSelf: "center" }}
        >
          <div style={{ alignSelf: "center" }}>
            {sold ? <CompleteLabel text={"Sell initiated"} /> : null}
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              sold ? handleClose() : handleSell();
            }}
            className="btn-inverse-round"
          >
            {sold ? "Close*" : "Sell"}
          </button>
        </div>
      </div>
      <div
        className="white"
        style={{ marginTop: "2rem", maxWidth: "30rem", alignSelf: "center" }}
      >
        <span style={{ textAlign: "center", fontSize: "0.9rem" }}>
          * Estimated to arrive in 1-5 days. {`\n`}
          Stocks buys made with promotional credit can be sold at any time{" "}
          {`\n`}
          after settling, but cannot be cashed out until 90 days of {`\n`}
          becoming a member.
        </span>
      </div>
    </div>
  );
  return sellIt;
}
