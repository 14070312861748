export default function Switcher({ active, onSwitch, title }) {
  return (
    <div
      className="mt-1 mb-1"
      style={{ alignSelf: "center", textAlign: "center" }}
    >
      <span className="sub-title bold">{title}</span>

      <div className={!active ? "lock-switcher-l" : "lock-switcher-r"}>
        {!active ? <span className="switcher-state bold">on</span> : null}
        <div className="switch" onClick={() => onSwitch()}></div>
        {active ? <span className="switcher-state bold">off</span> : null}
      </div>
    </div>
  );
}
