import { createSlice } from "@reduxjs/toolkit";

const brandsSlice = createSlice({
  name: "brands",
  initialState: {
    brand: null,
    brands: null,
    settings: null,
  },
  reducers: {
    setBrand: (state, action) => {
      state.brand = action.payload.brand;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setBrandsSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setBrand, setBrands, setBrandsSettings } = brandsSlice.actions;
export default brandsSlice.reducer;
