import React from "react";
import ErrorOutlineSharpIcon from "@material-ui/icons/ErrorOutlineSharp";

export default function ErrorMessage({ error, destiny, position, link }) {
  return (    
    <div>
      {error && error.destiny === destiny ? (
        <div
          style={{
            paddingTop: "0.5rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            position: position ? position : "relative",
            margin: "auto",
            maxWidth: "18rem",
          }}
        >
          <ErrorOutlineSharpIcon
            style={{
              color: "red",
              marginRight: "0.25rem",
              fontSize: "1rem",
            }}
          />
          <span className="error">{error.message}<span className=" error underline">{link}</span></span>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
