export const getStartedSteps = [
  {
    title: "Move with intention",
    description:
      "You want to grow your money, but never had the tools, support or trust fund.",
  },
  {
    title: "Join the wave",
    description:
      "Access the club in 30 seconds to create an account (bank-level privacy + security).",
  },
  {
    title: "Learn the game and start investing",
    description:
      "Own stock in the brands you love (your first $30 is on us). You’ll get access to monthly drops with every $1 you invest.",
  },
];

export const clubBenefitsSteps = [
  {
    title: "Community",
    description:
      "Join a club with like-minded people + get access to monthly drops.",
  },
  {
    title: "Unlocks",
    description:
      "Complex financial terms? Nope. We put you on game using things you already know.",
  },
  {
    title: "Tools",
    description:
      "Our platform allows you to start directly investing in the brands you love.",
  },
];
