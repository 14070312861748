import React, { createRef, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import SkewLoader from "react-spinners/SkewLoader";
import { css } from "@emotion/react";
import amex from "../../../images/amex.gif";
import { URIS } from "../../../Constants/Endpoints";
import axios from "axios";
import { loginSuccess } from "../../../Store/Login/Reducers";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import card from "../../../images/card-wallet.gif";
import { benefitsData } from "../../../Constants/Card";
import ErrorMessage from "../../../components/ErrorMessage";
import { openModal } from "../../../Store/Modal/Reducers";

export default function CardQualifyKYC({
  modal,
  setOpen,
  form,
  changeHandler,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(2).fill(false));
  const [frame, setFrame] = useState("");
  const [employed, setEmployed] = useState(false);
  const [consented, setConsented] = useState(false);
  let { user, access_token, vfp } = useSelector((state) => state.login);
  const ref = createRef();
  const query = new URLSearchParams(location.search);
  const vfpParam = query.get("vfp");
  const [cardAccepted, setCardAccepted] = useState(null);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    if (updatedCheckedState.filter((value) => value === true).length === 1) {
      handleClicks("disclosured", true);
    } else {
      handleClicks("disclosured", null);
    }
  };

  const handleClicks = (target, value) => {
    ReactGA.event({
      category: "KYCyesno",
      action: target + "_clicked",
    });
    changeHandler(target, value);
  };

  const handleFrame = (url) => {
    setFrame(url);
    setOpen(true);
    ref.current.scrollTo(0, 0);
  };

  useEffect(() => {
    if (consented === true) {
      setShowTerms(true);
    } else {
      setShowTerms(false);
    }
  }, [consented]);

  //Last step, ran after clicking next in the flag screen
  const handleWelcome = () => {
    let dashboardUrl =
      "/?vfp=" + vfpParam + "&phone_number=" + form.phone_number + "&card=true";
    history.push(dashboardUrl);
    dispatch(openModal({ modal: "CardDashboard" }));
  };

  const handleActivateCard = async () => {
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_ACTIVATE,
      },
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
      .then((res) => {
        setLoading(false);
        setError({});
        handleWelcome();
      })
      .catch((error) => {
        console.error(error.response);
        setCardAccepted(false);
        setLoading(false);
        setError({
          destiny: "card",
          message:
            "We ran into an issue activating your card. Hit us on chat and we'll figure this out.",
        });
        setTimeout(() => {
          handleWelcome();
        }, 5000);
      });
  };

  const handleCreateAccount = async () => {
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.CREATE_ACCT,
      },
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
      .then((message) => {
        //setLoading(false);
        if (message.data.status) {
          let data = { user: message.data.user, vfp: vfp };
          dispatch(loginSuccess(data));
          setError({});
          handleActivateCard();
        }
      })
      .catch((error) => {
        console.error(error.response);
        setCardAccepted(false);
        setLoading(false);
        setError({
          destiny: "card",
          message:
            "We ran into an issue creating your account. Hit us on chat and we'll figure this out.",
        });
      });
  };

  const handleAcceptCard = async () => {
    setError({});
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_ACCEPT,
        },
      })
      .then((res) => {
        setCardAccepted(true);
        handleCreateAccount();
      })
      .catch((err) => {
        setCardAccepted(false);
        setLoading(false);
        setError({
          destiny: "card",
          message:
            "We ran into an issue activating your card. Hit us on chat and we'll figure this out.",
        });
        console.error(err);
      });
  };

  const disclosure = (
    <div>
      <span className="txt-s futura-mid" style={{ fontWeight: "bold" }}>
        Member terms
      </span>

      <div
        className="txt-s futura-mid white"
        style={{
          minWidth: "auto",
          textAlign: "left",
          marginTop: "2rem",
          justifyContent: "center",
        }}
      >
        <div>
          <span>I agree that I am:</span>
          <br />
          <ol>
            <li>A United States Citizen or Legal Permanent Resident </li>
            <li>
              {" "}
              Not affiliated with or employed by FINRA or a Broker-Dealer{" "}
            </li>
            <li>
              Not a Politically Exposed Person or an owner of more than 10% in a
              publicly traded company.{" "}
            </li>
          </ol>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <span>I accept the following terms & conditions:</span>
          <p />{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <input
              className="checkbox"
              type="checkbox"
              id="term1"
              checked={checkedState[0]}
              onChange={() => handleOnChange(0)}
            />{" "}
            <ul style={{ listStyleType: "none", marginLeft: "-1.5rem" }}>
              <li>
                <label htmlFor="term1" style={{ lineHeight: 1.5 }}>
                  <a
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleFrame("https://joinneon.com/disclosures")
                    }
                  >
                    Neon member terms
                  </a>{" "}
                  , Investment partner terms (DriveWealth’s{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleFrame("https://apps.drivewealth.com/disclosures/")
                    }
                  >
                    disclosures,
                  </span>{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleFrame(
                        "https://legal.drivewealth.com/privacy-policy"
                      )
                    }
                  >
                    privacy policy,
                  </span>{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleFrame(
                        "https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true"
                      )
                    }
                  >
                    {" "}
                    w9-agreement
                  </span>
                  ),
                </label>
              </li>
              <br></br>
              <br></br>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const neonFrame = (
    <div style={{ maxWidth: "100vw", margin: "auto" }} ref={ref}>
      <div>
        <span
          style={{
            textAlign: "right",
            display: "block",
          }}
          className="title white pointer"
          onClick={() => setOpen(false)}
        >
          X
        </span>
      </div>
      <div style={{ position: "relative" }}>
        <iframe
          title="frame"
          src={frame}
          width="800"
          height="800"
          allowFullScreen={true}
          className="disclosure-Iframe"
        ></iframe>
      </div>
    </div>
  );

  const questions = (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <span
            className="futura-bold  white "
            style={{ width: "100%", fontSize: "1.5rem" }}
          >
            The Card That Invests In You
          </span>
        </div>
      </div>
      <div className="row" style={{ justifyContent: "center" }}>
        <img src={card} width="100px" />
      </div>
      <div
        style={{
          textAlign: "left",
        }}
      >
        <span className="sub-title">What you get</span>
      </div>
      {benefitsData.map((benefit, index) => {
        return (
          <div
            className="row mt-1 benefits-rows txt-1"
            style={{
              justifyContent: "center",
              textAlign: "left",
            }}
            key={index}
          >
            <div
              style={{
                padding: "0.5rem",
                textAlign: "center",
                width: "20%",
                margin: "auto",
              }}
            >
              <span className="bold sub-title ">{benefit.title}</span>
            </div>
            <div
              style={{
                padding: "0.5rem",
                width: "70%",
              }}
            >
              <span>{benefit.content}</span>
            </div>
          </div>
        );
      })}
      {!loading && cardAccepted !== false ? (
        <>
          <div
            className="title mt-1"
            style={{ position: "relative", textAlign: "center" }}
          >
            <img src={amex} width="100%" />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
              }}
            >
              <span>
                Congrats! <br />
                The first AMEX card to invest in you is ready.
              </span>
            </div>
          </div>
          <div className="white">
            <div style={{ paddingTop: "2rem" }}>
              <div>
                <div>
                  <span className="txt-s futura-mid">
                    Do you work? (employed/self-employed)
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "0.5rem" }}>
                    <button
                      id="work-option-yes"
                      className={form.employed ? "btn" : "btn-inverse"}
                      onClick={() => {
                        handleClicks("employed", true);
                        setEmployed(true);
                      }}
                    >
                      yes
                    </button>
                  </div>
                  <div style={{ padding: "0.5rem" }}>
                    <button
                      className={
                        !form.employed && form.employed != null
                          ? "btn"
                          : "btn-inverse"
                      }
                      onClick={() => {
                        handleClicks("employed", false);
                        setEmployed(false);
                      }}
                    >
                      no
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "2rem" }}>
              <div>
                <span className="txt-s futura-mid">
                  Have you reviewed{" "}
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      handleFrame("https://joinneon.com/electronic-consent")
                    }
                  >
                    electronic consent?
                  </span>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: "0.5rem" }}>
                  <button
                    id="electronic-consented-yes"
                    className={form.consented ? "btn" : "btn-inverse"}
                    onClick={() => {
                      handleClicks(
                        "consented",
                        form.first_name + " " + form.last_name
                      );
                      setConsented(true);
                    }}
                  >
                    yes
                  </button>
                </div>
                <div style={{ padding: "0.5rem" }}>
                  <button
                    className={
                      !form.consented && form.consented !== null
                        ? "btn"
                        : "btn-inverse"
                    }
                    onClick={() => {
                      handleClicks("consented", "");
                      setConsented(false);
                    }}
                  >
                    no
                  </button>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {showTerms ? disclosure : null}
              </div>
              <div
                style={{
                  paddingTop: "0.5rem",
                  maxWidth: "15rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <span className="error">
                  {form.disclosured === false
                    ? "In order to claim your stock, you need to agree to the disclosures."
                    : null}
                  {error.destiny === "other" ? error.message : null}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : cardAccepted !== false ? (
        <LoadingMembershipSpinner />
      ) : (
        <></>
      )}
      <div className="mt-1">
        <ErrorMessage error={error} destiny={"card"} />
      </div>
    </div>
  );

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem" }}>
        {modal ? neonFrame : questions}
        {consented && disclosure && !loading && cardAccepted !== false ? (
          <button
            className="btn-inverse-round txt-1 "
            style={{
              marginTop: "1rem",
            }}
            onClick={() => handleAcceptCard()}
          >
            Activate your new card
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function LoadingMembershipSpinner() {
  const override = css`
    display: inline-flex;
    margin-bottom: 1rem;
  `;
  return (
    <div className="mt-1">
      <div className="membership-loading">
        <span
          className="txt-1 futura-mid white"
          style={{ alignSelf: "center" }}
        >
          Your club access is loading...
        </span>
        <div style={{ marginTop: "1rem" }}>
          <span
            className="txt-1 futura-bold white"
            style={{
              alignSelf: "center",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            (Please don't refresh this page)
          </span>
        </div>
        <div style={{ marginTop: "3rem" }}>
          <SkewLoader color={"#16e7cf"} css={override} size={10} />
        </div>
        <div style={{ marginTop: "3rem" }}>
          <span className="txt-1-mid futura-mid white">
            “I deserve the threads in my closet Big boy deposits, Nike shares
            and stock tips Catching up with those who had a head start” -{" "}
            <a
              className="white pointer"
              style={{ textDecoration: "underline" }}
              href="https://genius.com/Nas-nas-is-good-lyrics/"
              target="_blank"
              rel="noreferrer"
            >
              Nas
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
