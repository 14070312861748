import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../Store/Modal/Reducers";
import "./DigitalWall.css";

export default function DigitalWallet() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);

  function close() {
    dispatch(closeModal());
  }

  function goBack() {
    dispatch(openModal({ modal: "CardDashboard" }));
  }

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span className="txt-1 neon-color pointer" onClick={() => goBack()}>
            Back
          </span>
          <div
            className="futura-bold white row"
            style={{
              maxwidth: "90%",
              textAlign: "left",
              fontSize: "1.5rem",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>Add to wallet</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: origin, origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            className="info-Card txt-s"
            style={{
              textAlign: "center",
              marginInline: "1rem",
            }}
          >
            <span style={{ marginInline: "1rem" }}>
              Want to add your card to a digital wallet like Apple Pay or Google
              Pay? Tap the button bellow.
            </span>
          </div>

          <div
            className="txt-s"
            style={{ color: "white", marginTop: "2rem", textAlign: "center" }}
          >
            Current status: Not yet added to Digital Wallet
          </div>
          <button className="btn-inverse-round txt-1 ">
            Add to Digital Wallet
          </button>
        </div>
      </div>
    </div>
  );
}
