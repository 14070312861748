import React from "react";

export default function StepList({ steps, title, isStep }) {
  return (
    <div
      className="white"
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2rem !important",
        maxWidth: "350px",
        margin: "auto",
      }}
    >
      <div>
        <span>{title}</span>
      </div>
      <div style={{ marginTop: title ? "1rem" : null }}>
        {steps.map((step, index) => (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}
            key={index}
          >
            <div
              style={{
                paddingInline: "0.60rem",
                paddingBlock: ".15rem",
                border: "1px solid white",
                borderRadius: "0.5rem",
                fontSize: "0.83rem",
                fontWeight: "bold",
                minWidth: "3rem",
                marginRight: "1rem",
                height: "1rem",
                lineHeight: "1rem",
                whiteSpace: "nowrap !important",
              }}
            >
              <span>
                {isStep ? "Step" : ""} {index + 1}
              </span>
            </div>
            <div
              style={{
                flexDirection: "column",
                maxWidth: "75%",
                textAlign: "left",
                paddingLeft: step.title ? "unset" : "0.5rem",
              }}
            >
              <div>
                <span className="bold">{step.title}</span>
              </div>
              <div
                style={{
                  fontSize: "0.83rem",
                  marginTop: step.title ? "1rem" : "none",
                }}
              >
                <span>{step.description}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
