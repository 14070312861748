import jordan from "../images/search-brands/jordan.png";
import nike from "../images/search-brands/Nike.png";
import converse from "../images/search-brands/converse.png";

import supreme from "../images/supreme.png";
import vans from "../images/search-brands/vans.png";
import northface from "../images/search-brands/northface.png";

import disney from "../images/search-brands/disney.png";
import espn from "../images/search-brands/espn.png";

import facebook from "../images/search-brands/facebook.png";
import Instagram from "../images/search-brands/Instagram.jpeg";

import gap from "../images/search-brands/gap.png";
import yeezy from "../images/search-brands/yeezy.png";

import xbox from "../images/search-brands/xbox.png";
import microsoft from "../images/search-brands/microsoft.png";
import linkedin from "../images/search-brands/linkedin.png";

import amazon from "../images/search-brands/amazon.png";
import mcdonalds from "../images/search-brands/mcdonalds.png";

import mac from "../images/search-brands/mac.png";
import tesla from "../images/search-brands/tesla.png";

export const brands = [
  {
    name: "Supreme",
    image: supreme,
    symbol: "VFC",
    description:
      "This streetwear titan was purchased by VF Corp (VF) in 2020. VF also owns brands like The Northface, Vans, Timberland and more. ",
  },
  {
    name: "Tesla",
    image: tesla,
    symbol: "TSLA",
    description: "",
  },
  {
    name: "Mac",
    image: mac,
    symbol: "EL",
    description: "",
  },
  {
    name: "Jordan",
    image: jordan,
    symbol: "NKE",
    description:
      "This is the house that Tinker Hatfiled and His Airness built. The Jordan brand is timeless and unbound by trends and has more collabs under its belt than any other. You can own this and the rest of the Nike brand family.",
  },
];

export const topTen = [
  {
    brands: "Nike, Jordan, Converse",
    images: [nike, jordan, converse],
    symbol: "NKE",
    owned_by: "Nike",
    description:
      "How many brands have their own Frank Ocean song? Just one. The Swoosh house is also home to MJ (Jordan Brand) and Chuck Taylor (Converse).",
  },
  {
    brands: "Supreme, Vans, Northface",
    images: [supreme, vans, northface],
    symbol: "VFC",
    owned_by: "VF",
    description:
      "Supreme, Vans and The Northface are some of the best stories in fashion today. Now you can own the company that owns them all - VF Corp.",
  },
  {
    brands: "Disney, ESPN",
    images: [disney, espn],
    symbol: "DIS",
    owned_by: "Disney",
    description:
      "From Mickey to Marvel to Monday Night Football - Disney doesn't miss.",
  },
  {
    brands: "Instagram, Facebook",
    images: [facebook, Instagram],
    symbol: "META",
    owned_by: "Meta",
    description:
      "If you know what Instagram is, then you're probably on Instagram. Now you can own its parent company - Facebook.",
  },
  {
    brands: "GAP, Yeezy",
    images: [gap, yeezy],
    symbol: "GPS",
    owned_by: "Gap",
    description: "Gap brought Ye back home and we love them for it. Period.",
  },
  {
    brands: "Xbox, Microsoft, Linkedin",
    images: [xbox, microsoft, linkedin],
    symbol: "MSFT",
    owned_by: "Microsoft",
    description:
      "From XBox to LinkedIn, Microsoft has literally been putting in work for decades. ",
  },
  {
    brands: "Tesla",
    images: [tesla],
    symbol: "TSLA",
    owned_by: "Tesla",
    description:
      "Founded by our real life version of Tony Stark.TESLA forced its competitors to innovate toward a greener future.",
  },
  {
    brands: "Amazon",
    images: [amazon],
    symbol: "AMZN",
    owned_by: "Amazon",
    description: "Seller of everything. Father of Alexa. First of its name.",
  },
  {
    brands: "McDonald's",
    images: [mcdonalds],
    symbol: "MCD",
    owned_by: "McDonald's",
    description:
      "Daddy Mac. The most consistent. The Jay Z of the burger game. If you had bought 100 shares in McDonald's when it debuted on the stock market, it would be worth $12M today.",
  },
  {
    brands: "MAC",
    images: [mac],
    symbol: "EL",
    owned_by: "Estee Lauder",
    description:
      "Purchased by Estee Lauder in 1996, M.A.C. has been at the forefront of celebrating beauty in all of its many forms.",
  },
];
