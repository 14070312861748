export const investings = [1, 3];
export const frequencies = [
  { text: "Daily", subtext: "Every weekday (Mon-Fri)", value: "daily" },
  {
    text: "Monthly",
    subtext: "Once a month (Every 1st Monday)",
    value: "monthly",
  },
  { text: "Just once", subtext: "One and done", value: "one-time" },
];
