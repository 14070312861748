import React from "react";

export const VerifiedMessageComp = ({ saved }) => {
  return (
    <div>
      <span
        style={{
          color: "#07dbab",
          fontSize: 12,
          paddingTop: "2rem",
        }}
      >
        {saved
          ? "Your profile has been updated."
          : "Your identity was verified. Tap the SMS we just sent you to complete the update."}
      </span>
    </div>
  );
};
