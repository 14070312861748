import React from "react";
import { logOut } from "../helpers/Auth";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { clearStore } from "../Store/Login/Reducers";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

export default function Menu({ setTab }) {
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClose() {
    dispatch(closeModal());
  }

  let { refresh_token } = useSelector((state) => state.login);

  async function handleClick(option) {
    dispatch(openModal({ modal: option }));
  }

  const handleLogOut = () => {
    ReactGA.event({ category: "LOGIN", action: "LOGOUT_CLICKED" });
    dispatch(clearStore());
    logOut(refresh_token, history);
  };

  const handleGoToTab = (tab) => {
    dispatch(closeModal());
    setTab(tab);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div
        style={{
          textAlign: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            Menu
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
        <div
          style={{
            marginTop: "3.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <button
            className="button-menu"
            onClick={() => handleClick("settings")}
          >
            ACCOUNT SETTINGS
          </button>
          <button
            className="button-menu"
            onClick={() => handleGoToTab("learn")}
          >
            LEARN
          </button>
          <button className="button-menu" onClick={() => handleGoToTab("club")}>
            CLUB
          </button>
          <button
            className="button-menu"
            onClick={() => handleGoToTab("wallet")}
          >
            WALLET
          </button>
          <button className="button-menu" onClick={() => handleLogOut()}>
            LOG OUT
          </button>
        </div>
      </div>
    </div>
  );
}
