export const investmentRisk = (
  <span className="txt-1 white pb-2 all-Inv">
    All investments involve risks, including the loss of principal. Securities
    trading offered through DriveWealth, LLC., a registered broker-dealer and
    Member SIPC. Full disclosures {"  "}
    <a
      className="white"
      style={{
        marginBottom: "0.75rem",
        cursor: "pointer",
        textDecoration: "underline",
      }}
      href="https://joinneon.com/disclosures"
      target="_blank"
    >
      here
    </a>
    .
  </span>
);

export const promoCreditText = (
  <span className="txt-1 white pb-2">
    Promotional credit will be used to make your first $30 in buys. When the
    credit runs out, you agree that Neon Money Club can debit your connected
    external Bank account one day before your scheduled buy(s) to be made at
    12PM ET the following day, each eligible day. You can edit or pause your
    buy(s) at any time.
    <br />
    <br /> Stock buy(s) made with promotional credit can be sold at any time,
    but cannot be cashed out until after 90 days of becoming a member.
  </span>
);

export const pendingText = (
  <span className="txt-1 white pb-2">
    An ACH has been initiated. This takes about a day and then we'll update your
    playlist buys.
  </span>
);
