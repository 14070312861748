import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./Store";
import { PersistGate } from "redux-persist/integration/react";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'

const options = {
  position: positions.TOP_CENTER,
  transition: transitions.FADE,
  containerStyle:{
    pointerEvents: 'initial'
  }
}

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div style={{ backgroundColor: '#1d4739', margin:10, display:'flex', flexDirection:'row', color:'white',alignContent:'space-around'}} onClick={close}>
      {message}
      <div  style={{color:'white', backgroundColor:'#1d4739', alignSelf:'center', width:30,marginLeft:20}}>X</div>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AlertProvider template={AlertTemplate}{...options}>
          <App />
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
  document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1,maximum-scale=1')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
