import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { URIS } from "../Constants/Endpoints";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { investings, frequencies } from "../Constants/WeeklyInvesting";
import CompleteLabel from "../components/CompleteLabel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ErrorMessage from "../components/ErrorMessage";
import PlaidLink from "../components/Plaid";
import { refresh } from "../helpers/Auth";
import TriangleSpinner from "../components/TriangleSpinner";
import { updatePlaid } from "../Store/Login/Reducers";
import { clearPlaylistsSettings } from "../Store/Playlists/Reducers";
import {
  pendingText,
  promoCreditText,
  investmentRisk,
} from "../helpers/BuyPlaylistTexts";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import GetSSN from "../components/GetSSN";
import ReactGA from "react-ga4";
import { getUserData } from "../helpers/DashboardMainFunctions";
import { isMobile } from "react-device-detect";
import {
  getCurrentDayAndWeek,
  getDayAndWeekFromDate,
} from "../helpers/Dates/DatesFunctions";

export default function BuyPlaylist() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dayAndWeek, setDayAndWeek] = useState(null);
  const [completedBuy, setCompletedBuy] = useState(false);
  const [gettingSsn, setGetSsn] = useState(false);
  const [completePause, setCompletedPause] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [firstBuy, setFirstBuy] = useState(false);
  const [acctAdded, setAcctAdded] = useState(false);
  const [buyDisabled, setBuyDisabled] = useState(false);
  const [buyAmount, setBuyAmount] = useState(null);
  const { playlist, playlist_detail, all_playlists_settings, playlist_local } =
    useSelector((state) => state.playlists);
  let { user, access_token, refresh_token, vfp, user_status } = useSelector(
    (state) => state.login
  );
  let { origin } = useSelector((state) => state.modal);
  const history = useHistory();
  const wallet = useSelector((state) => state.dashboard.wallet);
  const credit = parseFloat(user.reward_balance).toFixed(2);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(null);
  const [pendingState, setPendingState] = useState({
    state: false,
    message: "",
  });
  const [notification, setNotification] = useState(null);
  const [seeFrequency, setSeeFrequency] = useState(false);
  const [buyFrequency, setBuyFrequency] = useState(null);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState(null);
  const handlePause = async () => {
    clearStates();
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      playlist_id: parseInt(playlist.id),
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.PAUSE_BUY,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setBuyAmount(null);
          let message = "*Playlist will update the next tradable day.";
          setPendingState({ state: true, message: message });
          setCompletedPause(true);
        } else {
          setError({
            message:
              "Something went wrong, please try again. Hit us on chat if this issue persists.",
            destiny: "Buy",
          });
        }
        setSettings({ ...settings, is_paused: !settings.is_paused });
        setLoading(false);
      })
      .catch(async (error) => {
        setLoading(false);
        if (error.response.status in [404, 502, 500, 400, 403]) {
          history.push("/404");
        } else if (error.response.status === 401) {
          let access = await refresh(refresh_token);
          if (access) {
            handlePause();
            return;
          }
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    if (acctAdded) {
      dispatch(updatePlaid(true));
    }
  }, [acctAdded]);

  useEffect(() => {
    if (!dayAndWeek && !playlist_local.owned) {
      let dayAndWeek = getCurrentDayAndWeek();
      setDayAndWeek(dayAndWeek);
    }
    async function loadSettings() {
      let pastHours = await checkBuyTime();
      if (
        pastHours &&
        !playlist_local.owned &&
        (wallet.playlists.length >= 1 || wallet.brands.length >= 1) &&
        parseFloat(user.reward_balance) > 0
      ) {
        setError({
          message: "The market opens M-F 9:30AM-4:30PM EST.",
          destiny: "Buy",
        });
        setBuyDisabled(true);
      }
      refresh(refresh_token);
      if (all_playlists_settings) {
        handleSettings();
      }

      await axios
        .post(
          process.env.REACT_APP_AUTH_PROXY_URL,
          { phone_number: user.phone_number },
          {
            headers: {
              "NEON-AUTHORIZATION": `Bearer ${access_token}`,
              audience_url:
                process.env.REACT_APP_CORE_API + URIS.CREATE_PLAID_TOKEN,
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setLinkToken(data.link_token);
        })
        .catch((error) => {
          console.error(error.response);
          if (error.response.status in [404, 502, 500, 400, 403]) {
            history.push("/404");
          }
        });
    }
    loadSettings();
  }, [playlist_local, user.phone_number, wallet.playlists.length]);

  const handleSettings = () => {
    if (!playlist_local.owned) {
      setSettings({ is_paused: false });
      return;
    }

    for (let setting of all_playlists_settings) {
      if (setting.playlist_id === parseInt(playlist.id)) {
        setSettings(setting);
        if (!dayAndWeek) {
          let dayAndWeekFromDate = getDayAndWeekFromDate(
            new Date(setting.created_at)
          );
          setDayAndWeek(dayAndWeekFromDate);
        }
      }
    }
    if (wallet.playlists.length > 0 || wallet.brands.length > 0) {
      setFirstBuy(false);
    } else {
      setFirstBuy(true);
    }
  };

  useEffect(() => {
    if (settings && !buyFrequency) {
      setBuyFrequency(settings.frequency);
    }
  }, [settings]);

  //when clicking custom button
  const handleClickCustomAmount = () => {
    if (settings.is_paused) return;
    setSeeFrequency(true);
    setShowCustomAmount(true);
    setBuyAmount(null);
  };

  //when entering amount in custom input
  const handleCustomAmountInput = (e) => {
    setBuyAmount(null);
    const customAmount = e.target.value;
    if (customAmount > 30 && parseInt(user.reward_balance) > 0) {
      setError({
        message:
          "There is a $30 limit for buys done with promotional credit. Please adjust the custom amount to proceed.",
        destiny: "Buy",
      });
      setCustomAmount(null);
    } else {
      setError({});
      setCustomAmount(parseInt(e.target.value));
    }
    //setBuyAmount(parseInt(e.target.value));
  };

  const handleBuyFrequency = (frequency) => {
    if (settings.is_paused) return;
    setShowCustomAmount(false);
    setBuyFrequency(frequency);
  };

  const handleClickAmount = (amount) => {
    if (settings.is_paused) return;
    //setCustomAmount(null);
    setShowCustomAmount(false);
    setSeeFrequency(true);
    setBuyAmount(amount);
  };

  const validateFields = (amount) => {
    if (amount) {
      setBuyAmount(amount);
    } else if (!buyAmount && !amount && !customAmount) {
      setError({
        message: "Please select an amount to invest.",
        destiny: "Buy",
      });
      setLoading(false);
      return false;
    } else if (!buyFrequency) {
      setError({
        message: "Please select a frequency to invest.",
        destiny: "Buy",
      });
      setLoading(false);
      return false;
    }
    return true;
  };

  const handleBuyResponse = (response) => {
    setLoading(false);
    if (response.data.status) {
      getUserData(access_token, user.phone_number, vfp, dispatch, history);
      let message = "*Playlist will start the next tradable day.";
      if (!firstBuy) {
        setPendingState({ state: true, message: message });
      }
      setCompletedBuy(true);
      if (response.data.notification) {
        setNotification(response.data.message);
      }
    } else {
      setError({
        message:
          "Something went wrong, please try again. Hit us on chat if this issue persists.",
        destiny: "Buy",
      });
    }
  };

  const handleBuy = async (amount) => {
    clearStates();
    if (!validateFields(amount)) {
      return;
    }
    setLoading(true);
    let data = {
      user_id: user.id,
      type: "BUY",
      amount: buyAmount
        ? buyAmount
        : customAmount
        ? customAmount
        : settings.rule_amount,
      playlist_id: parseInt(playlist.id),
    };
    if (buyFrequency) {
      data.frequency = buyFrequency;
    }
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.STOCK_TRADING,
        },
      })
      .then((response) => {
        handleBuyResponse(response);
      })
      .catch(async (error) => {
        if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          history.push("/404");
        } else if (error.response.status === 401) {
          let access = await refresh(refresh_token);
          if (access) {
            handleBuy();
          }
        } else if (error.response.status === 406) {
          setLoading(false);
          setError({
            message:
              "It seems something went wrong. Please hit us on chat and we'll figure this out.",
            destiny: "Buy",
          });
        }
      });
  };

  const checkBuyTime = async (message) => {
    var now = new Date();
    var closeHours = 16.5;
    now = now.toLocaleString("en-US", { timeZone: "America/New_York" });
    now = new Date(now);
    if (now.getHours() + now.getMinutes() / 60 >= closeHours) {
      setPendingState({ state: true, message: message });
      return true;
    } else {
      return false;
    }
  };

  const handleEdit = async () => {
    clearStates();
    if (buyAmount === null && !buyFrequency && !customAmount) {
      setError({
        message: "Please select a different amount or frequency.",
        destiny: "Buy",
      });
      return;
    }
    setLoading(true);
    let data = {
      phone_number: user.phone_number,
      user_id: user.id,
      amount: buyAmount
        ? buyAmount
        : customAmount
        ? customAmount
        : settings.rule_amount,
      playlist_id: parseInt(playlist.id),
    };
    if (buyFrequency) {
      data.frequency = buyFrequency;
    }
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.EDIT_RULE,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setCompletedBuy(true);
          if (response.data.notification) {
            setNotification(response.data.message);
          }
          let message = "*Playlist will update the next tradable day.";
          setPendingState({ state: true, message: message });
        } else if (
          !response.data.status &&
          response.data.message === "Rule does not exist or is not active."
        ) {
          setError({
            message: "Playlist is currently paused.",
            destiny: "Buy",
          });
        } else {
          setError({
            message:
              "Something went wrong, please try again. Hit us on chat if this issue persists.",
            destiny: "Buy",
          });
        }
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          let access = await refresh(refresh_token);
          if (access) {
            handleEdit();
          }
        } else if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          history.push("/404");
        } else {
          setLoading(false);
          setError({
            message:
              "Something went wrong, please try again. Hit us on chat if this issue persists.",
            destiny: "Buy",
          });
        }
      });
  };

  const clearStates = () => {
    setCompletedPause(false);
    setPendingState(false);
    setAcctAdded(false);
    setCompletedBuy(false);
    setError(null);
  };

  const handleRefreshDashboard = () => {
    dispatch(clearPlaylistsSettings());
    dispatch(closeModal());
    history.push("/");
  };

  const notificationText = notification ? (
    <span className="txt-1 white pb-2">{notification}</span>
  ) : null;

  useEffect(() => {
    refresh(refresh_token);
  }, [linkToken]);

  const getButtonColor = () => {
    if (
      (settings.is_pending && !settings.is_paused) ||
      (settings.is_paused &&
        user.neon_userflow_status === "MEMBER-FUNDING-ISSUE")
    ) {
      return "gray";
    } else if (
      (user.registered_in_plaid ||
        acctAdded ||
        completedBuy ||
        completePause) &&
      !buyDisabled
    ) {
      return "#d1d1cc";
    } else {
      return "gray";
    }
  };

  const handleGetSsn = () => {
    if (!buyAmount) {
      setError({
        message: "Please select an amount to invest.",
        destiny: "Buy",
      });
      setLoading(false);
      return;
    }
    setGetSsn(true);

    if (isMobile) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.documentElement.style.overflow = "hidden";
    }
  };

  const callKYC = async () => {
    const form = {
      phone_number: user.phone_number,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      birthdate: user.birthdate,
      address: JSON.parse(user.address_raw),
      address_2: user.address_line_2,
      employed: user.employed,
      disclosured: true,
      consented: true,
      verifyToken: "",
    };

    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.KYC_VALIDATION,
      },
    };
    ReactGA.event({
      category: "LINKING-ACCOUNT",
      action: "SUBMITTED",
    });
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
      .then((message) => {
        if (message.data.status) {
          setLoading(false);
          handleBuy();
        }
      })
      .catch((error) => {
        ReactGA.event({
          category: "LINKING-ACCOUNT-FAILED",
          action: "FAILED",
        });
        console.error(error.response);
        setError({
          message:
            "Something went wrong. Please contact us on chat and we'll figure this out.",
          destiny: "Buy",
        });
        setLoading(false);
      });
  };
  const getButtonAction = () => {
    if (user.neon_userflow_status === "DW-ACCOUNT-NOT-CREATED") {
      history.push("/404");
    }
    if (
      (completedBuy && !playlist_local.owned) ||
      (completedBuy && playlist_local.owned) ||
      (completePause && playlist_local.owned)
    ) {
      return handleRefreshDashboard();
    } else if (playlist_local.owned === false && user_status.hasDW) {
      return handleBuy();
    } else if (settings.is_paused) {
      return handlePause();
    } else if (
      !user_status.hasDW ||
      user.neon_userflow_status === "DW-ACCOUNT-NOT-CREATED"
    ) {
      callKYC();
    } else if (!user_status.hasSSN) {
      return handleGetSsn();
    } else {
      return handleEdit();
    }
  };
  const getButtonText = () => {
    if (completePause || completedBuy) {
      return "Close";
    } else if (loading) {
      return "Loading...";
    } else if (settings.is_paused && !completePause) {
      return "Resume";
    } else if (settings.is_pending && !settings.is_paused) {
      return "Pending";
    } else if (playlist_local.owned === false) {
      return "Own";
    } else {
      return "Save";
    }
  };

  const getSsn = () => {
    setGetSsn(true);
  };

  return (
    <>
      {gettingSsn ? (
        <GetSSN
          handleClose={setGetSsn}
          handleBuy={handleBuy}
          clearBuyScreen={setLoading}
        />
      ) : (
        <div className="playlist-component">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
            }}
          >
            <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "left",
                  }}
                >
                  <span
                    className="txt-1 neon-color pointer"
                    onClick={() =>
                      dispatch(
                        openModal({
                          modal:
                            origin === "bankSecurity" ? "playlist" : origin,
                        })
                      )
                    }
                  >
                    Back
                  </span>
                  <span className="sup-title-mid white ">
                    {playlist.playlist_name || playlist.name}
                    <br />
                    <span className="title">Own what you know</span>
                  </span>
                </div>

                <div
                  id="close-modal-button"
                  className="title white pointer"
                  onClick={() => dispatch(closeModal())}
                  style={{ position: "absolute", top: "2rem", right: "0.5rem" }}
                >
                  X
                </div>
                <div>
                  {playlist_detail !== null &&
                  typeof playlist_detail === "object" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                      className="pt-1"
                    >
                      <span
                        className="white txt-1"
                        style={{ marginRight: "1rem" }}
                      >
                        ${playlist_detail.detail.balance.toFixed(2)}
                      </span>
                      {/**
                  <DynamicAmount
                    amount={playlist_detail.detail.difference_value.toFixed(2)}
                    text={"past 12 months"}
                  />*/}
                    </div>
                  ) : (
                    <Skeleton />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                    className="pt-1"
                  >
                    <span
                      className="white txt-s-light"
                      style={{ marginRight: "1rem" }}
                    >
                      Highest $
                      {playlist_detail !== null ? (
                        playlist_detail.detail.highest.toFixed(2)
                      ) : (
                        <Skeleton />
                      )}
                    </span>
                    <span className="txt-1 white">|</span>
                    <span
                      className="white txt-s-light"
                      style={{ marginLeft: "1rem" }}
                    >
                      Lowest $
                      {playlist_detail !== null ? (
                        playlist_detail.detail.lowest.toFixed(2)
                      ) : (
                        <Skeleton />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "2rem",
                  fontSize: "1rem",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {!playlist_local.owned &&
                  parseFloat(user.reward_balance) > 0 ? (
                    <div
                      className="sub-card"
                      style={{
                        padding: "1rem",
                        maxWidth: "25rem",
                        textAlign: "center",
                        lineHeight: "0.8rem",
                        marginTop: "0.2rem",
                        marginInline: "1rem",
                      }}
                    >
                      <span className="txt-s white">
                        Your first{" "}
                        <span
                          className="txt-s white"
                          style={{ fontWeight: "bold" }}
                        >
                          $30
                        </span>{" "}
                        is on us.
                      </span>
                    </div>
                  ) : null}
                  <div style={{ paddingTop: "1rem" }}>
                    <span className="white txt-1 bold">
                      How much do you want to invest?
                    </span>
                  </div>
                  {(settings && playlist_local.owned) ||
                  !playlist_local.owned ? (
                    <div className="column" style={{ margin: "auto" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        {investings.map((amount, index) => (
                          <div
                            style={{ width: "5rem", margin: "auto" }}
                            key={index}
                          >
                            <button
                              id={"daily-amount-" + amount}
                              className={
                                amount === buyAmount ||
                                (playlist_local.owned &&
                                  amount === settings.rule_amount &&
                                  !buyAmount &&
                                  !customAmount)
                                  ? "btn txt-1 pointer"
                                  : "btn-inverse txt-1 pointer"
                              }
                              style={{ width: "100%" }}
                              onClick={() => handleClickAmount(amount)}
                            >
                              ${amount}
                            </button>
                          </div>
                        ))}
                        <div
                          style={{
                            width: "5rem",
                            margin: "auto",
                          }}
                        >
                          {!showCustomAmount ? (
                            <div
                              style={{
                                position: "relative",
                              }}
                              className="column white"
                            >
                              <button
                                id={"daily-amount-" + "5"}
                                className={
                                  (customAmount && !buyAmount) ||
                                  (playlist_local.owned &&
                                    !buyAmount &&
                                    ![1, 3].includes(settings.rule_amount))
                                    ? "btn txt-1 pointer"
                                    : "btn-inverse txt-1 pointer"
                                }
                                style={{ width: "100%" }}
                                onClick={() => handleClickCustomAmount()}
                              >
                                {customAmount !== null
                                  ? "$" + customAmount
                                  : playlist_local.owned &&
                                    ![1, 3].includes(settings.rule_amount) &&
                                    settings.rule_amount !== null
                                  ? "$" + settings.rule_amount
                                  : "Custom"}
                              </button>
                              {!buyAmount &&
                              ((customAmount && !showCustomAmount) ||
                                (playlist_local.owned &&
                                  ![1, 3].includes(settings.rule_amount))) ? (
                                <span
                                  className=" txt-s"
                                  style={{
                                    position: "absolute",
                                    top: 38,
                                    left: 3,
                                    marginTop: "0.5rem",
                                  }}
                                >
                                  Tap to edit
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <div
                              className="txt-1"
                              style={{ position: "relative", margin: "0.1rem" }}
                            >
                              {customAmount ? (
                                <span
                                  style={{
                                    position: "absolute",
                                    left: 10,
                                    top: "60%",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                    color: "white",
                                  }}
                                >
                                  $
                                </span>
                              ) : (
                                <></>
                              )}
                              <input
                                pattern="\d*"
                                className="input txt-1"
                                value={customAmount ? customAmount : ""}
                                placeholder="Enter amount"
                                style={{ maxWidth: "100%" }}
                                onChange={(e) => handleCustomAmountInput(e)}
                                maxLength={6}
                                onBlur={() => setShowCustomAmount(false)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {seeFrequency || playlist_local.owned ? (
                        <>
                          <div style={{ paddingTop: "3rem" }}>
                            <span className="white txt-1 bold">How often?</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              marginTop: "1rem",
                            }}
                          >
                            {frequencies.map((frequency, index) => (
                              <div
                                className="column white"
                                style={{
                                  width: "5rem",
                                  margin: "auto",
                                  textAlign: "left",
                                  height: "6rem",
                                }}
                                key={index}
                              >
                                <button
                                  className={
                                    frequency.value === buyFrequency ||
                                    (playlist_local.owned &&
                                      frequency.value === settings.frequency &&
                                      !buyFrequency)
                                      ? "btn txt-1 pointer"
                                      : "btn-inverse txt-1 pointer"
                                  }
                                  style={{
                                    width: "100%",
                                    marginBottom: "0.5rem",
                                  }}
                                  onClick={() =>
                                    handleBuyFrequency(frequency.value)
                                  }
                                >
                                  {frequency.text}
                                </button>
                                <span className="txt-s">
                                  {frequency.value === "monthly" && dayAndWeek
                                    ? "Once a month (Every " +
                                      dayAndWeek.week +
                                      " " +
                                      dayAndWeek.day +
                                      ")"
                                    : frequency.subtext}
                                </span>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {whyDailyText()}
                    </div>
                  ) : (
                    <div style={{ width: "75%", margin: "auto" }}>
                      <Skeleton height="30px" />
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "1rem",
                      }}
                    >
                      <span className="white txt-1 bold">Investing with</span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          paddingBottom: "1rem",
                          justifyContent: "center",
                        }}
                      >
                        <span className="white txt-1">
                          {credit > 0
                            ? "Neon Money Credit: $" + credit
                            : "Your linked account"}
                        </span>
                        {(linkToken &&
                          (acctAdded || user.registered_in_plaid)) ||
                        (!user.registered_in_plaid &&
                          [
                            "MEMBER-NEED-PLAID-UPDATE",
                            "MEMBER-FUNDING-ISSUE",
                          ].includes(user.neon_userflow_status)) ? (
                          <PlaidLink
                            token={linkToken}
                            setError={setError}
                            acctAdded={acctAdded}
                            setAcctAdded={setAcctAdded}
                            setLoading={setLoading}
                            newRegistered={false}
                            buyAmount={buyAmount || customAmount}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {gettingSsn ? (
                    <div
                      className="sub-card"
                      style={{
                        padding: "0.5rem",
                        marginTop: "0rem",
                        marginBottom: "1rem",
                        maxWidth: "27rem",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        alignSelf: "center",
                        lineHeight: "1rem",
                        textAlign: "center",
                      }}
                    >
                      <span className="txt-s white">
                        The wealthiest of 10% Americans own 89% of all U.S.
                        stocks. They ain’t ready for us.
                      </span>
                    </div>
                  ) : null}
                  {acctAdded && !completedBuy ? (
                    <div style={{ marginBottom: "1rem" }}>
                      <div style={{ marginBottom: "1rem" }}>
                        <CompleteLabel text={"Bank updated!"} />
                      </div>
                    </div>
                  ) : null}
                  {completedBuy && !loading ? (
                    <CompleteLabel
                      text={"Complete!" + (pendingState.state ? "*" : "")}
                    />
                  ) : null}
                  {completePause ? (
                    <div style={{ marginBottom: "1rem" }}>
                      <div
                        style={{
                          marginBottom: "1rem",
                          margin: "auto",
                        }}
                      >
                        <CompleteLabel
                          text={
                            "Updated successfully!" + (pendingState ? "*" : "")
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <div
                      className="sub-card"
                      style={{
                        padding: "0.5rem",
                        marginTop: "0rem",
                        marginBottom: "2rem",
                        maxWidth: "27rem",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        alignSelf: "center",
                        lineHeight: "1rem",
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      <span className="txt-s white">
                        The wealthiest of 10% Americans own 89% of all U.S.
                        stocks. They ain’t ready for us.
                      </span>
                    </div>
                    {((user.registered_in_plaid && settings) ||
                      completedBuy ||
                      loading) &&
                    linkToken ? (
                      <button
                        id="button-close-process"
                        className="btn-inverse-round txt-1 "
                        style={{
                          color: getButtonColor(),
                          marginBottom: "1rem",
                        }}
                        disabled={
                          loading ||
                          (settings.is_pending && !settings.is_paused) ||
                          (settings.is_paused &&
                            user.neon_userflow_status ===
                              "MEMBER-FUNDING-ISSUE")
                            ? true
                            : (user.registered_in_plaid ||
                                acctAdded ||
                                completedBuy ||
                                completePause) &&
                              !buyDisabled
                            ? false
                            : true
                        }
                        onClick={loading ? null : () => getButtonAction()}
                      >
                        {getButtonText()}
                      </button>
                    ) : [
                        "MEMBER-NEED-PLAID-UPDATE",
                        "MEMBER-FUNDING-ISSUE",
                      ].includes(
                        user.neon_userflow_status
                      ) ? null : linkToken ? (
                      <PlaidLink
                        token={linkToken}
                        acctAdded={acctAdded}
                        setAcctAdded={setAcctAdded}
                        setLoading={setLoading}
                        buyAmount={buyAmount || customAmount}
                        newRegistered={true}
                        setError={setError}
                        completedBuy={completedBuy}
                        history={history}
                        handleRefreshDashboard={handleRefreshDashboard}
                        callback={getSsn}
                        handleBuy={handleBuy}
                        buyFrequency={buyFrequency}
                      />
                    ) : (
                      <div className="mt-1">
                        <TriangleSpinner />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "350px",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  {acctAdded && !completedBuy ? (
                    <div style={{ marginBottom: "1rem" }}>
                      <span className="txt-1 white ">
                        {" "}
                        Updates made after 4PM ET may not take effect until the
                        following business day.
                      </span>
                    </div>
                  ) : null}
                  {loading ? (
                    <div>
                      <TriangleSpinner />
                    </div>
                  ) : null}
                  {pendingState.state ? (
                    <span className="txt-1 white" style={{ padding: "1rem" }}>
                      {" "}
                      {pendingState.message}
                    </span>
                  ) : null}
                  {error ? (
                    <div style={{ marginBottom: "2rem" }}>
                      <ErrorMessage error={error} destiny={"Buy"} />
                    </div>
                  ) : null}
                  {settings &&
                  !completePause &&
                  !settings.is_paused &&
                  !settings.is_pending
                    ? pauseBtn(playlist_local, handlePause)
                    : null}
                  {completePause ? (
                    <div style={{ width: "350px", marginBottom: "1rem" }}>
                      <span className="txt-1 white ">
                        {" "}
                        Edits or Pauses made after 4PM ET may not take effect
                        until the following business day.
                      </span>
                    </div>
                  ) : null}
                  {notificationText}
                  {settings && settings.is_pending && credit <= 0
                    ? pendingText
                    : null}
                  {promoCreditText}
                  {investmentRisk}
                </div>
              </div>
            </SkeletonTheme>
          </div>
        </div>
      )}
    </>
  );
}
const whyDailyText = () => {
  return (
    <div className="single-title" style={{ marginInline: "1rem" }}>
      <span className="white txt-1" style={{ lineHeight: "1.8" }}>
        <span className="bold"> Why Invest Regularly?</span> Investing{" "}
        <a
          className="white pointer"
          style={{ textDecoration: "underline" }}
          href="https://intelligent.schwab.com/article/dollar-cost-averaging#:~:text=Dollar%20cost%20averaging%20is%20the,as%20well%20as%20your%20costs"
          target="_blank"
          rel="noreferrer"
        >
          regularly
        </a>{" "}
        has shown to be better than trying to time when to invest. The stock
        market is a wave - we ride it habitually. Plus, every $1 earns you an
        entry into this month’s giveaway.v
      </span>
    </div>
  );
};
function pauseBtn(playlist_local, handlePause) {
  return playlist_local.owned ? (
    <span
      id="button-pause"
      className="txt-1 neon-color pb-2 pointer"
      onClick={() => handlePause()}
    >
      Pause playlist
    </span>
  ) : null;
}
