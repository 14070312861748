import React, { useState, useEffect } from "react";
import nike from "../images/nike-white.png";
import PendingStockText from "./PendingStockText";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { closeModal } from "../Store/Modal/Reducers";
import { useDispatch } from "react-redux";

export default function YouOwnIt() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [view, setView] = useState(1);
  const [productDetail, setDetail] = useState({
    amount: 0,
    brand_name: "",
  });
  const [pending, setPending] = useState(false);
  function handleView() {
    ReactGA.event({
      category: "FIRST_REWARD",
      action: "SEE_BALANCE_CLICKED",
    });
    dispatch(closeModal());
    //setView(2);
  }
  const handleBenefits = () => {
    ReactGA.event({
      category: "FIRST_REWARD",
      action: "SEE_BENEFITS_CLICKED",
    });
    dispatch(closeModal());
  };
  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    ReactGA.event({
      category: "FIRST_REWARD",
      action: "VIEWED",
    });

    const state = history.location.state;
    if (state !== undefined) {
      setDetail({
        amount: state.product.amount_dollars.toFixed(2),
        brand_name: state.product.brand.name,
      });
      if (!state.product.brand.status) {
        setPending(true);
      }
    }
  }, []);

  const stockDescription = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify",
      }}
    >
      <div style={{ marginTop: "1rem" }}>
        <div style={{ maxWidth: "25rem" }}>
          <span className="sub-title white bold">
            {productDetail.amount}
            {pending ? "*" : null}
          </span>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <div style={{ maxWidth: "25rem" }}>
          <span className="sub-title white ">
            In {productDetail.brand_name} Stock
          </span>
        </div>
      </div>
      <div>
        <img src={nike} style={{ maxWidth: "10rem" }} alt="brand" />
      </div>
      <div style={{ width: "100%", textAlign: "center", paddingTop: "4rem" }}>
        <span className="white bold sub-title">
          Welcome to the Neon Money Club
        </span>
      </div>
    </div>
  );

  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <div
        style={{
          paddingTop: "4rem",
          paddingInline: "1rem",
          textAlign: "center",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <span className="title white pointer" onClick={() => handleClose()}>
            X
          </span>
        </div>
        <span className="white bolder" style={{ fontSize: "3rem" }}>
          YOU OWN IT
        </span>
      </div>
      {view === 1 ? stockDescription : <div></div>}
      <div style={{ width: "100%", textAlign: "center", paddingTop: "2rem" }}>
        <div>
          <button
            onClick={() => handleBenefits()}
            className="btn"
            style={{ fontSize: "1rem", width: "100%" }}
          >
            See Money Club Benefits
          </button>
        </div>
        <div style={{ paddingTop: "2rem" }}>
          <span
            className="link"
            style={{ fontSize: "1rem" }}
            onClick={() => handleView()}
          >
            See your wallet balance
          </span>
        </div>
        {pending ? <PendingStockText /> : null}
      </div>
    </div>
  );
}
