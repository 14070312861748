import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import TriangleSpinner from "./TriangleSpinner";

export default function Card({
  content,
  step,
  verifyFields,
  showBtn,
  loading,
  enableBtn,
  showSpinner,
  hideButton,
  referrer,
  validCodes,
}) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get("code");
  const customSteps = [1, 2, 3, 4];
  const getButtonText = () => {
    if (step === 1) {
      if (referrer !== "") {
        return (
          "Get $30 on " + referrer.charAt(0).toUpperCase() + referrer.slice(1)
        );
      } else if (code === "metaversal") {
        return "Enter to win the drop";
      } else if (validCodes.includes(code)) {
        return "Get started for free";
      } else {
        return "Let me in";
      }
    } else if (step === 4) {
      return "Let's GOOOOO!";
    } else if (step === 2) {
      return "Get started with $30 on us";
    } else if (step === 3) {
      return "Next";
    } else {
      return <AiOutlineArrowRight />;
    }
  };

  const customBtn = showBtn ? (
    showSpinner ? (
      <TriangleSpinner />
    ) : (step === 1 && hideButton) ||
      step === 6 ||
      step === "giveaway" ? null : (
      <button
        data-testid="fwd-btn"
        onClick={enableBtn ? () => verifyFields(step) : null}
        id="card-btn"
        className={
          customSteps.includes(step)
            ? (step === 1 && validCodes.includes(code)) || referrer
              ? "btn-reverse-round"
              : "btn-inverse-round"
            : "menu-button"
        }
        style={{
          width: customSteps.includes(step) ? "90%" : "2.5rem",
          borderRadius: customSteps.includes(step) ? "500px" : "unset",
          height: "2.5rem",
          padding: "unset",
          color:
            step === 4
              ? enableBtn
                ? "white"
                : "gray"
              : step === 2 || step === 1
              ? validCodes.includes(code) || referrer
                ? "black"
                : "white"
              : enableBtn
              ? "#07dbab"
              : "gray",
          borderColor: enableBtn ? "#07dbab" : "gray",
          maxWidth: 450,
          minWidth: 333,
        }}
      >
        {getButtonText()}
      </button>
    )
  ) : (
    <div></div>
  );

  return (
    <div
      className="card-deck"
      style={{
        marginInline: "1rem",
        marginBottom: step === 2 ? "6rem" : "0rem",
      }}
    >
      <div
        className="card-nb"
        id="custom-card"
        style={{
          minHeight: "auto",
          border: "none !important",
          marginTop: step === 3 ? 8 : 20,
          flexDirection: step === 3 ? "column" : "",
        }}
      >
        {content}
        <div
          style={{
            paddingTop: "0.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {step === 3 ? (
            <div
              style={{
                padding: "0.5rem",
                maxWidth: "25rem",
                textAlign: "center",
                paddingLeft: "1.5rem",
                marginTop: "1rem",
                alignSelf: "center",
              }}
            >
              <span className="txt-s futura-mid white">
                &#128274; BANK LEVEL PRIVACY AND SECURITY
              </span>
            </div>
          ) : null}
          <div style={{ marginTop: "0rem" }}>{loading ? null : customBtn}</div>
        </div>
      </div>
    </div>
  );
}
