import React from "react";

export function TradeConfirmsDisclosures() {
  return (
    <div style={{ maxWidth: "350px", textAlign: "left" }}>
      <span className="white txt-1" style={{ textAlign: "left !important" }}>
        1. This trade confirmation is furnished solely by DriveWealth, LLC, a
        registered broker dealer, for transactions in your brokerage account
        held with DriveWealth, LLC (DriveWealth or Us). DriveWealth is a
        registered clearing firm and custodian, and is affiliated with
        DriveLoyalty, LLC and DriveAdvisory, LLC. Unless otherwise noted,
        DriveWealth is independently owned and operated from your introducing
        broker or investment adviser, whose name appears on your statement.
        <br />
        <br />
        2. If you find any errors or omissions on this trade confirmation,
        please call your broker or investment adviser immediately using the
        contact information provided by your broker or investment adviser, or
        DriveWealth at support@drivewealth.com. To protect your rights, you must
        also notify us in writing. You should notify us immediately upon
        discovery of the error, but in any event no later than 10 days after you
        receive the confirmation. This confirmation will be deemed conclusive
        after 10 days. DriveWealth will not be liable for any losses that occur
        because of your failure to promptly notify us of an error.
        <br />
        <br />
        3. All transactions are subject to the rules, regulations, requirements
        and customs of the Federal Reserve Board, the exchange or market (and
        its clearing agency, if any) where executed, any association whose rules
        and regulations govern transactions in said market, and your account
        agreement(s) with DriveWealth. We will furnish, upon written request,
        the date and time when this transaction took place and the name of the
        other party to the transaction. Federal regulations require that all
        brokerage firms report sales transactions to the IRS unless the order
        was placed through an IRA, corporate or trustee account.
        <br />
        <br />
        4. Securities purchased by you or held by us may be hypothecated and/or
        commingled with securities carried for other customers.
        <br />
        <br />
        5. Payment for all purchases or delivery of securities sold is due
        promptly and in any event no later than the settlement date. We reserve
        the right to liquidate securities or otherwise cover a position without
        notice at any time, including prior to settlement date, when in our
        judgment the value of the collateral is in jeopardy or the account poses
        a risk to the firm. You will be held responsible for any resulting loss.
        <br />
        <br />
        6. Any commission charged to you in this transaction may be more or less
        than commissions charged to or by others in similar transactions. The
        source and amount of other commissions received in connection with this
        transaction will be furnished on request.
        <br />
        <br />
        7. For transactions involving fractional shares, DriveWealth may act in
        a principal or riskless principal capacity when executing fractional
        share orders. For whole share orders, or whole share portions of mixed
        capacity trades (e.g. 3.45 shares), DriveWealth acts in an agency
        capacity. The capacity in which DriveWealth has acted is contained
        within this confirmation.
        <br />
        <br />
        8. The DriveWealth Steady Saver ETF (STBL) and DriveWealth Power Saver
        ETF (EERN) are exchange-traded funds (ETFs). DriveWealth, LLC will
        provide certain services to the funds, which may include granting a
        limited, nonexclusive, royalty-free license to use DriveWealth in the
        funds names, and services relating to marketing activities and economic
        support of the funds. For its services, DriveWealth may receive payment
        from advisers to the funds.
        <br />
        <br />
        This statement is computed for payment by bank draft on settlement date.
        If payment is made at a later date, additional interest to date of
        payment will be charged. Payment for Order Flow Disclosure Your
        broker/dealer and/or DriveWealth, LLC may receive remuneration,
        compensation, or consideration on this transaction for directing orders
        in securities to particular broker/dealers or market centers for
        execution. The source and nature of any compensation received in
        connection with your particular transaction will be disclosed upon
        written request to your broker/dealer. Please review your broker/dealers
        annual disclosure on payment for order flow policies and order routing
        policies.
        <br />
        <span className="bold">Other Important Information</span>
        <br />
        To speak to a member of the Neon Team, hit us up through the Neon chat
        on www.joinneon.com or support@neonmoneyclub.com.
      </span>
    </div>
  );
}
