import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    open: false,
    modal: null,
    origin: null,
    data: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      state.modal = action.payload.modal;
      state.origin = action.payload.origin;
      state.data = action.payload.data;
    },
    closeModal: (state) => {
      state.open = false;
      state.modal = null;
      state.origin = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
