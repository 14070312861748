import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { loginSuccess, setUserStatus } from "../Store/Login/Reducers";
import { setPlaylistsSettings } from "../Store/Playlists/Reducers";
import { setUnlocks } from "../Store/Unlocks/Reducers";
import {
  setPlaylistsAndSingles,
  fetchWalletSuccess,
} from "../Store/Dashboard/Reducers";
import ReactGA from "react-ga4";
import { setBrandsSettings } from "../Store/Brands/Reducers";

export const fetchPlaylistsAndSingles = (data, auth, dispatch, history) => {
  axios
    .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${auth}`,
        audience_url:
          process.env.REACT_APP_CORE_API + URIS.GET_PLAYLISTS_AND_SINGLES,
      },
    })
    .then((response) => {
      if (response.msg === "Token has expired") {
      }
      dispatch(setPlaylistsAndSingles(response.data));
    })
    .catch((error) => {
      if (error.response.status in [404, 502, 500, 400, 403]) {
        history.push("/404");
      }
    });
};
export const getUnlocks = async (setState, data, access_token, dispatch) => {
  const authConfig = {
    headers: {
      "NEON-AUTHORIZATION": `Bearer ${access_token}`,
      audience_url: process.env.REACT_APP_CORE_API + URIS.UNLOCKS_SETS,
    },
  };
  await axios
    .post(process.env.REACT_APP_AUTH_PROXY_URL, data, authConfig)
    .then((res) => {
      if (res.data.status) {
        let allowedSet = null;
        let completed = false;
        let allowedSetLetter = null;
        if (res.data.allowed_sets.length > 0) {
          allowedSet = res.data.allowed_sets[0];
          allowedSetLetter = allowedSet.code;
          if (
            setState &&
            allowedSetLetter === setState[0] &&
            parseInt(setState.slice(-1)) === allowedSet.scenarios.length
          ) {
            completed = true;
          }
        }

        let unlocks = {
          unlocks: allowedSet,
          completed_sets: res.data.completed_sets,
          next_sets: res.data.next_sets,
          set_letter: allowedSetLetter,
          completed: completed,
        };
        dispatch(setUnlocks(unlocks));
      }
    });
};
export const fetchWallet = (data, auth, dispatch, history, user) => {
  axios
    .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${auth}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_STOCKS,
      },
    })
    .then((response) => {
      ReactGA.event({
        category: "DASHBOARD",
        action: "WALLET_SUCCESS",
      });
      let wallet = response.data;
      dispatch(fetchWalletSuccess(wallet));
      getPlaylistSettings(
        wallet.summary,
        auth,
        user.phone_number,
        user.id,
        dispatch,
        history
      );
    })
    .catch((error) => {
      ReactGA.event({
        category: "DASHBOARD",
        action: "WALLET_ERROR",
      });
      if (error.response.status in [404, 502, 500, 400, 403]) {
        history.push("/404");
      }
    });
};

export const getUserData = async (
  access_token,
  phone,
  vfp,
  dispatch,
  history
) => {
  await axios
    .get(process.env.REACT_APP_AUTH_PROXY_URL, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url:
          process.env.REACT_APP_CORE_API + URIS.UPDATE_USER + "/" + phone,
      },
    })
    .then((response) => {
      let data = {
        vfp: vfp,
        user: response.data.user,
        notification: response.data.user.notification,
      };
      dispatch(loginSuccess(data));
    })
    .catch((error) => {
      console.error("AUTH ERROR - GETTING USER DATA FAILED");
      console.error(error.response);
      if (error.response.status in [404, 502, 500, 400, 403]) {
        history.push("/404");
      }
    });
};

export const getPlaylistSettings = async (
  wallet,
  access_token,
  phone,
  userId,
  dispatch,
  history
) => {
  let settings = { playlists: [], brands: [] };
  for (let playlist of wallet.playlists) {
    let data = {
      phone_number: phone,
      user_id: userId,
      playlist_id: playlist.playlist_id,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.RULE_DETAIL,
        },
      })
      .then((response) => {
        let res = response.data;
        if (res.status) {
          res["playlist_id"] = playlist.playlist_id;
          if (res.frequency === null) {
            res["frequency"] = "daily";
          }
          settings.playlists.push(res);
        }
      })
      .catch((error) => {
        if (error.response.status in [404, 502, 500, 400, 403]) {
          history.push("/404");
        }
      });
  }
  for (let single of wallet.brands) {
    let data = {
      phone_number: phone,
      user_id: userId,
      symbol: single.symbol,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.RULE_DETAIL,
        },
      })
      .then((response) => {
        let res = response.data;
        if (res.status) {
          res["symbol"] = single.symbol;
          if (res.frequency === null) {
            res["frequency"] = "daily";
          }
          settings.brands.push(res);
        }
      })
      .catch((error) => {
        if (error.response.status in [404, 502, 500, 400, 403]) {
          history.push("/404");
        }
      });
  }
  dispatch(setPlaylistsSettings(settings.playlists));
  dispatch(setBrandsSettings(settings.brands));
};

export const getUserStatus = async (
  phone_number,
  access_token,
  dispatch,
  history
) => {
  await axios
    .get(process.env.REACT_APP_AUTH_PROXY_URL, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_USER_STATUS,
        "X-NEON-TOKEN": "d66e67309924b68cad05784f10e2417d1c173a98",
        "CLIENT-ID": "473e67a5e0a768380b5111a9aa90d04ab36f84d4",
      },
    })
    .then((res) => {
      let response = res.data;
      dispatch(setUserStatus(response));
    })
    .catch((error) => {
      console.error(error);
    });
};
