import BarChart from "../components/BarChart";
import LineChart from "../components/LineChart";
import DoughnutChart from "../components/DoughnutChart";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { URIS } from "../Constants/Endpoints";
import { useSelector, useDispatch } from "react-redux";
import { refresh } from "../helpers/Auth"
import { clearStore } from "../Store/Login/Reducers";
import { useHistory } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv'
import { Button } from "@mui/material";

const DashboardAdmin = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    let { user, refresh_token, access_token } = useSelector(
        (state) => state.login
    );
    const [countFlow, setCountFlow] = useState([]);
    const [data, setData] = useState([]);
    const [userFlow, setUserFlow] = useState([]);

    const [dataGroupByDailyAmount, setDataGroupByDailyAmount] = useState([]);
    const [amountUser, setAmountUser] = useState([]);
    const [amountPerDay, setAmountPerDay] = useState([]);
    const [userGroupbyAmount, setUserGroupbyAmount] = useState([]);

    const [dataGroupByPlaylist, setDataGroupByPlaylist] = useState([]);
    const [playlistNameFlow, setPlaylistNameFlow] = useState([]);
    const [countUsersByPlaylist, setCountUsersByPlaylist] = useState([]);
    const columnsTableUserWithPlaylistActives = [
        { field: 'created_at', headerName: 'Created', width: 130 },
        { field: 'name', headerName: 'First name', width: 130 },
        { field: 'last_name', headerName: 'Last name', width: 130 },  
        { field: 'phone', headerName: 'phone', width: 130 },
        { field: 'email', headerName: 'email', width: 300 },              
        { field: 'neon_userflow_status', headerName: 'neon_userflow_status', width: 130 },
        {
            field: 'day_strike',
            headerName: 'day_strike',
            type: 'number',
            width: 85,
        },
        { field: 'playlist_name', headerName: 'playlist_name', width: 130 },
        {
            field: 'amount',
            headerName: 'amount',
            type: 'number',
            width: 85,
        },
        { field: 'status', headerName: 'status', width: 130 }
        /*{
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        */
    ];
    
    const [rowsUserWithPlaylistActives, setRowsUserWithPlaylistActives] = useState([]);
    const [dataCSVFormatTable1, setDataCSVFormatTable1] = useState([]);

    
    const columnsTableCodesCampaign = [
        { field: 'code_id', headerName: 'Code ID', width: 200 },
        { field: 'code', headerName: 'Code', width: 400 },
        { field: 'use_limit', headerName: 'Use Limit', width: 200 },
        { field: 'current_use', headerName: 'Current Use', width: 200 },
        { field: 'status', headerName: 'Status', width: 200 }
    ];
    const [rowTableCodesCampaign, setRowTableCodesCampaign] = useState([]);

    const columnsTableWeeklyReport = [
        { field: 'created_at', headerName: 'CREATED', width: 150 },
        { field: 'user_id', headerName: 'ID', width: 55 },
        { field: 'first_name', headerName: 'FNAME', width: 150 },
        { field: 'neon_userflow_status', headerName: 'MSTATE', width: 200 },
        { field: 'email', headerName: 'EMAIL', width: 200 },
        { field: 'url', headerName: 'URL', width: 200 },
        { field: 'referal_code', headerName: 'RCODE', width: 200 },
        { field: 'access_code', headerName: 'ACODE', width: 155 },
        { field: 'nro_active_playlist', headerName: 'NRO Playlist', width: 120 },
        { field: 'status', headerName: 'Status', width: 100 },
    ];
    const [rowTableWeeklyReport, setRowTableWeeklyReport] = useState([]);

    const columnsTableSharingIsCaring = [
        { field: 'created_at', headerName: 'CREATED', width: 150 },
        { field: 'code_id', headerName: 'CODE ID', width: 55 },
        { field: 'use_limit', headerName: 'LIMIT', width: 150 },
        { field: 'current_uses', headerName: 'USES', width: 200 },
        { field: 'user_id', headerName: 'ID', width: 55 },
        { field: 'email', headerName: 'EMAIL', width: 200 },
        { field: 'neon_userflow_status', headerName: 'MSTATE', width: 200 },
        { field: 'referrer_code', headerName: 'RCODE', width: 155 },
        { field: 'url', headerName: 'URL', width: 120 },
        { field: 'access_code', headerName: 'ACODE', width: 120 },
        { field: 'status', headerName: 'Status', width: 100 },
    ];
    const [rowTableSharingIsCaring, setRowTableSharingIsCaring] = useState([]);

    const columnsTableSamRequestUserState = [
        { field: 'user_id', headerName: 'USER ID', width: 250 },
        { field: 'addres_state', headerName: 'ADDRESS STATE', width: 250 },
        { field: 'has_rules', headerName: 'HAS EVER HAD RULE', width: 250 },
        { field: 'status', headerName: 'Status', width: 100 }
    ];
    const [rowTableSamRequestUserState, setRowTableSamRequestUserState] = useState([]);


    const [flag, setFlag] = useState(false);
    setTimeout(() => {
        setFlag(true);
    }, 3600);

    /*
        TABLE USER WITH ACTIVE PLAYLIST
    */
    const getUserActivePlaylistTable = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_MEMBER_BY_ACTIVE_RULES,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setRowsUserWithPlaylistActives(res.data.response);
            
        } catch (error) {
            console.log(error);
        }
    }

    const refreshTableActivePlaylist = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "POST",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_MEMBER_BY_ACTIVE_RULES                        
                    }
                }
            )            
            setRowsUserWithPlaylistActives(res.data.response);                      
        } catch (error) {
            console.log(error);
        }
    }

    /*
        TABLE CODES CAMPAIGN
    */
    const getCodesCampaignTable = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_CODES_CAMPAIGN,
                        'Content-Type': 'application/json'
                    }
                }
            )            
            setRowTableCodesCampaign(res.data.response);            
        } catch (error) {
            console.log(error);
        }
    }

    const getTablesCodesCampaign = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "POST",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_CODES_CAMPAIGN                        
                    }
                }
            )            
            setRowTableCodesCampaign(res.data.response);                      
        } catch (error) {
            console.log(error);
        }
    }

    /*
        TABLE WEEKLY REPORT MAILCHIMP
    */
    const getTablesWeeklyReport = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_WEEKLY_REPORT
                    }
                }
            )
            setRowTableWeeklyReport(res.data.response);
        } catch (error) {
            console.log(error);
        }
    }

    const refreshTablesWeeklyReport = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "POST",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_WEEKLY_REPORT                        
                    }
                }
            )            
            setRowTableWeeklyReport(res.data.response);                      
        } catch (error) {
            console.log(error);
        }
    }

    /*
        TABLE SHARING IS CARING
    */
    const getTablesSharingIsCaring = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_SHARING_IS_CARING
                    }
                }
            )
            setRowTableSharingIsCaring(res.data.response);
        } catch (error) {
            console.log(error);
        }
    }

    const refreshTablesSharingIsCaring = async () => {
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "POST",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_SHARING_IS_CARING
                    }
                }
            )
            setRowTableSharingIsCaring(res.data.response);
        } catch (error) {
            console.log(error);
        }
    }

     /*
        TABLE SAM REQUEST USERS STATES
    */
        const getTablesSamRequestUsersStates = async () => {
            const labelData = [];
            try {
                const res = await axios(
                    {
                        method: "GET",
                        url: process.env.REACT_APP_AUTH_PROXY_URL,
                        headers: {
                            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                            audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_SAM_REQUEST_USERS
                        }
                    }
                )
                setRowTableSamRequestUserState(res.data.response);
            } catch (error) {
                console.log(error);
            }
        }
    
        const refreshTablesSamRequestUsersStates = async () => {
            const labelData = [];
            try {
                const res = await axios(
                    {
                        method: "POST",
                        url: process.env.REACT_APP_AUTH_PROXY_URL,
                        headers: {
                            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                            audience_url: process.env.REACT_APP_CORE_API + URIS.GET_TABLE_SAM_REQUEST_USERS
                        }
                    }
                )
                setRowTableSamRequestUserState(res.data.response);
            } catch (error) {
                console.log(error);
            }
        }

/*  GRAPHICS */
    const getUserGroupByStates = async () => {
        const labelCount = [];
        const labelData = [];
        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_MEMBER_REPORTERY,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setData(res.data.response);
            data.map(x => labelCount.push(x.count));
            setCountFlow(labelCount);
            data.map(x => labelData.push(x.neon_userflow_status))
            setUserFlow(labelData);
        } catch (error) {
            console.log(error);
        }
    }
    var chart = {
        labels: userFlow,
        datasets: [
            {
                label: "Summary count users by States",
                data: countFlow,
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#ecf0f1",
                    "#50AF95",
                    "#f3ba2f",
                    "#2a71d0",
                ],
                borderColor: "black",
                borderWidth: 2,
            },
        ]
    }
    const getUserGroupByDailyAmount = async () => {
        const labelAmountCount = [];
        const labelAmountPerDayCount = [];
        const labelUserByDailyAmount = [];

        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_MEMBERS_BY_AMOUNT,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setDataGroupByDailyAmount(res.data.response);
            dataGroupByDailyAmount.map(x => labelAmountCount.push("$" + x.amount));
            setAmountUser(labelAmountCount)
            dataGroupByDailyAmount.map(x => labelAmountPerDayCount.push(x.amount_per_day))
            setAmountPerDay(labelAmountPerDayCount);
            dataGroupByDailyAmount.map(x => labelUserByDailyAmount.push(x.users))
            setUserGroupbyAmount(labelUserByDailyAmount);

        } catch (error) {
            console.log(error);
        }
    }
    var usersGroupBydayliAmountChart = {
        labels: amountUser,
        datasets: [
            {
                label: "Summary count users by States",
                data: userGroupbyAmount,
                backgroundColor: [
                    "rgba(75,192,192,1)",
                    "#fe5852",
                    "#9396ef",
                    "#00c9e2"
                ],
                borderColor: "black",
                borderWidth: 2,
            },
        ]
    }
    const getUserGroupByPlaylist = async () => {
        const labelPlaylistName = [];
        const labelUserGroupbyPlayList = [];

        try {
            const res = await axios(
                {
                    method: "GET",
                    url: process.env.REACT_APP_AUTH_PROXY_URL,
                    headers: {
                        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
                        audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_MEMBERS_BY_PLAYLIST,
                        'Content-Type': 'application/json'
                    }
                }
            )
            setDataGroupByPlaylist(res.data.response);
            dataGroupByPlaylist.map(x => labelPlaylistName.push(x.playlist_name));
            setPlaylistNameFlow(labelPlaylistName)
            dataGroupByPlaylist.map(x => labelUserGroupbyPlayList.push(x.user_count))
            setCountUsersByPlaylist(labelUserGroupbyPlayList);

        } catch (error) {
            console.log(error);
        }
    }
    var usersGroupByPlayListChart = {
        labels: playlistNameFlow,
        datasets: [
            {
                label: "GRAPHIC PLAYLIST PER USER",
                data: countUsersByPlaylist,
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    '#2ef8a0',
                    'yellow'
                ],
                borderColor: "black",
                borderWidth: 2,
            },
        ]
    }

    useEffect(async () => {
        if (refresh_token) {
            let access = await refresh(refresh_token);
            if (access.status) {
                //codigo que queres que se ejecute despues de refrescar jwt
                getUserGroupByStates();
                getUserGroupByDailyAmount();
                getUserGroupByPlaylist();
                getUserActivePlaylistTable();
                getCodesCampaignTable();
                getTablesWeeklyReport();
                getTablesSharingIsCaring();
                getTablesSamRequestUsersStates();
            } else if (!access) {
                dispatch(clearStore());
                localStorage.clear();
                history.push("/login");
            }
        } else {
            dispatch(clearStore());
            localStorage.clear();
            history.push("/login");
        }
    }, [refresh_token, history,flag])
    return (
        <>
            <h1 className="white">DASHBOARD</h1>
            <br></br>
            <h2 className="white" style={{alignContent:"left"}}>Summary Users: States</h2>
            <div style={{ height: 400, width: '85%', backgroundColor:"white"}}>
                <DataGrid
                    style={{color:"black"}}
                    rows={rowTableSamRequestUserState}
                    columns={columnsTableSamRequestUserState}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
            <div>
            <Button onClick={refreshTablesSamRequestUsersStates}>
                REFRESH
            </Button>
                <CSVLink                    
                    data={rowTableSamRequestUserState}
                    headers={['user_id','addres_state','has_rules','status']}
                    filename='users_states_summary.csv'
                    className='hidden'
                    target='_blank'>
                  Download
                </CSVLink>
            </div>
            <br></br>
            <h2 className="white" style={{alignContent:"left"}}>Summary users with active rules:</h2>
            <div style={{ height: 400, width: '85%', backgroundColor:"white"}}>
                <DataGrid
                    style={{color:"black"}}
                    rows={rowsUserWithPlaylistActives}
                    columns={columnsTableUserWithPlaylistActives}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
            <div>
            <Button onClick={refreshTableActivePlaylist}>
                REFRESH
            </Button>         
                <CSVLink                    
                    data={rowsUserWithPlaylistActives}
                    headers={['created_at','name','last_name','phone','email','neon_userflow_status','day_strike','playlist_name','amount','status']}
                    filename='users_with_active_playlist.csv'
                    className='hidden'
                    target='_blank'>
                  Download
                </CSVLink>
            </div>
            <br></br>
            <h2 className="white" style={{alignContent:"left"}}>Codes Campaign</h2>
            <div style={{ height: 400, width: '85%', backgroundColor:"white"}}>
                <DataGrid
                    style={{color:"black"}}
                    rows={rowTableCodesCampaign}
                    columns={columnsTableCodesCampaign}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
            <div>
            <Button onClick={getTablesCodesCampaign}>
                REFRESH
            </Button>         
                <CSVLink                    
                    data={rowTableCodesCampaign}
                    headers={['code_id','code','use_limit','current_use','status']}
                    filename='codes_campaign.csv'
                    className='hidden'
                    target='_blank'>
                  Download
                </CSVLink>
            </div>
            <br></br>
            <h2 className="white" style={{alignContent:"left"}}>Weekly Mailchimp</h2>
            <div style={{ height: 400, width: '85%', backgroundColor:"white"}}>
                <DataGrid
                    style={{color:"black"}}
                    rows={rowTableWeeklyReport}
                    columns={columnsTableWeeklyReport}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
            <div>
            <Button onClick={refreshTablesWeeklyReport}>
                REFRESH
            </Button> 
                <CSVLink                    
                    data={rowTableWeeklyReport}
                    headers={['created_at','user_id','first_name','neon_userflow_status','email','url','referal_code','access_code','nro_active_playlist','status']}
                    filename='weekly_report_mailchimp.csv'
                    className='hidden'
                    target='_blank'>
                  Download
                </CSVLink>
            </div>
            <br></br>
            <h2 className="white" style={{alignContent:"left"}}>Sharing is Caring</h2>
            <div style={{ height: 400, width: '85%', backgroundColor:"white"}}>
                <DataGrid
                    style={{color:"black"}}
                    rows={rowTableSharingIsCaring}
                    columns={columnsTableSharingIsCaring}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                />
            </div>
            <div>
            <Button onClick={refreshTablesSharingIsCaring}>
                REFRESH
            </Button> 
                <CSVLink                    
                    data={rowTableSharingIsCaring}
                    headers={['created_at','code_id','use_limit','current_uses','user_id','email','neon_userflow_status','referrer_code','url','access_code','status']}
                    filename='sharing_is_caring.csv'
                    className='hidden'
                    target='_blank'>
                  Download
                </CSVLink>
            </div>

            {
                data.map(index =>
                    <p key={"value" + index.neon_userflow_status} className="white">{index.neon_userflow_status}:{index.count}</p>
                )
            }

            <h1 className="white"> Summary </h1>
            <table className="white">
                <thead>
                    <tr>
                        <th>
                            Daily Amount
                        </th>
                        <th>
                            Number of User
                        </th>
                        <th>
                            Daily Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataGroupByDailyAmount.map(index =>
                            <tr key={"value" + index.amount_per_day}>
                                <td>
                                    ${index.amount}
                                </td>
                                <td>
                                    {index.users}
                                </td>
                                <td>
                                    ${index.amount_per_day}
                                </td>
                            </tr>
                        )
                    }
                </tbody>                
            </table>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "60px",
                marginBottom: "60px"
            }}>
                <BarChart chartData={chart} />
                <DoughnutChart chartData={usersGroupBydayliAmountChart} />
            </div>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "60px",
                marginBottom: "60px"
            }}>
                <LineChart chartData={usersGroupByPlayListChart} />
                <DoughnutChart chartData={usersGroupByPlayListChart} />
            </div>

        </>

    )
}
export default DashboardAdmin;