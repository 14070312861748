import React from "react";

export default function NeonValueProps({ inCampaign }) {
  return (
    <div
      className="route-86"
      style={{
        minWidth: "26vw",
        textAlign: "center",
        marginBottom: "2rem",
        display: "inline",
      }}
    >
      <div style={{ minWidth: "22vw", margin: "auto", maxWidth: "350px" }}>
        <span className="white futura-mid">YOU BELONG HERE.</span>
      </div>
      <div style={{ paddingTop: "2rem" }}>
        <span
          className="white futura-light"
          style={{ fontSize: "1rem" }}
        ></span>
      </div>
    </div>
  );
}
