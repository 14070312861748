import React from "react";

export default function Modal({ content }) {
  return (
    <div className="card-deck">
      <div
        className="card"
        style={{ paddingInline: "1rem", border: "0px", marginTop: "-5rem" }}
      >
        {content}
        <div
          style={{
            paddingTop: "2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div></div>
          <div>
            <span className="error"></span>
          </div>
          <div
            style={{ textAlign: "left", padding: "1rem", paddingTop: "unset" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
