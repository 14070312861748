import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../Store/Modal/Reducers";
import { TradeConfirmsDisclosures } from "./TradeConfirmsDisclosures";

export default function TransactionDetail({
  header,
  data,
  backFn,
  trade,
  Type,
}) {
  const handleBack = () => {};
  const dispatch = useDispatch();
  return (
    <div className="modal">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minWidth: "350px",
          textAlign: "left",
        }}
      >
        <span
          className="txt-1 neon-color pointer"
          onClick={() => backFn(false)}
        >
          Back
        </span>
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white" style={{ width: "100%" }}>
            {header}
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => dispatch(closeModal())}
          >
            X
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {data.map((item, index) => (
          <div key={index} style={{ marginTop: "3rem" }}>
            <div>
              <span className="txt-1-mid white ">{item.header}</span>
            </div>
            <div>
              <span className="txt-1 white">{item.content}</span>
            </div>
          </div>
        ))}

        <div style={{ paddingTop: "3rem" }}>
          <div>
            {trade ? (
              Type === "Sold" || Type === "Cash" ? (
                ""
              ) : (
                <span
                  className="link"
                  style={{ fontSize: "1rem", paddingBottom: "2rem" }}
                >
                  View trade confirmation
                  <br />
                  <br />
                  <TradeConfirmsDisclosures />
                </span>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
