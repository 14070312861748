import React, { useState } from "react";
import referral from "../images/nmc-long-logo.png";
import winKicks from "../images/win-kicks.png";
import CompleteLabel from "./CompleteLabel";

export default function NeonValueProps({ verifyFields }) {
  const [confirmOne, setConfirmOne] = useState(false);
  return (
    <div
      className="route-86"
      style={{
        minWidth: "26vw",
        textAlign: "center",
        marginBottom: "2rem",
        display: "inline",
      }}
    >
      <a href="https://joinneon.com" target="_blank" rel="noreferrer">
        <img src={referral} width="300px" />
      </a>
      <div>
        <div>
          <img src={winKicks} width="200px" />

          <br />
          <br />
          <div style={{ width: "90%", margin: "auto" }}>
            <span
              className="white futura-light"
              style={{
                marginTop: "1rem",
                textJustify: "inter-word",
                fontSize: "0.875rem !important",
              }}
            >
              <span>
                {" "}
                Buy Stocks, Earn Drops. <br />
              </span>
            </span>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <span className="white futura-mid">
              YOU QUALIFY FOR THE GIVEAWAY
            </span>
          </div>
          <div style={{ marginTop: "1rem", fontSize: "0.875rem !important" }}>
            <span
              className="white txt-1"
              style={{ fontSize: "0.875rem !important" }}
            >
              You will receive 1 entry to win.
            </span>
          </div>
          <div
            style={{
              marginTop: "0rem",
              marginBottom: "1rem",
              width: "350px",
              margin: "auto",
            }}
          >
            {!confirmOne ? (
              <button
                className="btn-inverse-round"
                onClick={() => setConfirmOne(true)}
              >
                Confirm 1 entry to win
              </button>
            ) : (
              <div>
                <CompleteLabel
                  text={"Bet. Be on the lookout for an email from us."}
                />
              </div>
            )}
            <button
              className="btn-reverse-round"
              onClick={() => {
                verifyFields("giveaway");
              }}
            >
              Get 3 times the chance to win
            </button>
            <span className="white txt-s-light">
              By joining Neon Money Club (free to join)
            </span>
            <div style={{ marginTop: "1rem" }}>
              <a
                href="https://joinneon.com/faqs"
                target="_blank"
                style={{
                  fontSize: "0.83rem",
                  color: "white",
                  textDecoration: "underline",
                }}
                className="futura-mid pointer"
              >
                frequently asked questions
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
