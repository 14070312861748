import React, { useState } from "react";

import styled from "@emotion/styled";
import { config } from "react-spring";
import { Spring, animated } from "react-spring/";

const Container = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Dot = styled(animated.button)`
  outline: none;
  border: none;
  padding: 0px;
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
`;

export default function App({ setActive, active, data }) {
  return (
    <div>
      <Container>
        {data.map((item, i) => (
          <Spring
            config={config.wobbly}
            from={{ transform: `scale(1)` }}
            to={{ transform: active === i ? `scale(1.25)` : `scale(1)` }}
          >
            {({ transform }) => (
              <Dot style={{ transform }} onClick={() => setActive(i)} />
            )}
          </Spring>
        ))}
      </Container>
    </div>
  );
}
