import React, { useState, useEffect } from "react";
import StockDetail from "./StockDetail";
import DynamicAmount from "../components/DynamicAmount";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  setPlaylistLocal,
  setPlaylistDetail,
} from "../Store/Playlists/Reducers";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ToolTip from "../components/ToolTip";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import { setBrand } from "../Store/Brands/Reducers";

export default function StockGroup({ brandToSell, setBrandToSell }) {
  const dispatch = useDispatch();
  const playlistsArray = useSelector((state) => state.dashboard.playlists); //gets all the playlists
  let { user, access_token } = useSelector((state) => state.login);
  let { brands } = useSelector((state) => state.brands);
  let pendingText =
    "*Stock purchases outside normal hours will be awarded on the next available trading day (M-Fri 9:30AM-4PM ET)";
  const [detailOpen, setDetailOpen] = useState(false);

  function handleClose() {
    dispatch(closeModal());
  }
  const handleBack = () => {
    dispatch(openModal({ modal: "yourStocks" }));
  };
  const handleOpenBrand = (stock) => {
    dispatch(setBrand({ brand: stock }));
    setDetailOpen(true);
  };

  const handleSell = () => {
    dispatch(openModal({ modal: "sellStock" }));
  };

  const getPlaylistDetail = (data) => {
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.GET_PLAYLIST_DETAIL,
        },
      })
      .then((response) => {
        dispatch(setPlaylistDetail(response.data));
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };

  const handleEdit = async () => {
    const data = {
      phone_number: user.phone_number,
      playlist_id: brands.playlist_id,
    };
    let singlePlayList = JSON.parse(
      JSON.stringify(
        playlistsArray.find((x) => x.playlist_name === brands.playlist_name)
      )
    );
    singlePlayList["owned"] = true;
    dispatch(setPlaylistLocal({ playlist: singlePlayList, playlist_local: singlePlayList }));
    getPlaylistDetail(data);
    dispatch(openModal({ modal: "buyEdit", origin: "stockGroup" }));
  };

  const averageGainsTxt = (
    <span
      className="white"
      style={{
        textAlign: "justify !important",
        textJustify: "inter-word !important",
      }}
    >
      This is the difference between today's market value of your stocks versus
      when you bought them.
    </span>
  );

  return (
    <div>
      {!detailOpen ? (
        <div className="modal">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "left",
            }}
          >
            <span
              className="txt-1 neon-color pointer"
              onClick={() => handleBack()}
            >
              Back
            </span>
            <div
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="title white" style={{ width: "100%" }}>
                {brands.name}
              </span>
              <span
                className="title white pointer"
                style={{
                  textAlign: "right",
                  width: "5%",
                  right: "0",
                }}
                onClick={() => handleClose()}
              >
                X
              </span>
            </div>
          </div>
          <div>
            <div style={{ paddingTop: "0.5rem" }}>
              <div style={{ overflowX: "auto !important" }}>
                <table
                  className="table white"
                  style={{
                    overflowX: "auto !important",
                  }}
                >
                  <thead className="table white txt-1-mid">
                    <tr>
                      <th>You own</th>
                      <th>Today's value</th>
                      <th>
                        <div
                          style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          Average gains{"   "}
                          <ToolTip text={averageGainsTxt} place={"bottom"} />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {brands &&
                      brands.portfolio.length > 0 &&
                      brands.portfolio.map((stock, index) => (
                        <tr
                          className={
                            brands.portfolio.length - 1 === index
                              ? "pointer txt-s"
                              : "pointer txt-s tr-border"
                          }
                          key={index}
                          onClick={() => handleOpenBrand(stock)}
                        >
                          <td>{stock.symbol}</td>
                          <td>${stock.today_value.toFixed(2)}</td>
                          <td>
                            <DynamicAmount
                              amount={stock.average_gains.toFixed(2)}
                              text={""}
                            />
                          </td>
                          <td>
                            <MdOutlineKeyboardArrowRight />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              style={{
                paddingTop: "3rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div>
                <button
                  className="btn-inverse txt-s"
                  style={{
                    width: "100%",
                    borderRadius: "500px",
                    marginTop: "1rem",
                    color: "#d1d1cc",
                    borderColor: "#16e7cf",
                  }}
                  onClick={() => handleEdit()}
                >
                  Edit Daily Buys
                </button>
              </div>
              <div
                style={{
                  textAlign: "left",
                  marginTop: "2rem",
                  maxWidth: "20rem",
                }}
              >
                <span className="txt-s white">{pendingText}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <StockDetail
          setDetailOpen={setDetailOpen}
          handleEdit={handleEdit}
          brandToSell={brandToSell}
          setBrandToSell={setBrandToSell}
        />
      )}
    </div>
  );
}
