import React from "react";
import ErrorMessage from "./ErrorMessage";
import { useSelector } from "react-redux";

export function GeneralDataTab({
  form,
  changeHandler,
  error,
  setTab,
  handleSave,
}) {
  let { user } = useSelector((state) => state.login);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <div>
          <div>
            <span className="txt-s futura-mid white">Name you go by</span>
          </div>
          <div>
            <input
              placeholder="Your nickname"
              className="input"
              value={
                form.nickname === ""
                  ? user.neon_nickname
                    ? user.neon_nickname
                    : ""
                  : form.nickname
              }
              onChange={(e) => changeHandler("nickname", e.target.value)}
            />
            <ErrorMessage error={error} destiny={"nickname"} />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <div>
            <span className="txt-s futura-mid white">Legal name</span>
          </div>
          <div>
            <input
              placeholder="Tyler Okonma"
              disabled
              value={
                form.first_name === ""
                  ? user.first_name + " " + user.last_name
                  : form.first_name
              }
              className="input"
              onChange={(e) => changeHandler("first_name", e.target.value)}
            />
            <ErrorMessage error={error} destiny={"name"} />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <div>
            <span className="txt-s futura-mid white">Email</span>
          </div>
          <div>
            <input
              disabled
              className="input"
              placeholder="tyler@golfwang.com"
              value={user.email}
              style={{
                marginLeft: 70,
              }}
              onChange={(e) => changeHandler("email", e.target.value)}
            />
            <span
              style={{
                color: "#07dbab",
                fontSize: 15,
                marginLeft: 15,
                cursor: "pointer",
              }}
              onClick={() => setTab("ChangeEmail")}
            >
              CHANGE
            </span>
            <ErrorMessage error={error} destiny={"email"} />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <div>
            <span className="txt-s futura-mid white">Phone number</span>
          </div>
          <div>
            <input
              disabled
              className="input"
              placeholder="(378)789-1234"
              value={user.phone_number.replace(
                /^(\d{3})(\d{3})(\d+)$/,
                "($1)$2-$3"
              )}
              maxLength={13}
              style={{
                marginLeft: 70,
              }}
              onChange={(e) =>
                changeHandler(
                  "phone_number",
                  e.target.value.replace(/[^\d]/g, "")
                )
              }
            />
            <span
              style={{
                color: "#07dbab",
                fontSize: 15,
                marginLeft: 15,
                cursor: "pointer",
              }}
              onClick={() => setTab("ChangePhone")}
            >
              CHANGE
            </span>
            <ErrorMessage error={error} destiny={"email"} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            padding: "2rem",
            marginTop: "2rem",
          }}
        >
          <button
            className="btn-inverse txt-s"
            style={{
              width: "100%",
              borderRadius: "500px",
              marginTop: "1rem",
              color: "white",
              borderColor: "#16e7cf",
            }}
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
