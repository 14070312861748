export function getCurrentDayAndWeek() {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDate = new Date();
  let currentDay = daysOfWeek[currentDate.getDay()];
  let currentWeek = Math.ceil(currentDate.getDate() / 7);
  let ordinal = "";

  if (currentDay === "Saturday") {
    currentDate.setDate(currentDate.getDate() + 2);
    currentDay = "Monday";
    currentWeek = Math.ceil(currentDate.getDate() / 7);
  } else if (currentDay === "Sunday") {
    currentDate.setDate(currentDate.getDate() + 1);
    currentDay = "Monday";
    currentWeek = Math.ceil(currentDate.getDate() / 7);
  }

  switch (currentWeek) {
    case 1:
      ordinal = "1st";
      break;
    case 2:
      ordinal = "2nd";
      break;
    case 3:
      ordinal = "3rd";
      break;
    case 4:
      ordinal = "4th";
      break;
    default:
      ordinal = "";
      break;
  }
  return { day: currentDay, week: ordinal };
}

export function getDayAndWeekFromDate(dateString) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const inputDate = new Date(dateString);
  let inputDay = daysOfWeek[inputDate.getDay()];
  let inputWeek = Math.ceil(inputDate.getDate() / 7);
  let ordinal = "";

  if (inputDay === "Saturday") {
    inputDate.setDate(inputDate.getDate() + 2);
    inputDay = "Monday";
    inputWeek = Math.ceil(inputDate.getDate() / 7);
  } else if (inputDay === "Sunday") {
    inputDate.setDate(inputDate.getDate() + 1);
    inputDay = "Monday";
    inputWeek = Math.ceil(inputDate.getDate() / 7);
  }

  switch (inputWeek) {
    case 1:
      ordinal = "1st";
      break;
    case 2:
      ordinal = "2nd";
      break;
    case 3:
      ordinal = "3rd";
      break;
    case 4:
      ordinal = "4th";
      break;
    default:
      ordinal = "";
      break;
  }

  return { day: inputDay, week: ordinal };
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const today = new Date();

  // Check if it's today
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "Today";
  }

  // Check if it's yesterday
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return "Yesterday";
  }

  // Format the date as "Month DD, YYYY"
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export function formatDateMonthYear(dateString) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to get 0-based month
  const year = String(date.getFullYear()).slice(-2); // Extracting last two digits of the year
  return `${month}/${year}`;
}
