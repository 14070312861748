import React from "react";
import { useHistory } from "react-router";
import nmc from "../images/nmc-logo.jpg";

export default function FourOFour() {
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: "10rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "10rem",
          margin: "auto",
        }}
      >
        <img src={nmc} width="200px" />
        <span className="white txt-1">
          Looks like we ran into an error verifying your phone number. Please
          hit us on chat.
        </span>
      </div>
    </div>
  );
}
