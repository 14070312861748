import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import CompleteLabel from "../components/CompleteLabel";
import { closeModal } from "../Store/Modal/Reducers";
import PlaidLink from "../components/Plaid";
import TriangleSpinner from "../components/TriangleSpinner";

export default function CashOut() {
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(closeModal());
  }
  let { user, access_token } = useSelector((state) => state.login);
  const [cashAvailable, setCashAvailable] = useState(0);
  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState(0);
  const [error, setError] = useState({});
  const [sold, setSold] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [stock, setStock] = useState({
    amount: 0,
  });
  const handleInput = (e) => {
    const customAmount = parseFloat(e.target.value);
    if (customAmount <= cashAvailable && customAmount > 0) {
      setError({});
      setStock({ amount: customAmount });
    } else {
      setStock({ ...stock, amount: 0 });
      setError({
        message:
          "The amount must be equal or less than $" + cashAvailable.toFixed(2),
        destiny: "customAmount",
      });
    }
  };

  const getPlaidToken = async () => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url:
              process.env.REACT_APP_CORE_API + URIS.CREATE_PLAID_TOKEN,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setLinkToken(data.link_token);
      });
  };

  const getMaxCashAmount = async () => {
    const userId = user.id;
    await axios
      .get(process.env.REACT_APP_AUTH_PROXY_URL, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.CASH_OUT + "/" + userId,
        },
      })
      .then((response) => {
        setCashAvailable(response.data.max_amount_for_cashout);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getMaxCashAmount();
    getPlaidToken();
  }, []);

  const handleCashOut = async () => {
    setLoading(true);

    const data = {
      user_id: user.id,
      amount: stock.amount,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CASH_OUT,
        },
      })
      .then((response) => {
        setSold(true);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "";
        if (error.response.status === 406) {
          errorMessage =
            "Please update your connected bank account to complete your cashout ";
        } else {
          errorMessage =
            "There was an error adding your account, please refresh the page and try again.";
        }
        setError({
          message: errorMessage,
          destiny: "cashOutError",
        });
      });
  };

  const handleAmount = () => {
    setCustom(false);
    setError({});
    setStock({ ...stock, amount: cashAvailable });
  };

  const handleCustom = () => {
    setStock({ ...stock, amount: 0 });
    setCustom(true);
  };

  const handleBankAdded = () => {
    setError({});
    handleCashOut();
  };

  return (
    <div className="playlist-component">
      <div
        style={{
          paddingInline: "1rem",
          textAlign: "center",
          marginTop: "2rem",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <span className="title white pointer" onClick={() => handleClose()}>
            X
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "justify",
        }}
      >
        <div style={{ marginTop: "1rem" }}>
          <div
            style={{
              maxWidth: "25rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span className="sub-title white bold">Cash</span>
            <span className="sub-title white ">
              ${cashAvailable.toFixed(2)}
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            paddingTop: "2rem",
            fontSize: "1rem",
            justifyContent: "center",
          }}
        >
          <div>
            <div style={{ paddingTop: "2rem" }}>
              <span className="white bold">
                How much do you want to withdraw?
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "1rem",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "5rem", margin: "auto" }}>
                  <button
                    id="withdraw-max-button"
                    className={
                      custom === false ? "txt-1 btn" : "txt-1 btn-inverse"
                    }
                    style={{ width: "100%" }}
                    onClick={() => handleAmount()}
                  >
                    Max
                  </button>
                </div>
                <div
                  style={{ width: "5rem", margin: "auto", textAlign: "left" }}
                >
                  <span className="white txt-1">
                    ${cashAvailable.toFixed(2)}
                  </span>
                </div>
              </div>

              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ width: "5rem", margin: "auto" }}>
                  {!custom ? (
                    <button
                      className={"btn-inverse"}
                      style={{ width: "100%", fontSize: "1rem" }}
                      onClick={() => handleCustom()}
                    >
                      Custom
                    </button>
                  ) : (
                    <input
                      className="input txt-1"
                      type="number"
                      value={stock.amount > 0 ? stock.amount : ""}
                      placeholder="Enter amount"
                      style={{ maxWidth: "100%" }}
                      onChange={(e) => handleInput(e)}
                    />
                  )}
                </div>
                <div
                  style={{ width: "5rem", margin: "auto", textAlign: "left" }}
                >
                  <span className="white txt-1" style={{ color: "black" }}>
                    $50.12
                  </span>
                </div>
              </div>
            </div>
            <div>
              <ErrorMessage error={error} destiny={"customAmount"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "1rem",
              }}
            >
              <div style={{ paddingTop: "1rem" }}>
                <span className="white bold">Deposit into</span>
              </div>
              <div style={{ paddingTop: "1rem" }}>
                <span className="white">Connected bank account</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "2rem" }}>
        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
          {sold ? <CompleteLabel text={"Cash out initiated"} /> : null}
        </div>
        <div>
          <PlaidLink
            token={linkToken}
            setLoading={setLoading}
            plaidNotification={true}
            callback={() => handleBankAdded()}
          >
            {" "}
            <ErrorMessage
              error={error}
              destiny={"cashOutError"}
              link={"here."}
            />
          </PlaidLink>
        </div>
        <div>
          {loading ? (
            <div className="mt-1">
              <TriangleSpinner />
            </div>
          ) : (
            <button
              id="button-cashout-process"
              disabled={cashAvailable <= 0 ? true : false}
              onClick={() => {
                sold ? handleClose() : handleCashOut();
              }}
              className={
                cashAvailable <= 0
                  ? "btn-inverse-round gray"
                  : "btn-inverse-round"
              }
            >
              {sold ? "Done" : "Cash Out"}
            </button>
          )}
          <div>
            <span className="txt-1 white pb-2">
              Estimated to arrive in 1-5 days. <br />
              Promotional credit cannot be withdrawn. <br />
              Stock buys made with promotional credit can be sold at any time
              after settling, but cannot be cashed out until after 90 days of
              becoming a member.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
