import React from "react";
import spotifyig from "../images/footer/spoti-ig.png";
import spotify from "../images/footer/nmc-spotify.png";
import instagram from "../images/footer/nmc-ig.png";

export default function Footer({ showText = true }) {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        marginTop: "2rem",
        paddingBottom: !showText ? "6rem" : "0rem",
      }}
    >
      <div
        className="txt-1 white"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "2rem",
          fontFamily: "Courier New",
          maxWidth: 700,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "space-evenly",
            marginBottom: "1rem",
          }}
        >
          <div
            className="pointer"
            onClick={() =>
              window.open(
                "https://open.spotify.com/playlist/3Mw85i2xQUlR6IJ2FCUyw0?go=1&sp_cid=3e0cbf805cd6f146[…]fad73a8fc2&utm_source=embed_player_m&utm_medium=desktop&nd=1",
                "_blank"
              )
            }
          >
            <img style={{ maxWidth: "40%", height: "auto" }} src={spotify} />
          </div>
          <div>
            <img
              className="pointer"
              onClick={() =>
                window.open("https://www.instagram.com/neonmoneyclub", "_blank")
              }
              style={{ maxWidth: "40%", height: "auto" }}
              src={instagram}
            />
          </div>
        </div>
        <span style={{ marginBottom: "0.75rem" }}>NEON MONEY CLUB</span>
        <span style={{ marginBottom: "0.75rem" }}>
          © 2023 Neon Money, Inc. All rights reserved.
        </span>

        <a
          className="neon-color"
          style={{
            marginBottom: "0.75rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          href="https://joinneon.com/home"
          target="_blank"
        >
          home
        </a>

        <a
          className="neon-color"
          style={{
            marginBottom: "0.75rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          href="https://joinneon.com/about-us"
          target="_blank"
        >
          about us
        </a>

        <a
          className="neon-color"
          style={{
            marginBottom: "0.75rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          href="https://joinneon.com/faqs"
          target="_blank"
        >
          frequently asked questions
        </a>

        <a
          className="neon-color"
          style={{
            marginBottom: "0.75rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          href="https://joinneon.com/unlocks"
          target="_blank"
        >
          unlocks
        </a>
        <a
          className="neon-color"
          style={{
            marginBottom: "0.75rem",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          href="https://joinneon.com/disclosures"
          target="_blank"
        >
          disclosures
        </a>
        {showText ? (
          <>
            <span style={{ marginBottom: "0.75rem" }}>
              All investments involve risks including the possible loss of
              capital.{" "}
            </span>
            <span style={{ marginBottom: "0.75rem" }}>
              Brokerage services are provided to clients of Neon by DriveWealth
              LLC, a registered broker-dealer, member of FINRA and SIPC.{" "}
            </span>
            <span style={{ marginBottom: "0.75rem" }}>
              Made with ❤️ from {""}
              <a
                className="neon-color"
                style={{
                  marginBottom: "0.75rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                href="https://open.spotify.com/playlist/1g45wn5B19zhzFgDMAFoMb?si=7585aeb19d2c461a"
                target="_blank"
              >
                The Bay
              </a>
              {""} + {""}
              <a
                className="neon-color"
                style={{
                  marginBottom: "0.75rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                href="https://open.spotify.com/track/61EMdKE8HxRvMJmqOyZ7qi?si=f566f92203d642fa"
                target="_blank"
              >
                NYC
              </a>
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
}
