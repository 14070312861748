import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ErrorMessage from "../../../components/ErrorMessage";
import amex from "../../../images/amex.gif";
import { URIS } from "../../../Constants/Endpoints";
import axios from "axios";
import Dropdown from "../../../components/Dropdown/Dropdown";
import TriangleSpinner from "../../../components/TriangleSpinner";
import card from "../../../images/card-wallet.gif";
import AutoCompleteInput from "../../../components/AutoCompleteInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMask from "react-input-mask";
import io from "socket.io-client";
import { isMobile } from "react-device-detect";
import "./CardQualify.css";
import { benefitsData } from "../../../Constants/Card";
import validator from "validator";
import { forbiddenNumbers } from "../../../Constants/ForbiddenNumbers";

export default function CardQualify({ setLoading, loading, kycError }) {
  const history = useHistory();
  const [qualified, setQualified] = useState(false);
  const [form, setForm] = useState({
    ssn: "",
    email: "",
    phone_number: "",
    income_bucket: "C",
    first_name: "",
    last_name: "",
    address: "",
  });
  const [error, setError] = useState(false);
  const [viewForm, setViewForm] = useState(false);

  //checks access code is valid
  const checkAccessCode = async () => {
    let data = {
      phone_number: form.phone_number,
      code: "alpha",
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          audience_url: process.env.REACT_APP_CORE_API + URIS.FRONT_GATE,
        },
      })
      .then((message) => {
        if (message.data.status) {
          handleAccessCodeValid(message.data.code_id);
        }
      })
      .catch((error) => {});
  };

  //if access code valid do this
  const handleAccessCodeValid = (code_id) => {
    setForm({ ...form, code_id: code_id });
    openSocket();
    //setAccessVerified(true);
  };

  const openSocket = () => {
    const mode = isMobile ? "mobile" : "web";
    setLoading(true);
    const socket = io(process.env.REACT_APP_PROVE_API);
    socket.emit("init_private_channel", {
      phone_number: form.phone_number,
      birthdate: form.birthdate,
      mode: mode,
      email: form.email,
      access_code: "alpha",
      code_id: form.code_id,
      origin: "card",
      referral: "false",
      form: form,
    });
    socketOn(socket);
  };

  const socketOn = (socket) => {
    try {
      socket.on("prove_validation", function (message) {
        console.log(message);
        if (message.status.toLowerCase() === "registered") {
          history.push("/login");
        } else if (message.status === "True") {
          if (process.env.REACT_APP_PROVE_API.includes("stg-uat")) {
            //proveValidated(message.vfp);
          }
        } else if (message.status === "repeated_email") {
          setError({
            message: "This email is already in use.",
            destiny: "email",
          });
        } else {
          setError({
            message:
              "Looks like we weren’t able to verify your phone number. Please hit us on chat.",
            destiny: "card",
          });
        }
        setLoading(false);
      });
    } catch {
      console.error(error);
      setLoading(false);
    }
  };

  const validateFields = async () => {
    let splitAddress = form.address.split(", ");

    if (form.first_name === "" || form.last_name === "") {
      setError({
        message: "Please enter both your first and last name.",
        destiny: "card",
      });
      return false;
    } else if (
      !validator.isEmail(form.email) ||
      form.email.includes("gmail.con")
    ) {
      setError({
        message: "Please enter a valid email.",
        destiny: "card",
      });
      return false;
    } else if (form.address.length < 10) {
      setError({
        message: "Please enter a valid address.",
        destiny: "card",
      });
      setForm({ ...form, address: "" });
      return false;
    } else if (splitAddress.length < 4) {
      setError({
        message: "Please select your address from the dropdown menu.",
        destiny: "card",
      });
      setForm({ ...form, address: "" });
      return false;
    } else if (
      form.address.toLowerCase().includes("po box") ||
      form.address.toLowerCase().includes("p.o. box") ||
      form.address.toLowerCase().includes("p.o box")
    ) {
      setError({
        message: "PO Box addresses are not allowed.",
        destiny: "card",
      });
      setForm({ ...form, address: "" });
      return false;
    } else {
      let stateAndZip = splitAddress[2].split(" ");
      let state = stateAndZip[0];
      let zipCode = stateAndZip[1];

      if (state.length !== 2 || zipCode.length < 5) {
        setError({
          message: "Please select your address from the dropdown menu.",
          destiny: "card",
        });
        setForm({ ...form, address: "" });
        return false;
      }
    }
    if (form.ssn.length < 9) {
      setError({ message: "Please enter a valid SSN.", destiny: "card" });
      return false;
    } else if (!validator.isDate(form.birthdate, { format: "MM/DD/YYYY" })) {
      setError({
        message: "Please enter a valid date.",
        destiny: "card",
      });
      return false;
    } else if (!validator.isDate(form.birthdate, { format: "MM/DD/YYYY" })) {
      setError({
        message: "Please enter a valid date.",
        destiny: "card",
      });
      return false;
    }

    const areaCode = parseInt(form.phone_number.substring(0, 3));
    if (!validator.isMobilePhone(form.phone_number, ["en-US"])) {
      setError({
        message: "Please enter a valid U.S. mobile number",
        destiny: "card",
      });
      return false;
    } else if (forbiddenNumbers.includes(areaCode)) {
      setError({
        message: "We see you Canada. We'll hit you once we open up to ya'll.",
        destiny: "card",
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleRegister = async () => {
    if (viewForm) {
      let validation = await validateFields();
      if (validation) {
        checkAccessCode();
      }
    } else {
      setViewForm(true);
    }
  };

  const getButtonText = () => {
    if (qualified) {
      return "Activate your new card";
    } else {
      return "See if you're pre-approved";
    }
  };

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="futura-bold  white "
              style={{ width: "100%", fontSize: "1.5rem" }}
            >
              The Card That Invests In You
            </span>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          <img src={card} width="100px" />
        </div>

        <span className="sub-title">What you get</span>
        {benefitsData.map((benefit, index) => {
          return (
            <div
              className="row mt-1 benefits-rows txt-1"
              style={{
                justifyContent: "center",
                width: "100%",
              }}
              key={index}
            >
              <div
                style={{
                  padding: "0.5rem",
                  textAlign: "center",
                  width: "20%",
                  margin: "auto",
                }}
              >
                <span className="bold sub-title">{benefit.title}</span>
              </div>
              <div
                style={{
                  padding: "0.5rem",
                  width: "70%",
                }}
              >
                <span>{benefit.content}</span>
              </div>
            </div>
          );
        })}
        <div className="mt-1 row" style={{ marginBottom: "2rem" }}>
          <div style={{ margin: "auto", marginInline: "unset" }}>
            <span className="txt-1 ">
              First year annual fee waived. See all benefits{" "}
            </span>
          </div>
          <>
            <MdOutlineKeyboardArrowRight
              style={{
                color: "white",
                fontSize: "1.5rem",
                height: "100%",
                fontWeight: "bold",
                alignSelf: "flex-end",
                marginTop: "0.25rem",
              }}
            />
          </>
        </div>
        {viewForm && !loading && !kycError ? (
          <FormComponent setForm={setForm} form={form} error={error} />
        ) : null}

        {viewForm ? (
          <div className="mt-1 mb-1">
            <span className="txt-s">
              {false ? (
                <>
                  By clicking the button below you agree to Neon’s card member{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    terms and conditions
                  </span>
                </>
              ) : !error ? (
                "Your credit score will not be impacted if you aren’t approved. By clicking the button below, you authorize Neon Money Club to run a credit check."
              ) : (
                ""
              )}
            </span>
          </div>
        ) : null}
        <ErrorMessage error={error || kycError} destiny={"card"} />
        {!loading ? (
          !kycError ? (
            <button
              className="btn-inverse-round txt-1 "
              style={{
                marginTop: "1rem",
              }}
              onClick={() => handleRegister()}
            >
              {getButtonText()}
            </button>
          ) : (
            <></>
          )
        ) : (
          <div className="row" style={{ justifyContent: "center" }}>
            <TriangleSpinner />
          </div>
        )}
        {viewForm ? (
          <div
            style={{
              maxWidth: "25rem",
              textAlign: "center",
            }}
          >
            {!false && error.destiny !== "card" ? (
              <span className="txt-s futura-mid white">
                &#128274; BANK LEVEL PRIVACY AND SECURITY
              </span>
            ) : false ? (
              <span className="txt-s-light">
                Your rate will be % APR. Accepting your new card will result in
                an inquiry appearing on your credit report.{" "}
              </span>
            ) : null}
          </div>
        ) : error.destiny !== "card" && !kycError ? (
          <div style={{ textAlign: "center" }}>
            <span className="txt-s">
              Qualifying doesn't impact your credit score
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

//FORM COMPONENT
const FormComponent = ({ setForm, form, birthdateInput }) => {
  const [inputType, setInputType] = useState("");
  const [ssn, setSsn] = useState("");

  const handleInput = (ssnText) => {
    setSsn(ssnText);
    setForm({ ...form, ssn: ssnText });
  };
  const [error, setError] = useState(false);
  let nameRegex = /^[a-zA-Z\s_-]*$/;
  const [dateSelected, setDateSelected] = useState(false);
  const [birthText, setBirthText] = useState("+18 to enter");
  const [showBirthText, setShowBirthText] = useState(false);
  const [name, setName] = useState("");

  const salaryRanges = [
    { value: "A", range: "$0-$9,999" },
    { value: "B", range: "$10K-$39,999" },
    { value: "C", range: "$40K-$79,999" },
    { value: "D", range: "$80K-$119,999" },
    { value: "E", range: "$120K-$159,999" },
    { value: "F", range: "$160K and beyond" },
  ];

  const changeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleName = (name) => {
    if (!nameRegex.test(name)) {
      var subName = name.substring(0, name.length - 1);
      setName(subName);
    } else if (name[0] === " ") {
      setName("");
    } else {
      setName(name);
    }
  };

  function birthdateInput(e) {
    let date = "";
    if (e.$M < 9) {
      date = "0";
    }

    date = date + (e.$M + 1) + "/";
    if (e.$D < 10) {
      date = date + "0";
    }
    date = date + e.$D + "/" + e.$y;
    changeHandler("birthdate", date);
    setDateSelected(true);
  }

  const handlePhoneInput = (e) => {
    changeHandler("phone_number", e.target.value.replace(/[^\d]/g, ""));
  };

  //handles behavior when focus is on date of birth input
  const handleFocus = (target) => {
    if (target === "DOB_") {
      setError({ message: "", destiny: "" });
      setBirthText("mm/dd/yyyy");
      setShowBirthText(true);
    }
  };

  const setNameInForm = () => {
    const names = name.split(" ");
    if (name.length >= 7 && names.length >= 2 && names[1] !== "") {
      setForm({
        ...form,
        first_name: names[0],
        last_name: names[1] + (names[2] ? " " + names[2] : ""),
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        marginLeft: "-1rem",
      }}
    >
      <div className="row">
        <div className="column">
          <span className="txt-s futura-mid white">
            Legal first and last name
          </span>

          <div>
            <input
              id="full-name"
              placeholder="Your full name here"
              className="input-firs-last-name"
              value={name}
              onChange={(e) => handleName(e.target.value)}
              pattern="[A-Za-z]*"
              onBlur={() => setNameInForm()}
            />
            <div
              style={{
                maxWidth: "8rem",
                borderBottom: "white 1px solid",
                margin: "auto",
              }}
            />
            <ErrorMessage error={error} destiny={"name"} />
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="column">
          <span className="txt-s futura-mid white">Email address </span>
          <input
            style={{
              overflowX: "visible",
            }}
            id="email"
            className="input-email"
            placeholder="email@address.com"
            value={form.email}
            onChange={(e) => changeHandler("email", e.target.value)}
          />
          <div
            style={{
              width: "8rem",
              borderBottom: "#d1d1cc 1px solid",
              margin: "auto",
            }}
          />
          <ErrorMessage error={error} destiny={"email"} />
        </div>
      </div>
      <div style={{ paddingTop: "2rem" }}>
        <div>
          <span className="txt-s futura-mid white">Address</span>
        </div>
        <div>
          <AutoCompleteInput
            value={
              form.address
                ? form.address.line_1
                  ? form.address.line_1 +
                    " " +
                    form.address.city +
                    " " +
                    form.address.state +
                    " " +
                    form.address.zip_code
                  : form.address
                : ""
            }
            changeHandler={changeHandler}
          />
          <div
            style={{
              width: "8rem",
              borderBottom: "white 1px solid",
              margin: "auto",
            }}
          />
          <ErrorMessage error={error} destiny={"address"} />
        </div>
      </div>

      <div className="row">
        <div
          className="column"
          style={{ paddingTop: "2rem", marginInline: "1rem" }}
        >
          <span className="txt-s futura-mid white">SSN </span>
          <input
            pattern="\d*"
            className="input"
            value={ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3")}
            maxLength={11}
            onChange={(e) => handleInput(e.target.value.replace(/[^\d]/g, ""))}
            onFocus={() => {
              setInputType("text");
            }}
            onBlur={() => {
              setInputType("password");
            }}
            placeholder="XXXXXXXXX"
            type={inputType}
          />
        </div>
        <ErrorMessage error={error} destiny={"ssn"} />
        <div className="column mt-2 white">
          <span className="txt-s futura-mid white">Yearly Income </span>
          <Dropdown items={salaryRanges} setForm={setForm} form={form} />
        </div>
        <ErrorMessage error={error} destiny={"income"} />
      </div>

      <div className="row">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            paddingTop: "1.4rem",
            marginInline: "1rem",
          }}
        >
          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{ dateTableLabel: "Your Birthday" }}
            >
              <MobileDatePicker
                className=" -webkit-appearance: none;"
                disableFuture
                format="MM/DD/YYYY"
                views={["year", "month", "day"]}
                openTo="year"
                label="+18 to enter"
                maxDate={dayjs("01-01-2005")}
                minDate={dayjs("01-01-1938")}
                id="birth-date"
                sx={{
                  "& .MuiInputBase-input": {
                    color: "white",
                    fontSize: 13,
                    textAlign: "center",
                    width: 100,
                    fontFamily: "futura",
                    borderRadius: 0,
                    WebkitAppearance: "none",
                  },
                  "& .MuiFormLabel-root": {
                    color: dateSelected ? "black" : "#989898",
                    fontSize: 15,
                    fontFamily: "futura",
                    textAlign: "center",
                    alignSelf: "center",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderWidth: 0,
                    },
                  },
                }}
                renderInput={(params) => <input {...params} />}
                onAccept={(e) => birthdateInput(e)}
              />
            </LocalizationProvider>
            <div
              style={{
                maxWidth: "8rem",
                borderBottom: "#d1d1cc 1px solid",
                margin: "auto",
              }}
            />
            <div
              style={{
                color: dateSelected ? "black" : "white",
                fontSize: "0.8rem",
                paddingTop: 5,
              }}
            >
              Select your birthday
            </div>
            <ErrorMessage
              error={error}
              destiny={"birth"}
              position={"relative"}
            />
          </div>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {showBirthText ? (
              <span className="white txt-1" style={{ position: "relative" }}>
                Enter your birthdate.
              </span>
            ) : null}
          </div>
        </div>
        <ErrorMessage error={error} destiny={"ssn"} />
        <div className="column" style={{ paddingTop: "2rem" }}>
          <InputMask
            pattern="\d*"
            id="phone-number"
            className="input"
            placeholder="Phone number"
            value={
              form && form.phone_number
                ? form.phone_number.replace(
                    /^(\d{3})(\d{3})(\d+)$/,
                    "($1)$2-$3"
                  )
                : ""
            }
            mask="(999)999-9999"
            onChange={(e) => handlePhoneInput(e)}
            onFocus={() => handleFocus("PHONE_")}
          />
          <ErrorMessage error={error} destiny={"phone"} position={"relative"} />
        </div>
      </div>
    </div>
  );
};
