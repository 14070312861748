import { createSlice } from "@reduxjs/toolkit";

const playlistsSlice = createSlice({
  name: "playlists",
  initialState: {
    playlist: null,
    playlist_local: null,
    playlist_detail: null,
    brands: null,
    all_playlists_settings: null,
  },
  reducers: {
    setPlaylistLocal: (state, action) => {
      state.playlist_local = action.payload.playlist_local;
      state.playlist = action.payload.playlist;
    },
    setPlaylistDetail: (state, action) => {
      state.playlist_detail = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setPlaylistsSettings: (state, action) => {
      state.all_playlists_settings = action.payload;
    },
    clearPlaylistsSettings: (state) => {
      state.all_playlists_settings = null;
    },
  },
});

export const {
  setPlaylistLocal,
  setPlaylistDetail,
  setPlaylistsSettings,
  clearPlaylistsSettings,
} = playlistsSlice.actions;
export default playlistsSlice.reducer;
