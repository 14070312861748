import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Card from "../components/Card";
import SeeIfYouQualify from "../components/SeeIfYouQualify";
import DisclosuresForm from "../components/DisclosuresForm";
import NeonValueProps from "../components/NeonValueProps";
import KycForm from "../components/KycForm";
import CreationFailed from "../components/CreationFailed";
import WelcomeWithBenefits from "./WelcomeWithBenefits";
import validator from "validator";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import Error from "./Error";
import { refresh } from "../helpers/Auth";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, clearStore } from "../Store/Login/Reducers";
import { setJwt } from "../Store/Login/Reducers";
import { setVerified } from "../Store/Register/Reducers";
import { handleToken } from "../helpers/Auth";
import { forbiddenNumbers } from "../Constants/ForbiddenNumbers";
import ErrorMessage from "../components/ErrorMessage";
import winKicks from "../images/win-kicks.png";
import Giveaways from "../components/Giveaways";
import Toggle from "../components/Toggle";
import { setShowForm } from "../Store/Register/Reducers";
import CardOrigination from "./CardViews/CardOrigination/CardOrigination";

export default function ClaimStock() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const vfpParam = query.get("vfp");
  const birthdate = query.get("birthdate");
  const access_code = query.get("access_code")
    ? query.get("access_code")
    : query.get("code");
  const code_id = query.get("code_id");
  const phone_number = query.get("phone_number");
  const email = query.get("email");
  const [resendEnabled, setEnableResend] = useState(false);
  const referral = query.get("referral");
  const [speedBump, setSpeedBump] = useState(true);
  const [hideButton, setButtonHidden] = useState(false);
  let { verified, registerFlow, showForm } = useSelector(
    (state) => state.register
  );
  let { vfp, access_token, refresh_token, user } = useSelector(
    (state) => state.login
  );
  let history = useHistory();
  const [form, setForm] = useState({
    access_code: "",
    code_id: null,
    phone_number: "",
    first_name: "",
    last_name: "",
    email: "",
    ssn: "",
    birthdate: "",
    address: "",
    address_2: "",
    employed: null,
    disclosured: null,
    consented: null,
    verifyToken: "",
  });

  const validCodes = [
    "kicks",
    "ihyl",
    "drops",
    "complex",
    "singles",
    "katrina",
    "chris",
    "tj",
    "gc",
    "varo",
  ];
  const [referrer, setReferrer] = useState("");
  let inCampaign =
    validCodes.includes(access_code) ||
    validCodes.includes(form.access_code) ||
    referrer !== ""
      ? true
      : false;
  const [step, setStep] = useState(
    verified
      ? access_code === "metaversal" || referral === "false"
        ? "giveaway"
        : inCampaign
        ? 3
        : registerFlow === "normal"
        ? access_code === "drops"
          ? 3
          : 2
        : 1
      : 1
  );
  const handleResend = () => {
    ReactGA.event({
      category: "ACCESS_CODE",
      action: "RESEND_CLICKED",
    });
    setLoading(true);
    handleSeeIfYouQualify();
    setEnableResend(false);
  };
  const [error, setError] = useState({ message: "", destiny: "" });
  const [modal, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [qualified, setQualified] = useState(true);
  const [qualifyError, setQualifyError] = useState(
    "We weren't able to qualify your phone number. Hit us on chat with your email and we'll reach out with any updates."
  );
  const kycErrorMessage = "We weren't able to verify your identity";
  const speedBumpMessage =
    "We ran into a speed bump creating your account. Hit us on chat and we'll figure this out";
  const [accessVerified, setAccessVerified] = useState(false);
  const changeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const qualifyErrorText = (
    <div
      id="qualify_error"
      style={{
        margin: "auto",
        textAlign: "left",
        maxWidth: "20rem",
        marginBottom: "1rem",
      }}
    >
      <span className="white txt-s" style={{ fontFamily: "futura-medium" }}>
        {qualifyError}
      </span>
    </div>
  );

  useEffect(async () => {
    if ((step !== 1 && form.email !== "") || access_code === "alpha") {
      return;
    }
    if (vfpParam && birthdate && access_code && phone_number && email) {
      let registerFlow = "";
      setForm({
        ...form,
        phone_number: phone_number,
        access_code: access_code,
        code_id: code_id,
        birthdate: birthdate,
        email: email.replace(" ", "+"),
      });
      if (access_code === "metaversal" && referral === "false") {
        registerFlow = "kicks";
        setStep("giveaway");
      } else if (referral === "true") {
        registerFlow = "referral";
        setStep(3);
      } else {
        registerFlow = "normal";
        if (validCodes.includes(access_code)) setStep(3);
        else setStep(2);
      }
      let data = {
        phone_number: phone_number,
        access_code: access_code,
        birthdate: birthdate,
        email: email.replace(" ", "+"),
        code_id: code_id,
      };

      await handleToken(vfpParam, phone_number).then((res) => {
        dispatch(setJwt(res));
        dispatch(
          setVerified({
            form: data,
            verified: true,
            registerFlow: registerFlow,
          })
        );
      });
    } else {
      if (
        (registerFlow === "referral" || registerFlow === "kicks") &&
        access_code === null
      ) {
        endSession();
      }
    }
  }, [vfpParam, birthdate, access_code, phone_number, history]);

  const verifyFields = (step) => {
    if (step === 1) {
      handleSeeIfYouQualify();
    } else if (step === 2 || step === "giveaway") {
      handleReasonsToJoin();
    } else if (step === 3) {
      handleKYC();
    } else if (step === 4) {
      handleDisclosures();
    } else if (step === 5) {
      handleWelcome();
    }
  };

  function validateAge() {
    var today = new Date();
    var nowyear = today.getFullYear();
    var nowmonth = today.getMonth();
    var nowday = today.getDate();

    var birth = new Date(form.birthdate);
    var birthyear = birth.getFullYear();
    var birthmonth = birth.getMonth();
    var birthday = birth.getDate();

    var age = nowyear - birthyear;
    var ageMonth = nowmonth - birthmonth;
    var ageDay = nowday - birthday;

    if (
      age < 18 ||
      (age === 18 && ageMonth < 0) ||
      (age === 18 && ageMonth <= 0 && ageDay < 0)
    ) {
      setError({
        message: "You must be older than 18 to sign up.",
        destiny: "birth",
      });
      return false;
    }
    return true;
  }

  //Last step, ran after clicking next in the flag screen
  const handleWelcome = () => {
    let dashboardUrl =
      "/?vfp=" + vfpParam + "&phone_number=" + form.phone_number;
    history.push(dashboardUrl);
  };

  //Step one in 86 route
  const handleSeeIfYouQualify = () => {
    setButtonHidden(true);
    const areaCode = parseInt(form.phone_number.substring(0, 3));
    if (!validator.isMobilePhone(form.phone_number, ["en-US"])) {
      setError({
        message: "Please enter a valid U.S. mobile number",
        destiny: "phone",
      });
      setButtonHidden(false);
    } else if (forbiddenNumbers.includes(areaCode)) {
      setError({
        message: "We see you Canada. We'll hit you once we open up to ya'll.",
        destiny: "canada",
      });
      setButtonHidden(false);
    } else if (
      !validator.isEmail(form.email) ||
      form.email.includes("gmail.con")
    ) {
      setError({
        message: "Please enter a valid email.",
        destiny: "email",
      });
    } else if (!validator.isDate(form.birthdate, { format: "MM/DD/YYYY" })) {
      setError({
        message: "Please enter a valid date.",
        destiny: "birth",
      });
      setButtonHidden(false);
    } else if (!validateAge()) {
      setButtonHidden(false);
    } else if (form.access_code === "") {
      setError({
        message: "Please enter your access code.",
        destiny: "code",
      });
      setButtonHidden(false);
    } else if (accessVerified && referrer === "") {
      setAccessVerified(false);
      //setButtonHidden(false);
    } else {
      ReactGA.event({
        category: "ACCESS_CODE",
        action: "SUBMITTED",
        label: form.access_code,
      });
      dispatch(setShowForm(false));
      setLoading(true);
      setError({});
    }
  };

  const handleReasonsToJoin = () => {
    if (birthdate && access_code && phone_number) {
      setStep(3);
    } else {
      setStep(1);
    }
    dispatch(setVerified({ verified: false }));
  };
  const handleKYC = async () => {
    ReactGA.event({
      category: "KYCform",
      action: "NEXT_CLICKED",
    });
    const [firstName, ...lastNameArr] = name.split(" ");
    const lastName = lastNameArr.join(" ");
    let splitAddress = form.address.split(", ");

    if (!firstName || !lastName) {
      setError({
        message: "Please enter both your first and last name.",
        destiny: "name",
      });
    } else if (form.address.length < 10) {
      setError({
        message: "Please enter a valid address.",
        destiny: "address",
      });
      setForm({ ...form, address: "" });
    } else if (splitAddress.length < 4) {
      setError({
        message: "Please select your address from the dropdown menu.",
        destiny: "address",
      });
      setForm({ ...form, address: "" });
    } else if (
      form.address.toLowerCase().includes("po box") ||
      form.address.toLowerCase().includes("p.o. box") ||
      form.address.toLowerCase().includes("p.o box")
    ) {
      setError({
        message: "PO Box addresses are not allowed.",
        destiny: "address",
      });
      setForm({ ...form, address: "" });
    } else {
      var stateAndZip = splitAddress[2].split(" ");
      var state = stateAndZip[0];
      var zipCode = stateAndZip[1];
      let query = {
        line_1: splitAddress[0],
        city: splitAddress[1],
        state: state,
        zip_code: zipCode,
      };
      if (state.length !== 2 || zipCode.length < 5) {
        setError({
          message: "Please select your address from the dropdown menu.",
          destiny: "address",
        });
        setForm({ ...form, address: "" });
      } else {
        setForm({
          ...form,
          first_name: firstName,
          last_name: lastName,
          address: query,
          code_id: parseInt(form.code_id),
        });
        setError({});
        setStep(4);
      }
    }
  };

  const endSession = () => {
    dispatch(clearStore());
    localStorage.clear();
    history.push("/86");
  };

  useEffect(() => {
    if (step === 4 && loading) {
      const timer = setTimeout(() => {
        if (loading) {
          setLoading(false);
          setSpeedBump(true);
          setStep(6);
        }
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  useEffect(() => {
    if (accessVerified) {
      setTimeout(() => {
        setEnableResend(true);
      }, 10000);
    }
  }, [accessVerified]);

  function handleDisclosures() {
    ReactGA.event({
      category: "KYCyesno",
      action: "NEXT_CLICKED",
    });
    if (
      form.employed === null ||
      form.disclosured === null ||
      form.consented === ""
    ) {
      setError({
        message: "Please select yes for each answer to move forward.",
        destiny: "other",
      });
    } else {
      setLoading(true);
      submitForm();
    }
  }

  const submitForm = async () => {
    //Remember to delete this
    changeHandler("verifyToken");
    document.getElementsByClassName("btn-inverse-round")[0].style.display =
      "none";
    let access = await refresh(refresh_token);
    if (access.status) {
      const authConfig = {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access.access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CREATE_ACCT,
        },
      };
      await axios
        .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
        .then((message) => {
          setLoading(false);
          if (message.data.status) {
            let data = { user: message.data.user, vfp: vfp };
            dispatch(loginSuccess(data));
            setStep(5);
            setError("");
            return;
          } else {
            setStep(6);
            return;
          }
        })
        .catch((error) => {
          setLoading(false);
          setStep(6);
        });
    } else {
      endSession();
    }
  };

  const handleBack = () => {
    let backStep = 0;

    if (form.access_code === "metaversal" || referrer !== "") {
      backStep = "giveaway";
    } else {
      backStep = step - 1;
    }
    setStep(backStep);
  };

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      window.location = "https://joinneon.com";
    });
  }, [step === 1]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [step === 4, step === 5]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      window.location = "https://joinneon.com";
    });
  }, [step === 1]);

  useEffect(() => {
    // redirect back to dashboard if the user hasn't logged out yet.
    window.history.pushState(null, document.title, window.location.href);
    if (access_token && refresh_token && user && access_code !== "alpha") {
      history.push("/");
    } else if (inCampaign && step === 2) {
      endSession();
    }
  }, []);

  const content =
    step === 1 ? (
      <SeeIfYouQualify
        form={form}
        changeHandler={changeHandler}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        handleSeeIfYouQualify={handleSeeIfYouQualify}
        setStep={setStep}
        qualified={qualified}
        setQualified={setQualified}
        accessVerified={accessVerified}
        setAccessVerified={setAccessVerified}
        setButtonHidden={setButtonHidden}
        hideButton={hideButton}
        referrer={referrer}
        setReferrer={setReferrer}
        validCodes={validCodes}
      />
    ) : step === 2 ? (
      <NeonValueProps />
    ) : step === 3 ? (
      <KycForm
        form={form}
        changeHandler={changeHandler}
        error={error}
        name={name}
        setName={setName}
      />
    ) : step === 4 ? (
      <DisclosuresForm
        form={form}
        changeHandler={changeHandler}
        error={error}
        modal={modal}
        setOpen={setOpen}
        loading={loading}
      />
    ) : step === 5 ? (
      <WelcomeWithBenefits verifyFields={verifyFields} />
    ) : step === -1 ? (
      <Error text={kycErrorMessage} />
    ) : step === "giveaway" ? (
      <Giveaways verifyFields={verifyFields} />
    ) : (
      <CreationFailed text={speedBump ? speedBumpMessage : ""} />
    );
  const title =
    step === 3
      ? "Club access in 30 seconds"
      : step === 4
      ? "You're Almost Done"
      : "Neon Money Club";

  const showToggle = () => {
    if (inCampaign) {
      if ([1, 3].includes(step)) {
        return true;
      } else if (!loading && step === 4) {
        return true;
      } else return false;
    } else {
      return false;
    }
  };

  const showRegisterButton = () => {
    if (modal) {
      return false;
    } else if (
      (step === 1 && !inCampaign) ||
      (showForm && inCampaign && !loading)
    ) {
      return true;
    } else if (!showForm && inCampaign && step === 1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {access_code === "alpha" ? (
        <CardOrigination />
      ) : (
        <div
          style={{
            height: "auto",
            overflowX: "unset",
          }}
        >
          {((step === 3 || step === 4) && !modal) || step === 6 ? (
            <div
              style={{
                width: "100%",
                textAlign: "left",
                paddingLeft: "2rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {step === 4 ||
              (step === 3 &&
                (registerFlow === "referral" ||
                  validCodes.includes(access_code))) ? null : (
                <span
                  className="txt-1 neon-color pointer"
                  onClick={() => handleBack()}
                >
                  Back
                </span>
              )}
              <span
                className="title white futura-mid"
                style={{ fontSize: "1.5rem" }}
                id="step3-title"
              >
                {title}
              </span>
            </div>
          ) : (
            <div></div>
          )}

          <Card
            referrer={referrer}
            content={content}
            step={step}
            verifyFields={verifyFields}
            showBtn={showRegisterButton()}
            enableBtn={
              step === 4 &&
              (!form.disclosured || !form.consented || form.employed === null)
                ? false
                : true
            }
            showSpinner={showSpinner}
            hideButton={hideButton}
            validCodes={validCodes}
          />
          {!qualified ? qualifyErrorText : null}
          <div>
            <ErrorMessage
              error={error}
              destiny={"canada"}
              position={"relative"}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!showForm && inCampaign && step === 1 ? (
                <div
                  style={{
                    width: "75%",
                    maxWidth: "450px",
                    margingTop: "1rem",
                    margin: "auto",
                  }}
                >
                  <button
                    id="register-btn"
                    className={
                      loading ? "btn-inverse-round" : "btn-reverse-round"
                    }
                    onClick={() =>
                      loading ? null : dispatch(setShowForm(true))
                    }
                    style={{
                      marginInline: "auto",
                    }}
                  >
                    {loading
                      ? "We just texted you a secure link. Tap it."
                      : "Sign up in 30 seconds"}
                  </button>
                </div>
              ) : null}
              {resendEnabled && accessVerified && step === 1 ? (
                <span className="link mb-1" onClick={() => handleResend()}>
                  Resend Link
                </span>
              ) : null}
              <div
                className="row"
                style={{
                  justifyContent: "center",
                }}
              >
                <a
                  href="https://joinneon.com/#security"
                  target="_blank"
                  className="txt-1-mid pointer"
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontSize: "0.83rem !important",
                    marginTop: "auto",
                    marginBottom: "1rem",
                  }}
                >
                  🔒 How We Protect And Support You{" "}
                </a>
              </div>

              {showToggle() ? (
                <Toggle
                  reversed={step === 1 ? false : true}
                  customStyle={{ marginTop: "1rem" }}
                />
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <br />
              {step === 4 && loading ? null : (
                <>
                  <span
                    className="white futura-mid"
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    THIS MONTH'S DROP
                  </span>
                  <img src={winKicks} width="200px" />
                </>
              )}
            </div>
          </div>
          {step === 2 || step === 3 || step === 4 ? (
            <div style={{ margingTop: "1rem" }}>
              <a
                href="https://joinneon.com/faqs"
                target="_blank"
                style={{
                  fontSize: "0.83rem",
                  color: "white",
                  textDecoration: "underline",
                }}
                className="futura-mid pointer"
              >
                frequently asked questions
              </a>
            </div>
          ) : null}
          <Footer />
        </div>
      )}
    </>
  );
}
