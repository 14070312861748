import React from "react";
import ErrorMessage from "./ErrorMessage";
import { VerifiedMessageComp } from "./VerifiedMessageComp";

export function ChangeEmail({
  form,
  user,
  changeHandler,
  error,
  verified,
  saved,
  handleSavePersonal,
}) {
  return (
    <div>
      <div className="card-settings">
        <div className="card-top-padding">
          <div>
            <span className="txt-s futura-mid white">New Email</span>
          </div>
          <div>
            <input
              style={{
                overflowX: "visible",
                width: "auto",
              }}
              className="input"
              placeholder="tyler@golfwang.com"
              value={form.email}
              defaultValue={user.email}
              onChange={(e) => changeHandler("email", e.target.value)}
            />
            <ErrorMessage error={error} destiny={"email"} />
          </div>
          <div className="card-top-padding">
            <div>
              <span className="txt-s futura-mid white">Last 4 of SSN</span>
            </div>
            <div>
              <input
                onClick={() =>
                  form.ssn === "" ? changeHandler("SSN", "") : form.ssn
                }
                className="input"
                placeholder="1234"
                value={form.ssn.replace(/^(\d{4})(\d+)$/, "$1")}
                type="number"
                maxLength={4}
                onChange={(e) =>
                  changeHandler(
                    "ssn",
                    e.target.value.replace(/^(\d{4})(\d+)$/, "$1")
                  )
                }
              />
              <ErrorMessage error={error} destiny={"ssn"} />
            </div>
          </div>
          <div className="card-top-padding" style={{ paddingBottom: "1rem" }}>
            <div>
              <span className="txt-s futura-mid white">DOB</span>
            </div>
            <div>
              <input
                pattern="\d*"
                onClick={() =>
                  form.dob === "DD/MM/YYYY"
                    ? changeHandler("dob", "")
                    : form.dob
                }
                className="input"
                placeholder="19/07/1997"
                value={form.dob.replace(/^(\d{2})(\d{2})(\d+)$/, "$1/$2/$3")}
                maxLength={10}
                onChange={(e) => changeHandler("dob", e.target.value)}
              />
              <ErrorMessage error={error} destiny={"birth"} />
            </div>
          </div>
          {verified ? <VerifiedMessageComp saved={saved} /> : null}
          <div style={{ marginTop: "1rem" }}>
            <button
              onClick={() => handleSavePersonal()}
              className="btn-inverse txt-s card-button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
