import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../Store/Modal/Reducers";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ErrorMessage from "../../../components/ErrorMessage";
import amex from "../../../images/amex.gif";
import { URIS } from "../../../Constants/Endpoints";
import axios from "axios";
import Dropdown from "../../../components/Dropdown/Dropdown";
import TriangleSpinner from "../../../components/TriangleSpinner";
import card from "../../../images/card-wallet.gif";

export default function CardRegister() {
  const dispatch = useDispatch();
  const [viewForm, setViewForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { user, access_token, user_status } = useSelector(
    (state) => state.login
  );
  const date = new Date(user.date_of_birth.replace("00:00:00 GMT", ""));
  const birthdate =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    "/" +
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
    "/" +
    date.getFullYear();
  const [form, setForm] = useState({
    ssn: null,
    income_bucket: "C",
    email: user.email,
    phone_number: user.phone_number,
    birthdate: birthdate,
    first_name: user.first_name,
    last_name: user.last_name,
    address: JSON.parse(user.address_raw),
    address_2: user.address_line_2,
    employed: user.employed,
    disclosured: true,
    consented: true,
    verifyToken: "",
  });
  const [qualified, setQualified] = useState(false);
  const [rate, setRate] = useState(0);
  const { modal } = useSelector((state) => state.modal);
  function handleClose() {
    dispatch(closeModal());
  }

 
  useEffect(()=>{
    if(user.neon_card_userflow_status && user.neon_card_userflow_status == 'CARD-QUALIFIED'){
      setQualified(true)
    }
  },[])

  
  const benefitsData = [
    {
      title: "1.5X",
      content: "Earn unlimited 1.5X points for each dollar you spend.",
    },
    {
      title: "DOUBLE UP",
      content:
        "Automatically double all your points when you use it towards investing.",
    },
    {
      title: "$100",
      content: "Your first $100 of spend is on us. Go live your life.",
    },
  ];

  const handleBack = () => {
    dispatch(closeModal());
  };

  const getButtonText = () => {
    if (qualified) {
      return "Activate your new card";
    } else {
      return "See if you're pre-approved";
    }
  };

  const validateFields = () => {
    if (form.ssn === null) {
      setError({
        destiny: "ssn",
        message: "Please enter your SSN to continue.",
      });
      return false;
    } else if (form.ssn.length < 9) {
      setError({
        destiny: "ssn",
        message: "SSN should be 9 digit long.",
      });
    } else if (form.income_bucket === null) {
      setError({
        destiny: "income",
        message: "Please select an option to continue.",
      });
      return false;
    } else {
      return true;
    }
  };

  const getButtonAction = () => {
    if (!viewForm) {
      return setViewForm(true);
    } else {
      let validation = validateFields();
      if (!validation) {
        return;
      }
      if (!qualified) {
        return handleQualifyCard();
      } else if (qualified && viewForm) {
        return handleAcceptCard();
      }
    }
  };

  const handleQualifyCard = async () => {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_QUALIFY,
        },
      })
      .then((res) => {
        setLoading(false);
        let response = res.data;
        if (response.status) {
          setRate(response.decision_results.content.interest_rate.toFixed(2));
          setQualified(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError({
          destiny: "card",
          message:
            "Sorry, you weren’t approved for the card at this time. We’ll send you an email with the specific reason",
        });
        if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          console.error(error);
        }
      });
  };

  async function handleAcceptCard() {
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_ACCEPT,
        },
      })
      .then((res) => {
        setLoading(false);
        dispatch(openModal({ modal: "CardDashboard", origin: "cardRegister" }));
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
          dispatch(
            openModal({ modal: "CardDashboard", origin: "cardRegister" })
          );
        }, 5000);
        return;
        setQualified(false);
        setViewForm(false);
        setError({
          destiny: "card",
          message:
            "We ran into an issue activating your card. Hit us on chat and we'll figure this out.",
        });
      });
  }

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() => handleBack()}
          >
            Back
          </span>
          <div
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="futura-bold  white "
              style={{ width: "100%", fontSize: "1.5rem" }}
            >
              The Card That Invests In You
            </span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => handleClose()}
            >
              X
            </span>
          </div>
        </div>
        <div className="row" style={{ justifyContent: "center" }}>
          <img src={card} width="100px" />
        </div>
        <div>
          <span className="sub-title">What you get</span>
          {benefitsData.map((benefit, index) => {
            return (
              <div
                className="row mt-1 wallet-sub txt-1"
                style={{
                  justifyContent: "center",
                  marginLeft: "-0.35rem",
                }}
                key={index}
              >
                <div
                  style={{
                    padding: "0.5rem",
                    textAlign: "center",
                    width: "20%",
                    margin: "auto",
                  }}
                >
                  <span className="bold sub-title ">{benefit.title}</span>
                </div>
                <div
                  style={{
                    padding: "0.5rem",
                    width: "70%",
                  }}
                >
                  <span>{benefit.content}</span>
                </div>
              </div>
            );
          })}
          <div className="mt-1 row" style={{ marginBottom: "2rem" }}>
            <div
              style={{ margin: "auto", marginInline: "unset" }}
              onClick={() =>
                dispatch(openModal({ modal: "cardBenefits", origin: modal }))
              }
            >
              <span className="txt-1 ">
                First year annual fee waived. See all benefits{" "}
              </span>
            </div>
            <>
              <MdOutlineKeyboardArrowRight
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  height: "100%",
                  fontWeight: "bold",
                  alignSelf: "flex-end",
                  marginTop: "0.25rem",
                }}
              />
            </>
          </div>
          {viewForm && !qualified ? (
            <FormComponent setForm={setForm} form={form} error={error} />
          ) : null}
          {qualified ? (
            <div
              className="title"
              style={{ position: "relative", textAlign: "center" }}
            >
              <img src={amex} width="100%" />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                }}
              >
                <span>
                  Congrats! <br />
                  The first AMEX card to invest in you is ready.
                </span>
              </div>
            </div>
          ) : null}
          {viewForm ? (
            <div className="mt-1 mb-1">
              <span className="txt-s">
                {qualified ? (
                  <>
                    By clicking the button below you agree to Neon’s card member{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      terms and conditions
                    </span>
                  </>
                ) : !error ? (
                  "Your credit score will not be impacted if you aren’t approved. By clicking the button below, you authorize Neon Money Club to run a credit check."
                ) : (
                  ""
                )}
              </span>
            </div>
          ) : null}
          {(!error || (error && error.destiny !== "card")) && !loading ? (
            <button
              className="btn-inverse-round txt-1 "
              style={{
                marginTop: "1rem",
              }}
              onClick={() => getButtonAction()}
            >
              {getButtonText()}
            </button>
          ) : loading ? (
            <div className="row" style={{ justifyContent: "center" }}>
              <TriangleSpinner />
            </div>
          ) : (
            <ErrorMessage error={error} destiny={"card"} />
          )}
          {viewForm ? (
            <div
              style={{
                maxWidth: "25rem",
                textAlign: "center",
              }}
            >
              {!qualified && error.destiny !== "card" ? (
                <span className="txt-s futura-mid white">
                  &#128274; BANK LEVEL PRIVACY AND SECURITY
                </span>
              ) : qualified ? (
                <span className="txt-s-light">
                  Your rate will be {rate}% APR. Accepting your new card will
                  result in an inquiry appearing on your credit report.{" "}
                </span>
              ) : null}
            </div>
          ) : error.destiny !== "card" ? (
            <div style={{ textAlign: "center" }}>
              <span className="txt-s">
                Qualifying doesn't impact your credit score
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

const FormComponent = ({ setForm, form, error }) => {
  const [inputType, setInputType] = useState("");
  const [ssn, setSsn] = useState("");
  const handleInput = (ssnText) => {
    setSsn(ssnText);
    setForm({ ...form, ssn: ssnText });
  };

  const salaryRanges = [
    { value: "A", range: "$0-$9,999" },
    { value: "B", range: "$10K-$39,999" },
    { value: "C", range: "$40K-$79,999" },
    { value: "D", range: "$80K-$119,999" },
    { value: "E", range: "$120K-$159,999" },
    { value: "F", range: "$160K and beyond" },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <div className="column" style={{ margin: "auto" }}>
        <span className="txt-s futura-mid white">SSN </span>
        <input
          pattern="\d*"
          className="input"
          value={ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3")}
          maxLength={11}
          onChange={(e) => handleInput(e.target.value.replace(/[^\d]/g, ""))}
          onFocus={() => {
            setInputType("text");
          }}
          onBlur={() => {
            setInputType("password");
          }}
          placeholder="XXXXXXXXX"
          type={inputType}
        />
      </div>
      <ErrorMessage error={error} destiny={"ssn"} />

      <div className="column mt-2 white">
        <span className="txt-s futura-mid white">Yearly Income </span>
        <Dropdown items={salaryRanges} setForm={setForm} form={form} />
      </div>
      <ErrorMessage error={error} destiny={"income"} />
    </div>
  );
};
