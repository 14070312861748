import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DynamicAmount from "./DynamicAmount";
import ReactGA from "react-ga4";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateshowNews } from "../Store/Login/Reducers";
import ReactTooltip from "react-tooltip";
import { setBrands, setBrand } from "../Store/Brands/Reducers";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { openModal } from "../Store/Modal/Reducers";

export default function WalletBalance({ handleTransactions, setPreset }) {
  const { showNews } = useSelector((state) => state.login);
  const wallet = useSelector((state) => state.dashboard.wallet);
  const dispatch = useDispatch();
  const handleOpen = async (config) => {
    ReactGA.event({
      category: "DASHBOARD",
      action: "WALLET_SEE_ALL_CLICKED",
    });
    if (showNews === true) {
      dispatch(updateshowNews(false));
    }
    dispatch(openModal({ modal: config }));
  };
  const handleOpenCash = async () => {
    await setPreset("CASH");
    dispatch(openModal({ modal: "yourStocks" }));
  };

  const handleOpenGroup = async (group) => {
    await handleTransactions(group);
    dispatch(setBrands(group));
    dispatch(openModal({ modal: "stockGroup" }));
  };

  const handleOpenSinglesDetails = async (stock) => {
    dispatch(setBrand({ brand: stock }));
    dispatch(openModal({ modal: "transactionByStock" }));
  };

  const averageGainsTxt =
    "This is the difference between today's market value of your stocks versus when you bought them.";

  return (
    <div
      className="card wallet-balance-min-width"
      style={{ minHeight: "auto" }}
      id="walletBalance"
    >
      <div className="card-wrapper">
        <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignConten: "center",
            }}
          >
            <div style={{ width: "95%" }}>
              <span className="futura-mid-1pt17 white">Wallet Balance</span>
            </div>
            <div
              className="pointer"
              onClick={() => {
                dispatch(openModal({ modal: "wallet" }));
              }}
            >
              <span className="futura-mid-1pt17 white">...</span>
            </div>
          </div>
          <div
            style={{
              justifyItems: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: "0.5rem",
            }}
          >
            <div style={{ marginRight: "2rem" }}>
              <span className="futura-mid-2pt33 white">
                $
                {wallet && wallet.balance_amount
                  ? wallet.balance_amount.toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ overflowX: "auto !important" }}>
              <table
                className="table-wallet white"
                style={{ overflowX: "auto !important" }}
              >
                <thead className="txt-1-mid">
                  <tr>
                    <th>You own</th>
                    <th>Today's value</th>
                    <th style={{ paddingBottom: "0.6rem" }}>
                      Average {"  "}
                      <div
                        style={{
                          display: "inline-flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        gains
                        <div
                          style={{
                            display: "inline-flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <span
                            className="txt-s"
                            style={{
                              color: "white",
                              display: "inline-flex",
                              alignItems: "end",
                            }}
                            data-tip={averageGainsTxt}
                            data-for="tooltip5"
                          >
                            <IoMdInformationCircleOutline
                              style={{ fontSize: 17, paddingTop: 2 }}
                              viewBox="0 0 590 550"
                            />
                          </span>
                          <div
                            style={{
                              marginRight: "2rem",
                              display: "flex",
                            }}
                          >
                            <ReactTooltip
                              id="tooltip5"
                              type="warning"
                              effect="solid"
                              place="bottom"
                              backgroundColor="gray"
                              multiline={true}
                              clickable={true}
                              wrapper="div"
                            >
                              <span
                                className="white"
                                style={{
                                  display: "inline-block",
                                  width: "10rem",
                                  height: "auto",
                                  textAlign: "center",
                                }}
                              >
                                {averageGainsTxt}
                              </span>
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {wallet && wallet.playlists && wallet.playlists.length > 0 ? (
                    wallet.playlists.slice(0, 1).map((stock, index) => (
                      <tr
                        key={index}
                        className={
                          wallet.playlists.length - 1 === index
                            ? "pointer"
                            : "tr-border pointer"
                        }
                        onClick={() => handleOpenGroup(stock)}
                      >
                        <td className="txt-1 ">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>{stock.name}</div>
                          </div>
                        </td>
                        <td className="txt-1-mid">
                          ${stock.today_value.toFixed(2)}
                        </td>
                        <td className="txt-1">
                          <DynamicAmount
                            text={""}
                            amount={stock.average_gains.toFixed(2)}
                          />
                        </td>
                        <td style={{ maxWidth: "1rem" }} className={"txt-s"}>
                          <MdOutlineKeyboardArrowRight />
                        </td>
                      </tr>
                    ))
                  ) : wallet && wallet.brands && wallet.brands.length > 0 ? (
                    wallet.brands.slice(0, 1).map((stock, index) => (
                      <tr
                        key={index}
                        className={
                          wallet.brands.length - 1 === index
                            ? "pointer"
                            : "tr-border pointer"
                        }
                        onClick={() => handleOpenSinglesDetails(stock)}
                      >
                        <td className="txt-1 ">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>{stock.brand_name.split(" -")[0]}</div>
                          </div>
                        </td>
                        <td className="txt-1-mid">
                          ${stock.today_value.toFixed(2)}
                        </td>
                        <td className="txt-1">
                          <DynamicAmount
                            text={""}
                            amount={stock.average_gains.toFixed(2)}
                          />
                        </td>
                        <td style={{ maxWidth: "1rem" }} className={"txt-s"}>
                          <MdOutlineKeyboardArrowRight />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                        <td>
                          <Skeleton
                            style={{
                              backgroundColor: "#19301C",
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  {wallet &&
                  ((wallet.playlists && wallet.playlists.length) ||
                    (wallet && wallet.brands && wallet.brands.length)) > 0 ? (
                    <tr className="pointer" onClick={() => handleOpenCash()}>
                      <td className="txt-1 ">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>CASH</div>
                          <div></div>
                        </div>
                      </td>
                      <td className="txt-1-mid">
                        ${wallet.available_cash.toFixed(2)}
                      </td>
                      <td className="txt-1">
                        <span style={{ color: "black" }}>&#9660; $</span> --
                      </td>
                      <td style={{ maxWidth: "1rem" }} className={"txt-s"}>
                        <MdOutlineKeyboardArrowRight />
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "left",
                justifyContent: "center",
                paddingTop: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {wallet &&
              ((wallet.playlists && wallet.playlists.length) ||
                (wallet && wallet.brands && wallet.brands.length)) > 0 ? (
                showNews ? (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {" "}
                    <BsCircleFill
                      style={{
                        color: "#07dbab",
                        width: 10,
                        marginRight: 10,
                      }}
                    />
                    <span
                      className="neon-color pointer txt-1-mid"
                      onClick={() => handleOpen("yourStocks")}
                    >
                      See all &#x2192;
                    </span>
                  </div>
                ) : (
                  <span
                    className="neon-color pointer txt-1-mid"
                    onClick={() => handleOpen("yourStocks")}
                  >
                    See all &#x2192;
                  </span>
                )
              ) : null}
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  );
}
