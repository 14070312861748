import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

export default function Feed({ hasWallet }) {
  useEffect(() => {
    if (document.getElementById("EmbedSocialHashtagScript")) {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://widget.rss.app/v1/carousel.js";
    script.id = "curated";
    script.async = true;
  }, []);

  return (
    <div id="feed" style={{ alignSelf: "center" }}>
      <div className="card-wrapper">
        <div className="resp-padding" style={{ marginLeft: 0 }}>
          <span className="futura-mid-1pt17 white">Feed</span>
        </div>
        <div className="feed">
          <iframe
            src="https://rss.app/embed/v1/carousel/viUrGmbloCLQRjqg"
            style={{
              marginTop: "1rem",
              marginBottom: "-3rem",
              minHeight: "400px",
              maxWidth:
                hasWallet && !isMobile
                  ? "30vw"
                  : isMobile && hasWallet
                  ? "auto"
                  : "100vw",
              minWidth: isMobile ? "350px !important" : "30vw !important",
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
}
