import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import StepList from "../components/StepList";
import { AiOutlineArrowRight } from "react-icons/ai";
import { clubBenefitsSteps } from "../Constants/ClubSpecs/Steps";
export default function WelcomeWithBenefits({ verifyFields }) {
  useEffect(() => {
    ReactGA.event({
      category: "WELCOME",
      action: "VIEWED",
    });
  }, []);

  return (
    <div>
      <div style={{ marginTop: "3rem" }}>
        <span className="sub-title white ">
          Welcome to the Neon Money Club.
        </span>
      </div>

      <div>
        <span
          style={{
            color: "white",
            width: 250,
            display: "inline-block",
            paddingTop: "1rem",
            fontSize: "0.83rem",
          }}
        >
          Here's your member benefits
        </span>
      </div>
      <StepList steps={clubBenefitsSteps} title={""} isStep={false} />
      <button
        onClick={() => verifyFields(5)}
        id="welcome-button"
        className="btn-inverse"
        style={{
          marginTop: "2rem",
          width: "300px",
          borderRadius: "500px",
          height: "2.5rem",
          padding: "unset",
        }}
      >
        Put me on, I'm ready
      </button>
    </div>
  );
}
