import React from "react";
import { openModal } from "../../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";

export default function ManageCard() {
  const dispatch = useDispatch();
  let { modal } = useSelector((state) => state.modal);
  const benefits = [
    {
      text: "See card benefits",
      fn: () => dispatch(openModal({ modal: "cardBenefits", origin: modal })),
    },
    {
      text: "Manage transactions",
      fn: () =>
        dispatch(openModal({ modal: "CardTransactions", origin: modal })),
    },
    {
      text: "Lock account",
      fn: () => dispatch(openModal({ modal: "lockCard", origin: modal })),
    },
    {
      text: "See statements",
      fn: () => dispatch(openModal({ modal: "CardStatements", origin: modal })),
    },
  ];

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: "CardDashboard", origin: modal }))
            }
          >
            Back
          </span>
          <div
            className="futura-bold white "
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ width: "100%" }}>MANAGE CARD</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardDashboard", origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {benefits !== null && benefits.length > 0
              ? benefits.map((benefit, index) => (
                  <button
                    key={index}
                    style={{ marginTop: "2rem" }}
                    className="button-menu futura-mid bold"
                    onClick={benefit.fn}
                  >
                    {benefit.text}
                  </button>
                ))
              : null}
            <div id="intercom-custom-launcher">
              <button
                style={{ marginTop: "2rem" }}
                className="button-menu futura-mid bold"
              >
                Get help via chat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
