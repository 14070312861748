import React, { useState, useEffect } from "react";
import CardQualify from "../CardQualify/CardQualify";
import CardQualifyKYC from "../CardQualifyKYC/CardQualifyKYC";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setJwt, setAccess, clearStore } from "../../../Store/Login/Reducers";
import { setVerified, setKycQualified } from "../../../Store/Register/Reducers";
import { handleToken, refresh } from "../../../helpers/Auth";
import { getUserData } from "../../../helpers/DashboardMainFunctions";
import axios from "axios";
import { URIS } from "../../../Constants/Endpoints";
import ErrorMessage from "../../../components/ErrorMessage";

export default function CardOrigination() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState("qualify");
  const [rate, setRate] = useState(0);
  const [qualified, setQualified] = useState(false);
  const [error, setError] = useState(false);

  const query = new URLSearchParams(location.search);
  const vfpParam = query.get("vfp");
  const phoneNumberParam = query.get("phone_number");
  const incomeParam = query.get("income_bucket");
  const addressParam = query.get("address");
  const firstNameParam = query.get("first_name");
  const lastNameParam = query.get("last_name"); 
  let { user, refresh_token, access_token } = useSelector(
    (state) => state.login
  );
  let { kycQualified, registerForm } = useSelector((state) => state.register);
  const [form, setForm] = useState({
    ssn: null,
    income_bucket: incomeParam,
    email: "",
    phone_number: "",
    birthdate: "",
    first_name: firstNameParam,
    last_name: lastNameParam,
    address: "",
    address_2: "",
    employed: null,
    disclosured: null,
    consented: null,
    verifyToken: "",
    access_code: "alpha",
  });

  const handleAddress = (address) => {
    let splitAddress = address.split(", ");

    let stateAndZip = splitAddress[2].split(" ");
    let state = stateAndZip[0];
    let zipCode = stateAndZip[1];
    let query = {
      line_1: splitAddress[0],
      city: splitAddress[1],
      state: state,
      zip_code: zipCode,
    };
    return query;
  };

  const changeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleKycData = () => {
    setLoading(true);
    const date = new Date(user.date_of_birth.replace("00:00:00 GMT", ""));
    const birthdate =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      date.getFullYear();

    const parsedAddress = handleAddress(addressParam);

    setForm({
      ...form,
      birthdate: birthdate,
      address: parsedAddress,
      phone_number: user.phone_number,
      email: user.email,
    });
  };

  useEffect(async () => {
    if (vfpParam && phoneNumberParam && !access_token) {
      setLoading(true);
      const callback = () => {
        dispatch(clearStore());
      };

      await handleToken(vfpParam, phoneNumberParam, history, callback)
        .then(async (res) => {
          dispatch(setJwt(res));
          await getUserData(
            res.access_token,
            phoneNumberParam,
            vfpParam,
            dispatch,
            history
          );
        })
        .catch((error) => {
          console.error("AUTH ERROR - GETTING USER DATA FAILED");
          console.error(error);
          dispatch(clearStore());
          localStorage.clear();
        });
    } else if (
      user === null &&
      refresh_token === null &&
      vfpParam === null &&
      phoneNumberParam === null
    ) {
      return;
      dispatch(clearStore());
      localStorage.clear();
      history.push("/86?code=alpha");
    } else if (refresh_token === null && user === null) {
      dispatch(clearStore());
      localStorage.clear();
      history.push("/86?code=alpha");
    }
  }, [vfpParam, user, refresh_token]);

  useEffect(async () => {
    if (access_token && user && refresh_token) {
      let access = await refresh(refresh_token);
      if (access.status) {
        await handleRefreshAccessToken(access.access_token).then(() => {
          if (!kycQualified && (user.neon_card_userflow_status == null || user.neon_card_userflow_status == 'PROSPECT') ) {
            handleKycData();
          } else {
            setScreen("acceptCard");
          }
        });
      }
    }
  }, [user, refresh_token]);

  const handleRefreshAccessToken = (access_token) => {
    return new Promise((resolve) => {
      dispatch(setAccess(access_token));
      resolve(true);
    });
  };

  useEffect(() => {
    if (form.address !== "" && form.birthdate !== "" && user && !kycQualified) {
      callKYC();
    }
  }, [form.address, user]);

  const callKYC = async () => {
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_QUALIFY,
        },
      })
      .then((res) => {
        setLoading(false);
        let response = res.data;
        if (response.status) {
          setRate(response.decision_results.content.interest_rate.toFixed(2));
          setQualified(true);
          dispatch(setKycQualified());
          setScreen("acceptCard");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError({
          destiny: "card",
          message:
            "Sorry, you weren’t approved for the card at this time. We’ll send you an email with the specific reason",
        });
        if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          console.error(error);
        }
      });
  };

  const CardOriginationRouter = () => {
    switch (screen) {
      case "qualify":
        return (
          <CardQualify
            setLoading={setLoading}
            loading={loading}
            kycError={error}
          />
        );

      case "acceptCard":
        return (
          <CardQualifyKYC
            loading={loading}
            modal={false}
            error={error}
            form={form}
            changeHandler={changeHandler}
          />
        );

      default:
        return <CardQualify setLoading={setLoading} loading={loading} />;
    }
  };

  return CardOriginationRouter();
}
