import React from "react";
import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function BestMatch({ handleOpen }) {
  return (
    <span
      className="txt-s neon-color"
      style={{
        marginLeft: "5%",
        width: "80%",
        whiteSpace: "pre",
        paddingBottom: "0.5rem",
        alignContent: "center",
      }}
    >
      BEST{" "}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        MATCH
        <>
          <span
            className="txt-s"
            style={{
              color: "white",
              display: "inline-flex",
              alignItems: "end",
            }}
            data-tip={
              "This match is based on your member profile.<br /> You can update this at any time here."
            }
            data-for="tooltip2"
          >
            <IoMdInformationCircleOutline
              style={{ fontSize: 15 }}
              preserveAspectRatio="none"
              viewBox="0 0 590 590"
            />
          </span>
          <div
            style={{ marginRight: "2rem", display: "flex" }}
            onClick={handleOpen}
          >
            <ReactTooltip
              id="tooltip2"
              type="warning"
              place="top"
              effect="solid"
              backgroundColor="gray"
              multiline={true}
              delayHide={2000}
              clickable={true}
              wrapper="span"
              onClick={() => handleOpen()}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "10rem",
                  height: "auto",
                  wordBreak: "break-all",
                  textAlign: "justify !important",
                  textJustify: "inter-word !important",
                }}
              >
                This match is based on your <br></br>member profile. You can{" "}
                <br></br>update this at any time here.
              </span>
            </ReactTooltip>
          </div>
        </>
      </div>
    </span>
  );
}
