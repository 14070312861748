export const RememberMe = ({ checkedState, handleRememberMe }) => {
  return (
    <div
      className="pt-1 white-space"
      style={{
        margin: "auto",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'

      }}
    >
      <input
        className="checkbox"
        type="checkbox"
        id="term1"
        checked={checkedState}
        onChange={() => handleRememberMe(!checkedState)}
        style={{ transform: "translate(0,0)" }}
      />
      {" "}
      <label htmlFor="term1" style={{marginTop:1}}>
        <a
          className="white txt-1"
          style={{
            cursor: "pointer",
          }}
        >
        {""}  Remember me
        </a>{" "}
      </label>
    </div>
  );
};
