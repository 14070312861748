import React from "react";
import StepList from "./StepList";
import { clubBenefitsSteps } from "../Constants/ClubSpecs/Steps";

export function FoundingMember() {
  return (
    <div>
      <StepList steps={clubBenefitsSteps} title={""} isStep={false} />
    </div>
  );
}
