import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../Store/Modal/Reducers";
import "./CardTransactions.css";
import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function CardTransactionsDetail() {
  const dispatch = useDispatch();
  let { modal, origin, data } = useSelector((state) => state.modal);

  function goToReport() {
    dispatch(openModal({ modal: "CardReport", origin: modal }));
  }

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: "CardTransactions", origin: origin }))
            }
          >
            Back
          </span>
          <div
            className="futura-bold white "
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ width: "100%" }}>Transactions</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardTransactions" }));
              }}
            >
              X
            </span>
          </div>
        </div>

        <div style={{ color: "white", marginTop: "2rem", fontWeight: "bold" }}>
          {data.description}
        </div>

        <div
          style={{
            color: "white",
            fontSize: 25,
            fontWeight: "bold",
            marginTop: "1rem",
          }}
        >
          {"$" + (data.amount * -1).toFixed(2)}
        </div>

        <div
          style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <span
            className="txt-s futura-mid white"
            style={{ whiteSpace: "pre", paddingTop: 13, paddingLeft: 30 }}
          >
            67.68 points pending
          </span>
          <>
            <span
              style={{
                color: "white",
                display: "inline-flex",
                alignItems: "end",
              }}
              data-tip={
                <>
                  Q: Why I gotta enter my SSN? <br></br>
                  <br></br>
                  A: In order to own stocks in the U.S. we have to verify your
                  identity. Neon Money Club is a SEC-registered investment
                  advisor.
                </>
              }
              data-for="tooltip60"
            >
              <div
                style={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <IoMdInformationCircleOutline
                  style={{ fontSize: 20, alignSelf: "center" }}
                  preserveAspectRatio="none"
                  viewBox="0 0 590 590"
                />
              </div>
            </span>
            <div style={{ width: "-1px", display: "flex" }}>
              <ReactTooltip
                id="tooltip60"
                type="warning"
                place="left"
                effect="solid"
                backgroundColor="gray"
                multiline={true}
                clickable={true}
                onClick={() => {}}
                wrapper="div"
              >
                <span
                  onClick={() => {}}
                  style={{
                    display: "inline-block",
                    width: "10rem",
                    height: "auto",
                  }}
                >
                  <>
                    Q: Why I gotta enter my SSN? <br></br>
                    <br></br>
                    A: In order to own stocks in the U.S. we have to verify your
                    identity. Neon Money Club is a SEC-registered investment
                    advisor.
                  </>
                </span>
              </ReactTooltip>
            </div>
          </>
        </div>

        <div
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
            marginTop: "2.5rem",
          }}
        >
          Transaction Date
        </div>
        <div style={{ color: "white", fontSize: 15, marginTop: "10px" }}>
          {data.businessDate}
        </div>

        <div
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          Posted Date
        </div>
        <div style={{ color: "white", fontSize: 15, marginTop: "10px" }}>
          {data.businessDate}
        </div>
        <div
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          Description
        </div>
        <div style={{ color: "white", fontSize: 15, marginTop: "10px" }}>
          {data.description}
        </div>
        <div
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          Category
        </div>
        <div style={{ color: "white", fontSize: 15, marginTop: "10px" }}>
          System fees
        </div>

        <div
          style={{
            color: "#16e7cf",
            alignSelf: "center",
            marginTop: "3rem",
            fontSize: 12,
            marginBottom: "2rem",
          }}
          onClick={() => goToReport()}
        >
          Report a problem
        </div>
      </div>
    </div>
  );
}
