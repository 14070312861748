import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "@szhsin/react-menu/dist/index.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch } from "react-redux";
import { openModal } from "../Store/Modal/Reducers";
import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";

export default function Header({ user, hasPremiumAccess, setSettingsTab }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerType, setHeaderType] = useState("");
  const yellowText = "Welcome to the Long Money Mindset";

  const handleOpen = () => {
    dispatch(openModal({ modal: "menu", origin: "" }));
  };
  let name = user.neon_nickname === null ? user.first_name : user.neon_nickname;

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position <= scrollPosition) {
      setHeaderType("scroll");
    } else {
      setHeaderType("");
    }
    setScrollPosition(position);
  };

  const handlePremiumAccess = () => {
    dispatch(openModal({ modal: "settings" }));
    setSettingsTab("PremiumAccess");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <div className="header-inverse">
      <div className="header-container">
        <div className="header">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <span
                className="white futura-light mr-15"
                style={{ fontSize: "0.75rem" }}
              >
                founding member
              </span>
              <span className="txt-1 pill mr-15">{user.id}</span>
            </div>
            {hasPremiumAccess ? (
              <div
                className="pointer"
                style={{ display: "flex", flexDirection: "row", width: "auto" }}
              >
                <span
                  className="txt-1-mid white"
                  style={{ fontWeight: "bold", alignSelf: "center" }}
                  onClick={() => handlePremiumAccess()}
                >
                  club benefits
                </span>
                <div>
                  <MdOutlineKeyboardArrowRight
                    style={{
                      color: "white",
                      fontSize: "1.25rem",
                      height: "115%",
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ height: 40 }}>
            <span className="txt-1 yellow">
              Welcome to the{" "}
              <>
                <span
                  className="underline"
                  data-tip={<></>}
                  data-for="headerToolTip"
                >
                  Long Money Mindset
                </span>
                <div style={{ display: "flex", alignSelf: "right" }}>
                  <ReactTooltip
                    id="headerToolTip"
                    type="dark"
                    place="right"
                    effect="solid"
                    backgroundColor="#21211A"
                    multiline={true}
                    clickable={true}
                    wrapper="div"
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "auto",
                        height: "auto",
                        textAlign: "left",
                      }}
                    >
                      <>
                        LONG MONEY MINDSET <br></br>
                        <br />
                        <span className="bold">
                          Ownership is a lifestyle, this is your club.
                        </span>
                        <br />
                      </>
                    </span>
                    <ol
                      style={{ textAlign: "left", paddingInlineStart: "1rem" }}
                    >
                      <li>Come as you are. You belong here.</li>
                      <li>Start with what you have.</li>
                      <li>Stay consistent. Wealth isn't built overnight.</li>
                    </ol>
                  </ReactTooltip>
                </div>
              </>
            </span>
          </div>
        </div>
        <div
          className="menu-button"
          style={{
            textAlign: "right",
            minWidth: "auto",
          }}
        >
          <AiOutlineMenu onClick={handleOpen} />
        </div>
      </div>
    </div>
  );
}
