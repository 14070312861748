export const URIS = {
  CREATE_ACCT: "core/create_account",
  TRADE_STOCK: "core/fractional_trading",
  GET_BRANDS: "core/apex_brand_history",
  GET_PLAYLISTS_AND_SINGLES: "core/playlists_and_singles",
  GET_PLAYLIST_DETAIL: "core/playlist_detail",
  CREATE_PLAID_TOKEN_UPDATE_MODE: "plaid/create_link_token_update_mode",
  CREATE_PLAID_TOKEN: "core/create_link_token",
  UPDATE_LINKED_USER: "plaid/update_linked_user_status",
  CONFIRM_OPEN_PLAID: "plaid/window_open_confirmation",
  EXCHANGE_PUBLIC_PLAID_TOKEN: "plaid/exchange_public_token",
  GET_STOCKS: "core/stocks_summary",
  GET_BRAND_TRANSACTIONS: "core/detail_stock_transaction",
  GET_TRANSACTIONS: "core/detail_stock_transaction",
  GET_CRYPTO_TRANSACTIONS: "core/detail_stock_transaction",
  GET_BY_SYMBOL: "core/search_brands",
  GET_BY_TAG: "core/top_stocks",
  STOCK_TRADING: "core/fractional_trading",
  UPDATE_INVESTOR_PROFILE: "core/update_investor_profile",
  GET_BRAND_DETAILS: "core/brand_detail",
  UPDATE_USER: "core/user_profile",
  GET_ALL_DOCS: "core/get_documents",
  GET_DOC: "core/get_document",
  PAUSE_BUY: "core/pause_trading",
  CASH_OUT: "core/cash_out",
  CASH_TAB: "core/cash_tab",
  KYC_VALIDATION: "core/kyc_validation",
  CHECK_EMAIL: "core/check_email",
  CORPORATE_ACTIONS: "core/corporate_actions_notifications",
  SOLD_TAB: "core/sold_tab",
  RULE_DETAIL: "core/rule_detail",
  EDIT_RULE: "core/edit_rule",
  FRONT_GATE: "core/front_gate",
  VERIFY_ID: "core/verify_identity",
  UNLOCKS_SETS: "core/unlocks_allowed_sets",
  UNLOCKS_SET_STATE: "core/unlocks_set_state",
  EXISTS_SSN: "core/validate_ssn_exist",
  VERIFY_REFERRAL: "core/get_referring_user",
  GET_ALL_MEMBER_REPORTERY: "core/get_all_user_group_by_neon_userflow",
  GET_ALL_MEMBERS_BY_AMOUNT: "core/get_user_group_by_daily_amount",
  GET_ALL_MEMBERS_BY_PLAYLIST: "core/get_all_user_group_by_playlist_name",
  GET_ALL_MEMBER_BY_ACTIVE_RULES:
    "core/get_all_playlist_active_dashboard_admin",
  GET_TABLE_CODES_CAMPAIGN: "core/get_table_codes_campaing",
  GET_TABLE_WEEKLY_REPORT: "core/get_table_weekly_report",
  GET_TABLE_SHARING_IS_CARING: "core/get_table_sharing_is_caring",
  GET_TABLE_SAM_REQUEST_USERS: "core/get_table_sam_request",
  UPDATE_USER_EDUCATED: "core/update_user_educated",
  PROVE_AUTH_URL: "start-prove-validation",
  CARD_QUALIFY: "core/kyc-decisioning-service",
  CARD_ACCEPT: "core/accept-card",
  CARD_ACTIVATE: "core/card/add-and-activate",
  CARD_BALANCE: "core/card/balance",
  CARD_INFO: "core/card/info",
  CARD_HISTORY: "core/card/card-transaction-history",
  CARD_PAPERLESS: "core/card/paperless",
  CARD_CREATE_BANK_ACCT: "core/card/create-paying-bank-account",
  CARD_BANK_ACCT_LIST: "core/card/bank-accounts-list",
  CARD_LOCK: "core/card/set-card-status",
  CARD_PAY_BILL: "core/card/pay-bill",
  CARD_GET_PAYMENT_INFO: "core/card/get-credit-payment-info",
  CARD_SCHEDULE_PAYMETS: "core/card/scheduled-payments-list",
  GET_USER_STATUS: "db/user/user_status",
};
