import { createSlice } from "@reduxjs/toolkit";

const registerSlice = createSlice({
  name: "register",
  initialState: {
    registerForm: [],
    vfp: null,
    verified: false,
    registerFlow: null,
    showForm:false,
    kycQualified: false
  },
  reducers: {
    setVfp: (state, action) => {
      state.vfp = action.payload;
    },
    setVerified: (state, action) => {
      state.registerForm = action.payload.registerForm;
      state.verified = action.payload.verified;
      state.registerFlow = action.payload.registerFlow;
    },
    setShowForm: (state,action)=>{
      state.showForm = action.payload;
    },

    setKycQualified:(state)=>{
      state.kycQualified= true;
    }
  },
});

export const { setVfp, setVerified, setShowForm, setKycQualified } = registerSlice.actions;
export default registerSlice.reducer;
