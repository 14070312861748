import React from "react";
import "./styles.css";

export default function ClubMood() {
  return (
    <div className="card-deck">
      <div className="card-wrapper" style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className={"futura-mid-1pt17 white pointer"}>Club Mood</span>
        </div>
        <div className="column white mt-1" style={{ textAlign: "center" }}>
          <div className="quote">
            <span className="txt-1 ">
              "Knowing that I'm investing alongside other people that look like
              me and are from where I'm from feels good."
            </span>
            <br />
            <br />
            <span className="txt-s">- Shaun R.</span>
          </div>
          <br />
          <br />
          <div className="quote">
            <span className="txt-1 ">
              "Love the long term mentality. It teaches us how to be ballers,
              even with a couple bucks a day."
            </span>
            <br />
            <br />
            <span className="txt-s">- Andy R.</span>
          </div>
          <div id="intercom-custom-launcher">
            <button className="btn-inverse-round txt-1 mt-1">
              Share your mood
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
