import { createSlice } from "@reduxjs/toolkit";

const cardSlice = createSlice({
  name: "card",
  initialState: {
    billType:null,
    cardState:null
  },
  reducers: {
    setBillType: (state, action) => {
        state.billType = action.payload
    },
    setCardState: (state, action) => {
      state.cardState = action.payload;
    },
  },
});

export const { setBillType, setCardState } = cardSlice.actions;
export default cardSlice.reducer;
