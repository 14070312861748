import React, { useEffect, useState } from "react";
import BrandsThumbs from "./BrandsThumbs";
import { playlists } from "../Constants/Playlists/Playlists";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import TriangleSpinner from "./TriangleSpinner";
import {
  setPlaylistDetail,
  setPlaylistLocal,
} from "../Store/Playlists/Reducers";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { openModal } from "../Store/Modal/Reducers";
import BestMatch from "./BestMatch";

export default function PlaylistsBox() {
  const dispatch = useDispatch();
  const playlistsArray = useSelector((state) => state.dashboard.playlists); //gets all the playlists
  const wallet = useSelector((state) => state.dashboard.wallet); //gets the user wallet
  const [loading, setLoading] = useState(true);
  const [bestMatch, setBestMatch] = useState("");
  const [playRows, setPlayRows] = useState([]); //array that contains the playlists, split by row
  const [playlistsMap, setPlaylists] = useState(playlists); //array containing local playlists
  const rows = [...Array(Math.ceil((playlistsMap.length + 1) / 3))]; //number of rows
  let { user, access_token } = useSelector((state) => state.login);
  let { all_playlists_settings } = useSelector((state) => state.playlists);

  const getPlaylistDetail = (data) => {
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.GET_PLAYLIST_DETAIL,
        },
      })
      .then((response) => {
        dispatch(setPlaylistDetail(response.data));
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
        }
      });
  };

  const getPlaylistStatus = (playlist) => {
    if (playlist.is_pending && !playlist.is_paused) {
      return "pending";
    } else if (playlist.is_paused) {
      return "resume";
    } else if (playlist.is_paused === false && playlist.is_pending === false) {
      return "edit";
    } else {
      return "+$1";
    }
  };

  const handleOpen = async (playlist) => {
    ReactGA.event({
      category: "DASHBOARD",
      action: playlist.title + "_CLICKED",
    });

    const singlePlayList = playlistsArray.find(
      (x) => x.playlist_name === playlist.title
    );
    const data = {
      phone_number: user.phone_number,
      playlist_id: singlePlayList.id,
    };
    const playlistSetter = {
      playlist: singlePlayList,
      playlist_local: playlist,
    };
    dispatch(setPlaylistLocal(playlistSetter));
    getPlaylistDetail(data);
    if (playlist.owned) {
      dispatch(openModal({ modal: "playlist" }));
    } else {
      dispatch(openModal({ modal: "playlist", origin: "playlist" }));
    }
  };

  const handleOpenInvestor = () => {
    dispatch(openModal({ modal: "investor", origin: "playlist" }));
  };

  //looks for the best matched playlist
  useEffect(() => {
    if (playlistsArray === null || !Array.isArray(playlistsArray)) {
      return;
    }
    if (playlistsArray.length === 0) {
      return;
    }
    for (let playlist of playlistsArray) {
      if (playlist.is_best_match) {
        setBestMatch(playlist.playlist_name);
      }
    }
    setLoading(false);
  }, [playlistsArray]);

  //get paused, pending, etc
  const checkPlaylistOwned = async (playlist, setting) => {
    playlist["owned"] = true;
    playlist["is_paused"] = setting.is_paused;
    playlist["is_pending"] =
      setting.frequency === "one-time" ? true : setting.is_pending;
    playlist["rule_amount"] = setting.rule_amount;
  };

  const getPlaylistSettings = async (playlistMutable) => {
    const playlistPromises = playlistMutable.map(async (playlist) => {
      const singlePlayList = playlistsArray.find(
        (x) => x.playlist_name === playlist.title
      );
      const setting = all_playlists_settings.find(
        (setting) => parseInt(singlePlayList.id) === setting.playlist_id
      );
      if (setting) {
        await checkPlaylistOwned(playlist, setting);
      } else {
        playlist["owned"] = false;
      }
      return playlist;
    });

    const modifiedPlaylists = await Promise.all(playlistPromises);
    return modifiedPlaylists;
  };

  //searchs for the playlist the user owns
  useEffect(async () => {
    if (wallet === null || typeof wallet !== "object") {
      return;
    }
    let playlistMutable = JSON.parse(JSON.stringify(playlists));

    if (all_playlists_settings && playlistsArray) {
      await getPlaylistSettings(playlistMutable);
    }
    setPlaylists(playlistMutable);
  }, [wallet, all_playlists_settings, playlistsArray]);

  //sorts the playlists by best matched first and fill the playRows array
  useEffect(() => {
    playlistsMap.sort(function (x, y) {
      return x.title === bestMatch ? -1 : y.title === bestMatch ? 1 : 0;
    });
    setPlayRows(
      rows.map((row, idx) => {
        if (idx === 0) {
          return playlistsMap.slice(idx, 3);
        } else {
          return playlistsMap.slice(idx * 3, idx * 8);
        }
      })
    );
  }, [bestMatch, playlistsMap]);
  return (
    <>
      {" "}
      <div className="column">
        {" "}
        <span className="futura-mid-1pt17 white pointer ml-1 ">
          Investment Playlists{"   "}
        </span>
        {playlistsText()}
      </div>
      <div
        className="collections-position"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {playRows.map((playList, index) => (
          <div className="play-box-resp" key={index}>
            {playList.map((playlist, index) => (
              <div
                className={
                  bestMatch === playlist.title
                    ? "playlist-small pointer"
                    : "playlist-small-nbm pointer"
                }
                key={index}
                onClick={
                  bestMatch === playlist.title
                    ? () => {}
                    : () => handleOpen(playlist)
                }
              >
                {!loading &&
                bestMatch !== "" &&
                bestMatch === playlist.title ? (
                  <BestMatch handleOpen={handleOpenInvestor} />
                ) : null}
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  onClick={
                    bestMatch === playlist.title
                      ? () => handleOpen(playlist)
                      : () => {}
                  }
                >
                  <img
                    src={playlist.image}
                    alt="monkey"
                    width="90%"
                    style={{ margin: "auto" }}
                  />
                  <BrandsThumbs playlist={playlist.brands} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "1rem",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <span className="white txt-1 bold">{playlist.title}</span>
                      <span className="white txt-s-light">{playlist.info}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "right",
                        height: "70%",
                      }}
                    >
                      {!loading && all_playlists_settings !== null ? (
                        <button
                          id={"button-" + playlist.title}
                          className="btn-inverse txt-1"
                          onClick={() => handleOpen(playlist)}
                          style={{ textAlign: "center" }}
                        >
                          {getPlaylistStatus(playlist)}
                        </button>
                      ) : (
                        <div style={{ paddingTop: "0.5rem" }}>
                          <TriangleSpinner />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

const playlistsText = () => {
  return (
    <div
      className="single-title"
      style={{ lineHeight: "1.8", marginInline: "1rem" }}
    >
      <span className="white txt-1-mid">
        We curate collections of stocks for you to buy. Each playlist has 5
        stocks. Choose your vibe.
      </span>
    </div>
  );
};
