import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function ToolTip({ text, delay, clickTextFn, place, id }) {
  return (
    <>
      <span
        style={{
          color: "white",
          display: "inline-flex",
          alignItems: "end",
        }}
        data-tip={text}
        data-for={id ? id : "tooltip"}
      >
        <IoMdInformationCircleOutline
          style={{ fontSize: 17, paddingLeft: 5 }}
          preserveAspectRatio="none"
          viewBox="0 0 550 550"
        />
      </span>
      <div style={{ width: "-1px", display: "flex" }}>
        <ReactTooltip
          id={id ? id : "tooltip"}
          type="warning"
          place={place}
          effect="solid"
          backgroundColor="gray"
          multiline={true}
          delayHide={delay ? delay : 1}
          clickable={true}
          onClick={() => clickTextFn()}
          wrapper="div"
        >
          <span
            onClick={() => clickTextFn()}
            style={{ display: "inline-block", width: "10rem", height: "auto" }}
          >
            {text}
          </span>
        </ReactTooltip>
      </div>
    </>
  );
}
