import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import DynamicAmount from "../components/DynamicAmount";
import TransactionDetail from "../components/TransactionDetail";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, openModal } from "../Store/Modal/Reducers";

export default function FreeCrypto({ cryptos }) {
  const dispatch = useDispatch();
  let { user, access_token } = useSelector((state) => state.login);
  const [transactions, setTransactions] = useState(null);
  const [detail, setDetail] = useState(null);
  const [table, setTable] = useState("btc");
  const [view, setView] = useState("");
  const [title, setTitle] = useState("FREE CRYPTO");
  const handleOpenDetail = async (detail) => {
    const detailArray = [
      {
        header: "Date and time",
        content: detail.transaction_datetime,
      },
      {
        header: "Amount",
        content: detail.share_quatity + " BTC",
      },
      {
        header: "Order number",
        content: detail.order_id,
      },
      {
        header: "Deposited to",
        content: "Connected Bank Account",
      },
      {
        header: "Type",
        content: "Reward",
      },
      {
        header: "Support Email",
        content: "neonmoneyclub@zerhash.com",
      },
      {
        header: "Support Phone",
        content: "(855) 744-7333",
      },
      {
        header: "Address",
        content: "Zero Hash LLC 327 N Aberdeen St. Chicago, IL 60607 ",
      },
    ];
    await setDetail(detailArray);
    setView("detail");
  };

  const getCryptoDetail = (cryptoName) => {
    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      playlist_id: null,
      symbol: "BTC",
    };
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.GET_CRYPTO_TRANSACTIONS,
        },
      })
      .then((res) => {
        setTransactions(res.data.page.detail);
        setTitle(cryptoName);
        setTable("detail");
      })
      .catch((error) => {});
  };

  const brandTable = (
    <div className="modal">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "left",
        }}
      >
        <span
          className="txt-1 neon-color pointer"
          onClick={() => dispatch(openModal({ modal: "yourStocks" }))}
        >
          Back
        </span>
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white" style={{ width: "100%" }}>
            FREE CRYPTO
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => dispatch(closeModal())}
          >
            X
          </span>
        </div>
      </div>
      <div>
        <div style={{ paddingTop: "0.5rem" }}>
          <div style={{ overflowX: "auto !important" }}>
            <table
              className="table white"
              style={{
                overflowX: "auto !important",
              }}
            >
              <thead className="table white txt-1-mid">
                <tr>
                  <th>You own</th>
                  <th>Today's value</th>
                  <th>Total gains</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cryptos &&
                  cryptos.length > 0 &&
                  cryptos.map((crypto, index) => (
                    <tr
                      className={
                        cryptos.length - 1 === index
                          ? "pointer txt-s"
                          : "pointer txt-s tr-border"
                      }
                      key={index}
                      onClick={() => {
                        getCryptoDetail(crypto.brand_name);
                      }}
                    >
                      <td>{crypto.brand_name}</td>
                      <td>${crypto.today_value.toFixed(2)}</td>
                      <td>
                        <DynamicAmount
                          amount={crypto.since_purchase_value.toFixed(2)}
                          percentage={crypto.since_purchase_percent.toFixed(2)}
                          type={"percent"}
                        />
                      </td>
                      <td>
                        <AiOutlineArrowRight />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            paddingTop: "3rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              paddingTop: "2rem",
            }}
          ></div>
        </div>
      </div>
    </div>
  );

  const detailTable = (
    <div className="modal">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "left",
        }}
      >
        <span
          className="txt-1 neon-color pointer"
          onClick={() => dispatch(openModal({ modal: "yourStocks" }))}
        >
          Back
        </span>
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white" style={{ width: "100%" }}>
            {title}
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => dispatch(closeModal())}
          >
            X
          </span>
        </div>
      </div>
      <div>
        <div style={{ paddingTop: "0.5rem" }}>
          <div style={{ overflowX: "auto !important" }}>
            <table
              className="table white"
              style={{
                overflowX: "auto !important",
              }}
            >
              <thead className="table white txt-1-mid">
                <tr>
                  <th>Date</th>
                  <th>Today's value</th>
                  <th>Total gains</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.length > 0 &&
                  transactions.map((tx, index) => (
                    <tr
                      className={
                        transactions.length - 1 === index
                          ? "pointer txt-s"
                          : "pointer txt-s tr-border"
                      }
                      key={index}
                      onClick={() => {
                        handleOpenDetail(tx);
                      }}
                    >
                      <td>
                        {new Date(tx.transaction_datetime).toLocaleString(
                          "us-EN",
                          {
                            timeZone: "America/New_York",
                            month: "2-digit",
                            day: "2-digit",
                            year: "2-digit",
                          }
                        )}
                      </td>
                      <td>${tx.today_value.toFixed(2)}</td>
                      <td>
                        <DynamicAmount
                          amount={tx.since_purchase.toFixed(2)}
                          percentage={tx.since_purchase_percent.toFixed(2)}
                          type={"percent"}
                        />
                      </td>
                      <td>
                        <AiOutlineArrowRight />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            paddingTop: "3rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              paddingTop: "2rem",
            }}
          ></div>
        </div>
      </div>
    </div>
  );

  const tableRouter = () => {
    switch (table) {
      case "btc":
        return brandTable;
      case "detail":
        return detailTable;
    }
  };
  return view === "detail" ? (
    <TransactionDetail
      header={"Reward"}
      data={detail}
      backFn={() => setView("")}
      closeFn={() => dispatch(closeModal())}
    />
  ) : (
    tableRouter()
  );
}
