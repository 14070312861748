import React from "react";
import { SWAG } from "../Constants/Playlists/Playlists";

export default function BrandsThumbs({ playlist }) {
  const handleOpen = (target, brand) => {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "auto",
        marginTop: "-1rem",
      }}
    >
      {playlist.map((brand, index) => (
        <button
          key={index}
          className="related-button"
          style={{
            marginInline: "0.5rem",
            backgroundColor: "white",
            width: "2rem",
            height: "2rem",
            backgroundImage: "url(" + brand.image + ")",
            backgroundSize: "cover",
          }}
        />
      ))}
    </div>
  );
}
