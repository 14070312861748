import React, { useState, useEffect } from "react";

export default function DynamicAmount({
  amount,
  percentage,
  customStyle,
  type,
  fontType,
  text,
}) {
  let cleanAmount = Math.abs(amount);

  const spanWithPercent =
    amount > 0 ? (
      <span className="neon-color  rate txt-1">
        &#9650; ${cleanAmount.toFixed(2)} ({Math.abs(percentage)}%)
      </span>
    ) : amount < 0 ? (
      <span className="white rate txt-1">
        &#9660; ${cleanAmount.toFixed(2)} ({percentage}%)
      </span>
    ) : (
      <span className="white  rate txt-1">
       <span style={{color:"#000000"}}>&#9660;</span>  ${cleanAmount.toFixed(2)} ({Math.abs(percentage)}%)
      </span>
    );

  const spanWithMonths =
    amount > 0 ? (
      <span className={"neon-color  rate txt-1" + fontType}>
        &#9650; ${cleanAmount.toFixed(2) + " " + text}
      </span>
    ) : amount < 0 ? (
      <span className={"white rate txt-1" + fontType}>
        &#9660; ${cleanAmount.toFixed(2) + " " + text}
      </span>
    ) : (
      <span className={"white rate txt-1" + fontType}>
        <span style={{color:"#000000"}}>&#9660;</span> ${cleanAmount + " " + text}
      </span>
    );
  return (
    <>
      <div
        style={
          customStyle
            ? customStyle
            : { display: "flex", flex: "1", alignItems: "center" }
        }
      >
        {type === "percent" ? spanWithPercent : spanWithMonths}
      </div>
    </>
  );
}
