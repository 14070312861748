import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearStore, setAccess, setJwt } from "../Store/Login/Reducers";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import CardStatements from "../components/CardStatements/CardStatements";
import Collection from "../components/Collection";
import DigitalWallet from "../components/DigitalWallet/DigitalWallet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Modal from "../components/Modal";
import NavBar from "../components/NavBar/NavBar";
import NeonBenefit from "../components/NeonBenefit";
import NeonVideo from "../components/NeonVideo";
import Playlist from "../components/Playlist";
import PlaylistIntro from "../components/PlaylistIntro";
import CardTransactReport from "../components/ReportAnIssueCard/CardTransactionReport";
import SingleBox from "../components/SingleBox";
import TriangleSpinner from "../components/TriangleSpinner";
import UnlocksModule from "../components/UnlocksModule";
import WalletBalance from "../components/WalletBalance";
import YouOwnIt from "../components/YouOwnIt";
import { handleToken, refresh } from "../helpers/Auth";
import {
  fetchPlaylistsAndSingles,
  fetchWallet,
  getUnlocks,
  getUserData,
  getUserStatus,
} from "../helpers/DashboardMainFunctions";
import BankSecurity from "./BankSecurity";
import BuyBrand from "./BuyBrand";
import BuyPlaylist from "./BuyPlaylist";
import CardBenefits from "./CardViews/CardBenefits/CardBenefits";
import CardTransactions from "./CardViews/CardTransactions/CardTransactions";
import CardTransactionsDetail from "./CardViews/CardTransactions/CardTransactionsDetail";
import CardDashBoard from "./CardViews/CardDashboard/CardDashboard";
import CardDetail from "./CardViews/CardDetail/CardDetail";
import CardRealFriends from "./CardViews/CardRealFriends/CardRealFriends";
import CardRegister from "./CardViews/CardRegister/CardRegister";
import CardSpendingPower from "./CardViews/CardSpendingPower/CardSpendingPower";
import LockCard from "./CardViews/LockCard/LockCard";
import ManageCard from "./CardViews/ManageCard/ManageCard";
import PayCard from "./CardViews/PayCard/PayCard";
import CashOut from "./CashOut";
import ClubTab from "./ClubTab";
import Error from "./Error";
import Feed from "./Feed";
import FreeCrypto from "./FreeCrypto";
import HowItWorks from "./HowItWorks";
import InvestPoints from "./InvestPoints/InvestPoints";
import InvestTab from "./InvestTab";
import LearnTab from "./LearnTab";
import Menu from "./Menu";
import OwnBrand from "./OwnBrand";
import PointsOnPoints from "./PointsOnPoints/PointsOnPoints";
import SearchStocks from "./SearchStocks";
import SellStock from "./SellStock";
import Settings from "./Settings";
import StockDetail from "./StockDetail";
import StockGroup from "./StockGroup";
import UnlocksViewer from "./UnlocksViewer.js";
import WalletDetails from "./WalletDetails";
import WalletTab from "./WalletTab/WalletTab";
import YourStocks from "./YourStocks";

export default function Dashboard({}) {
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const vfpParam = query.get("vfp");
  const authParam = query.get("auth");
  const refreshParam = query.get("refresh");
  const phoneNumberParam = query.get("phone_number");
  const activate_card = query.get("activate_card");
  const cardParam = query.get("card");
  const fromCardApp = query.get('from_card_app')
  let history = useHistory();
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.dashboard.wallet);
  let { user, refresh_token, access_token } = useSelector(
    (state) => state.login
  );
  const [tab, setTab] = useState(query.get("tab") ? query.get("tab") : "club");
  let { unlocks } = useSelector((state) => state.unlocks);
  let { open, modal } = useSelector((state) => state.modal);
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState();
  const [brand, setBrand] = useState("");
  const [brandToSell, setBrandToSell] = useState({});
  const [hasWallet, setHasWallet] = useState(false);
  const [yourStocksPreset, setYourStocksPreset] = useState(null);
  const [settingsTab, setSettingsTab] = useState("general");
  const hasPremiumAccess = true;
  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  });

  useEffect(() => {
    if (cardParam === "true") {
      history.push("/");
      dispatch(openModal({ modal: "CardDashboard", origin: "" }));
    } else {
      dispatch(closeModal());
    }
  }, []);

  //Checks if dashboard still loading after 5 secs
  useEffect(() => {
    ReactGA.event({
      category: "DASHBOARD",
      action: "LANDED_ON_DASHBOARD",
    });
    let now = Date().toLocaleString().toString();
    ReactGA.set({
      dimension2: user ? user.phone_number : "",
      dimension3: Date().toLocaleString(),
    });
    setTimeout(() => {
      if (loadRef.current) {
        ReactGA.event({
          category: "DASHBOARD",
          action: "LOADING_MORE_THAN_5SEC",
        });
        dispatch(clearStore());
        localStorage.clear();
        history.push("/login");
      }
    }, 8000);
  }, []);

  //hides intercom except in menu
  useEffect(() => {
    let intercom = document.getElementsByClassName(
      "intercom-lightweight-app-launcher intercom-launcher"
    )[0];
    let intercom2 = document.getElementsByClassName(
      "intercom-launcher-frame intercom-qysac6 efr23pf0"
    )[0];

    if (intercom) {
      if ((tab === "invest" || tab === "wallet") && !open) {
        intercom.style.display = "none";
      } else {
        intercom.style.display = "block";
      }
    } else if (intercom2 && !open) {
      if (tab === "invest" || tab === "wallet") {
        intercom2.style.display = "none";
      } else {
        intercom2.style.display = "block";
      }
    }
  }, [tab, open]);

  useEffect(async () => {
    if (
      (user === null || refresh_token === null) &&
      vfpParam &&
      phoneNumberParam
    ) {
      const callback = () => {
        dispatch(clearStore());
      };
      await handleToken(vfpParam, phoneNumberParam, history, callback)
        .then(async (res) => {
          dispatch(setJwt(res));
          await getUserData(
            res.access_token,
            phoneNumberParam,
            "",
            dispatch,
            history
          );
          history.push("/"); // clear query params after loading user info
          setLoading(false);
        })
        .catch((error) => {
          console.error("AUTH ERROR - GETTING USER DATA FAILED");
          console.error(error);
          dispatch(clearStore());
          localStorage.clear();
          history.push("/login");
        });
    } else if (
      authParam &&
      refreshParam &&
      phoneNumberParam &&
      (user === null || refresh_token === null)
    ) {
      const auth = {
        access_token: authParam,
        refresh_token: refreshParam,
      };
      dispatch(setJwt(auth));

      await getUserData(authParam, phoneNumberParam, "", dispatch, history);
      history.push("/");
    } else if (
      (user === null &&
        refresh_token === null &&
        vfpParam === null &&
        phoneNumberParam === null) ||
      (refresh_token && user === null)
    ) {
      dispatch(clearStore());
      localStorage.clear();
      history.push("/login");
    }
  }, [vfpParam, phoneNumberParam, user, refresh_token]);

  const handleTransactions = (group) => {
    setGroup(group);
  };
  //refreshes refresh token everytime user comes back to dashboard
  useEffect(async () => {
    if (refresh_token && !open) {
      let access = await refresh(refresh_token);
      if (access.status) {
        await handleRefreshDashboard(access.access_token).then(() => {
          fetchDashboard(access.access_token);
          setLoading(false);
        });
      } else if (!access) {
        ReactGA.event({ category: "LOGIN", action: "REFRESH_TOKEN_INVALID" });
        dispatch(clearStore());
        localStorage.clear();
        history.push("/login");
      }
    }
  }, [user, refresh_token, open, history]);

  //function to refresh dashboard
  const handleRefreshDashboard = (access_token) => {
    return new Promise((resolve) => {
      dispatch(setAccess(access_token));
      resolve(true);
    });
  };

  //checks card status at login to redirect user to correct card screen
  const checkCardStatus = () => {
    if (user.neon_card_userflow_status === "CARD-ACTIVE" && !fromCardApp) {
      setLoading(false);
      dispatch(openModal({ modal: "CardDashboard", origin: "" }));
    } else if (user.neon_card_userflow_status === "CARD-QUALIFIED") {
      setLoading(false);
      dispatch(
        openModal({
          modal: "cardRegister",
          origin: "",
          data: { card_qualified: true },
        })
      );
    }
  };

  //checks if user has a buy to show wallet component or not
  useEffect(() => {
    if (user) {
      checkCardStatus();
      setHasWallet(
        parseInt(user.reward_balance) < 30 && user.dw_account_number
          ? true
          : false
      );
    }
  }, [user]);
  //fetches playlists and wallet
  const fetchDashboard = (access_token) => {
    if (user && access_token) {
      fetchWallet(
        {
          phone_number: user.phone_number,
          user_id: user.id,
        },
        access_token,
        dispatch,
        history,
        user
      );
      getUserStatus(user.phone_number, access_token, dispatch, history);
      fetchPlaylistsAndSingles(
        { phone_number: user.phone_number },
        access_token,
        dispatch,
        history
      );

      getUnlocks(
        user.set_state,
        { phone_number: user.phone_number },
        access_token,
        dispatch
      );
    }
  };
  //resets scroll position on change of tab
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  useEffect(() => {
    if (activate_card == "true") {
      dispatch(openModal({ modal: "cardRegister", origin: "" }));
    }
  }, [activate_card]);

  //routes content to different modals
  const contentRouter = () => {
    switch (modal) {
      case "works":
        return <HowItWorks />;
      case "unlocks":
        return <UnlocksViewer />;
      case "search":
        return <SearchStocks setBrand={setBrand} user={user} />;
      case "yourStocks":
        return <YourStocks wallet={wallet} preset={yourStocksPreset} />;
      case "ownBrand":
        return <OwnBrand brand={brand} />;
      case "settings":
        return <Settings selectedTab={settingsTab} />;
      case "stockGroup":
        return (
          <StockGroup
            group={group}
            brandToSell={brandToSell}
            setBrandToSell={setBrandToSell}
          />
        );
      case "youOwnIt":
        return <YouOwnIt />;
      case "playlist":
        return <Playlist />;
      case "buyEdit":
        return <BuyPlaylist />;
      case "investor":
        return <PlaylistIntro setSettingsTab={setSettingsTab} />;
      case "buyBrand":
        return <BuyBrand user={user} />;
      case "sellStock":
        return <SellStock brandToSell={brandToSell} />;
      case "menu":
        return <Menu setTab={setTab} />;
      case "wallet":
        return <WalletDetails user={user} />;
      case "cashOut":
        return <CashOut brandToSell={brandToSell} />;
      case "cryptos":
        return (
          <FreeCrypto cryptos={wallet.free_crypto ? wallet.free_crypto : []} />
        );
      case "neonVideo":
        return <NeonVideo />;
      case "transactionByStock":
        return <StockDetail setBrandToSell={setBrandToSell} />;
      case "CardDashboard":
        return <CardDashBoard />;
      case "CardTransactions":
        return <CardTransactions />;
      case "CardTransactDetail":
        return <CardTransactionsDetail />;
      case "PayCard":
        return <PayCard />;
      case "error":
        return <Error text={"Something went wrong. Please try again."} />;
      case "bankSecurity":
        return <BankSecurity setTab={setTab} />;
      case "cardRegister":
        return <CardRegister />;
      case "cardBenefits":
        return <CardBenefits />;
      case "CardDetails":
        return <CardDetail />;
      case "AddToWallet":
        return <DigitalWallet />;
      case "CardStatements":
        return <CardStatements />;
      case "CardReport":
        return <CardTransactReport />;
      case "manageCard":
        return <ManageCard />;
      case "lockCard":
        return <LockCard />;
      case "PointsOnPoints":
        return <PointsOnPoints />;
      case "investPoints":
        return <InvestPoints />;
      case "cardRealFriends":
        return <CardRealFriends />;
      case "spendingPower":
        return <CardSpendingPower />;
    }
  };
  //to access true value of loading in setTimeout
  const loadRef = useRef(loading);
  loadRef.current = loading;

  return !loading && user ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      id="/"
    >
      {open ? (
        <Modal content={contentRouter()} />
      ) : (
        <div
          className="dashboard-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          <Header
            user={user}
            hasPremiumAccess={hasPremiumAccess}
            setSettingsTab={setSettingsTab}
          />
          {isMobile ? (
            <ThreePillars
              tab={tab}
              setTab={setTab}
              handleTransactions={handleTransactions}
              setYourStocksPreset={setYourStocksPreset}
            />
          ) : (
            <DesktopDashboard
              hasWallet={hasWallet}
              handleTransactions={handleTransactions}
              setYourStocksPreset={setYourStocksPreset}
            />
          )}
          {isMobile ? <NavBar tab={tab} setTab={setTab} /> : null}
          <div style={{ margin: "auto" }}>
            <Footer showText={false} />
          </div>
        </div>
      )}
    </div>
  ) : (
    <TriangleSpinner />
  );
}

//approved dashboard component
function approvedDashboard(handleTransactions, setYourStocksPreset) {
  return (
    <div className="card-deck" style={{ height: "auto !important" }}>
      {!isMobile ? (
        <>
          {" "}
          <WalletBalance
            handleTransactions={handleTransactions}
            setPreset={setYourStocksPreset}
          />
          <NeonBenefit />
        </>
      ) : (
        <>
          <WalletBalance
            handleTransactions={handleTransactions}
            setPreset={setYourStocksPreset}
          />
          <NeonBenefit />
          <UnlocksModule />
        </>
      )}
    </div>
  );
}

const ThreePillars = ({ tab, setTab }) => {
  switch (tab) {
    case "learn":
      return <LearnTab />;
    case "club":
      return <ClubTab setTab={setTab} />;
    case "wallet":
      return <WalletTab setTab={setTab} />;
    case "invest":
      return <InvestTab />;
  }
};

const DesktopDashboard = ({
  hasWallet,
  handleTransactions,
  setYourStocksPreset,
}) => {
  return (
    <>
      {hasWallet ? (
        approvedDashboard(handleTransactions, setYourStocksPreset)
      ) : (
        <div className="card-deck" style={{ height: "auto !important" }}>
          <NeonBenefit />
          <UnlocksModule />
        </div>
      )}
      <SingleBox />
      {isMobile ? <Collection /> : null}
      <div
        className="card-deck"
        style={{
          height: "auto !important",
        }}
      >
        {hasWallet ? <UnlocksModule /> : null}
        <Feed hasWallet={hasWallet} />
      </div>
    </>
  );
};
