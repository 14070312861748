import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { investings, frequencies } from "../Constants/WeeklyInvesting";
import PlaidLink from "../components/Plaid";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import CompleteLabel from "../components/CompleteLabel";
import ToolTip from "../components/ToolTip";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import { PromoCreditText, LogosListed } from "../components/BuyBrandTexts";
import { setBrand } from "../Store/Brands/Reducers";
import TriangleSpinner from "../components/TriangleSpinner";
import GetSSN from "../components/GetSSN";
import ErrorMessage from "../components/ErrorMessage";
import { useHistory } from "react-router-dom";
import { getUserData } from "../helpers/DashboardMainFunctions";
import { refresh } from "../helpers/Auth";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import {
  getCurrentDayAndWeek,
  getDayAndWeekFromDate,
} from "../helpers/Dates/DatesFunctions";

export default function BuyBrand() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dayAndWeek, setDayAndWeek] = useState(null);
  const { brand } = useSelector((state) => state.brands);
  const { wallet } = useSelector((state) => state.dashboard);
  const { user, access_token, vfp, refresh_token, user_status } = useSelector(
    (state) => state.login
  );
  const { origin } = useSelector((state) => state.modal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [gettingSsn, setGetSsn] = useState(false);
  const [proceedToBuy, setProceed] = useState(
    origin === "bankSecurity" ? true : false
  );
  const [buyAmount, setBuyAmount] = useState(null);
  const [linkToken, setLinkToken] = useState(null);
  const [acctAdded, setAcctAdded] = useState(false);
  const [pendingState, setPendingState] = useState({
    state: false,
    message: "",
  });
  const [completedBuy, setCompletedBuy] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [completedPause, setCompletedPause] = useState(false);
  const [buyDisabled, setBuyDisabled] = useState(false);
  const [view, setView] = useState("");
  const credit = parseFloat(user.reward_balance).toFixed(2);
  const opacityValue = gettingSsn ? 0.4 : 1;
  const [seeFrequency, setSeeFrequency] = useState(false);
  const [buyFrequency, setBuyFrequency] = useState(
    brand && brand.frequency ? brand.frequency : null
  );
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState(null);
  const handleClose = () => {
    if (origin === "transactionByStock") {
      dispatch(openModal({ modal: "transactionByStock", origin: "buyBrand" }));
    } else {
      dispatch(setBrand({ brand: null }));
      dispatch(closeModal());
    }
  };

  //for back button on top left corner
  const handleBack = () => {
    if (proceedToBuy) {
      setPendingState({
        state: false,
        message: "",
      });
      setError({});
      setProceed(false);
    } else if (!proceedToBuy) {
      handleClose();
    }
  };

  //handle close after completing tx
  const handleRefreshDashboard = () => {
    dispatch(closeModal());
    history.push("/");
  };

  const investTitle = !proceedToBuy ? (
    <div style={{ textAlign: "justify" }}>
      <span className="white txt-1">
        {brand !== null ? <></> : <Skeleton count={5} />}
      </span>
    </div>
  ) : (
    <span className="white title-1pt5-mid pt-1">
      Invest as little as $1 a day
    </span>
  );

  const checkBuyTime = async (message) => {
    var now = new Date();
    var closeHours = 16.5;
    now = now.toLocaleString("en-US", { timeZone: "America/New_York" });
    now = new Date(now);
    if (now.getHours() + now.getMinutes() / 60 >= closeHours) {
      setPendingState({ state: true, message: message || "" });
      return true;
    } else {
      return false;
    }
  };

  const getDetail = async () => {
    let data = { phone_number: user.phone_number, symbol: brand.symbol };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_BRAND_DETAILS,
        },
      })
      .then((res) => {
        let brandData = { brand: JSON.parse(JSON.stringify(brand)) };
        brandData.brand["detail"] = res.data.brand;
        dispatch(setBrand(brandData));
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          let access = await refresh(refresh_token);
          if (access.status) {
            //getDetail();
          }
        }
      });
    setLoading(false);
  };
  useEffect(async () => {
    if (!dayAndWeek && !brand.owned) {
      let dayAndWeek = getCurrentDayAndWeek();
      setDayAndWeek(dayAndWeek);
    } else if (!dayAndWeek && brand.owned) {
      let dayAndWeekFromDate = getDayAndWeekFromDate(
        new Date(brand.created_at)
      );
      setDayAndWeek(dayAndWeekFromDate);
    }
    let pastHours = await checkBuyTime();
    if (
      pastHours &&
      !brand.owned &&
      (wallet.playlists.length >= 1 || wallet.brands.length >= 1) &&
      parseFloat(user.reward_balance) > 0
    ) {
      setError({
        message: "The market opens M-F 9:30AM-4:30PM EST.",
        destiny: "Buy",
      });
      setBuyDisabled(true);
    }

    if (brand.detail === null || brand.detail === undefined) {
      await getDetail();
    }
    getPlaidToken();
  }, []);

  //when clicking 1 or 3 amounts
  const handleClickAmount = (amount) => {
    if (brand.is_paused) return;
    //setCustomAmount(null);
    setShowCustomAmount(false);
    setSeeFrequency(true);
    setBuyAmount(brand.is_paused ? brand.rule_amount : amount);
  };

  //when clicking custom button
  const handleClickCustomAmount = () => {
    if (brand.is_paused) return;
    setSeeFrequency(true);
    setShowCustomAmount(true);
    setBuyAmount(null);
  };
  //when inputting in custom amount field
  const handleCustomAmountInput = (e) => {
    setBuyAmount(null);
    const customAmount = e.target.value;
    if (customAmount > 30 && parseInt(user.reward_balance) > 0) {
      setError({
        message:
          "There is a $30 limit for buys done with promotional credit. Please adjust the custom amount to proceed.",
        destiny: "Buy",
      });
      setCustomAmount(null);
    } else {
      setError({});
      setCustomAmount(parseInt(e.target.value));
    }
    //setBuyAmount(parseInt(e.target.value));
  };

  //when clicking a frequency button
  const handleBuyFrequency = (frequency) => {
    if (brand.is_paused) return;
    setShowCustomAmount(false);
    setBuyFrequency(frequency);
  };

  const getPlaidToken = async () => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url:
              process.env.REACT_APP_CORE_API + URIS.CREATE_PLAID_TOKEN,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setLinkToken(data.link_token);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const handleBuy = async () => {
    setError({});
    setLoading(true);
    let firstBuy =
      wallet.playlists.length > 0 || wallet.brands.length > 0 ? false : true;
    let data = {
      user_id: user.id,
      type: "BUY",
      amount: buyAmount
        ? buyAmount
        : customAmount
        ? customAmount
        : brand.rule_amount,
      symbol: brand.symbol,
      playlist: null,
    };

    if (buyFrequency) {
      data.frequency = buyFrequency;
    }
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.STOCK_TRADING,
        },
      })
      .then((res) => {
        getUserData(access_token, user.phone_number, vfp, dispatch, history);
        setLoading(false);
        let message = "*Single will start the next tradable day.";
        if (!firstBuy) {
          setPendingState({ state: true, message: message });
        }
        setCompletedBuy(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError({
          message:
            "There was an error processing your request. Please try again later.",
          destiny: "Buy",
        });
      });
  };

  const handleEdit = async () => {
    if (!buyAmount && buyFrequency === brand.frequency && !customAmount) {
      setError({
        message: "Please select a different amount or frequency.",
        destiny: "Buy",
      });
      return;
    } else {
      setError({});
    }
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      amount: buyAmount
        ? buyAmount
        : customAmount
        ? customAmount
        : brand.rule_amount,
      symbol: brand.symbol,
    };
    if (buyFrequency) {
      data.frequency = buyFrequency;
    }
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.EDIT_RULE,
        },
      })
      .then((res) => {
        setLoading(false);
        setCompletedBuy(true);
        let message =
          "*Edits or Pauses made after 4PM ET may not take effect until the following tradable day.";
        setPendingState({ state: true, message: message });
      })
      .catch(async (error) => {
        console.error(error);
      });
  };

  const getButtonAction = () => {
    if (!proceedToBuy) {
      return setProceed(true);
    } else if (!buyAmount && !customAmount && !brand.owned) {
      return setError({ message: "Please select an amount.", destiny: "Buy" });
    } else if (!buyFrequency && !brand.owned) {
      return setError({
        message: "Please select a frequency to invest.",
        destiny: "Buy",
      });
    } else if (completedBuy || completedPause) {
      handleRefreshDashboard();
    } else if (!user_status.hasSSN) {
      return setGetSsn(true);
    } else {
      handleAction();
    }
  };

  const handleAction = async () => {
    let access = await refresh(refresh_token);
    if (access.status) {
      if (!completedBuy && !brand.owned) {
        return handleBuy();
      } else if (!completedBuy && brand.owned && !brand.is_paused) {
        return handleEdit();
      } else if (!completedBuy && brand.owned && brand.is_paused) {
        return handlePause();
      }
    }
  };

  const getTextButton = () => {
    if (loading) {
      return "Loading...";
    } else if (
      ((!completedBuy && proceedToBuy) ||
        (!completedBuy && !proceedToBuy && credit < 30)) &&
      !brand.owned
    ) {
      return "Own";
    } else if ((completedBuy || completedPause) && proceedToBuy) {
      return "Close";
    } else if (
      brand.owned &&
      !brand.is_paused &&
      !brand.is_pending &&
      brand.frequency !== "one-time"
    ) {
      return "Edit";
    } else if (brand.is_paused) {
      return "Resume";
    } else if (brand.is_pending || brand.frequency === "one-time") {
      return "Pending";
    } else {
      return "Own";
    }
  };

  const handlePause = async () => {
    setError({});
    setCompletedBuy(false);
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      symbol: brand.symbol,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.PAUSE_BUY,
        },
      })
      .then((res) => {
        setLoading(false);
        let message =
          "*Edits or Pauses made after 4PM ET may not take effect until the following tradable day.";
        setPendingState({ state: true, message: message });
        setCompletedPause(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setError({
          message:
            "There was an error processing your request. Please try again later.",
          destiny: "Buy",
        });
      });
  };
  const pauseBtn =
    brand.owned &&
    !brand.is_pending &&
    !brand.is_paused &&
    user.neon_userflow_status !== "MEMBER-NEED-PLAID-UPDATE" ? (
      <span
        id="button-single-pause"
        className="txt-1 neon-color pb-2 pointer"
        onClick={() => handlePause()}
      >
        Pause single
      </span>
    ) : null;

  return (
    <>
      {gettingSsn ? (
        <GetSSN
          handleClose={() => setGetSsn(false)}
          handleBuy={handleBuy}
          clearBuyScreen={setLoading}
        />
      ) : (
        <div className="playlist-component">
          <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
            <div style={{ padding: "1rem" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  textAlign: "left",
                  opacity: opacityValue,
                }}
              >
                <span
                  className="txt-1 neon-color pointer"
                  onClick={() => handleBack()}
                  style={{ padding: "0.5rem" }}
                >
                  Back
                </span>
                <div
                  style={{
                    maxwidth: "90%",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "auto",
                      marginRight: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img src={brand.image} width="50" />
                  </div>
                  <span
                    className="futura-mid-1pt17 white "
                    style={{ width: "100%" }}
                  >
                    {brand !== null && brand.symbol ? (
                      brand.brand_name.toUpperCase() + " (" + brand.symbol + ")"
                    ) : (
                      <Skeleton width="5rem" />
                    )}
                  </span>

                  <span
                    id="close-single-modal-button"
                    className="title white pointer"
                    style={{
                      textAlign: "right",
                      width: "5%",
                      right: "0",
                    }}
                    onClick={() => handleClose()}
                  >
                    X
                  </span>
                </div>
                {view === "" ? investTitle : null}
              </div>
              <PricesComponent
                opacityValue={opacityValue}
                brand={brand}
                setView={setView}
                consolidated={false}
                proceedToBuy={proceedToBuy}
                setReadMore={setReadMore}
                readMore={readMore}
              />
              {proceedToBuy ? (
                <div>
                  <div style={{ paddingTop: "2rem" }}>
                    <span className="white txt-1 bold">
                      {" "}
                      How much do you want to invest?
                    </span>
                  </div>
                  <div className="column" style={{ margin: "auto" }}>
                    <div
                      className="row mt-1"
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      {investings.map((amount, index) => (
                        <div
                          style={{ width: "5rem", margin: "auto" }}
                          key={index}
                        >
                          <button
                            id={"daily-single-amount-" + amount}
                            className={
                              amount === buyAmount ||
                              (brand.rule_amount === amount &&
                                !buyAmount &&
                                !customAmount)
                                ? "btn txt-1 pointer"
                                : "btn-inverse txt-1 pointer"
                            }
                            style={{ width: "100%" }}
                            onClick={() => handleClickAmount(amount)}
                          >
                            ${amount}
                          </button>
                        </div>
                      ))}
                      <div style={{ width: "5rem", margin: "auto" }}>
                        {!showCustomAmount ? (
                          <div
                            style={{
                              position: "relative",
                            }}
                            className="column white"
                          >
                            <button
                              id={"daily-amount-" + "5"}
                              className={
                                (customAmount && !buyAmount) ||
                                (brand.rule_amount &&
                                  !buyAmount &&
                                  ![1, 3].includes(brand.rule_amount))
                                  ? "btn txt-1 pointer"
                                  : "btn-inverse txt-1 pointer"
                              }
                              style={{ width: "100%" }}
                              onClick={() => {
                                handleClickCustomAmount();
                              }}
                            >
                              {customAmount
                                ? "$" + customAmount
                                : brand.owned &&
                                  ![1, 3].includes(brand.rule_amount)
                                ? "$" + brand.rule_amount
                                : "Custom"}
                            </button>
                            {!buyAmount &&
                            ((customAmount && !showCustomAmount) ||
                              (brand.owned &&
                                ![1, 3].includes(brand.rule_amount))) ? (
                              <span
                                className=" txt-s"
                                style={{
                                  position: "absolute",
                                  top: 38,
                                  left: 3,
                                  marginTop: "0.5rem",
                                }}
                              >
                                Tap to edit
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <div
                            className=" txt-1"
                            style={{ position: "relative" }}
                          >
                            {customAmount ? (
                              <span
                                style={{
                                  position: "absolute",
                                  left: 10,
                                  top: "60%",
                                  transform: "translateY(-50%)",
                                  zIndex: 1,
                                  color: "white",
                                }}
                              >
                                $
                              </span>
                            ) : (
                              <></>
                            )}
                            <input
                              pattern="\d*"
                              className="input txt-1"
                              value={customAmount ? customAmount : ""}
                              placeholder="Enter amount"
                              style={{ maxWidth: "100%" }}
                              onChange={(e) => {
                                handleCustomAmountInput(e);
                              }}
                              maxLength={6}
                              onBlur={() => setShowCustomAmount(false)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {seeFrequency || brand.owned ? (
                      <>
                        <div style={{ paddingTop: "3rem" }}>
                          <span className="white txt-1 bold">How often?</span>
                        </div>
                        <div
                          className="row mt-1"
                          style={{
                            justifyContent: "center",
                            textAlign: "left",
                          }}
                        >
                          {frequencies.map((frequency, index) => (
                            <div
                              style={{
                                width: "5rem",
                                margin: "auto",
                                height: "6rem",
                              }}
                              key={index}
                              className="column white"
                            >
                              <button
                                className={
                                  frequency.value === buyFrequency ||
                                  (brand.owned &&
                                    frequency.value === brand.frequency &&
                                    !buyFrequency)
                                    ? "btn txt-1 pointer"
                                    : "btn-inverse txt-1 pointer"
                                }
                                style={{
                                  width: "100%",
                                  marginBottom: "0.5rem",
                                }}
                                onClick={() =>
                                  handleBuyFrequency(frequency.value)
                                }
                              >
                                {frequency.text}
                              </button>
                              <span className="txt-s">
                                {frequency.value === "monthly" && dayAndWeek
                                  ? "Once a month (Every " +
                                    dayAndWeek.week +
                                    " " +
                                    dayAndWeek.day +
                                    ")"
                                  : frequency.subtext}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {whyDailyText()}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "1rem",
                        }}
                      >
                        <span className="white txt-1 bold">Buying with</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          <span className="white txt-1">
                            {credit > 0
                              ? "Neon Money Credit: $" + credit
                              : "Your linked account"}
                          </span>
                          {(linkToken &&
                            (acctAdded || user.registered_in_plaid)) ||
                          (!user.registered_in_plaid &&
                            [
                              "MEMBER-NEED-PLAID-UPDATE",
                              "MEMBER-FUNDING-ISSUE",
                            ].includes(user.neon_userflow_status)) ? (
                            <PlaidLink
                              token={linkToken}
                              acctAdded={acctAdded}
                              setAcctAdded={setAcctAdded}
                              newRegistered={false}
                              buyammount={buyAmount}
                              setLoading={setLoading}
                            />
                          ) : null}
                        </div>
                      </div>
                      {completedBuy ? (
                        <CompleteLabel
                          text={"Complete!" + (pendingState.state ? "*" : "")}
                        />
                      ) : null}
                      {completedPause && brand.owned ? (
                        <div style={{ width: "350px", marginBottom: "1rem" }}>
                          <CompleteLabel
                            text={
                              "Updated successfully!" +
                              (pendingState ? "*" : "")
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
              {((user.registered_in_plaid || completedBuy || loading) &&
                linkToken) ||
              !proceedToBuy ? (
                <button
                  id="button-single-process"
                  className="btn-inverse-round txt-1 "
                  disabled={brand.is_pending || buyDisabled ? true : false}
                  style={{
                    marginTop: "1rem",
                    color: brand.is_pending || buyDisabled ? "gray" : "#d1d1cc",
                  }}
                  onClick={() => getButtonAction()}
                >
                  {getTextButton()}
                </button>
              ) : ["MEMBER-NEED-PLAID-UPDATE", "MEMBER-FUNDING-ISSUE"].includes(
                  user.neon_userflow_status
                ) && proceedToBuy ? null : linkToken ? (
                <PlaidLink
                  token={linkToken}
                  acctAdded={acctAdded}
                  setAcctAdded={setAcctAdded}
                  setLoading={setLoading}
                  buyAmount={buyAmount || customAmount}
                  newRegistered={true}
                  setError={setError}
                  completedBuy={completedBuy}
                  history={history}
                  handleRefreshDashboard={handleRefreshDashboard}
                  callback={() => {
                    setGetSsn(true);
                  }}
                  handleBuy={handleBuy}
                  buyFrequency={buyFrequency}
                />
              ) : (
                <TriangleSpinner />
              )}
              {proceedToBuy && loading ? <TriangleSpinner /> : null}
              {error ? (
                <div style={{ marginBottom: "1rem" }}>
                  <ErrorMessage error={error} destiny={"Buy"} />
                </div>
              ) : null}
              {proceedToBuy &&
              brand &&
              !brand.is_paused &&
              !brand.is_pending &&
              !completedPause
                ? pauseBtn
                : null}
              {pendingState.state && pendingState.message !== "" ? (
                <span className="txt-1 white">
                  <br />
                  {pendingState.message}
                </span>
              ) : null}
              <LogosListed />
              {view === "consolidated" ? (
                <button
                  className="btn-inverse txt-1 "
                  style={{
                    width: "100%",
                    borderRadius: "500px",
                    color: "white",
                    borderColor: "#16e7cf",
                    marginTop: "3rem",
                    marginBottom: "2rem",
                  }}
                  disabled={true}
                  onClick={() => getButtonAction()}
                >
                  {proceedToBuy ? "Own" : "Coming soon"}
                </button>
              ) : null}
            </div>
          </SkeletonTheme>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              opacity: opacityValue,
            }}
          >
            {view === "" && proceedToBuy ? (
              <>
                <PromoCreditText />
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

const PricesComponent = ({
  opacityValue,
  brand,
  proceedToBuy,
  setReadMore,
  readMore,
}) => {
  return (
    <div style={{ opacity: opacityValue }}>
      {brand !== null && brand.detail ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            textAlign: "left",
          }}
          className="pt-1"
        >
          {!proceedToBuy ? (
            <>
              <span className="white txt-1">
                {brand.description + " "}
                {!readMore ? (
                  <span
                    className="pointer bold"
                    onClick={() => setReadMore(true)}
                  >
                    READ MORE{" "}
                  </span>
                ) : null}
              </span>
              {readMore ? (
                <>
                  <span className="white txt-1" style={{ paddingTop: "1rem" }}>
                    {brand.detail.description}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      className="pointer bold white txt-1"
                      onClick={() => setReadMore(false)}
                    >
                      READ LESS
                    </span>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          <div
            style={{
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <span className="white txt-1 bold" style={{ marginTop: "1rem" }}>
              Price today:
            </span>
            <span className="white txt-1 ">
              {" "}
              ${brand.detail.price.toFixed(2)}{" "}
            </span>
            {brand.in_low ? (
              <>
                <div
                  style={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px #07dbab",
                      borderRadius: "0.5rem",
                      paddingBlock: "0.25rem",
                      paddingInline: "0.25rem",
                      display: "inline-flex",
                      verticalAlign: "middle",
                      width: "85px",
                    }}
                    data-tip={""}
                    data-for="tooltipInLow"
                  >
                    <span className="neon-color txt-s white-space">
                      ON THE LOW{" "}
                    </span>
                    <>
                      <span
                        className="txt-s"
                        style={{
                          color: "white",
                          display: "inline-flex",
                          alignItems: "end",
                        }}
                      >
                        <IoMdInformationCircleOutline
                          style={{ fontSize: 15 }}
                          preserveAspectRatio="none"
                          viewBox="0 0 590 590"
                        />
                      </span>
                      <div style={{ marginRight: "2rem", display: "flex" }}>
                        <ReactTooltip
                          id="tooltipInLow"
                          type="warning"
                          place="right"
                          effect="solid"
                          backgroundColor="gray"
                          multiline={true}
                          delayHide={0}
                          clickable={true}
                          wrapper="div"
                        >
                          {onTheLowText}
                        </ReactTooltip>
                      </div>
                    </>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {brand.price_5y_ago ? (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <span className="white txt-1 bold" style={{ marginTop: "1rem" }}>
                Price 5 yrs ago:
              </span>
              <span className="white txt-1 ">
                {" "}
                ${brand.price_5y_ago.toFixed(2)}{" "}
              </span>
              {brand.price_5y_ago &&
              brand.price_5y_ago < brand.detail.price.toFixed(2) ? (
                <>
                  <div
                    style={{
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  >
                    <div
                      style={{
                        border: "solid 1px #07dbab",
                        borderRadius: "0.5rem",
                        paddingBlock: "0.25rem",
                        paddingInline: "0.25rem",
                        display: "inline-flex",
                        verticalAlign: "middle",
                        width: "90px",
                        wordBreak: "keep-all",
                      }}
                      data-tip={""}
                      data-for="tooltip4"
                    >
                      <span className="neon-color txt-s white-space">
                        LONG MONEY{" "}
                      </span>
                      <>
                        <span
                          className="txt-s"
                          style={{
                            color: "white",
                            display: "inline-flex",
                            alignItems: "end",
                          }}
                        >
                          <IoMdInformationCircleOutline
                            style={{ fontSize: 15 }}
                            preserveAspectRatio="none"
                            viewBox="0 0 590 590"
                          />
                        </span>
                        <div style={{ marginRight: "2rem", display: "flex" }}>
                          <ReactTooltip
                            id="tooltip4"
                            type="warning"
                            place="bottom"
                            effect="solid"
                            backgroundColor="gray"
                            multiline={true}
                            delayHide={0.5}
                            clickable={true}
                            wrapper="div"
                          >
                            {longMoneyText}
                          </ReactTooltip>
                        </div>
                      </>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          ) : (
            highLowComponent(brand)
          )}
        </div>
      ) : (
        <Skeleton height={50} />
      )}
    </div>
  );
};

const highLowComponent = (brand) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
      }}
      className="pt-1"
    >
      <span className="white txt-s-light" style={{ marginRight: "1rem" }}>
        Highest $
        {brand !== null && brand.detail && brand.detail.highest >= 0 ? (
          brand.detail.highest.toFixed(2)
        ) : (
          <Skeleton />
        )}
      </span>
      <span className="txt-1 white">|</span>
      <span className="white txt-s-light" style={{ marginLeft: "1rem" }}>
        Lowest $
        {brand !== null && brand.detail && brand.detail.lowest >= 0 ? (
          brand.detail.lowest.toFixed(2)
        ) : (
          <Skeleton />
        )}
      </span>
    </div>
  );
};

const onTheLowText = (
  <span
    className="white"
    style={{
      display: "inline-block",
      width: "10rem",
      height: "auto",
      wordBreak: "break-all",
      textAlign: "justify !important",
      textJustify: "inter-word !important",
    }}
  >
    This is less than the average price per share over the last 200 days.
    <br />
    <br />
    Unlock: It's like a pair of AF1s were $100 for the last 200 days and now you
    can buy it for cheaper.
  </span>
);

const longMoneyText = (
  <span
    className="white"
    style={{
      display: "inline-block",
      width: "10rem",
      height: "auto",
      wordBreak: "break-all",
      textAlign: "justify !important",
      textJustify: "inter-word !important",
    }}
  >
    The long term average return of the stock market is ~10% per year (this
    looks at 30 <br />
    years of data). We're here for that Long Money. <br />
    <br />
    Historical prices at the close of Jan 3, 2017. Close price adjusted for
    splits (Yahoo
    <br /> Finance).
  </span>
);

const whyDailyText = () => {
  return (
    <div className="single-title" style={{ marginInline: "1rem" }}>
      <span className="white txt-1" style={{ lineHeight: "1.8" }}>
        <span className="bold"> Why daily?</span> Investing{" "}
        <a
          className="white pointer"
          style={{ textDecoration: "underline" }}
          href="https://intelligent.schwab.com/article/dollar-cost-averaging#:~:text=Dollar%20cost%20averaging%20is%20the,as%20well%20as%20your%20costs"
          target="_blank"
          rel="noreferrer"
        >
          regularly
        </a>{" "}
        has shown to be better than trying to time when to invest. The stock
        market is a wave - we ride it daily.
      </span>
    </div>
  );
};
