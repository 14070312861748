import React from "react";

export default function AddBank({ acctAdded, handleAddAcct }) {
  return acctAdded ? (
    <div
      style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}
    >
      <span className="white txt-s">Buying with</span>
      <span className="white txt-1">Neon Money Credit: $30.00</span>
    </div>
  ) : null;
}
