import React from "react";

export default function PendingStockText() {
  const text =
    "*Your stock will be awarded on the next available trading day (M-Fri 8:30AM-3PM CST)";
  return (
    <div style={{ paddingTop: "1rem", textAlign: "left" }}>
      <span className="txt-1 white">{text}</span>
    </div>
  );
}
