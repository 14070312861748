import React, { createRef, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import SkewLoader from "react-spinners/SkewLoader";
import { css } from "@emotion/react";

export default function DisclosuresForm({
  form,
  changeHandler,
  error,
  modal,
  setOpen,
  loading,
}) {
  const [showTerms, setShowTerms] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(2).fill(false));
  const [frame, setFrame] = useState("");
  const [employed, setEmployed] = useState(false);
  const [consented, setConsented] = useState(false);
  const [disclosureTitle, showDisclosureTitle] = useState(false);
  const ref = createRef();

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    if (updatedCheckedState.filter((value) => value === true).length === 1) {
      handleClicks("disclosured", true);
    } else {
      handleClicks("disclosured", null);
    }
  };
  const handleClicks = (target, value) => {
    ReactGA.event({
      category: "KYCyesno",
      action: target + "_clicked",
    });
    changeHandler(target, value);
  };

  const handleFrame = (url) => {
    setFrame(url);
    setOpen(true);
    ref.current.scrollTo(0, 0);
  };

  useEffect(() => {
    if (consented) {
      setShowTerms(true);
      showDisclosureTitle(true);
    } else {
      setShowTerms(false);
      showDisclosureTitle(false);
    }
  }, [consented]);

  useEffect(() => {
    ReactGA.event({
      category: "KYCyesno",
      action: "VIEWED",
    });
  }, []);

  const disclosure = (
    <div
      className="txt-s futura-mid white"
      style={{
        minWidth: "auto",
        textAlign: "left",
        marginTop: "2rem",
        justifyContent: "center",
      }}
    >
      <div>
        <span>I agree that I am:</span>
        <br />
        <ol>
          <li>A United States Citizen or Legal Permanent Resident </li>
          <li> Not affiliated with or employed by FINRA or a Broker-Dealer </li>
          <li>
            Not a Politically Exposed Person or an owner of more than 10% in a
            publicly traded company.{" "}
          </li>
        </ol>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <span>I accept the following terms & conditions:</span>
        <p />{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <input
            className="checkbox"
            type="checkbox"
            id="term1"
            checked={checkedState[0]}
            onChange={() => handleOnChange(0)}
          />{" "}
          <ul style={{ listStyleType: "none", marginLeft: "-1.5rem" }}>
            <li>
              <label htmlFor="term1" style={{ lineHeight: 1.5 }}>
                <a
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() =>
                    handleFrame("https://joinneon.com/disclosures")
                  }
                >
                  Neon member terms
                </a>{" "}
                , Investment partner terms (DriveWealth’s{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() =>
                    handleFrame("https://apps.drivewealth.com/disclosures/")
                  }
                >
                  disclosures,
                </span>{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() =>
                    handleFrame("https://legal.drivewealth.com/privacy-policy")
                  }
                >
                  privacy policy,
                </span>{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() =>
                    handleFrame(
                      "https://apps.drivewealth.com/disclosures/?showOnlyTaxDoc=true"
                    )
                  }
                >
                  {" "}
                  w9-agreement
                </span>
                ),
              </label>
            </li>
            <br></br>
            <br></br>
          </ul>
        </div>
      </div>
    </div>
  );

  const neonFrame = (
    <div style={{ maxWidth: "100vw", margin: "auto" }} ref={ref}>
      <div>
        <span
          style={{
            textAlign: "right",
            display: "block",
          }}
          className="title white pointer"
          onClick={() => setOpen(false)}
        >
          X
        </span>
      </div>
      <div style={{ position: "relative" }}>
        <iframe
          title="frame"
          src={frame}
          width="800"
          height="800"
          allowFullScreen={true}
          className="disclosure-Iframe"
        ></iframe>
      </div>
    </div>
  );

  const questions = (
    <div className="route-86" style={{ minWidth: "100%" }}>
      <div
        style={{
          paddingTop: "0rem",
          minWidth: "28vw",
          textAlign: "left",
        }}
      >
        <span className="white futura-mid">Neon Money Club</span>
      </div>
      <div className="white">
        <div style={{ paddingTop: "2rem" }}>
          <div>
            <div>
              <span className="txt-s futura-mid">
                Do you work? (employed/self-employed)
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: "0.5rem" }}>
                <button
                  id="work-option-yes"
                  className={form.employed ? "btn" : "btn-inverse"}
                  onClick={() => {
                    handleClicks("employed", true);
                    setEmployed(true);
                  }}
                >
                  yes
                </button>
              </div>
              <div style={{ padding: "0.5rem" }}>
                <button
                  className={
                    !form.employed && form.employed != null
                      ? "btn"
                      : "btn-inverse"
                  }
                  onClick={() => {
                    handleClicks("employed", false);
                    setEmployed(false);
                  }}
                >
                  no
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "2rem" }}>
          <div>
            <span className="txt-s futura-mid">
              Have you reviewed{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() =>
                  handleFrame("https://joinneon.com/electronic-consent")
                }
              >
                electronic consent?
              </span>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ padding: "0.5rem" }}>
              <button
                id="electronic-consented-yes"
                className={form.consented ? "btn" : "btn-inverse"}
                onClick={() => {
                  handleClicks(
                    "consented",
                    form.first_name + " " + form.last_name
                  );
                  setConsented(true);
                }}
              >
                yes
              </button>
            </div>
            <div style={{ padding: "0.5rem" }}>
              <button
                className={
                  !form.consented && form.consented != null
                    ? "btn"
                    : "btn-inverse"
                }
                onClick={() => {
                  handleClicks("consented", "");
                  setConsented(false);
                }}
              >
                no
              </button>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "2rem" }}>
          {disclosureTitle ? (
            <div>
              <span className="txt-s futura-mid" style={{ fontWeight: "bold" }}>
                Member terms
              </span>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {consented ? disclosure : null}
          </div>
          <div
            style={{
              paddingTop: "0.5rem",
              maxWidth: "15rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <span className="error">
              {form.disclosured === false
                ? "In order to claim your stock, you need to agree to the disclosures."
                : null}
              {error.destiny === "other" ? error.message : null}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const override = css`
    display: inline-flex;
    margin-bottom: 1rem;
  `;
  const loadingMembership = (
    <div>
      <div className="membership-loading">
        <span
          className="txt-1 futura-mid white"
          style={{ alignSelf: "center" }}
        >
          Your club access is loading...
        </span>
        <div style={{ marginTop: "1rem" }}>
          <span
            className="txt-1 futura-bold white"
            style={{
              alignSelf: "center",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            (Please don't refresh this page)
          </span>
        </div>
        <div style={{ marginTop: "3rem" }}>
          <SkewLoader color={"#16e7cf"} css={override} size={10} />
        </div>
        <div style={{ marginTop: "3rem" }}>
          <span className="txt-1-mid futura-mid white">
            “I deserve the threads in my closet Big boy deposits, Nike shares
            and stock tips Catching up with those who had a head start” -{" "}
            <a
              className="white pointer"
              style={{ textDecoration: "underline" }}
              href="https://genius.com/Nas-nas-is-good-lyrics/"
              target="_blank"
              rel="noreferrer"
            >
              Nas
            </a>
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? loadingMembership : !loading && modal ? neonFrame : questions}
    </div>
  );
}
