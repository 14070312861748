import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../Store/Modal/Reducers";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import CompleteLabel from "../components/CompleteLabel";
import { getUserData } from "../helpers/DashboardMainFunctions";
import { useHistory } from "react-router-dom";
import TriangleSpinner from "../components/TriangleSpinner";
import ReactGA from "react-ga4";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";
import viewing from "../images/buttons/VIEWING.png";
import check from "../images/buttons/CHECK.png";
import ActionCustomComponent from "../components/ActionCustomComponent/ActionCustomComponent";

export default function UnlocksFlow() {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = React.useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [scenario, setScenario] = useState(1);
  const [setCompleted, setSetCompleted] = useState(false);
  const [setFinished, setSetFinished] = useState(false);
  const [currentUnlock, setCurrentUnlock] = useState(null);
  const [lastScenario, setLastScenario] = useState(false);

  let { user, access_token, vfp } = useSelector((state) => state.login);
  let { unlocks, set_letter, completed } = useSelector(
    (state) => state.unlocks
  );

  useEffect(() => {
    if (
      user.set_state &&
      set_letter === user.set_state[0] &&
      parseInt(user.set_state.slice(-1)) === unlocks.scenarios.length
    ) {
      setSetCompleted(true);
    }
  }, [unlocks, user]);

  const forceSwipeWithBtn = () => {
    swiper.slideNext();
    setScenario(currentUnlock.scenario + 1);
    if (currentUnlock.scenario + 1 === unlocks.scenarios.length) {
      setLastScenario(true);
    }
  };

  const handleNextMobile = (e, next) => {
    if (e) {
      if (e.swipeDirection === "next") {
        next();
      } else if (e.swipeDirection === "prev") {
        setLastScenario(false);
        setScenario(currentUnlock.scenario - 1);
      }
    } else {
      forceSwipeWithBtn();
    }
  };

  const handleNext = (e) => {
    const next = () => {
      if (currentUnlock.scenario + 1 === unlocks.scenarios.length) {
        setLastScenario(true);
      }
      setScenario(currentUnlock.scenario + 1);
    };
    if (isMobile) {
      handleNextMobile(e, next);
    } else {
      next();
    }
  };

  //gets and sets current set available for user
  useEffect(() => {
    if (unlocks && unlocks.scenarios) {
      let getCurrentScenario = unlocks.scenarios.filter(
        (unlock) => unlock.scenario === scenario
      );
      if (
        getCurrentScenario.length > 0 &&
        getCurrentScenario[0].scenario <= unlocks.scenarios.length
      ) {
        setCurrentUnlock(getCurrentScenario[0]);
      } else {
        setLastScenario(true);
      }
    }
  }, [scenario, unlocks]);

  //Finish set and get 10 entries for raffle
  const getEntries = async () => {
    ReactGA.event({
      category: "UNLOCKS",
      action: "GETTING_ENTRIES",
    });
    setLoading(true);
    let data = {
      phone_number: user.phone_number,
      set_code: currentUnlock.set_code,
      scenario: scenario,
    };
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.UNLOCKS_SET_STATE,
      },
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, authConfig)
      .then((res) => {
        if (res.data.status) {
          getUserData(access_token, user.phone_number, vfp, dispatch, history);
          if (lastScenario) {
            setSetFinished(true);
          }
          setLoading(false);
        }
      });
  };

  const getButtonText = () => {
    if (lastScenario && (setCompleted || setFinished)) {
      return "More unlocks coming soon";
    } else if (lastScenario && !setCompleted) {
      return "Tap to complete and get 10 entries";
    } else {
      return "Got it. Show next one.";
    }
  };
  const getButtonAction = () => {
    if (lastScenario && (setCompleted || setFinished)) {
      return dispatch(closeModal());
    } else if (lastScenario && !setCompleted) {
      return getEntries();
    } else {
      return handleNext(null);
    }
  };
  /*
  useEffect(() => {
    if (isMobile) {
       const row = document.getElementsByClassName(
        "swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
      );
      row[0].classList.add("margin-swiper");
      const mockCircle = document.createElement("span");
      mockCircle.className = "mock-bullet";
      row[0].prepend(mockCircle);
    }
  }, [isMobile]);*/

  return (
    <div
      style={{
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div
        style={{
          paddingInline: "1rem",
          textAlign: "left",
          width: "100%",
        }}
      >
        <span className="title white bold">Unlocks: Learn the Game</span>
        {unlocks ? (
          <>
            <ActionCustomComponent
              image={completed ? check : viewing}
              textTop={"Viewing this month's Unlocks"}
              textBtm={"Get 10 entries to win this month's drop."}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              {isMobile ? (
                <Swiper
                  pagination={true}
                  modules={[Virtual, Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  className="mySwiper"
                  onSlideChange={(e) => {
                    handleNext(e);
                  }}
                  style={{ top: "10" }}
                  onSwiper={(s) => setSwiper(s)}
                >
                  {unlocks &&
                    unlocks.scenarios.map((unlock, index) => (
                      <SwiperSlide key={index} virtualIndex={index}>
                        <img
                          src={"/images/SETS/" + unlock.image + ".jpg"}
                          style={{ width: "100%" }}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : currentUnlock ? (
                <img
                  src={"/images/SETS/" + currentUnlock.image + ".jpg"}
                  style={{ maxWidth: "100%" }}
                />
              ) : null}
              {setFinished ? (
                <div style={{ marginBottom: "-1rem" }}>
                  <CompleteLabel text={"Success!"} />
                </div>
              ) : null}
              {loading && lastScenario ? (
                <div style={{ margin: "auto", marginTop: "1rem" }}>
                  <TriangleSpinner />
                </div>
              ) : null}

              <button
                id="unlocks-next-button"
                className="btn-inverse-round txt-1 "
                onClick={() => getButtonAction()}
              >
                {getButtonText()}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
