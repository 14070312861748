import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch, useSelector } from "react-redux";
import { URIS } from "../Constants/Endpoints";
import { updatePlaid } from "../Store/Login/Reducers";
import { openModal } from "../Store/Modal/Reducers";
import { getUserData } from "../helpers/DashboardMainFunctions";
import TriangleSpinner from "./TriangleSpinner";

export default function PlaidLink({
  token,
  acctAdded,
  setAcctAdded = () => {},
  setLoading,
  plaidNotification,
  buyAmount,
  newRegistered = false,
  setError,
  completedBuy,
  history,
  handleRefreshDashboard,
  callback,
  handleBuy,
  buyFrequency,
  children,
}) {
  let buyAmmount = useRef(null);
  let { access_token, vfp, user } = useSelector((state) => state.login);
  let { modal } = useSelector((state) => state.modal);
  let dispatch = useDispatch();
  const [form, setForm] = useState({
    phone_number: user.phone_number,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    birthdate: user.birthdate,
    address: JSON.parse(user.address_raw),
    address_2: user.address_line_2,
    employed: user.employed,
    disclosured: true,
    consented: true,
    verifyToken: "",
  });
  const skipSSN = async () => {
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.KYC_VALIDATION,
      },
    };
    ReactGA.event({
      category: "KYC-IN-PLAID",
      action: "SUBMITTED",
    });
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
      .then((message) => {
        if (message.data.status) {
          setLoading(false);
          handleBuy();
        }
      })
      .catch((error) => {
        ReactGA.event({
          category: "KYC-IN-PLAID",
          action: "FAILED",
        });
        console.error(error.response);
        setLoading(false);
      });
  };

  const checkSSN = async () => {
    await axios
      .get(process.env.REACT_APP_AUTH_PROXY_URL, {
        headers: {
          NEON_AUTHORIZATION: `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.EXISTS_SSN,
        },
      })
      .then((message) => {
        if (!message.data.status) {
          callback();
        } else {
          skipSSN();
        }
      });
  };

  const onSuccess = useCallback(
    async (public_token, metadata) => {
      // send public_token to server
      let added = false;
      setTimeout(() => {
        if (!added) {
          setLoading(false);
          setError({
            message:
              "There was an error adding your account, please refresh the page and try again.",
            destiny: "Buy",
          });
        }
      }, 10000);

      //if user is in update mode, don't exchange public token
      if (
        user.neon_userflow_status === "MEMBER-NEED-PLAID-UPDATE" &&
        user.registered_in_plaid
      ) {
        added = true;
        //update user status to member-linked
        await axios
          .post(
            process.env.REACT_APP_AUTH_PROXY_URL,
            {
              phone_number: user.phone_number,
            },
            {
              headers: {
                NEON_AUTHORIZATION: `Bearer ${access_token}`,
                audience_url:
                  process.env.REACT_APP_CORE_API + URIS.UPDATE_LINKED_USER,
              },
            }
          )
          .then((res) => {
            setAcctAdded(true);
            getUserData(
              access_token,
              user.phone_number,
              vfp,
              dispatch,
              history
            );
          });
      } else {
        setLoading(true);
        let changeAccount =
          user.neon_userflow_status === "NEON-MEMBER-CREATED" ? false : true;
        let data = {
          phone_number: user.phone_number,
          public_token: public_token,
          metadata: metadata,
          change_account: changeAccount,
          amount_to_buy: user.registered_in_plaid ? null : buyAmount,
        };
        await axios
          .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
            headers: {
              NEON_AUTHORIZATION: `Bearer ${access_token}`,
              audience_url:
                process.env.REACT_APP_CORE_API +
                URIS.EXCHANGE_PUBLIC_PLAID_TOKEN,
            },
          })
          .then((response) => {
            if (response.data.status) {
              added = true;
              getUserData(
                access_token,
                user.phone_number,
                vfp,
                dispatch,
                history
              );

              if (newRegistered) {
                dispatch(updatePlaid(true));
                checkSSN();
              } else {
                setLoading(false);
                setAcctAdded(true);
                callback() ?? callback();
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "";
            if (error.response.status === 406) {
              errorMessage =
                "The account you attempted to connect doesn’t have funds in it. Please choose a different account or make a deposit.";
            } else {
              errorMessage =
                "There was an error adding your account, please refresh the page and try again.";
            }
            setError({
              message: errorMessage,
              destiny: "Buy",
            });
            console.error(error);
          });
      }
    },
    [token]
  );

  const onExit = useCallback(async (error, metadata) => {
    console.error(error);
    setLoading(false);
    setAcctAdded(false);
  });

  const onEvent = useCallback(async (msg) => {
    if (error) {
      setError({
        message: "There was an error, please refresh the page and try again.",
        destiny: "Buy",
      });
    }
  });

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
  };

  const { open, ready, error, exit } = usePlaidLink(config);

  useEffect(() => {
    if (error) {
      ReactGA.event({
        category: "PLAID",
        action: "PLAID_ERROR",
        label: error,
      });
      console.error(error);
      exit();
    }
    if (plaidNotification) {
      return (
        <a href="#" className="white" onClick={() => handleEditBank()}>
          here.
        </a>
      );
    }
  }, [error, plaidNotification]);

  const firstBuy = async () => {
    if (!buyAmount) {
      setError({
        message: "Tap your investing amount above",
        destiny: "Buy",
      });
      return;
    } else if (!buyFrequency) {
      setError({
        message: "Tap your investing frequency above",
        destiny: "Buy",
      });
      return;
    } else if (!buyFrequency) {
      setError({
        message: "Tap your investing frequency above",
        destiny: "Buy",
      });
      return;
    } else {
      setError({});
    }
    buyAmmount.current = buyAmount;
    if (ready) {
      await confirmOpenPlaid();
      open();
    }
  };

  const notRegistered = (
    <button
      id="button-plaid-process"
      className="btn-inverse-round txt-1 "
      disabled={!ready}
      style={{
        color: "#d1d1cc",
        marginBottom: "1rem",
      }}
      onClick={() => (!completedBuy ? firstBuy() : handleRefreshDashboard())}
    >
      {!completedBuy ? "Activate free $30 by linking bank" : "Close"}
    </button>
  );

  const confirmOpenPlaid = async () => {
    const data = { phone_number: user.phone_number };

    await axios.post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
      headers: {
        NEON_AUTHORIZATION: `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.CONFIRM_OPEN_PLAID,
      },
    });
  };

  const handleEditBank = async () => {
    if (ready) {
      await confirmOpenPlaid();
      open();
    } else if (error) {
      exit();
      try {
        open();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    dispatch(
      openModal({
        modal: "bankSecurity",
        origin: modal,
      })
    );
  };

  if (ready && children) {
    return (
      <div onClick={() => handleEditBank()} disabled={!ready}>
        {children}
      </div>
    );
  }

  return ready ? (
    <>
      {newRegistered ? (
        <div
          className="column"
          style={{
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {notRegistered}
          <a
            href="#"
            onClick={(e) => {
              handleOpenModal(e);
            }}
            target="_blank"
            className="txt-1-mid pointer"
            style={{
              color: "white",
              textDecoration: "underline",
              fontSize: "0.83rem !important",
              marginTop: "auto",
              marginBottom: "1rem",
            }}
          >
            🔒 How We Protect And Support You{" "}
          </a>
        </div>
      ) : (
        <span
          className="neon-color txt-1 pointer white-space bolder"
          onClick={() => handleEditBank()}
          disabled={!ready}
        >
          {" "}
          {user.registered_in_plaid || acctAdded ? "EDIT BANK" : "ADD BANK"}
        </span>
      )}
    </>
  ) : (
    <TriangleSpinner />
  );
}
