import { createSlice } from "@reduxjs/toolkit";

const unlocksSlice = createSlice({
  name: "unlocks",
  initialState: {
    unlocks: null,
    set_viewer: null,
    set_letter: null,
    completed: false,
    completed_sets: null,
    next_sets: null,
  },
  reducers: {
    setUnlocks: (state, action) => {
      state.unlocks = action.payload.unlocks;
      state.set_letter = action.payload.set_letter;
      state.completed = action.payload.completed;
      state.completed_sets = action.payload.completed_sets;
      state.next_sets = action.payload.next_sets;
    },
    setViewer: (state, action) => {
      state.set_viewer = action.payload;
    },
  },
});

export const { setUnlocks, setViewer } = unlocksSlice.actions;
export default unlocksSlice.reducer;
