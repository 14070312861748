import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../Store/Modal/Reducers";

export default function BankSecurity({ setTab }) {
  const dispatch = useDispatch();
  const { origin } = useSelector((state) => state.modal);
  function handleClose(handler) {
    if (handler === "modal") {
      dispatch(
        openModal({
          modal: origin,
          origin: "bankSecurity",
        })
      );
    } else if (handler === "chat") {
      dispatch(closeModal());
      dispatch(setTab("club"));
    }
  }

  return (
    <div className="playlist-component">
      <div
        style={{
          textAlign: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: "1rem",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            Bank Level Security and Privacy
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose("modal")}
          >
            X
          </span>
        </div>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            lineHeight: "2",
          }}
        >
          <ol className="white txt-1">
            <li>
              We're regulated by the{" "}
              <a
                href="https://adviserinfo.sec.gov/firm/summary/316896"
                target="_blank"
                className="neon-color underline"
              >
                SEC
              </a>
              . We don't do NFTs or Crypto.
            </li>
            <li>
              Bank Level Security. All your data is encrypted and protected.
            </li>
            <li>
              We have real people here. Hit us on{" "}
              <span
                className="neon-color underline"
                onClick={() => handleClose("chat")}
              >
                chat
              </span>{" "}
              anytime.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
