import React, { useState } from "react";
import { openModal } from "../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";

export default function PointsOnPoints() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  const [action, setAction] = useState("double");
  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: "CardDashboard", origin: modal }))
            }
          >
            Back
          </span>
          <div
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span className="futura-mid-1pt17 white " style={{ width: "100%" }}>
              Points on points
            </span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardDashboard", origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <span className="txt-1-mid">Points available now</span>
            <span className="sub-title bold mt-1">300</span>
            <span className="txt-s mt-1">600 points pending</span>
            <span className="txt-1-mid" style={{ marginTop: "4rem" }}>
              What do you wanna do?
            </span>
            <div className="row mt-1 txt-1-mid">
              <div className="column">
                <button
                  className={action === "use" ? "btn" : "btn-inverse "}
                  style={{ marginInline: "0.5rem", width: "8rem" }}
                  onClick={() => setAction("use")}
                >
                  Use Towards Bill
                </button>
                <div
                  style={{
                    width: "5rem",
                    paddingInline: "0.5rem",
                    textAlign: "left",
                  }}
                >
                  <span className="white txt-s">$6.00 </span>
                </div>
              </div>
              <div className="column">
                <button
                  className={action === "double" ? "btn" : "btn-inverse "}
                  style={{ marginInline: "0.5rem", width: "8rem" }}
                  onClick={() => setAction("double")}
                >
                  Double Up
                </button>
                <div
                  style={{
                    width: "5rem",
                    paddingInline: "0.5rem",
                    textAlign: "left",
                  }}
                >
                  <span className="white txt-s">$6.00 </span>
                </div>
              </div>
            </div>
            <div className="single-title">
              <span className="white txt-s" style={{ lineHeight: "1.8" }}>
                Neon Money Club doubles your points if you choose to invest it
                in the Stock Market. We're the only Card that invests in your
                Long Money Mindset.
              </span>
            </div>
            <span className="txt-s mt-1" style={{ textAlign: "center" }}>
              {action === "use"
                ? "A $3.00 statement credit will be applied to your current bill in 1-5 business days."
                : ""}
            </span>{" "}
            <button
              className="btn-inverse-round txt-1 "
              style={{
                marginTop: "1rem",
              }}
              onClick={() =>
                action === "double"
                  ? dispatch(openModal({ modal: "investor", origin: modal }))
                  : null
              }
            >
              {action === "double" ? "Next" : "Confirm"}
            </button>
            <span className="neon-color txt-s mt-1" onClick={() => {}}>
              Problems or lost your card?{" "}
            </span>
            <span className="white txt-s mt-2" onClick={() => {}}>
              Some disclosures
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
