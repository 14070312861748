import React from "react";
import { openModal } from "../../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function CardBenefits() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  const benefits = ["1.5X", "September", "August", "July", "June", "May"];
  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: origin, origin: modal }))
            }
          >
            Back
          </span>
          <div
            className="futura-bold white "
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <span style={{ width: "100%" }}>Card Benefits</span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: origin, origin: modal }));
              }}
            >
              X
            </span>
          </div>
          <table className="table white">
            <thead className=" txt-1-mid">
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{}}>
              {benefits !== null && benefits.length > 0
                ? benefits.map((benefit, index) => (
                    <tr
                      className="txt-s tr-border pointer"
                      key={index}
                      onClick={() => {}}
                    >
                      <td>{benefit}</td>
                      <td
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <MdOutlineKeyboardArrowRight />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
