import React from "react";

export default function CreationFailed({ text }) {
  return (
    <div className="route-86">
      <div style={{ marginTop: "3rem", height: "30 rem" }}>
        <span className="sub-title white ">
          {text
            ? text
            : "We ran into an issue creating your account. Hit us on chat and we'll figure this out."}
        </span>
      </div>
    </div>
  );
}
