import { SkewLoader } from "react-spinners";
import { css } from "@emotion/react";

export default function TriangleSpinner() {
  const override = css`
    display: inline-flex;
    margin-bottom: 1rem;
  `;
  return <SkewLoader color={"#16e7cf"} css={override} size={10} />;
}
