import React from "react";
import ErrorMessage from "./ErrorMessage";
import { VerifiedMessageComp } from "./VerifiedMessageComp";

export function ChangePhone({
  changeHandler,
  error,
  form,
  verified,
  saved,
  handleSavePersonal,
}) {
  return (
    <div>
      <div className="card-settings">
        <div className="card-top-padding">
          <div>
            <span className="txt-s futura-mid white">New phone number</span>
          </div>
          <div>
            <input
              pattern="\d*"
              className="input"
              placeholder="(378)789-1234"
              value={form.phone_number.replace(
                /^(\d{3})(\d{3})(\d+)$/,
                "($1)$2-$3"
              )}
              maxLength={13}
              onChange={(e) =>
                changeHandler(
                  "phone_number",
                  e.target.value.replace(/[^\d]/g, "")
                )
              }
            />
            <ErrorMessage error={error} destiny={"phone"} />
          </div>
          const validateSSNWrapper = (
          <div className="card-top-padding">
            <div>
              <span className="txt-s futura-mid white">Last 4 of SSN</span>
            </div>
            <div>
              <input
                onClick={() =>
                  form.ssn === "" ? changeHandler("SSN", "") : form.ssn
                }
                className="input"
                placeholder="1234"
                value={form ? form.ssn.replace(/^(\d{4})(\d+)$/, "$1") : ""}
                type="number"
                maxLength={4}
                onChange={(e) =>
                  changeHandler(
                    "ssn",
                    e.target.value.replace(/^(\d{4})(\d+)$/, "$1")
                  )
                }
              />
              <ErrorMessage error={error} destiny={"ssn"} />
            </div>
          </div>
          ); const validateDOBWrapper = (
          <div className="card-top-padding" style={{ paddingBottom: "1rem" }}>
            <div>
              <span className="txt-s futura-mid white">DOB</span>
            </div>
            <div>
              <input
                onClick={() =>
                  form.dob === "DD/MM/YYYY"
                    ? changeHandler("dob", "")
                    : form.dob
                }
                className="input"
                placeholder="19/07/1997"
                value={
                  form
                    ? form.dob.replace(/^(\d{2})(\d{2})(\d+)$/, "$1/$2/$3")
                    : ""
                }
                maxLength={10}
                onChange={(e) => changeHandler("dob", e.target.value)}
              />
              <ErrorMessage error={error} destiny={"birth"} />
            </div>
          </div>
          );
          {verified ? <VerifiedMessageComp saved={saved} /> : null}
          const saveButtonWrapper = ( // TODO: implement validation ajax calls
          and save
          <div style={{ marginTop: "1rem" }}>
            <button
              onClick={() => handleSavePersonal()}
              className="btn-inverse txt-s card-button"
            >
              Save
            </button>
          </div>
          );
        </div>
      </div>
    </div>
  );
}
