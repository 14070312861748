import React, { useState } from "react";
import AutoCompleteInput from "./AutoCompleteInput";
import ErrorMessage from "./ErrorMessage";

export default function KycForm({ form, changeHandler, error, name, setName }) {
  const [showAddress, setShowAddress] = useState(false);
  let nameRegex = /^[a-zA-Z\s_-]*$/;
  const handleName = (name) => {
    if (!nameRegex.test(name)) {
      var subName = name.substring(0, name.length - 1);
      setName(subName);
    } else if (name[0] === " ") {
      setName("");
    } else {
      setName(name);
    }
  };

  const addAdress = (
    <div style={{ paddingTop: "2rem" }}>
      <span
        className="txt-s futura-mid white pointer"
        onClick={() => setShowAddress(true)}
      >
        + Apartment/Unit # (optional)
      </span>
    </div>
  );
  const adressLine2 = (
    <div style={{ paddingTop: "2rem", width: "100%" }}>
      <div>
        <span className="txt-1 white">Apartment/Unit #</span>
      </div>
      <div>
        <input
          className="input"
          value={form.address_2}
          onChange={(e) => changeHandler("address_2", e.target.value)}
          style={{ width: "60%" }}
        />
      </div>
    </div>
  );

  return (
    <>
      <div
        className="route-86"
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          paddingBottom: "1rem",
          minWidth: "100%",
          alignSelf: "center",
        }}
      >
        <div
          className="white"
          style={{
            display: "flex",
            alignItems: "flex-start",
            paddingBottom: "8px",
            fontSize: 15,
          }}
        >
          Neon Money Club
        </div>
        <div
          className="sub-card"
          style={{
            padding: "0.5rem",
            marginTop: "0rem",
            marginBottom: "1rem",
            width: "85%",
            maxWidth: "400px",
            textAlign: "left",
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            alignSelf: "center",
            lineHeight: "1rem",
            textAlign: "center",
          }}
        >
          <span className="txt-s white">
            Get 3 entries into this month's giveaway just by joining.
          </span>
        </div>
        <div
          style={{
            paddingTop: "1rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div>
              <span className="txt-s futura-mid white">
                Legal first and last name
              </span>
            </div>

            <div>
              <input
                id="full-name"
                placeholder="Your full name here"
                className="input-firs-last-name"
                value={name}
                onChange={(e) => handleName(e.target.value)}
                pattern="[A-Za-z]*"
              />
              <div
                style={{
                  maxWidth: "8rem",
                  borderBottom: "white 1px solid",
                  margin: "auto",
                }}
              />
              <ErrorMessage error={error} destiny={"name"} />
            </div>
          </div>
          <div style={{ paddingTop: "2rem" }}>
            <div>
              <span className="txt-s futura-mid white">Address</span>
            </div>
            <div>
              <AutoCompleteInput
                value={
                  form.address
                    ? form.address.line_1
                      ? form.address.line_1 +
                        " " +
                        form.address.city +
                        " " +
                        form.address.state +
                        " " +
                        form.address.zip_code
                      : form.address
                    : ""
                }
                changeHandler={changeHandler}
              />
              <div
                style={{
                  maxWidth: "8rem",
                  width: "8rem",
                  borderBottom: "white 1px solid",
                  margin: "auto",
                }}
              />
              <ErrorMessage error={error} destiny={"address"} />
            </div>
          </div>
          {!showAddress ? addAdress : adressLine2}
        </div>
      </div>
    </>
  );
}
