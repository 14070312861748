import React from "react";
import Feed from "./Feed";
import NeonBenefit from "../components/NeonBenefit";
import ClubMood from "../components/Club Mood/ClubMood";
import ActionCustomComponent from "../components/ActionCustomComponent/ActionCustomComponent";
import check from "../images/buttons/CHECK.png";
import tap from "../images/buttons/TAP.png";
import { useSelector } from "react-redux";
import { monthNames } from "../Constants/Months.js";
import I2cComponent from "../components/ui/i2cComponent";

export default function ClubTab({ setTab }) {
  const { completed, unlocks } = useSelector((state) => state.unlocks);
  const { wallet } = useSelector((state) => state.dashboard);
  const date = new Date();

  return (
    <>
      <div style={{ margin: "1rem" }}>
        <span className="title white bold">
          {monthNames[date.getMonth()] + "'s Club Actions"}
        </span>
        {unlocks ? (
          <div onClick={() => setTab("learn")}>
            <ActionCustomComponent
              image={completed ? check : tap}
              textTop={"View this month's Unlocks"}
              textBtm={"Get 10 entries to win this month's drop."}
              arrow={true}
            />
          </div>
        ) : null}
        <div onClick={() => setTab("invest")}>
          {wallet &&
          wallet.playlists.length === 0 &&
          wallet.brands.length === 0 ? (
            <ActionCustomComponent
              image={tap}
              textTop={"Explore brands to invest"}
              textBtm={"Your first $30 is on us. Get started."}
              arrow={true}
            />
          ) : null}
        </div>
      </div>

      <NeonBenefit setTab={setTab} />
      <ClubMood />
      <Feed />
    </>
  );
}
