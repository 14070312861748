import React, { useState, useEffect, useRef } from "react";

function AutoCompleteInput({ value, changeHandler }) {
  const [query, setQuery] = useState("");
  const [queryTwo, setQueryTwo] = useState("");
  const autoCompleteRef = useRef(null);

  let autoComplete;

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  function autofillUnitNumber(address) {
    var regex = RegExp("(.*?)#(.+?),(.*)"); // get all the user entered values before a match with the street name; group #1 = Street Number + Name, group #2 = Apartment Number
    var results = regex.exec(address);

    if (Array.isArray(results)) {
      var address = results[1].trim() + "," + results[3];
      var streetAddress2 = results[2]; // group #2

      setQuery(address);
      setQueryTwo(streetAddress2);
    } else {
      setQueryTwo("");
    }
  }
  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    const addressComponents = addressObject.address_components;

    // Check if any address component has the types of "post_box" or "postal_box"
    const hasPOBox = addressComponents.some(
      (component) =>
        component.types.includes("post_box") ||
        component.types.includes("postal_box")
    );

    if (hasPOBox) {
      // Clear the input field
      setQuery("");
      return;
    }

    updateQuery(query);
    autofillUnitNumber(query);
  }
  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, []);

  useEffect(() => {
    changeHandler("address", query);
  }, [query]);

  useEffect(() => {
    changeHandler("address_2", queryTwo);
  }, [queryTwo]);

  return (
    <div className="search-location-input" style={{ justifyContent: "center" }}>
      <input
        id="address"
        data-testid="google-input"
        className="input-address"
        placeholder="7815 S South Shore Dr, Chicago, IL 60649"
        ref={autoCompleteRef}
        onChange={(e) => setQuery(e.target.value)}
        value={value}
      />
    </div>
  );
}

export default AutoCompleteInput;
