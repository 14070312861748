import React, { useState } from "react";
import { openModal } from "../../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import StepList from "../../../components/StepList";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReactTooltip from "react-tooltip";

export default function CardRealFriends() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  const [action, setAction] = useState("double");
  const [friendsAdded, setFriendsAdded] = useState(false);
  const addFriendsSteps = [
    {
      title: "",
      description: "Add friends to your group",
    },
    {
      title: "",
      description: "They get the Neon Money Club Cream American Express Card",
    },
    {
      title: "",
      description:
        "Our platform allows you to start directly investing in the brands you love.",
    },
  ];
  const friends = [
    {
      name: "YOU",
      points: "+800 points pending",
    },
    {
      name: "MATT H.",
      points: "+800 points pending",
    },
    {
      name: "SAMANTHA S.",
      points: "+800 points pending",
    },
  ];

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: "CardDashboard", origin: modal }))
            }
          >
            Back
          </span>
          <div
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              className="futura-bold white "
              style={{ width: "100%", fontSize: "1.5rem" }}
            >
              RFGMT
            </span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => {
                dispatch(openModal({ modal: "CardDashboard", origin: modal }));
              }}
            >
              X
            </span>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {" "}
            <span className="txt-1-mid">Real Friends Get Money Together</span>
            <span className="txt-s mt-1">
              You each get up to 800 monthly bonus points <PointsToolTip />
            </span>
            <div className="mt-2">
              <StepList steps={addFriendsSteps} title={""} isStep={false} />
            </div>
            <span className="txt-1-mid mt-2">
              Total Points Your Group Earned This Year
            </span>
            <span className="sub-title bold mt-1">300</span>
            {!friendsAdded ? (
              <>
                {" "}
                <CustomFriendsList friends={friends} />
                <button
                  className="btn-inverse-round txt-1 "
                  onClick={() => setFriendsAdded(true)}
                >
                  {action === "double" ? "Add friends" : "Confirm"}
                </button>
              </>
            ) : (
              <ShareYourLink setFriendsAdded={setFriendsAdded} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function PointsToolTip() {
  const pointsText = (
    <div
      className="white"
      style={{
        marginTop: "1rem",
        width: "12rem",
        height: "auto",
        wordBreak: "break-all",
        textAlign: "left !important",
      }}
    >
      <span className="bold">Bonus points</span>
      <br />
      <br />
      Bonus points are awarded once each group member pays their bill on time.
      <br />
      <br />
      <br />
      1 Member = each earns 0 bonus points
      <br /> 2 Members = each earns 500 bonus points
      <br />
      3 Members = each earns 600 bonus points
      <br />
      4 Members = each earns 700 bonus points
      <br />
      5 Members = each earns 800 bonus points
      <br />
      Group sizes are limited to 5 members.
    </div>
  );
  return (
    <>
      <div
        style={{
          display: "inline-flex",
          verticalAlign: "middle",
        }}
      >
        <div
          style={{
            borderRadius: "0.5rem",
            paddingBlock: "0.25rem",
            paddingInline: "0.25rem",
            display: "inline-flex",
            verticalAlign: "middle",
            wordBreak: "keep-all",
          }}
          data-tip={""}
          data-for="pointsTooltip"
        >
          <span className="neon-color txt-s white-space"></span>
          <>
            <span
              className="txt-s"
              style={{
                color: "white",
                display: "inline-flex",
                alignItems: "end",
              }}
            >
              <IoMdInformationCircleOutline
                style={{ fontSize: 15 }}
                preserveAspectRatio="none"
                viewBox="0 0 590 590"
              />
            </span>
            <div style={{ display: "flex", textAlign: "left" }}>
              <ReactTooltip
                id="pointsTooltip"
                type="warning"
                place="left"
                effect="solid"
                backgroundColor="gray"
                multiline={true}
                delayHide={0.5}
                clickable={true}
                wrapper="div"
              >
                {pointsText}
              </ReactTooltip>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

function ShareYourLink({ setFriendsAdded }) {
  return (
    <div
      className="column mt-2"
      style={{
        width: "100%",
        alignItems: "center",
      }}
    >
      <span className="txt-1-mid"> Share your group link</span>

      <input
        className="input-link mt-1"
        placeholder="joinneon.com/group/mhenry11"
        style={{ width: "15rem !important" }}
      />
      <button
        className="btn-inverse-round txt-1 "
        style={{
          marginTop: "1rem",
        }}
      >
        Copy link
      </button>
      <button
        className="btn-inverse-round txt-1 "
        style={{
          marginTop: "1rem",
        }}
      >
        Share via Text (SMS)
      </button>
      <button
        className="btn-inverse-round txt-1 "
        style={{
          marginTop: "1rem",
        }}
        onClick={() => setFriendsAdded(false)}
      >
        Done{" "}
      </button>
    </div>
  );
}

function CustomFriendsList({ friends }) {
  return (
    <div
      className="mt-1 neon-color"
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2rem !important",
        maxWidth: "350px",
        margin: "auto",
        width: "100%",
      }}
    >
      {friends.map((friend, index) => (
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}
          key={index}
        >
          <div
            style={{
              paddingInline: "0.60rem",
              paddingBlock: ".15rem",
              border: "1px solid ",
              borderRadius: "0.5rem",
              fontSize: "0.83rem",
              fontWeight: "bold",
              width: "8rem",
              marginRight: "1rem",
              height: "1rem",
              lineHeight: "1rem",
              whiteSpace: "nowrap !important",
            }}
          >
            <span>{friend.name}</span>
          </div>
          <div
            style={{
              flexDirection: "column",
              width: "70%",
              textAlign: "left",
              paddingLeft: friend.name ? "unset" : "0.5rem",
            }}
          >
            <span className="txt-s">{friend.points}</span>
          </div>
          <span className="white">...</span>
        </div>
      ))}
      <div style={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
        <div
          style={{
            paddingInline: "0.60rem",
            paddingBlock: ".15rem",
            border: "1px dashed ",
            borderRadius: "0.5rem",
            fontSize: "0.83rem",
            fontWeight: "bold",
            width: "8rem",
            marginRight: "1rem",
            height: "1rem",
            lineHeight: "1rem",
            whiteSpace: "nowrap !important",
          }}
        >
          <span>ADD</span>
        </div>
        <div
          style={{
            flexDirection: "column",
            textAlign: "left",
            paddingLeft: "0.5rem",
            width: "70%",
          }}
        >
          <span className="txt-s white">
            Your friends show here when they join your group
          </span>
        </div>
        <span className="white">...</span>
      </div>
    </div>
  );
}
