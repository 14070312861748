import { DocsTabs } from "../components/DocsTabs";
import { GeneralDataTab } from "../components/GeneralDataTab";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess, setJwt } from "../Store/Login/Reducers";
import io from "socket.io-client";
import { isMobile } from "react-device-detect";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import { FoundingMember } from "../components/FoundingMember";
import { SettingsTabs } from "../components/SettingsTabs";
import { handleToken } from "../helpers/Auth";
import { useHistory } from "react-router-dom";
import { ChangeEmail } from "../components/ChangeEmail";
import { ChangePhone } from "../components/ChangePhone";

export default function Settings({ selectedTab }) {
  const [error, setError] = useState("");
  const [documents, setDocs] = useState(null);
  const [backButton, showBackButton] = useState(false);
  const [verified, setVerified] = useState(false);
  const [filter, setFilter] = useState("years");
  const [saved, setSaved] = useState(false);
  const [showDocs, setShowDocs] = useState(false);
  const [statmentTabs, setStatementTabs] = useState([]);
  const [tab, setTab] = useState(
    selectedTab === "general"
      ? "general"
      : selectedTab === "PremiumAccess"
      ? "PremiumAccess"
      : selectedTab === "docs"
      ? "docs"
      : "InvestorsProfile"
  );
  const dispatch = useDispatch();
  let { user, vfp, access_token } = useSelector((state) => state.login);
  const [form, setForm] = useState({
    nickname: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    dob: "DD/MM/YYYY",
    ssn: "",
  });
  const premiumMonths = 6;
  const [titleScreen, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  //sets values to form
  const changeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  //close modal
  function handleClose() {
    dispatch(closeModal());
    setTab("general");
  }

  useEffect(() => {
    showBackButton(false);
    setStatementTabs([]);
  }, [tab === "docs"]);

  const handleInvestor = () => {
    setTab("InvestorsProfile");
    dispatch(openModal({ modal: "investor", origin: "settings" }));
  };

  //update nickname
  const handleSave = () => {
    const data = {
      phone_number: user.phone_number,
      neon_nickname: form.nickname,
    };
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.UPDATE_USER,
        },
      })
      .then((res) => {
        if (res.data.status) {
          let data = {
            user: res.data.user,
            vfp: vfp,
          };
          dispatch(loginSuccess(data));
        }
      })
      .catch(() => {});
  };

  const handleDocs = (docs) => {
    let filteredDocs = [];
    let years = {};
    for (let doc of docs) {
      if (doc.documents.length > 0) {
        let concat = "01 ".concat(doc.date);
        let date = new Date(concat);
        let year = date.getFullYear();
        if (!years[year]) {
          years[year] = [];
        }
        years[year].push(doc);
      }
    }
    filteredDocs = Object.entries(years).map(([k, v]) => ({
      [k]: v,
      year: k,
      docs: v,
    }));
    if (filteredDocs.length > 0) {
      setDocs(filteredDocs);
      setStatementTabs(
        statmentTabs.length > 0
          ? statmentTabs.concat({ filter: filter, doc: filteredDocs })
          : [{ filter: filter, doc: filteredDocs }]
      );
      setShowDocs(true);
    } else {
      setShowDocs(false);
    }
  };

  const handleTrades = (docs) => {
    let filteredDocs = [];
    let years = {};
    for (let doc of docs) {
      let dateStr = doc.displayName.replace(" Trade Confirm", "");
      let date = new Date(dateStr);
      let year = date.getFullYear();
      let month = date.toLocaleString("en-US", {
        month: "long",
      });
      if (!years[year]) {
        years[year] = {};
      }
      if (!years[year][month]) {
        years[year][month] = [];
      }
      years[year][month].push(doc);
    }
    filteredDocs = Object.entries(years).map(([k, v]) => ({
      year: k,
      docs: v,
    }));

    if (filteredDocs.length > 0) {
      setDocs(filteredDocs);
      setShowDocs(true);
      showBackButton(true);
      setStatementTabs(
        statmentTabs.length > 0
          ? statmentTabs.concat({ filter: filter, doc: filteredDocs })
          : [{ filter: filter, doc: filteredDocs }]
      );
    } else {
      setShowDocs(false);
    }
  };

  const showDocsByDay = (docs) => {
    setDocs(docs);
    setFilter("days");
    setStatementTabs(statmentTabs.concat({ filter: "days", doc: docs }));
  };

  const showDocsByMonth = (docs) => {
    let filteredDocs = [];
    if (!Array.isArray(docs)) {
      filteredDocs = Object.entries(docs).map(([month, docs]) => ({
        date: month,
        documents: docs,
      }));
      setDocs(filteredDocs);
      setStatementTabs(
        statmentTabs.concat({ filter: "months", doc: filteredDocs })
      );
    } else {
      setDocs(docs);
      setStatementTabs(statmentTabs.concat({ filter: "months", doc: docs }));
    }
    setFilter("months");
  };

  const getTradeConfirms = async (type) => {
    setLoading(true);
    const now = Date.now();
    let nowIso = new Date(now).toISOString();
    const nowWithoutMili = nowIso.includes(".")
      ? nowIso.split(".")[0] + "Z"
      : nowIso;
    const data = {
      phone_number: user.phone_number,
      type: type,
      from: "2022-04-01T00:00:00Z",
      to: nowWithoutMili,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_DOCS,
        },
      })
      .then((res) => {
        const docs = res.data.data[0].documents;
        setLoading(false);

        if (docs.length > 0) {
          handleTrades(docs);
        } else {
          showDocs(false);
        }
      })
      .catch((error) => {});
  };

  const handleBack = () => {
    setLoading(true);
    if (statmentTabs.length == 1 && backButton) {
      showBackButton(false);
      setShowDocs(false);
      setStatementTabs([]);
    } else {
      const copy = [...statmentTabs];
      copy.pop();
      setFilter(copy[copy.length - 1].filter);
      setDocs(copy[copy.length - 1].doc);
      setStatementTabs(copy);
    }
    setLoading(false);
  };

  const getDocuments = async (type) => {
    setLoading(true);
    const data = {
      phone_number: user.phone_number,
      type: type,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_DOCS,
        },
      })
      .then((res) => {
        const docs = res.data.data;
        handleDocs(docs);
        if (!backButton && res.data.data[0].documents.length > 0) {
          showBackButton(true);
        }
        setLoading(false);
      })
      .catch((error) => {});
  };

  const downloadDoc = async (fileKey) => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number, fileKey: fileKey },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url: process.env.REACT_APP_CORE_API + URIS.GET_DOC,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setTimeout(() => {
            window.open(res.data.url, "_blank");
          });
        }
      })
      .catch((error) => {});
  };

  const renewJwt = async (newVfp) => {
    const newToken = await handleToken(newVfp, form.phone_number, history);
    dispatch(setJwt(newToken));
  };

  const updateUserInfo = async (newVfp, type) => {
    const data = {
      new_phone_number: type === "phone" ? form.phone_number : null,
      email: type === "email" ? form.email : null,
      phone_number: user.phone_number,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.UPDATE_USER,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSaved(true);
          let data = {
            user: res.data.user,
            vfp: newVfp,
          };
          if (type === "phone") {
            renewJwt(newVfp);
          }
          dispatch(loginSuccess(data));
        }
      })
      .catch((err) => {});
  };

  const socketOn = (socket) => {
    try {
      socket.on("prove_validation", function (message) {
        if (message.status === "True" || message.status) {
          //checkForUserInfo(key);
          //setVerified(false);
          updateUserInfo(message.vfp, "phone");
          socket.close();
          socket.disconnect();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  function waitForResponse() {
    const mode = isMobile ? "mobile" : "web";
    setLoading(true);
    const socket = io(process.env.REACT_APP_PROVE_API);

    socket.emit("init_private_channel", {
      phone_number:
        tab === "ChangeEmail" ? user.phone_number : form.phone_number,
      mode: mode,
      origin: "settings",
    });
    socketOn(socket);
  }
  const handleSavePersonal = async () => {
    let type = "";
    if (tab === "ChangeEmail") {
      type = "email";
    } else if (tab === "ChangePhone") {
      type = "phone";
    }
    setVerified(false);
    setSaved(false);
    const data = {
      phone_number: user.phone_number,
      birthdate: form.dob,
      ssn: form.ssn,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.VERIFY_ID,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setVerified(true);
          if (type === "phone") {
            waitForResponse();
          } else {
            updateUserInfo("", type);
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setTitle(tab === "PremiumAccess" ? "Premium Access" : "Account Settings");
  }, [tab]);

  const contentRouter = () => {
    switch (tab) {
      case "general":
        return (
          <GeneralDataTab
            form={form}
            changeHandler={changeHandler}
            error={error}
            setTab={setTab}
            handleSave={handleSave}
          />
        );

      case "docs":
        return (
          <DocsTabs
            showDocs={showDocs}
            loading={loading}
            getTradeConfirms={getTradeConfirms}
            getDocuments={getDocuments}
            filter={filter}
            documents={documents}
            showDocsByMonth={showDocsByMonth}
            downloadDoc={downloadDoc}
            showDocsByDay={showDocsByDay}
            setDocs={setDocs}
          />
        );

      case "ChangeEmail":
        return (
          <ChangeEmail
            form={form}
            user={user}
            changeHandler={changeHandler}
            error={error}
            verified={verified}
            saved={saved}
            handleSavePersonal={handleSavePersonal}
          />
        );

      case "ChangePhone":
        return (
          <ChangePhone
            changeHandler={changeHandler}
            error={error}
            form={form}
            verified={verified}
            saved={saved}
            handleSavePersonal={handleSavePersonal}
          />
        );

      case "PremiumAccess":
        return <FoundingMember premiumMonths={premiumMonths} />;
      default:
        return null;
    }
  };

  return (
    <div className="settings">
      <div
        style={{
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingInline: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {backButton && tab === "docs" ? (
            <span
              className="txt-1 neon-color pointer"
              onClick={() => handleBack()}
            >
              Back
            </span>
          ) : null}
          <span className="title white ">{titleScreen}</span>
        </div>
        <span
          className="title white pointer"
          style={{
            textAlign: "right",
            width: "5%",
            right: "0",
          }}
          onClick={() => handleClose()}
        >
          X
        </span>
      </div>
      <div
        className="card settings-Card"
        style={{ height: "auto", width: "auto" }}
      >
        <SettingsTabs
          setFilter={setFilter}
          setShowDocs={setShowDocs}
          setTab={setTab}
          tab={tab}
          handleInvestor={handleInvestor}
        />

        {contentRouter()}
      </div>
    </div>
  );
}
