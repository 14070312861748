import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import kicks from "../images/kicks-bg.png";
import TriangleSpinner from "./TriangleSpinner";

export default function NeonBenefit({ setTab }) {
  let { user } = useSelector((state) => state.login);
  let { wallet } = useSelector((state) => state.dashboard);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
        marginTop: "1rem",
      }}
    >
      <div className="card-wrapper mt-1">
        <div>
          <span className="futura-mid-1pt17 white">This Month's Drop</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          {user ? (
            <div style={{ minHeight: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                  }}
                >
                  <img width="100%" src={kicks} />
                  <div
                    style={{
                      textAlign: "left",
                      lineHeight: "1rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    {wallet && wallet.kicks.amount < 3 ? (
                      <span
                        className="white txt-1-mid"
                        style={{ lineHeight: "1.8" }}
                      >
                        You earn an entry for every $1 you buy in Stocks.
                        <br />
                        Win the drop or $200 to buy stocks.
                      </span>
                    ) : (
                      <span
                        id="entries-count"
                        className="white txt-1-mid"
                        style={{ lineHeight: "1.8" }}
                      >
                        {wallet
                          ? "You have " +
                            wallet.kicks.amount +
                            " entries to win this drop. You earn an entry every $1 you buy in Stocks. Get more "
                          : ""}
                        <span
                          className="underline pointer"
                          onClick={() => setTab("invest")}
                        >
                          here.
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}
