import React from "react";
import { openModal } from "../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";

export default function UnlocksModule() {
  const dispatch = useDispatch();
  const swiperCircles = [1, 2, 3, 4];
  let { user } = useSelector((state) => state.login);
  let { set_letter } = useSelector((state) => state.unlocks);
  const userUnlocksSetLetter = user.set_state ? user.set_state[0] : "A";
  const userUnlocksNumber = user.set_state
    ? parseInt(user.set_state.slice(-1))
    : 1;
  const sameSet = userUnlocksSetLetter === set_letter ? true : false;
  const handleOpen = (e) => {
    ReactGA.event({
      category: "UNLOCKS",
      action: "OPENED",
    });
    dispatch(openModal({ modal: "unlocks" }));
  };

  return (
    <div
      className="card"
      style={{ minHeight: "auto", maxWidth: "auto !important" }}
    >
      <div
        className="card-wrapper"
        style={{ minHeight: "auto", maxWidth: "300px !important" }}
      >
        <div className="club-benefits-padding">
          <span className="futura-mid-1pt17 white">
            Unlocks{" "}
            <span className="txt-1-mid">(Financial education nuggets)</span>
          </span>
        </div>
        {isMobile ? (
          <Swiper
            pagination={true}
            modules={[Virtual, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            virtual
            className="mySwiper"
            onSlideChange={(e) => {
              handleOpen();
            }}
          >
            {swiperCircles.map((circle, index) => (
              <SwiperSlide key={index} virtualIndex={index}>
                {index === 0
                  ? unlocksContent(
                      handleOpen,
                      sameSet,
                      userUnlocksNumber,
                      user.set_state
                    )
                  : emptyBox()}
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          unlocksContent(handleOpen, sameSet, userUnlocksNumber, user.set_state)
        )}
      </div>
    </div>
  );
}

const unlocksContent = (handleOpen, sameSet, userUnlocksNumber, setState) => {
  return (
    <div className="sub-card-dark txt-1-mid" style={{ textAlign: "left" }}>
      <div>
        <span className="white">1 of 4</span>
      </div>
      <br />
      <div>
        <span className="white" style={{ marginTop: "4rem !important" }}>
          Generational wealth had a head start. We're here to level up.
        </span>
      </div>
      <br />
      <div>
        <span className="white">
          {(!sameSet && userUnlocksNumber >= 3) ||
          (userUnlocksNumber < 3 && sameSet) ||
          setState === null
            ? "As a bonus, you'll get 10 entries into this month's giveaway after viewing these 3 unlocks."
            : "Congrats, you got 10 entries into this month's giveaway just for viewing these 3 unlocks."}
        </span>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          id="unlocks-button"
          className="btn-inverse txt-1"
          style={{
            width: "60%",
            borderRadius: "500px",
            height: "2.5rem",
            padding: "unset",
            marginBottom: "2rem",
          }}
          onClick={() => handleOpen()}
        >
          View this month's unlocks
        </button>
      </div>
    </div>
  );
};

const emptyBox = () => {
  return (
    <div
      className="sub-card-dark txt-1-mid"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "240px",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <span className="white" style={{ marginTop: "4rem !important" }}>
        Loading...
      </span>
    </div>
  );
};
