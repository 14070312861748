import React from "react";

export function SettingsTabs({
  setFilter,
  setShowDocs,
  setTab,
  tab,
  handleInvestor,
}) {
  return (
    <>
      {" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <div>
          <span
            className="txt-s white white-space pointer"
            onClick={() => {
              setFilter("years");
              setShowDocs(false);
              setTab("general");
            }}
            style={
              tab === "general"
                ? {
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            Settings |
          </span>
        </div>
        <div>
          <span
            className="txt-s white white-space pointer"
            onClick={() => setTab("docs")}
            style={
              tab === "docs"
                ? {
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {" "}
            Documents |
          </span>
        </div>
        <div>
          <span
            className="txt-s white white-space pointer"
            onClick={() => handleInvestor()}
            style={
              tab === "InvestorsProfile"
                ? {
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {" "}
            Profile |
          </span>
        </div>
        <div>
          <span
            className="txt-s white white-space pointer"
            onClick={() => setTab("PremiumAccess")}
            style={
              tab === "PremiumAccess"
                ? {
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {" "}
            Club benefits
          </span>
        </div>
      </div>
      );
    </>
  );
}
