import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../Store/Modal/Reducers";
import axios from "axios";
import { URIS } from "../../../Constants/Endpoints";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { formatDateMonthYear } from "../../../helpers/Dates/DatesFunctions";
export default function CardDetail() {
  const dispatch = useDispatch();
  let { modal, origin } = useSelector((state) => state.modal);
  let { user, access_token } = useSelector((state) => state.login);
  const [cardInfo, setCardInfo] = useState({
    cardNumber: null,
    cardExpiration: null,
    securityCode: null,
  });
  function close() {
    dispatch(closeModal());
  }

  function goBack() {
    dispatch(openModal({ modal: "CardDashboard" }));
  }

  const getCardInfo = async () => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_INFO,
        },
      })
      .then((res) => {
        const response = res;
        if (response.data.status) {
          const cardResults =
            response.data.results.data.getCardAccountsResponse.cards[0];
          const cardAuth = response.data.results.data.getCardAuthDataResponse;
          const cardNumber = cardResults.cardNumber;
          const cardExpiration = formatDateMonthYear(cardResults.expiryDate);
          const securityCode = cardAuth.cvv2 ? cardAuth.cvv2 : "0000";

          setCardInfo({
            cardNumber: cardNumber,
            cardExpiration: cardExpiration,
            securityCode: securityCode,
          });
        }
      });
  };

  useEffect(() => {
    getCardInfo();
  }, []);

  return (
    <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
      <div className="playlist-component white">
        <div style={{ padding: "1rem", textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "left",
            }}
          >
            <span
              className="txt-1 neon-color pointer"
              onClick={() =>
                dispatch(openModal({ modal: origin, origin: modal }))
              }
            >
              Back
            </span>
            <div
              className="futura-bold white "
              style={{
                maxwidth: "90%",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              <span style={{ width: "100%" }}>Card details</span>
              <span
                id="close-single-modal-button"
                className="title white pointer"
                style={{
                  textAlign: "right",
                  width: "5%",
                  right: "0",
                }}
                onClick={() => {
                  dispatch(openModal({ modal: origin, origin: modal }));
                }}
              >
                X
              </span>
            </div>
          </div>
          <div className="mock-Card" style={{ marginInline: "auto" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
              marginTop: "2rem",
              fontSize: 12,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>Purchase APR (as of feb 28, 2023)</div>
              <div>26%</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <div>Card Number</div>
              {cardInfo.cardNumber ? (
                <div>{cardInfo.cardNumber}</div>
              ) : (
                <Skeleton width={"50px"} />
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <div>Card Expiration</div>
              {cardInfo.cardExpiration ? (
                <div>{cardInfo.cardExpiration}</div>
              ) : (
                <Skeleton width={"50px"} />
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <div>Security Code</div>
              {cardInfo.securityCode ? (
                <div>{cardInfo.securityCode}</div>
              ) : (
                <Skeleton width={"50px"} />
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
