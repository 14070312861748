import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { URIS } from "../Constants/Endpoints";
import { setBrand, setBrands } from "../Store/Brands/Reducers";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import DottedWell from "../components/DottedWell";
import DynamicAmount from "../components/DynamicAmount";
import PlaidLink from "../components/Plaid";
import ToolTip from "../components/ToolTip";
import TransactionDetail from "../components/TransactionDetail";
import TriangleSpinner from "../components/TriangleSpinner";
import { refresh } from "../helpers/Auth";

export default function YourStocks({ wallet, preset }) {
  const history = useHistory();
  const dispatch = useDispatch();
  let { user, access_token, refresh_token } = useSelector(
    (state) => state.login
  );
  const [transactionType, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(preset ? "CASH" : "BUY");
  const [soldTransactions, setSoldTransactions] = useState(null);
  const [cashTransactions, setCashTransactions] = useState(null);
  const [view, setView] = useState("");
  const [detail, setDetail] = useState(null);
  const [notifications, setNotifictions] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [disablePagination, setDisablePagination] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cryptoBalance, setCryptoBalance] = useState({
    name: "FREE CRYPTO",
    today_value: 0,
    total_gains: 0,
    since_purchase_percent: 0,
    cryptos: wallet ? wallet.free_crypto : "",
  });
  const playlists = wallet ? wallet.playlists : null;
  const [userBalancePerPage, setUserBalanceArray] = useState([
    {
      page: 1,
      initial_balance: 0.0,
    },
  ]);
  const [active, setActive] = useState(0);
  const brands = wallet ? wallet.brands : null;
  useEffect(() => {
    if (typeof wallet !== "object") {
      return;
    }
  }, [wallet]);

  useEffect(async () => {
    await refresh(refresh_token);
    if (preset) {
      handleCashTab(1);
      return;
    }
    axios
      .get(process.env.REACT_APP_AUTH_PROXY_URL, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API +
            URIS.CORPORATE_ACTIONS +
            "?user_id=" +
            user.id,
        },
      })
      .then((resp) => {
        if (resp.data.status) {
          setNotifictions(resp.data.notifications);
        }
      })
      .catch((err) => {});
    getPlaidToken();
  }, [preset]);

  const getPlaidToken = async () => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url:
              process.env.REACT_APP_CORE_API + URIS.CREATE_PLAID_TOKEN,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setLinkToken(data.link_token);
      })
      .catch((error) => {
        if ([404, 502, 500, 400, 403].includes(error.response.status)) {
          history.push("/404");
        }
      });
  };

  let pendingText;
  const tableRouter = () => {
    switch (filter) {
      case "BUY":
        pendingText =
          "*Stock purchases outside normal hours will be awarded on the next available trading day (M-Fri 9:30AM-4PM ET)";
        return assetsTable;
      case "SELL":
        pendingText = "*Estimated to arrive in your wallet in 1-5 days.";
        return soldTable;
      case "CASH":
        pendingText =
          "*Pending amounts will be included in your Cash balance within 1-5 business days.";
        return cashTable;
    }
  };

  const handleOpenSingle = (stock) => {
    dispatch(setBrands(null));
    dispatch(setBrand({ brand: stock }));
    dispatch(openModal({ modal: "transactionByStock" }));
  };
  const handleOpenGroup = async (brands) => {
    dispatch(setBrands(brands));
    dispatch(openModal({ modal: "stockGroup" }));
  };
  const handleOpenDetail = (detail, type) => {
    setType(type);
    setView("detail");
    if (type === "Sold") {
      const detailArray = [
        {
          header: "Date and time",
          content: new Date(detail.created_at).toLocaleString("us-EN", {
            timeZone: "America/New_York",
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }),
        },
        {
          header: "Amount",
          content:
            "$" +
            parseFloat(detail.amount_dollars).toFixed(2) +
            " (" +
            detail.share_quantity +
            ")",
        },
        {
          header: "Reference number",
          content: detail.order_id,
        },
        {
          header: "Deposited to",
          content: "Connected Bank Account",
        },
        {
          header: "Type",
          content: detail.transaction_type,
        },
      ];
      setDetail(detailArray);
    } else if (type === "Cash") {
      const detailArray = [
        {
          header: "Date and time",
          content: new Date(detail.date).toLocaleString("us-EN", {
            timeZone: "America/New_York",
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }),
        },
        {
          header: "Amount",
          content: "$" + parseFloat(detail.amount).toFixed(2),
        },
        {
          header: "Reference number",
          content: "N/A",
        },
        {
          header: "Deposited to",
          content: "Connected Bank Account",
        },
        {
          header: "Type",
          content: detail.type,
        },
      ];
      setDetail(detailArray);
    }
  };

  const handleSoldTab = async () => {
    const data = {
      phone_number: user.phone_number,
      page: 1,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.SOLD_TAB,
        },
      })
      .then((response) => {
        setFilter("SELL");
        setSoldTransactions(response.data.page.detail);
      })
      .catch((error) => {});
  };

  const handleCashTab = async (nextPage) => {
    let tempArray = userBalancePerPage;
    if (nextPage <= 0) {
      nextPage = 1;
    }
    if (nextPage === 1 && tempArray.length > 1) {
      tempArray.slice(0, -(tempArray.length - 1));
    }
    setDisablePagination(true);
    const data = {
      phone_number: user.phone_number,
      initial_balance: parseFloat(
        tempArray.find((item) => item.page === nextPage).initial_balance
      ),
      page: nextPage,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CASH_TAB,
        },
      })
      .then((response) => {
        setNextPage(response.data.page.next);
        setPrevPage(response.data.page.prev);
        setCurrentPage(response.data.page.current);
        setCashTransactions(response.data.page.detail);
        tempArray.push({
          page: nextPage + 1,
          initial_balance: response.data.page.ending_balance,
        });
        setUserBalanceArray(tempArray);
        setFilter("CASH");
        setDisablePagination(false);
      })
      .catch((error) => {});
  };
  const averageGainsTxt = (
    <span
      className="white"
      style={{
        textAlign: "justify !important",
        textJustify: "inter-word !important",
      }}
    >
      This is the difference between today's market value of your stocks versus
      when you bought them.
    </span>
  );

  const assetsTable = (
    <table
      className="table white"
      style={{
        overflowX: "auto !important",
      }}
    >
      <thead className="table white txt-1-mid">
        <tr>
          <th>You own</th>
          <th>Today's value</th>
          <th>
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Average gains{"   "}
              <ToolTip text={averageGainsTxt} place={"bottom"} />
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {playlists &&
          playlists.length > 0 &&
          playlists.map((stock, index) => (
            <tr
              className="txt-s tr-border pointer"
              onClick={() =>
                stock.portfolio.length > 0 ? handleOpenGroup(stock) : null
              }
              key={index}
            >
              <td>{stock.name}</td>
              <td>${stock.today_value.toFixed(2)}</td>
              <td>
                <DynamicAmount
                  amount={stock.average_gains.toFixed(2)}
                  text={""}
                />
              </td>
              <td>
                {stock.portfolio.length > 0 ? (
                  <MdOutlineKeyboardArrowRight />
                ) : null}
              </td>
            </tr>
          ))}

        {brands &&
          brands.length > 0 &&
          brands.map((brandStock, index) => (
            <tr
              className="txt-s tr-border pointer"
              key={index}
              onClick={() => {
                handleOpenSingle(brandStock);
              }}
            >
              <td>{brandStock.brand_name}</td>
              <td>${brandStock.today_value.toFixed(2)}</td>
              <td>
                <DynamicAmount
                  amount={brandStock.average_gains.toFixed(2)}
                  text={""}
                />
              </td>
              <td>
                <MdOutlineKeyboardArrowRight />
              </td>
            </tr>
          ))}

        <tr className="txt-s pointer" onClick={() => handleCashTab(1)}>
          <td id="wallet_cash_button">CASH</td>
          <td>${wallet ? wallet.available_cash.toFixed(2) : null}</td>
          <td>--</td>
          <td>
            <MdOutlineKeyboardArrowRight />
          </td>
        </tr>
      </tbody>
    </table>
  );

  const soldTable = (
    <table
      className="table white"
      style={{
        overflowX: "auto !important",
      }}
    >
      <thead className="table white txt-1-mid">
        <tr>
          <th>Date</th>
          <th>Symbol</th>
          <th>Shares</th>
          <th>Earned</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {soldTransactions &&
          soldTransactions.map((trans, index) => (
            <tr
              className={
                soldTransactions.length - 1 === index
                  ? "txt-s pointer"
                  : "txt-s tr-border pointer"
              }
              key={index}
              onClick={() =>
                trans.transaction_status === "NEW"
                  ? null
                  : handleOpenDetail(trans, "Sold")
              }
            >
              <td>
                {new Date(trans.created_at).toLocaleString("us-EN", {
                  timeZone: "America/New_York",
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                })}
              </td>
              <td>{trans.quote_symbol}</td>
              <td style={{ display: "flex", flexDirection: "row" }}>
                {parseFloat(trans.share_quantity).toFixed(8)}
                {trans.transaction_status === "NEW" ? "*" : ""}
              </td>
              <td>${parseFloat(trans.amount_dollars).toFixed(2)}</td>
              <td>
                <MdOutlineKeyboardArrowRight />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
  const cashTable = (
    <div>
      <table
        className="table white"
        style={{
          overflowX: "auto !important",
        }}
      >
        <thead className="table white txt-1-mid">
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Balance</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cashTransactions &&
            cashTransactions.map((trans, index) => (
              <tr
                className={
                  cashTransactions.length - 1 === index
                    ? "txt-s pointer"
                    : "txt-s tr-border pointer"
                }
                key={index}
                onClick={() =>
                  trans.pending ? null : handleOpenDetail(trans, "Cash")
                }
              >
                <td>
                  {new Date(trans.date).toLocaleString("us-EN", {
                    timeZone: "America/New_York",
                    month: "2-digit",
                    day: "2-digit",
                    year: "2-digit",
                  })}
                </td>
                <td>{trans.type}</td>
                <td
                  className={trans.amount[0] === "-" ? "white" : "neon-color"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {trans.amount[0] === "-"
                    ? "-$" + trans.amount.substring(1)
                    : "+$" + trans.amount}
                  {trans.status === "PENDING" ? "*" : ""}
                </td>
                <td>
                  $
                  {trans.cash[0] === "-" ? trans.cash.substring(1) : trans.cash}
                </td>
                <td>
                  <MdOutlineKeyboardArrowRight />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {!cashTransactions || (prevPage == null && nextPage == null) ? null : (
        <div
          className="white txt-1-mid"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: "2rem",
          }}
        >
          {prevPage != null ? (
            <MdOutlineKeyboardArrowLeft
              onClick={() =>
                !disablePagination ? handleCashTab(currentPage - 1) : null
              }
              className="pointer"
            />
          ) : null}
          <span style={{ marginInline: "1rem" }}>{currentPage}</span>
          {nextPage != null ? (
            <MdOutlineKeyboardArrowRight
              onClick={() =>
                !disablePagination ? handleCashTab(currentPage + 1) : null
              }
              className="pointer"
            />
          ) : null}
        </div>
      )}
    </div>
  );

  const updateBank = !loading ? (
    <span className="white"> Tap here to add your bank account</span>
  ) : (
    <div>
      <TriangleSpinner />
    </div>
  );

  const walletMessage =
    (notifications && notifications.length > 0) ||
    ((user.neon_userflow_status === "MEMBER-FUNDING-ISSUE" ||
      !user.registered_in_plaid) &&
      linkToken) ? (
      <div>
        <div
          className="sub-card txt-mid-1"
          style={{
            padding: "1rem",
            textAlign: "center",
            lineHeight: "0.8rem",
            marginTop: "2rem",
            maxWidth: "350px",
          }}
        >
          <span className="txt-s white">
            {user.neon_userflow_status === "MEMBER-FUNDING-ISSUE" ||
            !user.registered_in_plaid
              ? updateBank
              : notifications
              ? notifications[active]
              : null}
          </span>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <DottedWell
            setActive={setActive}
            active={active}
            data={notifications}
          />
        </div>
      </div>
    ) : null;

  useEffect(() => {
    if (
      (notifications && notifications.length > 0) ||
      ((["MEMBER-NEED-PLAID-UPDATE", "MEMBER-FUNDING-ISSUE"].includes(
        user.neon_userflow_status
      ) ||
        !user.registered_in_plaid) &&
        linkToken)
    ) {
      setShowMessage(true);
    }
  }, [notifications, user, linkToken]);

  return view === "" ? (
    <div className="modal">
      <div
        style={{
          paddingTop: "1rem",
          width: "100%",
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span className="title white" style={{ width: "100%" }}>
          Your ownership
        </span>
        <span
          className="title white pointer"
          style={{
            textAlign: "right",
            width: "5%",
            right: "0",
          }}
          onClick={() => dispatch(closeModal())}
        >
          X
        </span>
      </div>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "2rem",
            }}
          >
            <span
              className={
                " white pointer " + (filter === "BUY" ? "txt-1-mid" : "txt-1")
              }
              onClick={() => setFilter("BUY")}
            >
              ASSETS
            </span>
            <span className="bold white" style={{ whiteSpace: "pre" }}>
              {"  "}|{"  "}
            </span>
            <span
              className={
                "white pointer " + (filter === "SELL" ? "txt-1-mid" : "txt-1")
              }
              onClick={() => handleSoldTab()}
            >
              SOLD
            </span>
            <span className="bold white" style={{ whiteSpace: "pre" }}>
              {"  "}|{"  "}
            </span>
            <span
              className={
                " white pointer " + (filter === "CASH" ? "txt-1-mid" : "txt-1")
              }
              onClick={() => handleCashTab(1)}
            >
              CASH
            </span>
          </div>

          {showMessage && filter === "BUY" ? (
            <PlaidLink
              token={linkToken}
              setLoading={setLoading}
              plaidNotification={true}
            >
              {" "}
              {walletMessage}{" "}
            </PlaidLink>
          ) : null}
          <div style={{ overflowX: "auto !important" }}>{tableRouter()}</div>
        </div>
        <div
          style={{
            paddingTop: "2rem",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {filter === "CASH" ? (
            <button
              id="withdraw-button-cash"
              className="btn-inverse-round"
              onClick={() => dispatch(openModal({ modal: "cashOut" }))}
            >
              Withdraw cash
            </button>
          ) : null}
        </div>
      </div>
      <div style={{ textAlign: "left", marginTop: "2rem", maxWidth: "20rem" }}>
        <span className="txt-s white">{pendingText}</span>
      </div>
    </div>
  ) : (
    <TransactionDetail
      header={detail[4].content}
      data={detail}
      backFn={() => setView("")}
      trade={true}
      Type={transactionType}
    />
  );
}
