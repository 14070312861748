import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { investings } from "../Constants/WeeklyInvesting";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import CompleteLabel from "../components/CompleteLabel";
import PendingStockText from "../components/PendingStockText";
import DynamicAmount from "../components/DynamicAmount";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactGA from "react-ga4";
import AddBank from "../components/AddBank";
import { closeModal } from "../Store/Modal/Reducers";

export default function OwnBrand({ brand }) {
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(closeModal());
  }
  const vfp = useSelector((state) => state.login.vfp);
  const userId = useSelector((state) => state.login.user_id);
  const brands = useSelector((state) => state.dashboard.brands);
  const [brandInfo, setBrandInfo] = useState();
  const [view, setView] = useState(1);
  const [investingAmount, setAmount] = useState(0);
  const [acctAdded, setAcctAdded] = useState(false);
  const [paid, setPaid] = useState(false);
  const [owned, setOwned] = useState(false);
  const [pending, setPending] = useState(false);
  const [stock, setStock] = useState({
    vfp: vfp,
    user_id: userId,
    type: "BUY",
    amount: 0,
    symbol: "",
  });
  const handleAmount = (amount) => {
    setAmount(amount);
    setStock({ ...stock, amount: amount });
  };
  function handleView() {
    ReactGA.event({
      category: "BRAND_INTRO",
      action: "CLICKED",
    });
    setView(2);
  }
  const handleAddAcct = () => {
    ReactGA.event({
      category: "STOCK",
      action: "ADD_BANK_CLICKED",
    });
    setAcctAdded(true);
  };
  useEffect(() => {
    ReactGA.event({
      category: "BRAND_INTRO",
      action: "VIEWED",
    });
    if (brands !== []) {
      const singleBrand = brands.find((x) => x.name === brand.name);
      setBrandInfo(singleBrand);
      setStock({ ...stock, symbol: "ADS" });
    }
  }, [brand]);

  const buyStock = async () => {
    ReactGA.event({
      category: "STOCK",
      action: "OWN_IT_CLICKED",
    });
    if (stock.amount > 0) {
      await axios
        .post(process.env.REACT_APP_CORE_API + URIS.TRADE_STOCK, stock)
        .then((response) => {
          setPaid(true);
          setPending(true);
          if (response.data.transaction_status === "pending") {
            setPending(true);
          }
        })
        .catch((error) => {});
    }
  };

  const ownItButton = (
    <div>
      <button
        onClick={() =>
          view === 1 ? handleView() : paid ? dispatch(closeModal()) : buyStock()
        }
        className="btn"
        style={{ fontSize: "1rem", width: "100%" }}
      >
        {paid ? "Close" : "Own it"}
      </button>
    </div>
  );

  const saveAndPauseButtons = (
    <div>
      <div>
        <button
          onClick={() =>
            view === 1
              ? handleView()
              : paid
              ? dispatch(closeModal())
              : buyStock()
          }
          className="btn"
          style={{ fontSize: "1rem", width: "100%" }}
        >
          Save
        </button>
      </div>
      <div style={{ paddingTop: "1rem" }}>
        <button
          onClick={() =>
            view === 1
              ? handleView()
              : paid
              ? dispatch(closeModal())
              : buyStock()
          }
          className="btn"
          style={{ fontSize: "1rem", width: "100%" }}
        >
          Pause playlist
        </button>
      </div>
    </div>
  );

  const stockDescription = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify",
      }}
    >
      <div style={{ marginTop: "4rem" }}>
        <div style={{ maxWidth: "25rem" }}>
          <span className="sub-title white ">
            {brand.description || <Skeleton />}
          </span>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center", paddingTop: "2rem" }}>
        <span className="white">Changes in the past year: </span>
        {(
          <DynamicAmount
            amount={brandInfo ? brandInfo.difference_value.toFixed(2) : ""}
            percentage={
              brandInfo ? brandInfo.difference_percent.toFixed(2) : ""
            }
            customStyle={{}}
          />
        ) || <Skeleton duration={10} />}
      </div>
    </div>
  );

  const buyIt = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify",
      }}
    >
      <div style={{ marginTop: "1rem" }}>
        <div
          style={{
            maxWidth: "25rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <span className="sub-title white ">NYSE: VF</span>
          <span className="sub-title white ">
            ${brandInfo ? brandInfo.price : ""} {pending ? "*" : null}
          </span>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "2rem",
          fontSize: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <DynamicAmount
            amount={brandInfo ? brandInfo.difference_value.toFixed(2) : ""}
            percentage={
              brandInfo ? brandInfo.difference_percent.toFixed(2) : ""
            }
            customStyle={{}}
          />

          <span className="white" style={{ whiteSpace: "pre" }}>
            {" "}
            past year
          </span>
        </div>
        <div>
          <div style={{ paddingTop: "4rem" }}>
            <span className="white bold">Weekly investing amount</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "1rem",
              justifyContent: "center",
            }}
          >
            {investings.map((amount, index) => (
              <div
                key={index}
                className="flex-center-column"
                style={{
                  padding: "1rem",
                }}
              >
                <div style={{ width: "5rem", margin: "auto" }}>
                  <button
                    className={
                      investingAmount === amount ? "btn" : "btn-inverse"
                    }
                    style={{ width: "100%" }}
                    onClick={() => handleAmount(amount)}
                  >
                    ${amount}
                  </button>
                </div>
                <div
                  style={{ width: "5rem", margin: "auto", textAlign: "left" }}
                >
                  <span className="white txt-1">
                    Some text here to prove that there is text here
                  </span>
                </div>
              </div>
            ))}
          </div>
          <AddBank acctAdded={acctAdded} handleAddAcct={handleAddAcct} />
          {paid ? <CompleteLabel /> : null}
          {pending ? <PendingStockText /> : null}
        </div>
      </div>
    </div>
  );
  return (
    <div style={{ width: "70%", margin: "auto" }}>
      <div
        style={{
          paddingTop: "4rem",
          paddingInline: "1rem",
          textAlign: "center",
        }}
      >
        <div style={{ textAlign: "right" }}>
          <span className="title white pointer" onClick={() => handleClose()}>
            X
          </span>
        </div>
        <img src={brand.image} style={{ maxWidth: "10rem" }} />
      </div>
      {view === 1 ? stockDescription : buyIt}
      <div style={{ width: "100%", textAlign: "center", paddingTop: "2rem" }}>
        {owned ? saveAndPauseButtons : ownItButton}
        <div style={{ paddingTop: "2rem" }}>
          <span className="link" style={{ fontSize: "1rem" }}>
            See all stocks
          </span>
        </div>
      </div>
    </div>
  );
}
