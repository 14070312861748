import React, { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URIS } from "../../Constants/Endpoints";
import { closeModal, openModal } from "../../Store/Modal/Reducers";
import { setBrand } from "../../Store/Brands/Reducers";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReactTooltip from "react-tooltip";
import { Singles } from "../../Constants/Singles";

export default function InvestPoints() {
  const dispatch = useDispatch();
  const { brand } = useSelector((state) => state.brands);
  const { modal } = useSelector((state) => state.modal);
  const localBrand = Singles.find((single) => single.symbol === "VOO");
  const { user, access_token, vfp, refresh_token } = useSelector(
    (state) => state.login
  );
  const { origin } = useSelector((state) => state.modal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [proceedToBuy, setProceed] = useState(true);
  const [readMore, setReadMore] = useState(false);
  const [view, setView] = useState("");
  const handleClose = () => {
    if (origin === "transactionByStock") {
      dispatch(openModal({ modal: "transactionByStock", origin: "buyBrand" }));
    } else {
      dispatch(setBrand({ brand: null }));
      dispatch(closeModal());
    }
  };

  //for back button on top left corner
  const handleBack = () => {
    dispatch(openModal({ modal: "PointsOnPoints", origin: modal }));
  };

  const getDetail = async () => {
    let data = { phone_number: user.phone_number, symbol: "VOO" };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_BRAND_DETAILS,
        },
      })
      .then((res) => {
        let brandData = { brand: res.data.brand };
        brandData.brand["price_5y_ago"] = 45.85;
        brandData.brand["local_description"] = localBrand.description;

        dispatch(setBrand(brandData));
      })
      .catch(async (error) => {});
    setLoading(false);
  };

  useEffect(() => {
    if (brand === null) {
      getDetail();
    }
  }, [brand]);

  const getButtonAction = () => {};

  return (
    <>
      <div className="playlist-component white">
        <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
          <div style={{ padding: "1rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                textAlign: "left",
              }}
            >
              <span
                className="txt-1 neon-color pointer"
                onClick={() => handleBack()}
                style={{ padding: "0.5rem" }}
              >
                Back
              </span>
              <div
                style={{
                  maxwidth: "90%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                    marginRight: "1rem",
                    justifyContent: "center",
                  }}
                >
                  <img src={localBrand.image} width="50" />
                </div>
                <span
                  className="futura-mid-1pt17 white "
                  style={{ width: "100%" }}
                >
                  {brand !== null ? (
                    "TOP 500 BRANDS " + " (VOO)"
                  ) : (
                    <Skeleton width="5rem" />
                  )}
                </span>

                <span
                  id="close-single-modal-button"
                  className="title white pointer"
                  style={{
                    textAlign: "right",
                    width: "5%",
                    right: "0",
                  }}
                  onClick={() => handleBack()}
                >
                  X
                </span>
              </div>
            </div>
            <PricesComponent
              brand={brand}
              setView={setView}
              consolidated={false}
              proceedToBuy={proceedToBuy}
              setReadMore={setReadMore}
              readMore={readMore}
            />
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="txt-1-mid">Investing amount</span>
              <span className="txt-s mt-1">300 card points ($3.00)</span>
              <span className="txt-s mt-1 neon-color">
                +300 bonus points ($3.00)
              </span>
            </div>
            <div className="single-title">
              <span className="white txt-s" style={{ lineHeight: "1.8" }}>
                Neon Money Club doubles your points if you choose to invest it
                in the Stock Market. We're the only Card that invests in your
                Long Money Mindset.
              </span>
            </div>
            <span className="white txt-s-light">
              Total investing in TOP 500 BRANDS: $6.00
            </span>
            <button
              className="btn-inverse-round txt-1 "
              disabled={true}
              onClick={() => getButtonAction()}
            >
              Confirm
            </button>
            <span className="neon-color txt-s mt-1" onClick={() => {}}>
              Lorem ipsum
            </span>
          </div>
        </SkeletonTheme>
      </div>
    </>
  );
}

const PricesComponent = ({
  opacityValue = 1,
  brand,
  setReadMore,
  readMore,
}) => {
  return (
    <div style={{ opacity: opacityValue }}>
      {brand !== null ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            textAlign: "left",
          }}
          className="pt-1"
        >
          <>
            <span className="white txt-1">
              {brand.local_description + " "}
              {!readMore ? (
                <span
                  className="pointer bold"
                  onClick={() => setReadMore(true)}
                >
                  READ MORE{" "}
                </span>
              ) : null}
            </span>
            {readMore ? (
              <>
                <span className="white txt-1" style={{ paddingTop: "1rem" }}>
                  {brand.description}
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <span
                    className="pointer bold white txt-1"
                    onClick={() => setReadMore(false)}
                  >
                    READ LESS
                  </span>
                </div>
              </>
            ) : null}
          </>
          <div
            style={{
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <span className="white txt-1 bold" style={{ marginTop: "1rem" }}>
              Price today:
            </span>
            <span className="white txt-1 "> ${brand.price.toFixed(2)} </span>
            {brand.in_low ? (
              <>
                <div
                  style={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px #07dbab",
                      borderRadius: "0.5rem",
                      paddingBlock: "0.25rem",
                      paddingInline: "0.25rem",
                      display: "inline-flex",
                      verticalAlign: "middle",
                      width: "85px",
                    }}
                    data-tip={""}
                    data-for="tooltipInLow"
                  >
                    <span className="neon-color txt-s white-space">
                      ON THE LOW{" "}
                    </span>
                    <>
                      <span
                        className="txt-s"
                        style={{
                          color: "white",
                          display: "inline-flex",
                          alignItems: "end",
                        }}
                      >
                        <IoMdInformationCircleOutline
                          style={{ fontSize: 15 }}
                          preserveAspectRatio="none"
                          viewBox="0 0 590 590"
                        />
                      </span>
                      <div style={{ marginRight: "2rem", display: "flex" }}>
                        <ReactTooltip
                          id="tooltipInLow"
                          type="warning"
                          place="right"
                          effect="solid"
                          backgroundColor="gray"
                          multiline={true}
                          delayHide={0}
                          clickable={true}
                          wrapper="div"
                        >
                          {onTheLowText}
                        </ReactTooltip>
                      </div>
                    </>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <span className="white txt-1 bold" style={{ marginTop: "1rem" }}>
              Price 5 yrs ago:
            </span>
            <span className="white txt-1 ">
              {" "}
              ${brand.price_5y_ago.toFixed(2)}{" "}
            </span>
            {brand.price_5y_ago &&
            brand.price_5y_ago < brand.price.toFixed(2) ? (
              <>
                <div
                  style={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                  }}
                >
                  <div
                    style={{
                      border: "solid 1px #07dbab",
                      borderRadius: "0.5rem",
                      paddingBlock: "0.25rem",
                      paddingInline: "0.25rem",
                      display: "inline-flex",
                      verticalAlign: "middle",
                      width: "90px",
                      wordBreak: "keep-all",
                    }}
                    data-tip={""}
                    data-for="tooltip4"
                  >
                    <span className="neon-color txt-s white-space">
                      LONG MONEY{" "}
                    </span>
                    <>
                      <span
                        className="txt-s"
                        style={{
                          color: "white",
                          display: "inline-flex",
                          alignItems: "end",
                        }}
                      >
                        <IoMdInformationCircleOutline
                          style={{ fontSize: 15 }}
                          preserveAspectRatio="none"
                          viewBox="0 0 590 590"
                        />
                      </span>
                      <div style={{ marginRight: "2rem", display: "flex" }}>
                        <ReactTooltip
                          id="tooltip4"
                          type="warning"
                          place="bottom"
                          effect="solid"
                          backgroundColor="gray"
                          multiline={true}
                          delayHide={0.5}
                          clickable={true}
                          wrapper="div"
                        >
                          {longMoneyText}
                        </ReactTooltip>
                      </div>
                    </>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <Skeleton height={50} />
      )}
    </div>
  );
};

const onTheLowText = (
  <span
    className="white"
    style={{
      display: "inline-block",
      width: "10rem",
      height: "auto",
      wordBreak: "break-all",
      textAlign: "justify !important",
      textJustify: "inter-word !important",
    }}
  >
    This is less than the average price per share over the last 200 days.
    <br />
    <br />
    Unlock: It's like a pair of AF1s were $100 for the last 200 days and now you
    can buy it for cheaper.
  </span>
);

const longMoneyText = (
  <span
    className="white"
    style={{
      display: "inline-block",
      width: "10rem",
      height: "auto",
      wordBreak: "break-all",
      textAlign: "justify !important",
      textJustify: "inter-word !important",
    }}
  >
    The long term average return of the stock market is ~10% per year (this
    looks at 30 <br />
    years of data). We're here for that Long Money. <br />
    <br />
    Historical prices at the close of Jan 3, 2017. Close price adjusted for
    splits (Yahoo
    <br /> Finance).
  </span>
);
