import axios from "axios";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";
import { AiOutlineArrowRight } from "react-icons/ai";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isMobilePhone from "validator/lib/isMobilePhone";
import { URIS } from "../Constants/Endpoints";
import { updateshowNews } from "../Store/Login/Reducers";
import ErrorMessage from "../components/ErrorMessage";
import Footer from "../components/Footer";
import { NotAMember } from "../components/NotAMember";
import { RememberMe } from "../components/RememberMe";
import { SecureLinkText } from "../components/SecureLinkText";
import nmcheader from "../images/nmc-white-header.png";

export default function Login() {
  ReactGA.initialize("G-FL88DXW76R");

  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let { user, access_token, refresh_token } = useSelector(
    (state) => state.login
  );
  const [phone, setPhone] = useState("");
  const [error, setError] = useState({ message: "", destiny: "" });
  const [tryCounter, setTryCounter] = useState(0);
  const [pending, setPending] = useState(false);
  let history = useHistory();
  const [resendEnabled, setEnableResend] = useState(false);
  const [checkedState, setCheckedState] = useState(false);

  //get phone number cookie
  const phoneCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("phone_number="))
    ?.split("=")[1];

  //checks for cookie
  useEffect(() => {
    if (phoneCookie) {
      setCheckedState(true);
      setPhone(phoneCookie);
    }
  }, [phoneCookie]);

  //deletes cookie
  const deleteCookie = (name) => {
    document.cookie = name + "=;" + "" + -1;
  };

  //handles behavior for remember be checkbox
  const handleRememberMe = (remember) => {
    if (remember) {
      if (isMobilePhone(phone, ["en-US"])) {
        setError("");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1000 * 36000 * 8760;
        now.setTime(expireTime);
        document.cookie =
          "phone_number=" +
          phone +
          "; expires=" +
          now.toUTCString() +
          '; path=/;"';
      } else {
        setError({
          message: "Please enter a valid U.S. mobile phone number.",
          destiny: "login",
        });
        setCheckedState(false);
        return;
      }
    } else {
      deleteCookie("phone_number");
    }
    setCheckedState(!checkedState);
  };

  useEffect(() => {
    ReactGA.event({
      category: "DASHBOARD_LOGIN",
      action: "VIEWED",
    });
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: "/login",
      title: "Custom Title",
    });
    localStorage.clear();
    // redirect back to dashboard if the user hasn't logged out yet.
    if (access_token && refresh_token && user) {
      history.push("/");
    }
  }, []);

  const handleLogin = () => {
    setPending(false);
    setTimeout(() => setEnableResend(true), 10000);
    if (isMobilePhone(phone, ["en-US"])) {
      if (checkedState) {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1000 * 36000 * 8760;
        now.setTime(expireTime);
        document.cookie =
          "phone_number=" +
          phone +
          "; expires=" +
          now.toUTCString() +
          '; path=/;"';
      }
      setError({});
      waitForResponse();
    } else {
      setError({
        message: "Please enter a valid U.S. mobile phone number.",
        destiny: "login",
      });
    }
  };

  const handleResend = () => {
    ReactGA.event({
      category: "LOGIN",
      action: "RESEND_CLICKED",
    });
    handleLogin();
    setEnableResend(false);
  };

  async function waitForResponse() {
    ReactGA.set({ userId: phone });
    const mode = isMobile ? "mobile" : "web";
    setPending(false);
    setLoading(true);
    let data = {
      phone_number: phone,
      mode: mode,
      origin: "react_login",
    };

    await axios
      .post(process.env.REACT_APP_PROVE_API + URIS.PROVE_AUTH_URL, data, null)
      .then((res) => {
        const message = res.data;

        if (message.status === "True" || message.status === true) {
          return;
        } else if (
          message.status === "False" &&
          message.description === "unsent"
        ) {
          setEnableResend(false);
        } else if (
          message.status === "pending" ||
          message.status === "Pending-User"
        ) {
          setLoading(false);
          setPending(true);
        } else {
          setLoading(false);
          history.push("/404");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleFocus = () => {
    ReactGA.event({
      category: "DASHBOARD_LOGIN",
      action: "PHONE_FIELD_INTERACTED",
    });
  };

  const handleClickNotRegistered = (registerType) => {
    if (registerType === "token") {
      history.push("/86");
    } else {
      window.open("https://joinneon.com", "_blank").focus();
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13 && !resendEnabled && !loading) {
      handleLogin();
      setTimeout(() => setEnableResend(true), 10000);
    }
  };

  const handleInput = (e) => {
    setPhone(e.target.value.replace(/[^\d]/g, ""));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "auto",
      }}
    >
      <div style={{ marginTop: "3rem" }}>
        <a href="https://joinneon.com" target="_blank" rel="noreferrer">
          <img src={nmcheader} width="200px" alt="" />
        </a>
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <span className="white txt-1" style={{ fontFamily: "futura-medium" }}>
            Log in
          </span>
        </div>
        <div>
          <InputMask
            pattern="\d*"
            id="phone-number"
            className="input"
            mask="(999)999-9999"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => handleInput(e)}
            onFocus={() => handleFocus()}
            onKeyDown={(e) => handleEnter(e)}
          />
        </div>
        {pending ? pendingText : null}
        <RememberMe
          checkedState={checkedState}
          handleRememberMe={handleRememberMe}
        />
        <ErrorMessage error={error} destiny={"login"} position={"relative"} />

        <div style={{ marginTop: "2rem" }}>
          {!loading ? (
            <button
              id="login-button"
              data-testid="fwd-btn"
              onClick={() => handleLogin()}
              className="login-button"
              style={{ width: "2.5rem", height: "2.5rem", padding: "unset" }}
            >
              <AiOutlineArrowRight />
            </button>
          ) : null}
        </div>

        <div style={{ paddingTop: "1rem" }}>
          {loading ? (
            <SecureLinkText
              resendEnabled={resendEnabled}
              handleResend={handleResend}
            />
          ) : null}
        </div>

        <NotAMember handleClickNotRegistered={handleClickNotRegistered} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

const pendingText = (
  <div style={{ marginTop: "0.5rem" }}>
    <span
      className="white txt-1"
      style={{ fontFamily: "futura-medium", marginTop: "0.5rem" }}
    >
      Looks like you don’t have a Neon Money Club card.
      <br /> You can apply for one here.
    </span>
  </div>
);
