import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../Store/Modal/Reducers";
import { loginSuccess } from "../Store/Login/Reducers";
import ActionCustomComponent from "../components/ActionCustomComponent/ActionCustomComponent";
import step1 from "../images/HowItWorks/hiw-1.gif";
import step2 from "../images/HowItWorks/hiw-2.gif";
import step3 from "../images/HowItWorks/hiw-3.gif";
import one from "../images/HowItWorks/1.png";
import two from "../images/HowItWorks/2.png";
import three from "../images/HowItWorks/3.png";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";

export default function HowItWorks() {
  const dispatch = useDispatch();
  let { user, access_token, vfp } = useSelector((state) => state.login);
  function handleClose() {
    dispatch(closeModal());
  }

  useEffect(() => {
    if (user.user_educated) {
      return;
    }
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url:
          process.env.REACT_APP_CORE_API + URIS.UPDATE_USER_EDUCATED,
      },
    };
    const data = { phone_number: user.phone_number };
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, authConfig)
      .then((res) => {
        if (res.data.status) {
          let data = {
            user: res.data.user,
            vfp: vfp,
          };
          dispatch(loginSuccess(data));
        }
      })
      .catch((error) => {});
  }, [user]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const dailyAmountText = (
    <>
      Investing{" "}
      <a
        className="white pointer"
        style={{ textDecoration: "underline" }}
        href="https://intelligent.schwab.com/article/dollar-cost-averaging#:~:text=Dollar%20cost%20averaging%20is%20the,as%20well%20as%20your%20costs"
        target="_blank"
        rel="noreferrer"
      >
        regularly
      </a>{" "}
      has shown to be better than trying to time the stock market.
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div
        style={{
          paddingInline: "1rem",
          textAlign: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            How to get started (1, 2, 3)
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
        <div className="column mt-1">
          <ActionCustomComponent
            image={one}
            textTop={"Start with the brands you know"}
            textBtm={"Invest in the brands you love."}
          />
          <div className="row" style={{ margin: "auto", textAlign: "left" }}>
            <div style={{ width: "40px" }}></div>{" "}
            <img
              className="mt-1"
              alt="hiw1"
              src={step1}
              width="70%"
              style={{ border: "solid #929292 1px", padding: "1rem" }}
            />
          </div>
        </div>
        <div className="column mt-1">
          <ActionCustomComponent
            image={two}
            textTop={"Activate $30 on us"}
            textBtm={"We give you credits to get started."}
          />{" "}
          <div className="row" style={{ margin: "auto", textAlign: "left" }}>
            <div style={{ width: "40px" }}></div>{" "}
            <img
              className="mt-1"
              alt="hiw2"
              src={step2}
              width="70%"
              style={{ border: "solid #929292 1px", padding: "1rem" }}
            />
          </div>
        </div>
        <div className="column mt-1">
          <ActionCustomComponent
            image={three}
            textTop={"Choose a daily amount"}
            textBtm={dailyAmountText}
          />{" "}
          <div
            className="row"
            style={{
              margin: "auto",
              textAlign: "left",
            }}
          >
            <div style={{ width: "40px" }}></div>{" "}
            <img
              className="mt-1"
              alt="hiw3"
              src={step3}
              width="70%"
              style={{ border: "solid #929292 1px", padding: "1rem" }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2" style={{ width: "100%" }}>
        <button
          className="btn-inverse-round txt-1"
          style={{
            marginTop: "1rem",
            color: "#d1d1cc",
            width: "100%",
          }}
          onClick={() => handleClose()}
        >
          I'm ready
        </button>
      </div>
      <div>
        <span
          id="button-single-pause"
          className="txt-1 neon-color pb-2 pointer"
          onClick={() => handleClose()}
        >
          Close
        </span>
      </div>
    </div>
  );
}
