import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "./ErrorMessage";
import ReactGA from "react-ga4";
import nmc from "../images/nmc-logo.jpg";
import ihyl from "../images/ihyl.png";
import gotsole from "../images/gotsole-nmc.gif";
import hype from "../images/hypebeast-nmc.gif";
import io from "socket.io-client";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import campaignLogo from "../images/nmc-white-header.png";
import winKicks from "../images/win-kicks.png";
import { setShowForm } from "../Store/Register/Reducers";
import InputMask from "react-input-mask";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

export default function SeeIfYouQualify({
  form,
  changeHandler,
  error,
  setError,
  loading,
  setLoading,
  qualified,
  setQualified,
  accessVerified,
  setAccessVerified,
  setButtonHidden,
  referrer,
  setReferrer,
  validCodes,
}) {
  let location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const [dateSelected, setDateSelected] = useState(false);
  const [birthText, setBirthText] = useState("+18 to enter");
  const [showBirthText, setShowBirthText] = useState(false);
  const [tryCounter, setTryCounter] = useState(0);
  const [accessExpired, setAccessExpired] = useState(false);
  let history = useHistory();
  const codeParam = query.get("code");
  let { showForm } = useSelector((state) => state.register);
  let inCampaign =
    referrer !== "" || (codeParam && validCodes.includes(codeParam))
      ? true
      : false;
  const checkForReferral = async (data) => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { referral_code: data },
        {
          headers: {
            audience_url: process.env.REACT_APP_CORE_API + URIS.VERIFY_REFERRAL,
            "content-type": "application/json",
          },
        }
      )
      .then((message) => {
        changeHandler("access_code", codeParam);
        setReferrer(message.data.referrer_first_name);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (codeParam === "getguap") {
      window.location.replace("https://joinneon.com/getguap");
    } else if (codeParam && codeParam.match(/[a-z]+[1-9]+/g)) {
      checkForReferral(codeParam);
    }
    if (codeParam) {
      changeHandler("access_code", codeParam);
      ReactGA.event({
        category: "ACCESS_CODE",
        action: "CODE_IN_URL",
      });
    }
  }, [codeParam]);

  //initiates socket after access code has been validated
  useEffect(() => {
    if (loading && accessVerified) {
      const mode = isMobile ? "mobile" : "web";
      const socket = io(process.env.REACT_APP_PROVE_API);
      socket.emit("init_private_channel", {
        phone_number: form.phone_number,
        birthdate: form.birthdate,
        mode: mode,
        email: form.email,
        access_code: form.access_code,
        code_id: form.code_id,
        origin: "register",
        referral: referrer !== "" ? "true" : "false",
      });
      socketOn(socket);
      return () => {
        socket.close();
        socket.disconnect();
      };
    } else if (loading && !accessVerified) {
      checkAccessCode();
    }
  }, [loading, accessVerified]);

  useEffect(() => {
    ReactGA.event({
      category: "ACCESS_CODE",
      action: "VIEWED",
    });
    if (showForm) {
      dispatch(setShowForm(false));
    }
  }, []);

  //After we receive success response from socket
  const proveValidated = async (vfp) => {
    let urlRegister =
      "/86/?vfp=" +
      vfp +
      "&phone_number=" +
      form.phone_number +
      "&birthdate=" +
      form.birthdate +
      "&access_code=" +
      form.access_code +
      "&code_id=" +
      form.code_id +
      "&email=" +
      form.email +
      "&referral=" +
      (referrer !== "" ? "true" : "false");
    history.push(urlRegister);
  };

  function birthdateInput(e) {
    let date = "";
    if (e.$M < 9) {
      date = "0";
    }

    date = date + (e.$M + 1) + "/";
    if (e.$D < 10) {
      date = date + "0";
    }
    date = date + e.$D + "/" + e.$y;
    changeHandler("birthdate", date);
    setDateSelected(true);
  }

  //turns soocket ON - waiting for response in socket
  const socketOn = (socket) => {
    try {
      socket.on("prove_validation", function (message) {
        if (message.status.toLowerCase() === "registered") {
          history.push("/login");
        } else if (message.status === "True") {
          if (process.env.REACT_APP_PROVE_API.includes("stg-uat")) {
            proveValidated(message.vfp);
          }
        } else if (message.status === "repeated_email") {
          setError({
            message: "This email is already in use.",
            destiny: "email",
          });
        } else {
          setQualified(false);
        }
        setLoading(false);
      });
    } catch {
      console.error(error);
      setLoading(false);
      let count = tryCounter + 1;
      if (tryCounter < 5) {
        socketOn();
        setTryCounter(count);
      } else {
        history.push("/404");
      }
    }
  };

  //checks access code is valid
  const checkAccessCode = async () => {
    let data = {
      phone_number: form.phone_number,
      code: form.access_code,
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          audience_url: process.env.REACT_APP_CORE_API + URIS.FRONT_GATE,
        },
      })
      .then((message) => {
        if (message.data.status) {
          handleAccessCodeValid(message.data.code_id);
        } else if (message.data.used_up) {
          handleAccessCodeExpired();
        } else {
          handleAccessCodeInvalid();
        }
      })
      .catch((error) => {});
  };

  //if access code valid do this
  const handleAccessCodeValid = (code_id) => {
    changeHandler("code_id", code_id);
    setAccessVerified(true);
  };

  //if access code is expired/used up
  const handleAccessCodeExpired = () => {
    setButtonHidden(false);
    setLoading(false);
    setAccessExpired(true);
  };

  //if access code is NOT valid do this
  const handleAccessCodeInvalid = () => {
    setButtonHidden(false);
    setLoading(false);
    setError({
      message: "Invalid access code.",
      destiny: "code",
    });
  };

  //handles behavior when focus is on date of birth input
  const handleFocus = (target) => {
    if (target === "DOB_") {
      setError({ message: "", destiny: "" });
      setBirthText("mm/dd/yyyy");
      setShowBirthText(true);
    }
  };

  useEffect(() => {
    if (error.message !== "") {
      setShowBirthText(false);
    }
  }, [error]);

  const logoRouter = (code) => {
    switch (code) {
      case "gotsole":
        return gotsole;
      case "hypemoney":
        return hype;
      default:
        return campaignLogo;
    }
  };

  const headerRouter = () => {
    if (referrer) {
      return (
        <>
          <span
            className="white futura-mid"
            style={{ fontSize: 40, fontWeight: "bold" }}
          >
            {referrer.toUpperCase()} JUST <br />
            PUT YOU ON
          </span>
        </>
      );
    } else if (codeParam && codeParam === "metaversal") {
      return <img src={winKicks} width="200px" />;
    } else if (validCodes.includes(codeParam)) {
      return <></>;
    } else {
      return (
        <span
          className="white futura-mid"
          style={{ marginTop: "1rem", fontSize: "0.87rem" }}
        >
          Welcome to Neon Money Club, <br />
          an exclusive community of Owners.
        </span>
      );
    }
  };

  const handlePhoneInput = (e) => {
    changeHandler("phone_number", e.target.value.replace(/[^\d]/g, ""));
  };

  const handleBirthdateInput = (event) => {
    let input = event.target.value;
    input = input.replace(/\D/g, ""); // remove all non-numeric characters
    let formattedInput = "";
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      changeHandler("birthdate", event.target.value);
      return;
    }
    if (input.length < 2) {
      formattedInput = input.replace(/^(\d{0,2})/, "$1");
    } else if (input.length < 4) {
      formattedInput = input.replace(/^(\d{0,2})(\d{0,2})/, "$1/$2");
    } else {
      formattedInput = input.replace(
        /^(\d{0,2})(\d{0,2})(\d{0,4})/,
        "$1/$2/$3"
      );
    }
    changeHandler("birthdate", formattedInput);
  };

  return (
    <div
      className="route-86"
      style={{
        minWidth: "26vw",
        textAlign: "center",
        paddingBottom: "1rem",
        display: "inline",
        paddingTop: "-1rem !important",
      }}
    >
      <a
        href="https://joinneon.com"
        target="_blank"
        rel="noreferrer"
        style={{ alignItems: "center" }}
      >
        <img
          src={codeParam ? logoRouter(codeParam.toLocaleLowerCase()) : nmc}
          width="225px"
        />
      </a>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="route-86"
          style={{
            paddingInline: "1rem",
            minWidth: "26vw",
            textAlign: "center",
            paddingBottom: "1.5rem",
            display: "inline",
          }}
        >
          <div>
            {headerRouter()}

            <div style={{ width: "90%", margin: "auto", marginTop: "1rem" }}>
              <span
                className="white futura-light"
                style={{
                  marginTop: "1rem",
                  textJustify: "inter-word",
                }}
              >
                {codeParam && codeParam === "hypemoney" ? (
                  "Our curated Investment Playlists are like Spotify, but we swapped the music for stocks from your favorite brands. Own them, don’t let them own you."
                ) : referrer || (codeParam && codeParam === "metaversal") ? (
                  <span>
                    Real friends get money together.
                    <br />
                    {referrer.charAt(0).toUpperCase() +
                      referrer.slice(1) +
                      " gets $30, you get $30."}
                  </span>
                ) : validCodes.includes(codeParam) ? (
                  <>
                    <span>Featured in</span>
                  </>
                ) : (
                  "Instead of songs, our curated Investment Playlists have stocks from your favorite brands. Own them, don’t let them own you."
                )}
              </span>
            </div>
          </div>
        </div>
        {validCodes.includes(codeParam) ? (
          <img src={ihyl} width="300px" />
        ) : null}

        {(qualified && !inCampaign) || (showForm && inCampaign) ? (
          <div style={{ marginTop: "1rem" }}>
            <div>
              <InputMask
                pattern="\d*"
                id="phone-number"
                className="input"
                placeholder="Phone number"
                value={form.phone_number.replace(
                  /^(\d{3})(\d{3})(\d+)$/,
                  "($1)$2-$3"
                )}
                mask="(999)999-9999"
                onChange={(e) => handlePhoneInput(e)}
                onFocus={() => handleFocus("PHONE_")}
              />
              <ErrorMessage
                error={error}
                destiny={"phone"}
                position={"relative"}
              />
            </div>
            <div style={{ paddingTop: "0.5rem" }}>
              <input
                style={{
                  overflowX: "visible",
                }}
                id="email"
                className="input-email"
                placeholder="email@address.com"
                value={form.email}
                onChange={(e) => changeHandler("email", e.target.value)}
              />
              <div
                style={{
                  maxWidth: "8rem",
                  borderBottom: "#d1d1cc 1px solid",
                  margin: "auto",
                }}
              />
              <ErrorMessage error={error} destiny={"email"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                paddingTop: "0.5rem",
              }}
            >
              <div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{ dateTableLabel: "Your Birthday" }}
                >
                  <MobileDatePicker
                    className=" -webkit-appearance: none;"
                    disableFuture
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    openTo="year"
                    label="+18 to enter"
                    maxDate={dayjs("01-01-2005")}
                    minDate={dayjs("01-01-1938")}
                    id="birth-date"
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "white",
                        fontSize: 13,
                        textAlign: "center",
                        width: 100,
                        fontFamily: "futura",
                        borderRadius: 0,
                        WebkitAppearance: "none",
                      },
                      "& .MuiFormLabel-root": {
                        color: dateSelected ? "black" : "#989898",
                        fontSize: 15,
                        fontFamily: "futura",
                        textAlign: "center",
                        alignSelf: "center",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderWidth: 0,
                        },
                      },
                    }}
                    renderInput={(params) => <input {...params} />}
                    onAccept={(e) => birthdateInput(e)}
                  />
                </LocalizationProvider>
                <div
                  style={{
                    maxWidth: "8rem",
                    borderBottom: "#d1d1cc 1px solid",
                    margin: "auto",
                  }}
                />
                <div
                  style={{
                    color: dateSelected ? "black" : "white",
                    fontSize: "0.8rem",
                    marginTop: 5,
                  }}
                >
                  Select your birthday
                </div>
                <ErrorMessage
                  error={error}
                  destiny={"birth"}
                  position={"relative"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {showBirthText ? enterBirth : null}
              </div>
            </div>
            {inCampaign ? null : (
              <>
                <div style={{ paddingTop: "0.5rem" }}>
                  <input
                    id="acces-code"
                    className="input"
                    placeholder="Access Code"
                    onFocus={() => handleFocus("CODE_")}
                    value={codeParam ? codeParam : form.acces_code}
                    onChange={(e) =>
                      changeHandler("access_code", e.target.value.toLowerCase())
                    }
                    maxLength={10}
                  />
                  <ErrorMessage
                    error={error}
                    destiny={"code"}
                    position={"relative"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {codeParam ? yourCode : null}
                  {accessExpired ? accessExpiredText : null}
                </div>
              </>
            )}

            <div>
              {!accessVerified ? (
                <div style={{ marginTop: "2rem" }}>
                  <span style={{ color: "white", fontSize: "0.7rem" }}>
                    We’ll send you a link to verify your phone. <br />
                    By clicking the button below, you agree to our{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "white",
                      }}
                      href="https://joinneon.com/terms-of-use"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms
                    </a>
                    .
                  </span>
                </div>
              ) : null}
              {accessExpired && (referrer || codeParam === "metaversal")
                ? accessExpiredText
                : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const enterBirth = (
  <span className="white txt-1" style={{ position: "relative" }}>
    Enter your birthdate.
  </span>
);

const accessExpiredText = (
  <span
    className="white txt-1"
    style={{ position: "relative", marginTop: "1rem" }}
  >
    That access code was all used up. Join queue{" "}
    <a
      href="joinneon.com"
      className="pointer white"
      style={{
        textDecoration: "underline",
      }}
    >
      here.
    </a>
  </span>
);

const yourCode = (
  <span
    className="white txt-1"
    style={{ position: "relative", fontSize: "0.7rem" }}
  >
    Your access code to skip the waitlist
  </span>
);
