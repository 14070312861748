import axios from "axios";
import ReactGA from "react-ga4";

export const handleToken = async (vfp, phone, history, callback) => {
  return new Promise(async (resolve) => {
    const data = {
      phone_number: phone,
      vfp: vfp,
    };
    let resolved = false;
    setTimeout(async () => {
      if (!resolved) {
        ReactGA.event({
          category: "LOGIN",
          action: "7_SEC_LOGIN_ERROR",
        });
        await callback();
        localStorage.clear();
        history.push("/login");
      }
    }, 7000);
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          audience_url: process.env.REACT_APP_CORE_API + "auth/login",
        },
      })
      .then((res) => {
        resolved = true;
        resolve(res.data);
      })
      .catch(async (err) => {
        console.error("AUTH ERROR - GETTING JWT FAILED");
        console.error(err.response);
        ReactGA.event({ category: "LOGIN", action: "GETTING JWT FAILED" });
        await callback();
        localStorage.clear();
        history.push("/login");
      });
  });
};
export const refresh = (refresh_token) => {
  return new Promise((resolve) => {
    const refreshConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${refresh_token}`,
        audience_url: process.env.REACT_APP_CORE_API + "auth/refresh",
      },
    };
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, null, refreshConfig)
      .then((res) => {
        if (res.data.access_token) {
          resolve({ status: true, access_token: res.data.access_token });
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const logOut = async (auth, history) => {
  await axios
    .delete(process.env.REACT_APP_AUTH_PROXY_URL, {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${auth}`,
        audience_url: process.env.REACT_APP_CORE_API + "auth/logout",
      },
    })
    .then((res) => {
      localStorage.clear();
      history.push("/login");
    })
    .catch((err) => {
      localStorage.clear();
      history.push("/login");
    });
};
