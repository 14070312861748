import React, { useState } from "react";
import "./styles.css";

export default function NavBar({ tab, setTab }) {
  const getPositioning = () => {
    if (tab === "learn") {
      return "left";
    } else if (tab === "club") {
      return "center";
    } else if (tab === "wallet" || tab === "invest") {
      return "right";
    }
  };
  return (
    <div className="navbar-sticky txt-1-mid">
      <div
        className="line"
        style={{ display: "flex", justifyContent: getPositioning() }}
      >
        <div className="selector" />
      </div>
      <div className="navbar-row white">
        <div
          className={"navbar-btn" + (tab === "learn" ? " neon-color" : "")}
          onClick={() => setTab("learn")}
        >
          <div
            className={"square" + (tab === "learn" ? " active-navbar-tab" : "")}
          ></div>
          <span id="learn-button"> Learn</span>
        </div>
        <div
          className={"navbar-btn" + (tab === "club" ? " neon-color" : "")}
          onClick={() => setTab("club")}
        >
          <div
            className={"triangle" + (tab === "club" ? " active-triangle" : "")}
          ></div>

          <span id="club-button">Club</span>
        </div>
        <div
          className={
            "navbar-btn" +
            (tab === "invest" || tab === "wallet" ? " neon-color" : "")
          }
          onClick={() => setTab("wallet")}
        >
          <div
            className={
              "circle" +
              (tab === "invest" || tab === "wallet" ? " active-navbar-tab" : "")
            }
          ></div>
          <span id="invest-button">Wallet</span>
        </div>
      </div>
    </div>
  );
}
