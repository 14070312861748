import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPlaylistDetail } from "../Store/Playlists/Reducers";
import { playlists } from "../Constants/Playlists/Playlists";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ToolTip from "./ToolTip";
import { refresh } from "../helpers/Auth";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import { setBrand } from "../Store/Brands/Reducers";
const onTheLowText = (
  <span
    className="white"
    style={{
      textAlign: "justify !important",
      textJustify: "inter-word !important",
    }}
  >
    This is less than the average price per share over the last 200 days.
    <br />
    <br />
    Unlock: It's like a pair of AF1s were $100 for the last 200 days and now you
    can buy it for cheaper.
  </span>
);

export default function Playlist() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const { playlist, playlist_local, playlist_detail } = useSelector(
    (state) => state.playlists
  );
  const wallet = useSelector((state) => state.dashboard.wallet);
  let { user, refresh_token, access_token } = useSelector(
    (state) => state.login
  );
  const [brands, setBrands] = useState(null);
  const [image, setImage] = useState(null);
  const handleClose = () => {
    dispatch(setPlaylistDetail(null));
    dispatch(closeModal());
  };
  const handleBuyEdit = () => {
    refresh(refresh_token);
    if (
      playlist_detail === null ||
      typeof (playlist_detail || wallet) !== "object"
    ) {
      return;
    }
    if (
      user.in_case_of_loss === null &&
      user.neon_userflow_status !== "MEMBER-INVERSTING"
    ) {
      dispatch(openModal({ modal: "investor", origin: "buyEdit" }));
    } else {
      dispatch(openModal({ modal: "buyEdit", origin: "playlist" }));
    }
  };

  const reloadPlaylist = () => {
    setReload(false);
    const data = {
      phone_number: user.phone_number,
      playlist_id: playlist.id,
    };
    if (playlist_detail && typeof playlist_detail !== "object") {
      return;
    } else {
      setLoading(true);
      dispatch(setPlaylistDetail(data));
    }
  };

  const handleOpenBrand = async (brand) => {
    for (let brandLocal of brands) {
      if (brandLocal.symbol === brand.symbol) {
        brand["description"] = brandLocal.description;
      }
    }
    let brandData = { brand: brand };
    brandData.brand.rule_amount = 0;
    brandData.brand.owned = false;
    dispatch(setBrand(brandData));
    dispatch(openModal({ modal: "buyBrand" }));
  };

  useEffect(() => {
    if (playlist_detail !== null && playlist_detail.detail) {
      var cleanBrands = JSON.parse(
        JSON.stringify(playlist_detail.detail.brands)
      );
      const images = playlists.find((x) => x.title === playlist.playlist_name);
      for (let brand of images.brands) {
        for (let brandDetail of cleanBrands) {
          if (
            brand.title.toLowerCase() === brandDetail.brand_name.toLowerCase()
          ) {
            brandDetail["image"] = brand.image;
            brandDetail["description"] = brand.description;
          }
        }
      }
      setBrands(cleanBrands);
      setLoading(false);
    }
  }, [playlist_detail]);

  useEffect(() => {
    setImage(playlist_local.image);
    let cleanBrands = JSON.parse(JSON.stringify(playlist.brands));
    for (let local of playlist_local.brands) {
      for (let brandDetail of cleanBrands) {
        if (local.title === brandDetail.brand_name.toLowerCase()) {
          brandDetail["image"] = local.image;
        }
      }
    }
    //setBrands(cleanBrands);
  }, [playlist_local]);
  const loadingState = useRef(loading);
  const checkReload = () => {
    if (loading) {
      setReload(true);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (loadingState.current) {
        checkReload();
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [loading]);

  const reloadBtn = (
    <div>
      <button
        className="btn-inverse-round txt-1 "
        onClick={() => reloadPlaylist()}
      >
        Reload
      </button>
    </div>
  );

  return (
    <div className="playlist-component">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
          <div style={{ position: "relative" }}>
            {image ? (
              <div className="item">
                <div className="img-wrap">
                  <img
                    src={image}
                    alt="monkey"
                    style={{ width: "auto", height: "auto" }}
                  />
                </div>
              </div>
            ) : (
              <Skeleton height="200px" />
            )}
            <div
              className="close-playlist-btn title pointer"
              onClick={() => handleClose()}
            >
              X
            </div>
            <div
              className="sup-title-mid white"
              style={{ position: "absolute", bottom: "0.5rem", left: "0.5rem" }}
            >
              {playlist.playlist_name}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: "1rem",
            }}
          >
            <span className="white txt-1 ">{playlist_local.long_info}</span>
            <span className="white title-1pt5-mid pt-1">
              Invest as little as $1 a day
            </span>
            {reload ? (
              reloadBtn
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                    className="pt-1"
                  >
                    <span
                      className="white txt-1"
                      style={{ marginRight: "1rem" }}
                    >
                      $
                      {!loading && typeof playlist_detail === "object" ? (
                        playlist_detail.detail.balance.toFixed(2)
                      ) : (
                        <Skeleton width="100px" />
                      )}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                    className="pt-1"
                  >
                    <span
                      className="white txt-s-light"
                      style={{ marginRight: "1rem" }}
                    >
                      Highest $
                      {!loading ? (
                        playlist_detail.detail.highest.toFixed(2)
                      ) : (
                        <Skeleton />
                      )}
                    </span>
                    <span className="txt-1 white">|</span>
                    <span
                      className="white txt-s-light"
                      style={{ marginLeft: "1rem" }}
                    >
                      Lowest $
                      {!loading ? (
                        playlist_detail.detail.lowest.toFixed(2)
                      ) : (
                        <Skeleton />
                      )}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <table className="table white txt-1 ">
                    <thead>
                      <tr className="white txt-1-mid">
                        <th>Brand</th>
                        <th>Traded as</th>
                        <th>Price/share</th>
                      </tr>
                    </thead>
                    <tbody style={{ borderBottom: "none" }}>
                      {brands && brands.length > 0 ? (
                        brands.map((brand, index) => (
                          <tr key={index}>
                            <td>
                              <button
                                key={index}
                                className="related-button"
                                style={{
                                  marginInline: "0.5rem",
                                  backgroundColor: "white",
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  backgroundImage: "url(" + brand.image + ")",
                                  backgroundSize: "cover",
                                }}
                                onClick={() => {
                                  handleOpenBrand(brand);
                                }}
                              />
                            </td>
                            <td className="txt-s">{brand.symbol}</td>
                            <td
                              className="txt-s"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              {brand.price >= 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingRight: "0.5rem",
                                    alignContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "left",
                                      width: 70,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignSelf: "center",
                                      }}
                                    >
                                      ${brand.price.toFixed(2)}
                                    </div>
                                    <div>
                                      {brand.price.toFixed(2) < 100.0 ? (
                                        brand.price.toFixed(2) < 10.0 ? (
                                          <div style={{ width: 12 }}></div>
                                        ) : (
                                          <div style={{ width: 5 }}></div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {brand.in_low ? (
                                      <ToolTip
                                        text={onTheLowText}
                                        place={"bottom"}
                                      />
                                    ) : null}
                                  </div>
                                  {brand.in_low ? (
                                    <span className="neon-color txt-s">
                                      on the low
                                    </span>
                                  ) : null}
                                </div>
                              ) : (
                                <Skeleton width="75%" />
                              )}{" "}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td>
                              <Skeleton count={5} />
                            </td>
                            <td>
                              <Skeleton count={5} />
                            </td>
                            <td>
                              <Skeleton count={5} />
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="sub-card"
                  style={{
                    padding: "0.5rem",
                    marginTop: "0rem",
                    maxWidth: "21rem",
                    textAlign: "left",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    alignSelf: "center",
                    lineHeight: "1rem",
                    textAlign: "center",
                  }}
                >
                  <span className="txt-s white">
                    A $5 investment into this playlist of stocks will buy you $1
                    worth of each stock.
                  </span>
                </div>
                <div>
                  <button
                    id="playlist-button-action"
                    className="btn-inverse-round txt-1 "
                    onClick={() => handleBuyEdit()}
                  >
                    {!playlist_local.owned
                      ? user.neon_userflow_status === "NEON-MEMBER-CREATED" ||
                        user.neon_userflow_status === "MEMBER-LINKED"
                        ? "Start ($30 on us)"
                        : "Own"
                      : playlist_local.is_paused
                      ? "Resume"
                      : "Edit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </SkeletonTheme>
        {reload ? null : (
          <span
            className="white txt-s"
            style={{ textAlign: "left", marginBottom: "1rem" }}
          >
            The logos listed here are registered trademarks of their respective
            owners and not Neon Money Club.
          </span>
        )}
      </div>
    </div>
  );
}
