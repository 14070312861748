import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../Store/Modal/Reducers";

export default function WalletDetails({ user }) {
  const dispatch = useDispatch();
  const [view, setView] = useState("menu");
  function handleClose() {
    dispatch(closeModal());
  }

  const menuView = () => {
    return (
      <>
        <button className="button-menu" onClick={() => setView("acct")}>
          FULL ACCOUNT NUMBER
        </button>
      </>
    );
  };

  const accountNumberView = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
          textAlign: "center",
        }}
      >
        <div>
          <div>
            <span className="txt-s futura-mid white">Account number</span>
          </div>
          <div>
            <input placeholder={user.dw_account_number} className="input" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <div
        className="search-width"
        style={{
          paddingInline: "1rem",
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="search-width"
            style={{
              paddingInline: "1rem",
              textAlign: "left",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingInline: "1rem",
              }}
            >
              <span className="title white " style={{ width: "90%" }}>
                {view === "menu" ? "Wallet Details" : "Full account number"}
              </span>
              <span
                className="title white pointer"
                style={{
                  textAlign: "right",
                  width: "5%",
                  right: "0",
                }}
                onClick={() => handleClose()}
              >
                X
              </span>
            </div>
            <div
              style={{
                marginTop: "3.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {view === "menu" ? menuView() : accountNumberView()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
