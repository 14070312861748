export const benefitsData = [
  {
    title: "Amex",
    content:
      "The Neon Money CLub Cream card is beautiful and secure, backed by the power of American Express.",
  },
  {
    title: "1.5X",
    content: "Earn unlimited 1.5X points for each dollar you spend.",
  },
  {
    title: "DOUBLE UP",
    content:
      "Automatically double all your points when you convert it to own Stocks. It's the only credit card to invest in you.",
  },
];
