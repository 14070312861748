import React from "react";

export const SecureLinkText = ({ resendEnabled, handleResend }) => {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <span className="link white">
        We just texted you a secure link, tap it.
      </span>
      <br />
      {resendEnabled ? (
        <span className="link" onClick={() => handleResend()}>
          Resend Link
        </span>
      ) : (
        <div></div>
      )}
    </div>
  );
};
