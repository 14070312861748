import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: null,
    user_status: null,
    vfp: null,
    access_token: null,
    refresh_token: null,
    showNews: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.vfp = action.payload.vfp;
    },
    setJwt: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    updatePlaid: (state, action) => {
      state.user.registered_in_plaid = action.payload;
    },
    updateCash: (state, action) => {
      state.user.reward_balance = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserStatus: (state, action) => {
      state.user.neon_userflow_status = action.payload;
    },
    setAccess: (state, action) => {
      state.access_token = action.payload;
    },
    clearStore: (state, action) => {
      state = {};
    },
    updateshowNews: (state, action) => {
      state.showNews = action.payload;
    },
    setUserStatus: (state, action) => {
      state.user_status = action.payload;
    },
  },
});
export const {
  loginSuccess,
  setJwt,
  updatePlaid,
  updateCash,
  updateUserStatus,
  updateUser,
  setAccess,
  clearStore,
  updateshowNews,
  setUserStatus,
} = loginSlice.actions;

export default loginSlice.reducer;
