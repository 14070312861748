import React, { useEffect, useState } from "react";
import DynamicAmount from "../components/DynamicAmount";
import { URIS } from "../Constants/Endpoints";
import axios from "axios";
import { refresh } from "../helpers/Auth";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, openModal } from "../Store/Modal/Reducers";
import { TradeConfirmsDisclosures } from "../components/TradeConfirmsDisclosures";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { setBrand } from "../Store/Brands/Reducers";
import { Singles } from "../Constants/Singles";
export default function StockDetail({
  setDetailOpen,
  handleEdit,
  setBrandToSell,
}) {
  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState(false);
  const [tradeAvailable, setTradeAvailable] = useState(false);
  const [fileKey, setFileKey] = useState(null);
  let { brands, brand } = useSelector((state) => state.brands);
  const [currentBrand, setBrands] = useState(brand);
  let { user, access_token, refresh_token } = useSelector(
    (state) => state.login
  );
  const { settings } = useSelector((state) => state.brands);
  const [prevPage, setPrevPage] = useState(brand.page ? brand.page.prev : null);
  const [nextPage, setNextPage] = useState(brand.page ? brand.page.next : null);
  const [currentPage, setCurrentPage] = useState(
    brand.page ? brand.page.current : null
  );
  const [disablePagination, setDisablePagination] = useState(false);
  const [detail, setDetail] = useState();
  function handleClose() {
    dispatch(closeModal());
  }
  let pendingText =
    "*Stock purchases outside normal hours will be awarded on the next available trading day (M-Fri 9:30AM-4PM ET)";
  const handleOpenDetail = (tx) => {
    getDocument(tx.transaction_datetime);
    setDetail(tx);
    setOpenDetail(true);
  };

  const [loading, setLoading] = useState(true);

  const handleSell = () => {
    dispatch(openModal({ modal: "sellStock" }));
  };

  const handlePage = async (stock, page) => {
    if (page <= 0) {
      page = 1;
    }

    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      playlist_id: brand.playlist_id,
      symbol: brand.symbol,
      page: page,
    };

    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.GET_BRAND_TRANSACTIONS,
        },
      })
      .then((response) => {
        if (response.data.status) {
          let res = response.data;
          res["brand_name"] = stock.brand_name;
          res["playlist_name"] = brands.name;
          res["symbol"] = brand.symbol;
          res["playlist_id"] = brand.playlist_id;
          setNextPage(response.data.page.next);
          setPrevPage(response.data.page.prev);
          setCurrentPage(response.data.page.current);
          setBrands(res);
          setDisablePagination(false);
        } else {
          setDisablePagination(false);
          return;
        }
      })
      .catch((error) => {});
  };

  const handleOpenBrand = async () => {
    let stockMutable = JSON.parse(JSON.stringify(brand));
    stockMutable["playlist_id"] = brands ? brands.playlist_id : null;
    setBrandToSell(stockMutable);
    const data = {
      phone_number: user.phone_number,
      user_id: user.id,
      playlist_id: brands ? brands.playlist_id : null,
      symbol: brand.symbol,
    };

    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.GET_BRAND_TRANSACTIONS,
        },
      })
      .then((response) => {
        if (response.data.status) {
          let res = response.data;
          res["brand_name"] = brand.brand_name;
          res["playlist_name"] = brands ? brands.name : null;
          res["symbol"] = brand.symbol;
          res["playlist_id"] = brands ? brands.playlist_id : null;
          res["owned"] = true;
          setLoading(false);
          setBrands(res);
        } else {
          return;
        }
      })
      .catch((error) => {});
  };

  const handleEditSingle = async () => {
    let stockMutable = JSON.parse(JSON.stringify(brand));
    stockMutable["detail"] = null;
    Singles.find((single) => {
      if (single.symbol === stockMutable.symbol) {
        for (let setting of settings) {
          if (single.symbol === setting.symbol) {
            stockMutable["owned"] = true;
            stockMutable["is_paused"] = setting.is_paused;
            stockMutable["is_pending"] = setting.is_pending;
            stockMutable["rule_amount"] = setting.rule_amount;
          }
        }
        stockMutable["image"] = single.image;
      }
    });

    dispatch(setBrand({ brand: stockMutable }));
    dispatch(openModal({ modal: "buyBrand", origin:"transactionByStock" }));
  };

  useEffect(() => {
    handleOpenBrand();
  }, []);

  const getStockStatusText = () => {
    let retunrString = "";
    if (
      detail.status.toLowerCase() === "rejected" ||
      detail.status.toLowerCase() === "cancelled"
    ) {
      return "Error - please hit us un chat";
    }
    retunrString =
      retunrString +
      detail.amount_dollars.toFixed(2) +
      " ( " +
      detail.share_quatity.toFixed(8) +
      " shares @ $";

    if (
      detail.status.toLowerCase() === "rejected" ||
      detail.status.toLowerCase() === "cancelled"
    ) {
      return (retunrString = retunrString + "");
    } else {
      if (detail.status === "FILLED") {
        return (retunrString =
          retunrString +
          (detail.amount_dollars / detail.share_quatity).toFixed(2) +
          " )");
      } else {
        return (retunrString =
          retunrString +
          (detail.amount_dollars / detail.share_quatity).toFixed(2) +
          "* - pending fulfillment )");
      }
    }
  };

  const getDocument = async (date) => {
    const newDate = new Date(date);
    const startMonth =
      newDate.getMonth() + 1 <= 9
        ? "0" + (newDate.getMonth() + 1)
        : newDate.getMonth() + 1;
    const startDay =
      newDate.getDate() + 1 <= 9 ? "0" + newDate.getDate() : newDate.getDate();
    const startDate =
      newDate.getFullYear() + "-" + startMonth + "-" + startDay + "T00:00:00Z";

    const endMonth =
      newDate.getMonth() + 1 <= 9
        ? "0" + (newDate.getMonth() + 1)
        : newDate.getMonth() + 1;
    const endDay =
      newDate.getDate() + 1 <= 9 ? "0" + newDate.getDate() : newDate.getDate();
    const endtDate =
      newDate.getFullYear() + "-" + endMonth + "-" + endDay + "T23:59:59Z";

    const data = {
      phone_number: user.phone_number,
      type: "CONFIRMS",
      from: startDate,
      to: endtDate,
    };

    await refresh(refresh_token);
    axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.GET_ALL_DOCS,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTradeAvailable(true);
          if (response.data.data[0].documents[0])
            setFileKey(response.data.data[0].documents[0].fileKey);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const download = async (fileKey) => {
    await axios
      .post(
        process.env.REACT_APP_AUTH_PROXY_URL,
        { phone_number: user.phone_number, fileKey: fileKey },
        {
          headers: {
            "NEON-AUTHORIZATION": `Bearer ${access_token}`,
            audience_url: process.env.REACT_APP_CORE_API + URIS.GET_DOC,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setTimeout(() => {
            window.open(res.data.url, "_blank");
          });
        }
      })
      .catch((error) => {});
  };

  const detailComponent = detail ? (
    <div className="modal">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "left",
        }}
      >
        <span
          className="txt-1 neon-color pointer"
          onClick={() => setOpenDetail(false)}
        >
          Back
        </span>
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white" style={{ width: "100%" }}>
            {currentBrand.brand_name}
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: "4rem" }}>
          <div>
            <span className="txt-1-mid white ">Purchase date and time</span>
          </div>
          <div>
            <span className="txt-1 white">
              {new Date(detail.transaction_datetime).toLocaleString("us-EN", {
                timeZone: "America/New_York",
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })}
            </span>
          </div>
        </div>
        <div style={{ paddingTop: "3rem" }}>
          <div>
            <span className="txt-1-mid white">
              Amount purchased{detail.share_quatity > 0 ? "" : "*"}
            </span>
          </div>
          <div>
            <span className="txt-1 white">${getStockStatusText()}</span>
          </div>
        </div>
        <div style={{ paddingTop: "3rem" }}>
          <div>
            <span className="txt-1-mid white ">Order reference number</span>
          </div>
          <div>
            <span className="txt-1 white">
              {detail.status.toLowerCase() === ("rejected" || "canceled")
                ? "Error - please hit us un chat"
                : detail.order_id}
            </span>
          </div>
        </div>
        <div style={{ paddingTop: "3rem" }}>
          <div>
            <span className="txt-1-mid white ">Purchased with</span>
          </div>
          <div>
            <span className="txt-1 white">Connected bank account</span>
          </div>
        </div>
        <div style={{ paddingTop: "3rem" }}>
          <div>
            <span className="txt-1-mid white ">Type</span>
          </div>
          <div>
            <span className="txt-1 white">Market Buy</span>
          </div>
        </div>
        <div style={{ paddingTop: "3rem" }}>
          {detail.status === "FILLED" && fileKey ? (
            <div>
              <span
                className="link"
                style={{ fontSize: "1rem", paddingBottom: "2rem" }}
                onClick={() => download(fileKey)}
              >
                View trade confirmation
                <br />
                <br />
              </span>
              <TradeConfirmsDisclosures />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : null;

  const detailList = (
    <div
      className="modal"
      style={{
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        top: "5rem",
        height: "auto",
        paddingBottom: "2rem",
      }}
    >
      <SkeletonTheme color="#19301C" highlightColor="#16E7CF">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              setDetailOpen ? setDetailOpen(false) : handleClose()
            }
          >
            Back
          </span>
          <div
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span className="title white" style={{ width: "100%" }}>
              {loading ? (
                <Skeleton width={"75%"} />
              ) : (
                currentBrand.brand_name.split(" -")[0]
              )}{" "}
              {currentBrand.playlist_name ? (
                <>({currentBrand.playlist_name})</>
              ) : null}
            </span>
            <span
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={() => handleClose()}
            >
              X
            </span>
          </div>
        </div>
        <div>
          <div style={{ paddingTop: "0.5rem" }}>
            <div style={{ overflowX: "auto !important" }}>
              <table
                className="table white"
                style={{
                  overflowX: "auto !important",
                }}
              >
                <thead className="table white txt-1-mid">
                  <tr>
                    <th>Purchase date</th>
                    <th>Today's value</th>
                    <th>Total gains</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ) : (
                    currentBrand &&
                    currentBrand.page &&
                    currentBrand.page.detail.length > 0 &&
                    currentBrand.page.detail.map((tx, index) => (
                      <tr
                        className={
                          currentBrand.page.detail.length - 1 === index
                            ? "pointer txt-s"
                            : "pointer txt-s tr-border"
                        }
                        key={index}
                        onClick={() => {
                          handleOpenDetail(tx);
                        }}
                      >
                        <td>
                          {new Date(tx.transaction_datetime).toLocaleString(
                            "us-EN",
                            {
                              timeZone: "America/New_York",
                              month: "2-digit",
                              day: "2-digit",
                              year: "2-digit",
                            }
                          )}
                        </td>
                        <td>
                          {tx.status.toLowerCase() === "rejected" ||
                          tx.status.toLowerCase() === "cancelled"
                            ? "..."
                            : "$" +
                              tx.today_value.toFixed(2) +
                              (tx.status !== "FILLED" ? "*" : "")}
                        </td>
                        <td>
                          {tx.status === "FILLED" ? (
                            <DynamicAmount
                              amount={tx.since_purchase.toFixed(2)}
                              text={""}
                            />
                          ) : tx.status.toLowerCase() === "rejected" ? (
                            <span className="neon-color">Failed</span>
                          ) : tx.status.toLowerCase() === "cancelled" ? (
                            <span className="neon-color">Cancelled</span>
                          ) : (
                            <span className="neon-color">Pending*</span>
                          )}
                        </td>
                        <td>
                          <MdOutlineKeyboardArrowRight />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="white txt-1-mid"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            {prevPage != null ? (
              <MdOutlineKeyboardArrowLeft
                onClick={() =>
                  !disablePagination
                    ? handlePage(currentBrand, currentPage - 1)
                    : null
                }
                className="pointer"
              />
            ) : null}
            <span style={{ marginInline: "1rem" }}>{currentPage}</span>
            {nextPage != null ? (
              <MdOutlineKeyboardArrowRight
                onClick={() =>
                  !disablePagination
                    ? handlePage(currentBrand, currentPage + 1)
                    : null
                }
                className="pointer"
              />
            ) : null}
          </div>
          <div
            style={{
              paddingTop: "3rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div>
              <button
                className="btn-inverse txt-s"
                style={{
                  width: "100%",
                  borderRadius: "500px",
                  marginTop: "1rem",
                  color: "#d1d1cc",
                  borderColor: "#16e7cf",
                }}
                onClick={() =>
                  currentBrand.playlist_name ? handleEdit() : handleEditSingle()
                }
              >
                Edit Daily Buys for{" "}
                {currentBrand.playlist_name
                  ? currentBrand.playlist_name
                  : currentBrand.brand_name}
              </button>
            </div>
            <div
              style={{
                paddingTop: "2rem",
              }}
            >
              <span
                className="txt-1-mid pointer neon-color"
                onClick={() => handleSell()}
              >
                Sell
              </span>
            </div>
            <div
              style={{
                textAlign: "left",
                marginTop: "2rem",
                maxWidth: "20rem",
              }}
            >
              <span className="txt-s white">{pendingText}</span>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );

  return !openDetail ? detailList : detailComponent;
}
