import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../Store/Modal/Reducers";
import TriangleSpinner from "../components/TriangleSpinner";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";

export default function UnlocksViewer() {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = React.useState(null);
  const [scenario, setScenario] = useState(1);
  const [currentUnlock, setCurrentUnlock] = useState(null);
  const [lastScenario, setLastScenario] = useState(false);
  function handleClose() {
    dispatch(closeModal());
  }
  let { set_viewer } = useSelector((state) => state.unlocks);

  const checkClose = (e, index) => {
    if (e.swipeDirection === "prev") {
    }
  };

  const forceSwipeWithBtn = () => {
    swiper.slideNext();
    setScenario(currentUnlock.scenario + 1);
    if (currentUnlock.scenario + 1 === set_viewer.scenarios.length) {
      setLastScenario(true);
    }
  };
  const handleNextMobile = (e, next) => {
    if (e) {
      if (e.swipeDirection === "next") {
        next();
      } else if (e.swipeDirection === "prev") {
        setLastScenario(false);
        setScenario(currentUnlock.scenario - 1);
      }
    } else {
      forceSwipeWithBtn();
    }
  };

  const handleNext = (e) => {
    const next = () => {
      if (currentUnlock.scenario + 1 === set_viewer.scenarios.length) {
        setLastScenario(true);
      }
      setScenario(currentUnlock.scenario + 1);
    };
    if (isMobile) {
      handleNextMobile(e, next);
    } else {
      next();
    }
  };

  //gets and sets current set available for user
  useEffect(() => {
    if (set_viewer) {
      let getCurrentScenario = set_viewer.scenarios.filter(
        (unlock) => unlock.scenario === scenario
      );
      if (
        getCurrentScenario.length > 0 &&
        getCurrentScenario[0].scenario <= set_viewer.scenarios.length
      ) {
        setCurrentUnlock(getCurrentScenario[0]);
      } else {
        setLastScenario(true);
      }
    }
  }, [scenario, set_viewer]);

  const getButtonText = () => {
    if (lastScenario) {
      return "Close";
    } else {
      return "Got it. Show next one.";
    }
  };
  const getButtonAction = () => {
    if (lastScenario) {
      return dispatch(closeModal());
    } else {
      return handleNext(null);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div
        style={{
          paddingInline: "1rem",
          textAlign: "left",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            {set_viewer.month + " unlocks"}
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          {isMobile ? (
            <Swiper
              pagination={true}
              modules={[Virtual, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              className="mySwiper"
              onTransitionStart={(e) => {
                checkClose(e, currentUnlock.scenario);
              }}
              onSlideChange={(e) => {
                handleNext(e);
              }}
              style={{ top: "10" }}
              onSwiper={(s) => setSwiper(s)}
            >
              {set_viewer.scenarios.map((unlock, index) => (
                <SwiperSlide key={index} virtualIndex={index}>
                  <img
                    src={"/images/SETS/" + unlock.image + ".jpg"}
                    style={{ width: "100%" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : currentUnlock ? (
            <img
              src={"/images/SETS/" + currentUnlock.image + ".jpg"}
              style={{ maxWidth: "100%" }}
            />
          ) : null}
          <button
            className="btn-inverse-round txt-1 "
            onClick={() => getButtonAction()}
          >
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
}
