import React from "react";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";

export default function CompleteLabel({ text }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <CheckCircleOutlineSharpIcon
        style={{ color: text == "Bank updated!" ? "grey" : "green", marginRight: "10px" }}
      />
      <span className="white txt-mid-1">{text}</span>
    </div>
  );
}
