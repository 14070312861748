import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../Store/Modal/Reducers";
import "./CardTransactions.css";
import { URIS } from "../../../Constants/Endpoints";
import axios from "axios";
import { formatDate } from "../../../helpers/Dates/DatesFunctions";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function CardTransactions() {
  const dispatch = useDispatch();
  const { access_token, user } = useSelector((state) => state.login);
  let { modal, origin } = useSelector((state) => state.modal);
  const [searchText, setSearchText] = useState("");
  const [allTransactions, setallTransactions] = useState(null);

  const getCardHistory = async () => {
    const data = {};
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          "NEON-AUTHORIZATION": `Bearer ${access_token}`,
          audience_url: process.env.REACT_APP_CORE_API + URIS.CARD_HISTORY,
        },
      })
      .then((res) => {
        if (res.data.status) {
          let results = res.data.raw_response.statement.transactions;
          setallTransactions(results);
        }
      });
  };

  useEffect(() => {
    getCardHistory();
  }, []);

  function goBack() {
    dispatch(
      openModal({
        modal: ["CardDashboard", "manageCard"].includes(origin)
          ? origin
          : "CardDashboard",
        origin: modal,
      })
    );
  }

  function close() {
    dispatch(closeModal());
  }

  function goToTransaction(transaction) {
    dispatch(
      openModal({
        modal: "CardTransactDetail",
        origin: modal,
        data: transaction,
      })
    );
  }

  return (
    <SkeletonTheme color="#19301C" highlightColor="#16e7cf">
      <div className="playlist-component white">
        <div style={{ padding: "1rem", textAlign: "left" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              textAlign: "left",
            }}
          >
            <span className="txt-1 neon-color pointer" onClick={() => goBack()}>
              Back
            </span>
            <div
              className="futura-bold white row"
              style={{
                maxwidth: "90%",
                textAlign: "left",
                alignItems: "center",
                fontSize: "1.5rem",
              }}
            >
              <span style={{ width: "100%" }}> All transactions</span>
              <span
                id="close-single-modal-button"
                className="title white pointer"
                style={{
                  textAlign: "right",
                  width: "5%",
                  right: "0",
                }}
                onClick={() => goBack()}
              >
                X
              </span>
            </div>
          </div>

          <div
            className="row white txt-1"
            style={{
              alignItems: "center",
              borderStyle: "solid ",
              borderColor: "grey",
              borderWidth: "1px",
              borderRadius: "0.5rem",
              marginTop: "2rem",
              height: 35,
            }}
          >
            <input
              className="search-box"
              value={searchText}
              onFocus={() => setSearchText("")}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ alignSelf: "center", justifySelf: "center" }}
            />
            <div
              style={{ color: "white", marginRight: 10, fontWeight: "bold" }}
            >
              SEARCH
            </div>
          </div>
          <div
            className="txt-1"
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <div
              style={{
                marginTop: "3rem",
                marginBottom: "1rem",
                alignSelf: "flex-start",
              }}
            >
              {allTransactions && allTransactions.length} Most recent
              transactions
            </div>
            {allTransactions ? (
              allTransactions
                .filter((transaction) =>
                  transaction.description
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .map((transaction, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => goToTransaction(transaction)}
                      style={{
                        borderTop: index === 0 ? "1px solid grey" : 0,
                        borderBottom: "1px solid grey",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="column white"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <span>{transaction.description}</span>
                        <span className="txt-s" style={{ textAlign: "left" }}>
                          {formatDate(transaction.localDateTime)}
                        </span>
                      </div>

                      <div
                        className="row"
                        style={{
                          alignItems: "center", // align items vertically
                          justifyContent: "center", // center horizontally
                          height: 50,
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            lineHeight: "25px",
                          }}
                        >
                          {"$" + (transaction.amount * -1).toFixed(2)}
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: 40,
                            transform: "translateY(-4px)", // adjust the arrow's vertical position
                            marginInline: "1rem",
                          }}
                        >
                          &#x203A;
                        </span>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="mt-2 mb-1">
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </div>
            )}
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
