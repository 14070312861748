import React, { useState, useEffect } from "react";
import axios from "axios";
import { URIS } from "../Constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../Store/Login/Reducers";
import { closeModal, openModal } from "../Store/Modal/Reducers";

export default function PlaylistIntro({ setSettingsTab }) {
  const experienceLevels = ["Novice", "Medium", "Expert"];
  const approach = ["Sell all", "Wait it out", "Invest more"];
  const dispatch = useDispatch();
  let { user, vfp, access_token } = useSelector((state) => state.login);
  let { origin, modal } = useSelector((state) => state.modal);
  const [form, setForm] = useState({
    experience: null,
    approach: null,
  });

  const changeHandler = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleCloseFromSettings = () => {
    setSettingsTab("general");
    dispatch(openModal({ modal: origin, origin: modal }));
  };
  //sets buttons selected with current values
  useEffect(() => {
    if (user.in_case_of_loss) {
      let appr = user.in_case_of_loss.replaceAll("_", " ").toLowerCase();
      let exp = user.investing_experience.toLowerCase();
      appr = appr[0].toUpperCase() + appr.slice(1);
      exp = exp[0].toUpperCase() + exp.slice(1);
      setForm({ experience: exp, approach: appr });
    }
  }, [user]);

  //action to do after succesfully updating investor profile
  const handleInvestorUpdateSuccess = () => {
    if (origin === "PointsOnPoints") {
      dispatch(openModal({ modal: "investPoints", origin: modal }));
    } else {
      dispatch(openModal({ modal: origin, origin: modal }));
    }
  };

  //submits form
  const handleSubmit = async () => {
    let data = {
      phone_number: user.phone_number,
      investing_experience: form.experience.toUpperCase().replaceAll(" ", "_"),
      in_case_of_loss: form.approach.toUpperCase().replaceAll(" ", "_"),
    };
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, data, {
        headers: {
          NEON_AUTHORIZATION: `Bearer ${access_token}`,
          audience_url:
            process.env.REACT_APP_CORE_API + URIS.UPDATE_INVESTOR_PROFILE,
        },
      })
      .then(async (res) => {
        if (res.data.status) {
          let data = {
            user: res.data.user,
            vfp: vfp,
          };
          dispatch(loginSuccess(data));
        }
        handleInvestorUpdateSuccess();
      })
      .catch((error) => {});
  };

  const getLabelText = () => {
    if (user.investing_experience) {
      return "Updating your level makes playlists and singles more relevant.";
    } else if (["playlist", "buyBrand"].includes(origin)) {
      return (
        "Before you jump into your first" +
        origin +
        ", tell us a little about you so we can curate your investment " +
        origin +
        "s."
      );
    } else if (origin === "PointsOnPoints") {
      return "Before you jump into investing your points, choose your preferences below.";
    }
  };

  const getButtonText = () => {
    if (user.investing_experience) {
      if (origin === "buyBrand") {
        return "Take me to my single";
      } else if (origin === "playlist") {
        return "Take me to my playlist";
      } else if (origin === "PointsOnPoints") {
        return "Next";
      }
    } else {
      return "Next";
    }
  };

  const getViewTitle = () => {
    if (origin === "PointsOnPoints") {
      return "Invest your points";
    } else if (user.investing_experience) {
      return "Your level";
    } else {
      return "What's your level";
    }
  };

  return (
    <div className="playlist-component white">
      <div style={{ padding: "1rem", textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "left",
          }}
        >
          <span
            className="txt-1 neon-color pointer"
            onClick={() =>
              dispatch(openModal({ modal: origin, origin: modal }))
            }
          >
            Back
          </span>
          <div
            style={{
              maxwidth: "90%",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span className="futura-mid-1pt17 white " style={{ width: "100%" }}>
              {getViewTitle()}{" "}
            </span>
            <span
              id="close-single-modal-button"
              className="title white pointer"
              style={{
                textAlign: "right",
                width: "5%",
                right: "0",
              }}
              onClick={
                origin
                  ? () => handleCloseFromSettings()
                  : () => dispatch(closeModal())
              }
            >
              X
            </span>
          </div>
          <div className="card" style={{ display: "inline-flex" }}>
            <div
              className="investor-profile"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "0.5rem",
                  maxWidth: "25rem",
                  textAlign: "center",
                  marginInline: "0.7rem",
                  fontSize: "0.83rem",
                }}
              >
                <div className="single-title">
                  <span className="white txt-s" style={{ lineHeight: "1.8" }}>
                    {getLabelText()}
                  </span>
                </div>
              </div>

              <div>
                <span className="white txt-s">
                  How much experience do you have investing?
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "1rem",
                    justifyContent: "center",
                  }}
                >
                  {experienceLevels.map((exp, index) => (
                    <button
                      id={"investor-" + exp}
                      key={index}
                      className={
                        form.experience === exp && form.experience != null
                          ? "btn txt-1 button-width"
                          : "btn-inverse txt-1 button-width"
                      }
                      onClick={() => changeHandler("experience", exp)}
                    >
                      {exp}
                    </button>
                  ))}
                </div>
              </div>
              <div style={{ paddingTop: "2rem", width: "92%" }}>
                <span className="white txt-s" style={{ maxWidth: "2rem" }}>
                  What would you do if you lost 20% or more of your investments?
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "1rem",
                    paddingBottom: "3rem",
                    justifyContent: "center",
                  }}
                >
                  {approach.map((appr, index) => (
                    <button
                      id={"investor-" + appr.replace(/ /g, "-")}
                      key={index}
                      className={
                        form.approach === appr && form.approach != null
                          ? "btn txt-1 button-width"
                          : "btn-inverse txt-1 button-width"
                      }
                      style={{ width: "6rem", marginInline: "0.5rem" }}
                      onClick={() => changeHandler("approach", appr)}
                    >
                      {appr}
                    </button>
                  ))}
                </div>
                <button
                  id="button-investor-profile"
                  className="btn-inverse"
                  onClick={handleSubmit}
                  style={{
                    width: "100%",
                    borderRadius: "500px",
                    height: "2.5rem",
                    padding: "unset",
                    marginBottom: "2rem",
                  }}
                >
                  {getButtonText()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
