import React from "react";

export default function ListAction({ brands, handleOpenBrand }) {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ overflowX: "auto !important" }}>
        <table className="table white" style={{ overflowX: "auto !important" }}>
          <thead className="txt-2">
            <tr>
              <th>Stock</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brands &&
              brands.map((brand, index) => (
                <tr
                  key={index}
                  className="pointer txt-2"
                  onClick={() => handleOpenBrand(brand.symbol)}
                >
                  <td
                    className="pointer txt-2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="bold">{brand.symbol}</div>
                    <div>{brand.brand_name}</div>
                  </td>
                  <td className=" sub-title" style={{ textAlign: "right" }}>
                    {">"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
