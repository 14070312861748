import React, { useState, useEffect } from "react";
import StepList from "./StepList";
import {
  getStartedSteps,
  clubBenefitsSteps,
} from "../Constants/ClubSpecs/Steps";
import ReactGA from "react-ga4";

export default function Toggle({ reversed, customStyle }) {
  const [steps, setSteps] = useState("benefits");
  useEffect(() => {
    if (reversed) {
      setSteps("benefits");
    }
  }, [reversed]);

  const handleToggle = (config) => {
    ReactGA.event({
      category: "TOGGLE",
      action: "TOGGLE_CLICKED",
    });
    setSteps(config);
  };

  return (
    <div className="toggle pointer" style={customStyle}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <div
          className={
            steps === "getStarted" ? "toggle-active bold" : "toggle-inactive"
          }
          onClick={() => handleToggle("getStarted")}
        >
          <span>How to get started</span>
        </div>
        <div
          className={
            steps === "benefits" ? "toggle-active bold" : "toggle-inactive"
          }
          onClick={() => handleToggle("benefits")}
        >
          <span>Club benefits</span>
        </div>
        </div>*/}
      <div>
        <span
          className="white futura-mid"
          style={{ fontSize: 20, fontWeight: "bold" }}
        >
          WHAT WE'RE ABOUT
        </span>
      </div>
      <StepList
        steps={steps === "getStarted" ? getStartedSteps : clubBenefitsSteps}
        isStep={steps === "getStarted" ? true : false}
      />
    </div>
  );
}
