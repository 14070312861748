import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../Store/Modal/Reducers";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";
import { setViewer } from "../Store/Unlocks/Reducers";
import check from "../images/buttons/CHECK.png";
export default function Collection() {
  const [pairs, setPairs] = useState(null);
  const [initialSlide, setInitialSlide] = useState(null);
  let { unlocks, completed_sets, next_sets, completed } = useSelector(
    (state) => state.unlocks
  );
  const dispatch = useDispatch();

  const handleOpen = (set) => {
    dispatch(setViewer(set));
    dispatch(
      openModal({
        modal: "unlocks",
        origin: "collection",
      })
    );
  };

  //maps completed sets to rows
  const mapCompleted = async (allSets) => {
    if (completed_sets) {
      let completedSets = JSON.parse(JSON.stringify(completed_sets));
      for (const [index, completedSet] of completedSets.entries()) {
        if (unlocks) {
          if (completedSet.code !== unlocks.code) {
            completedSet["status"] = false;
            completedSet["completed"] = true;
            completedSet["month"] = "Month " + (index + 1);
            allSets.push(completedSet);
          }
        } else {
          completedSet["status"] = false;
          completedSet["completed"] = true;
          completedSet["month"] = "Month " + (index + 1);
          allSets.push(completedSet);
        }
      }
    }
    return allSets;
  };

  //maps next month sets to rows
  const mapNext = (allSets) => {
    if (next_sets) {
      let nextSets = JSON.parse(JSON.stringify(next_sets));
      for (const nextSet of nextSets) {
        if (nextSet.title) {
          nextSet["status"] = false;
          nextSet["month"] = "Month " + (allSets.length + 1).toString();
          allSets.push(nextSet);
        }
      }
    }
  };

  //to get the index of the current set to set initial slide in swiper
  useEffect(() => {
    if (pairs && unlocks) {
      let indexOfCurrent = pairs.findIndex((set) => {
        return set.code === unlocks.code;
      });
      setInitialSlide(indexOfCurrent);
    } else if (pairs) {
      setInitialSlide(pairs.length);
    }
  }, [pairs, unlocks]);

  useEffect(async () => {
    let allSets = [];
    let mappedSets = await mapCompleted(allSets);
    if (unlocks) {
      let currentSet = JSON.parse(JSON.stringify(unlocks));
      currentSet["status"] = true;
      currentSet["month"] = "Month " + (mappedSets.length + 1).toString();
      if (currentSet) mappedSets.push(currentSet);
    }
    mapNext(mappedSets);
    setPairs(mappedSets);
  }, [unlocks, completed_sets]);

  return (
    <div className="card-deck" data-testid="curated" id="curated">
      <div className="card">
        <div className="card-wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <span className={"futura-mid-1pt17 white pointer"}>
                Collections
              </span>
            </div>
          </div>
          <div>
            {financialText()}
            <div
              className="collections-position"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {initialSlide !== null ? (
                <Swiper
                  pagination={true}
                  modules={[Virtual, Pagination]}
                  spaceBetween={1}
                  slidesPerView={2}
                  className="mySwiper"
                  initialSlide={initialSlide}
                >
                  {pairs &&
                    pairs.map((set, index) => (
                      <SwiperSlide key={index} virtualIndex={index}>
                        <div className="single-box-resp">
                          <div
                            className="single-box-resp"
                            key={index}
                            onClick={
                              set.completed ? () => handleOpen(set) : null
                            }
                          >
                            <div
                              className={
                                set.status
                                  ? "unlock-small-active pointer"
                                  : "unlock-small pointer"
                              }
                              style={{
                                paddingTop: "2rem",
                                width: "20% !important",
                              }}
                              key={index}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100px",
                                }}
                              >
                                <span className="white txt-s-light bold">
                                  {set.month}
                                </span>
                                <span className="white txt-s-light mt-1">
                                  {set.title}
                                </span>
                                <div
                                  style={{
                                    bottom: "15px",
                                    width: "90%",
                                    textAlign: "right",
                                    verticalAlign: "middle",
                                    height: "20px",
                                    margin: "auto",
                                    marginTop: "2.5rem",
                                  }}
                                >
                                  {set.status && set.code === unlocks.code ? (
                                    <span
                                      className="txt-s-light neon-color bold"
                                      style={{ textAlign: "right" }}
                                    >
                                      IN PROGRESS
                                    </span>
                                  ) : set.completed ||
                                    (completed && set.code === unlocks.code) ? (
                                    <img
                                      id="check"
                                      src={check}
                                      width="20px"
                                      height="20px"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const financialText = () => {
  return (
    <div className="single-title">
      <span className="white txt-1-mid" style={{ lineHeight: "1.8" }}>
        Financial education, but not boring. 30 seconds or less for each set of
        Unlocks.
      </span>
    </div>
  );
};
