import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import "./styles.css";

export default function ActionCustomComponent({
  image,
  textTop,
  textBtm,
  arrow = false,
}) {
  return (
    <div className="row mt-1">
      <img src={image} width="40px" height="40px" />
      <div
        className="column ml-1"
        style={{ marginTop: "auto", marginBottom: "auto" }}
      >
        <div className="gray row" style={{ alignItems: "center" }}>
          <span className="white txt-3 bold">{textTop}</span>
          {arrow ? <MdOutlineKeyboardArrowRight size={18} /> : null}
        </div>
        <div>
          <span className="white txt-1">{textBtm}</span>
        </div>
      </div>
    </div>
  );
}
