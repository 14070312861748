import React from "react";

export const ConsolidatedText = () => {
  return (
    <span className="txt-1 white pb-2 pt-2" style={{ paddingInline: "1rem" }}>
      "Approximate Share Price" represents the last available market price for
      the stock, as provided by the consolidated market information. Note that
      the actual price you receive for your trade may vary based on the volume,
      timing, and execution venue of your trade, as wel as prevailing market
      conditions. 'Approximate Shares' represents an estimate of the number of
      shares you will receive in your trade based on the approximate share
      price. Note that the actual price you receive for your trade may vary
      based on the volume, timing, and execution venue of your trade, as wel as
      prevailing market conditions.
      <br />
      <br /> For Daily Buys, the Approximate Share Price and Approximate Shares
      shown on the confirmation screen are as of that date and time and do not
      necessarily reflect the price or number of shares for subsequent trades.
      Each recurring order placed via Daily Buys will be placed at the
      then-effective market price.
    </span>
  );
};

export const PromoCreditText = () => {
  return (
    <span className="txt-1 white pb-2">
      Promotional credit will be used to make your first $30 in buys. When the
      credit runs out, you agree that Neon Money Club will debit your connected
      external Bank account one day before your scheduled buy(s) to be made at
      12PM ET the following day, each eligible day. You can edit or pause your
      buy(s) at any time. Updates will start the next tradable day.
      <br />
      <br /> Stock buy(s) made with promotional credit can be sold at any time,
      but cannot be cashed out until after 90 days of becoming a member.
    </span>
  );
};

export const LogosListed = () => {
  return (
    <div style={{ textAlign: "left", marginTop: "0.5rem" }}>
      <span className="txt-s-light white pb-2">
        The logos listed here are registered trademarks of their respective
        owners and not Neon Money, Inc.
      </span>
    </div>
  );
};

export const AllTheThings = () => {
  return (
    <span className="txt-1 neon-color pb-2">See all things you can own</span>
  );
};
