import React, { useState } from "react";
import ErrorMessage from "./ErrorMessage";
import ReactTooltip from "react-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReactGA from "react-ga4";
import { useSelector, useDispatch } from "react-redux";
import { URIS } from "../Constants/Endpoints";
import axios from "axios";
import TriangleSpinner from "./TriangleSpinner";
import { updateUserStatus } from "../Store/Login/Reducers";
import { isMobile } from "react-device-detect";

export default function GetSSN({ handleClose, handleBuy, clearBuyScreen }) {
  const dispatch = useDispatch();
  const [ssn, setSsn] = useState("");
  let { user, access_token } = useSelector((state) => state.login);
  const [inputType, setInputType] = useState("");
  const [error, setError] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const date = new Date(user.date_of_birth.replace("00:00:00 GMT", ""));
  const birthdate =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) +
    "/" +
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
    "/" +
    date.getFullYear();
  const [form, setForm] = useState({
    phone_number: user.phone_number,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    ssn: ssn,
    birthdate: birthdate,
    address: JSON.parse(user.address_raw),
    address_2: user.address_line_2,
    employed: user.employed,
    disclosured: true,
    consented: true,
    verifyToken: "",
  });

  const handleInput = (ssnText) => {
    setSsn(ssnText);
    setForm({ ...form, ssn: ssnText });
  };
  const handleKYC = () => {
    if (ssn.length !== 9) {
      setError({
        destiny: "ssn",
        message: "Please enter a valid SSN.",
      });
    } else {
      ReactGA.event({
        category: "KYC",
        action: "SSN_SUBMITTED",
      });
      setLoading(true);
      setError({});
      validateKyc();
    }
  };

  const validateKyc = async () => {
    clearBuyScreen(false);
    const authConfig = {
      headers: {
        "NEON-AUTHORIZATION": `Bearer ${access_token}`,
        audience_url: process.env.REACT_APP_CORE_API + URIS.KYC_VALIDATION,
      },
    };
    ReactGA.event({
      category: "KYC",
      action: "SUBMITTED",
    });
    await axios
      .post(process.env.REACT_APP_AUTH_PROXY_URL, form, authConfig)
      .then((message) => {
        if (message.data.status) {
          setLoading(false);
          dispatch(updateUserStatus("KYC-PASSED"));
          handleBuy();
          handleClose();
          document.body.style.overflow = "";
          document.body.style.position = "";
          document.documentElement.style.overflow = "";
        }
      })
      .catch((error) => {
        ReactGA.event({
          category: "KYC",
          action: "FAILED",
        });
        console.error(error);
        setLoading(false);
        setFailed(true);
        clearBuyScreen(false);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "black",
      }}
    >
      <div
        style={{
          textAlign: "left",
          width: "350px",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="title white " style={{ width: "95%" }}>
            Promo credit on us
          </span>
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => {
              clearBuyScreen(false);
              handleClose(false);
              document.body.style.overflow = "";
              document.body.style.position = "";
              document.documentElement.style.overflow = "";
            }}
          >
            X
          </span>
        </div>
        <div
          style={{
            marginTop: "1rem",
            alignItems: "left",
          }}
        >
          <span className="txt-3 white " style={{ width: "95%" }}>
            In order to own stocks in the U.S. we have to verify your identity.
          </span>
        </div>
        <div
          style={{
            marginTop: "3.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              className="txt-s futura-mid white"
              style={{ whiteSpace: "pre", paddingTop: 13, paddingLeft: 30 }}
            >
              SSN{" "}
            </span>
            <>
              <span
                style={{
                  color: "white",
                  display: "inline-flex",
                  alignItems: "end",
                }}
                data-tip={
                  <>
                    Q: Why I gotta enter my SSN? <br></br>
                    <br></br>
                    A: In order to own stocks in the U.S. we have to verify your
                    identity. Neon Money Club is a SEC-registered investment
                    advisor.
                  </>
                }
                data-for="tooltip6"
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <IoMdInformationCircleOutline
                    style={{ fontSize: 20, alignSelf: "center" }}
                    preserveAspectRatio="none"
                    viewBox="0 0 550 550"
                  />
                </div>
              </span>
              <div style={{ width: "-1px", display: "flex" }}>
                <ReactTooltip
                  id="tooltip6"
                  type="warning"
                  place="left"
                  effect="solid"
                  backgroundColor="gray"
                  multiline={true}
                  clickable={true}
                  onClick={() => {}}
                  wrapper="div"
                >
                  <span
                    onClick={() => {}}
                    style={{
                      display: "inline-block",
                      width: "10rem",
                      height: "auto",
                    }}
                  >
                    <>
                      Q: Why I gotta enter my SSN? <br></br>
                      <br></br>
                      A: In order to own stocks in the U.S. we have to verify
                      your identity. Neon Money Club is a SEC-registered
                      investment advisor.
                    </>
                  </span>
                </ReactTooltip>
              </div>
            </>
          </div>
          <div>
            <input
              id="ssn"
              className="input"
              value={ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3")}
              maxLength={11}
              onChange={(e) =>
                handleInput(e.target.value.replace(/[^\d]/g, ""))
              }
              onFocus={() => {
                setInputType("text");
              }}
              onBlur={() => {
                setInputType("password");
              }}
              placeholder="XXXXXXXXX"
              type={inputType}
            />
            <ErrorMessage error={error} destiny={"ssn"} />
          </div>
          <div
            style={{
              padding: "0.5rem",
              maxWidth: "25rem",
              textAlign: "center",
              paddingLeft: "1.5rem",
              marginTop: "1rem",
            }}
          >
            <span className="txt-s futura-mid white">
              &#128274; BANK LEVEL PRIVACY AND SECURITY
            </span>
          </div>
          {failed ? (
            <div style={{ marginTop: "1rem" }}>
              <span className="txt-1 white">
                We weren't able to verify your identity. Please hit us on chat
                and we'll figure this out.
              </span>
            </div>
          ) : (
            <>
              <button
                id="button-ssn"
                className="btn-inverse-round txt-1 "
                disabled={loading}
                onClick={() => handleKYC()}
              >
                {!loading ? "Verify me and give me my $30" : "Verifying..."}
              </button>
              <div>{loading ? <TriangleSpinner /> : null}</div>
            </>
          )}
          <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
            <a
              href="https://joinneon.com/faqs"
              target="_blank"
              style={{
                fontSize: "0.83rem",
                color: "white",
                textDecoration: "underline",
              }}
              className="futura-mid pointer"
            >
              frequently asked questions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
