import "./App.css";
import React from "react";
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import Register from "./views/Register";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import ReactGA from "react-ga4";
import BuyPlaylist from "./views/BuyPlaylist";
import FourOFour from "./views/404";
import Check from "./views/Check";
import GetGuap from "./components/GetGuap";
import { useSelector } from "react-redux";
import DashboardAdmin from "./views/DashboardAdmin";
import InvestTab from "./views/InvestTab";

function App() {
  let { user } = useSelector((state) => state.login);
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  return (
    <Router>
      <div className="container">
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/playlist" component={BuyPlaylist} />
          <Route exact path="/86" component={Register} />
          <Route exact path="/check" component={Check} />
          <Route exact path="/404" component={FourOFour} />
          <Route path="/dashboardadmin" component={DashboardAdmin} />
          <Route path="/invest" component={InvestTab} />
          <Redirect to="/login" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
