import React from "react";
import { closeModal } from "../Store/Modal/Reducers";
import { useDispatch } from "react-redux";

export default function NeonVideo() {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <div
      style={{
        marginTop: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1rem",
      }}
    >
      <div
        style={{
          paddingInline: "1rem",
          textAlign: "left",
          width: "350px",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "right",
            alignItems: "center",
          }}
        >
          <span
            className="title white pointer"
            style={{
              textAlign: "right",
              width: "5%",
              right: "0",
            }}
            onClick={() => handleClose()}
          >
            X
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              marginBottom: "-2rem",
            }}
          >
            <iframe
              className="dashboard-vid"
              src="https://player.vimeo.com/video/742065144?h=c26e547116&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ width: "100%", minHeight: "350px", maxHeight: "800px" }}
              title="BUY THE SHOE, OWN THE STOCK"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
