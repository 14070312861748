import React, { useState } from "react";
import "./Dropdown.css";

function Dropdown({ items, setForm, form }) {
  const [selectedItem, setSelectedItem] = useState(form.income_bucket);

  const handleChange = (event) => {
    setSelectedItem(event.target.value);
    setForm({ ...form, income_bucket: event.target.value });
  };

  return (
    <div className="input white">
      <select value={selectedItem ? selectedItem : ""} onChange={handleChange}>
        {items &&
          items.map((item, index) => (
            <option key={index} value={item.value}>
              {item.range}
            </option>
          ))}
      </select>
    </div>
  );
}

export default Dropdown;
