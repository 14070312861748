import React from "react";
import "./WalletTab.css";
import { openModal } from "../../Store/Modal/Reducers";
import { useDispatch, useSelector } from "react-redux";
import invest from "../../images/invest-wallet.gif";
import card from "../../images/card-wallet.gif";

export default function WalletTab({ setTab }) {
  const dispatch = useDispatch();
  let { user_status } = useSelector((state) => state.login);
  console.log(user_status);
  const subWalletsConfig = [
    {
      title: "Invest",
      content: "Your first $30 to invest is on us",
      actionText: "Activate",
      actionFn: () => setTab("invest"),
      labels: ["THE BUY-IN", "LONG MONEY"],
      image: invest,
    },
    {
      title: "Card",
      content: "The first AMEX card that pays you to invest in yourself + $100",
      actionText: "Activate",
      image: card,
      actionFn: () => {
        dispatch(
          openModal({
            modal: user_status ? "CardDashboard" : "cardRegister",
          })
        );
      },
      labels: [],
    },
  ];

  return (
    <>
      <div style={{ margin: "1rem", fontSize: "1.5rem" }}>
        <span className=" white futura-bold ">Long Money Wallet</span>
      </div>
      {subWalletsConfig.map((subWallet, index) => {
        return (
          <div
            className="row mt-1 wallet-sub"
            style={{
              marginInline: "auto",
            }}
            onClick={subWallet.actionFn}
            key={index}
          >
            {/*<img src={kicks} width="100px" height="auto" />*/}
            <div
              className="row"
              style={{
                height: "auto",
                backgroundColor: "black",
                marginInline: "0.5rem",
                verticalAlign: "center",
                borderRadius: "0.5rem",
              }}
            >
              {" "}
              <img
                src={subWallet.image}
                width="100px"
                height="auto"
                style={{ borderRadius: "0.5rem" }}
              />
            </div>

            <div
              className="column"
              style={{ width: "80%", marginLeft: "0.5rem" }}
            >
              <>
                <div className="" style={{ alignItems: "center" }}>
                  <span className="white txt-3 bold">{subWallet.title}</span>
                </div>
                <div>
                  <span className="white txt-1 ">{subWallet.content}</span>
                </div>
                <div className="row mt-1 txt-s">
                  {subWallet.labels.map((label, index) => {
                    return (
                      <div
                        style={{
                          border: "solid 1px #07dbab",
                          borderRadius: "0.5rem",
                          paddingBlock: "0.25rem",
                          paddingInline: "0.25rem",
                          marginRight: "0.25rem",
                        }}
                        key={index}
                      >
                        <span className="neon-color txt-s white-space">
                          {label}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
            <div style={{ position: "relative", width: "20%" }}>
              <span
                className="txt-1-mid neon-color"
                style={{ position: "absolute", right: "10px", bottom: "0" }}
              >
                {subWallet.actionText}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
}
